import React from 'react'
import { Switch, Route } from 'react-router-dom'
import collectionContainer from '../containers/collectionContainer'
import EditCollectionContainer from '../containers/editCollectionContainer'
import ShowCollectionContainer from '../containers/showCollectionContainer'

export default ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}`} component={collectionContainer} />
    <Route path={`${match.path}/new`} component={EditCollectionContainer} />
    <Route path={`${match.path}/edit/:id`} component={EditCollectionContainer} />
    <Route path={`${match.path}/:id`} component={ShowCollectionContainer} />
  </Switch>
)
