import React, { useState, useEffect } from "react";
import { Grid, Divider, Paper, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, ListItemIcon, Typography, Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import DragIndicator from '@mui/icons-material/DragIndicator';
import useTranslation from '../../hooks/useTranslation'

import {
  DndContext, 
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import {useSortable} from '@dnd-kit/sortable'
import {CSS} from '@dnd-kit/utilities'
import ImageThumbRect from './ImageThumbRect'

const style = {
  paper: {
    padding: '20px',
  },
}

const LeftPanelContainer = ({ newSplashImage, toggleSplashImageVisisbility, removeSplashImage, frontpage, selectedPreview, updateStaticContentVisibility, handleListItemClick, sortSplashImages}) => {
  const [homeSplashImages, setHomeSplashImages] = useState([]);
  const { t } = useTranslation()

  useEffect(() => {
    let didCancel = false // to cancel side effects
    if(!didCancel) {
      // state update goes here
      setHomeSplashImages([...frontpage.splash_images])
    }
    return () => { didCancel = true }
  }, [frontpage])

  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { delay: '200' } }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  const handleDragEnd = event => {
    const { active, over } = event
    if (active?.id !== over?.id) {
      const oldIndex = homeSplashImages.findIndex(v => v.id ?  v.id === active?.id : v._id === active?.id )
      const newIndex = homeSplashImages.findIndex(v => v.id ?  v.id === over?.id : v._id === over?.id )
      var reordered = arrayMove(homeSplashImages, oldIndex, newIndex)
      reordered = reordered.map((v, index) => { return {...v, position: index} })
      setHomeSplashImages(reordered)
      sortSplashImages(reordered)
    }
  }

  return (
    <Paper sx={style.paper} elevation={3}>
      <Grid container justifyContent='space-between' alignItems="center">
        <Typography variant="h5">{t('labels.splashImages')}</Typography>
        <Fab size="small" color="primary" aria-label="add" onClick={newSplashImage}>
          <AddIcon />
        </Fab>
      </Grid>
      <Divider style={{ marginTop: '5px', marginBottom: '5px'}}/>
      <List dense={true}>
        <DndContext 
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd} >
          <SortableContext 
            items={homeSplashImages}
            strategy={verticalListSortingStrategy} >
            {homeSplashImages.map(splashImage => (
              <SortableItem 
                key={`item-${splashImage.id ? splashImage.id : splashImage._id}`}
                splashImage={splashImage}
                handleListItemClick={handleListItemClick}
                toggleSplashImageVisisbility={toggleSplashImageVisisbility}
                removeSplashImage={removeSplashImage}
                selectedPreview={selectedPreview}/>
            ))}
          </SortableContext>
        </DndContext>
      </List>
      <Divider style={{ marginTop: '5px', marginBottom: '5px'}}/>
      <Typography variant="h5">{t('labels.staticContentSection')}</Typography>
      <List>
        <ListItem button selected={ selectedPreview.type === 'staticContent' } onClick={(event) => handleListItemClick('', 'staticContent')}>
          <ListItemIcon>
            <ImageThumbRect image={frontpage.image_preview_url ? frontpage.image_preview_url : frontpage.image}/>
          </ListItemIcon>
          <ListItemText primary={t('labels.staticContentSection')} />
          <ListItemSecondaryAction>
            {
              frontpage.show_header ?
              <VisibilityIcon edge="end" onClick={(event)=>updateStaticContentVisibility(false)}/> 
              :
              <VisibilityOffIcon edge="end" onClick={(event)=>updateStaticContentVisibility(true)}/> 
            }
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </Paper>
  )
}

const truncateString = (string = '', maxLength = 15) => 
  string.length > maxLength 
    ? `${string.substring(0, maxLength)}…`
    : string

const SortableItem = (({ splashImage, handleListItemClick, toggleSplashImageVisisbility,removeSplashImage, selectedPreview }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable(
    { id: splashImage.id },
  )
  const { t } = useTranslation()

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <div className={( splashImage._delete ? 'hidden' : '' )}>
    <ListItem
      button
      {...attributes}
      {...listeners}
      ref={setNodeRef}
      style={style}
      selected={splashImage.id ? splashImage.id === selectedPreview.selectedObject.id : splashImage._id === selectedPreview.selectedObject._id}
      onClick={(event) => handleListItemClick(splashImage, 'splashImage')} >
        <ListItemIcon edge="start">
            <DragIndicator {...attributes} {...listeners} edge="end"/>
        </ListItemIcon>
        <ListItemIcon>
          <ImageThumbRect image={splashImage?.image_preview_url ? splashImage?.image_preview_url : splashImage.image}/>
        </ListItemIcon>
        <ListItemText
         primary={ splashImage.name ? truncateString(splashImage.name) : (splashImage?.headline_text ?  truncateString(splashImage.headline_text) : '') } />
        <ListItemSecondaryAction>
          {
            splashImage.visible ?
            <IconButton edge="end" aria-label="delete" onClick={(event)=>toggleSplashImageVisisbility(splashImage, false)}>
              <VisibilityIcon/> 
            </IconButton>
            :
            <IconButton edge="end" aria-label="delete" onClick={(event)=>toggleSplashImageVisisbility(splashImage, true)}>
              <VisibilityOffIcon/>
            </IconButton>
          }
          <IconButton edge="end" onClick={(event)=>{ if (window.confirm(t('labels.areYouSure'))) removeSplashImage(event, splashImage.id)}}>
            <DeleteIcon
              edge="end"
              color="error"/>
           </IconButton>
        </ListItemSecondaryAction>
    </ListItem>
    </div>
  )
})

export default LeftPanelContainer;