import React from 'react'
import PropTypes from 'prop-types'
import { Grid, FormGroup, Button, Box } from '@mui/material'
import FieldGroup from '../fieldGroup'
import EditPageLowerContainer from '../Shared/EditPageLowerContainer'
import EditPageUpperContainer from '../Shared/EditPageHeader'
import useTranslation from '../../hooks/useTranslation'

const FormUserGroup = ({ userGroup, validationErrors, onSaveBtnClicked, onFieldValueChanged }) => {
  const { t } = useTranslation()
  const actionType = userGroup?.id ? 'Edit' : 'New'

 const leftFirstContent = () => (
  <Grid item xs={12} sm={12}>
    <FieldGroup
      id="userGroupName"
      type="text"
      label={t('labels.name')}
      name="name"
      value={userGroup?.name}
      validationState={validationErrors?.hasOwnProperty('name') ? 'error' : null}
      placeholder={t('labels.name')}
      onChange={onFieldValueChanged} />
  </Grid>
 )
 return (
 <div>
    {/* <h1>{ userGroup?.id ? 'Edit' : 'New' } User Group</h1> */}
    <EditPageUpperContainer actionType={actionType} page={t('labels.menu.user_groups')} onSave={onSaveBtnClicked} />
    <hr/>
    <form>
      <EditPageLowerContainer
        leftContent={ [leftFirstContent] }
        actionType={actionType}
        onSave={onSaveBtnClicked}
      />
      {/* <Grid container>
      </Grid> */}
      {/* <Grid container sx={{marginTop: '20px'}}>
        <Grid item xs={12} sm={2}>
          <FormGroup>
            <Button
              type="submit"
              bsStyle="primary"
              variant='contained'
              onClick={onSaveBtnClicked}>
              { userGroup?.hasOwnProperty('id') && userGroup?.id  ? 'Update' : 'Create' }
            </Button>
          </FormGroup>
        </Grid>
      </Grid> */}
    </form>
  </div>
 )
}

FormUserGroup.propTypes = {
  userGroup: PropTypes.object,
  validationErrors: PropTypes.object,
  onSaveBtnClicked: PropTypes.func.isRequired,
  onFieldValueChanged: PropTypes.func.isRequired
}

export default FormUserGroup
