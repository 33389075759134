import React from 'react'
import PropTypes from 'prop-types'
import {bindActionCreators} from 'redux'
import { connect } from 'react-redux'

import * as errorActions from '../actions/errorActions'
import ErrorNotification from '../components/Errors/ErrorNotification';

const ErrorsContainer = props => {
  
  const handleClose = () => {
    props.actions.hideError({ type: 'HIDE_ERROR' })
  }


  return <ErrorNotification errors={props.errors} isOpen={props.isOpen} handleClose={handleClose}/>
}

ErrorsContainer.propTypes = {  
  errors: PropTypes.array.isRequired,
  isOpen: PropTypes.bool
}

const mapStateToProps = (state, ownProps) => {
  return state.errors
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(errorActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorsContainer)
