import React from 'react';
import { Switch, Route } from 'react-router-dom';
import UserGroupContainer from '../containers/UserGroup/userGroupContainer';
import ShowUserGroupContainer from '../containers/UserGroup/showUserGroupContainer';
import FormUserGroupContainer from '../containers/UserGroup/formUserGroupContainer';

export default ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}`} component={UserGroupContainer} />
    <Route exact path={`${match.path}/new`} component={FormUserGroupContainer} />
    <Route exact path={`${match.path}/:id/edit`} component={FormUserGroupContainer} />
    <Route exact path={`${match.path}/:id`} component={ShowUserGroupContainer} />
  </Switch>
)
