import { FrontpageApi } from "../api/frontpageApi"

export const GET_FRONTPAGE = '[Mstore] GET_FRONTPAGE'
export const GET_FRONTPAGE_SUCCESS = '[Mstore] GET_FRONTPAGE_SUCCESS'
export const GET_FRONTPAGE_ERROR = '[Mstore] GET_FRONTPAGE_ERROR'

// Update
export const UPDATE_FRONTPAGE = '[Mstore] UPDATE_FRONTPAGE'
export const UPDATE_FRONTPAGE_SUCCESS = '[Mstore] UPDATE_FRONTPAGE_SUCCESS'
export const UPDATE_FRONTPAGE_ERROR = '[Mstore] UPDATE_FRONTPAGE_ERROR'

export const getFrontpage = () => {
  return (dispatch, getState) => {
    dispatch({
      type: GET_FRONTPAGE
    })

    FrontpageApi.getFrontpage().then(res => {
      dispatch(getFrontpageSuccess(res.data))
    })
  }
}

export const getFrontpageSuccess = frontpage => {
  return {
    type: GET_FRONTPAGE_SUCCESS,
    payload: frontpage
  }
}

export const getFrontpageFailure = error => {
  return {
    type: GET_FRONTPAGE_ERROR,
    payload: error
  }
}

//Update
export const updateFrontpage = (frontpage, splashImages) => {
  return async (dispatch, getState) => {
    dispatch({
      type: UPDATE_FRONTPAGE,
      payload: frontpage
    })

    try {
      await FrontpageApi.updateFrontpage(frontpage)
      dispatch(updateFrontpageSuccess(frontpage))

      const splashImagePromises = splashImages.map(async splashImage => {
        const id = splashImage.get('id')
        if (splashImage.get('_delete') && id > 0) {
          return FrontpageApi.removeSplashImage({ id })
        } else if (id > 0) {
          return FrontpageApi.updateSplashImage(id, splashImage)
        } else {
          return FrontpageApi.createSplashImage(splashImage)
        }
      })

      await Promise.all(splashImagePromises)

      const allSplashImagesRes = await FrontpageApi.getSplashImages()
      const allSplashImages = allSplashImagesRes.data

      return { allSplashImages }
    } catch (error) {
      console.error('Error updating frontpage:', error)
      throw error
    }
  }
}

export const updateFrontpageSuccess = frontpage => {
  return {
    type: UPDATE_FRONTPAGE_SUCCESS,
    payload: frontpage
  }
}