import React from 'react'
import PropTypes from 'prop-types'
import { TableContainer, Table, TableHead, TableBody, TableCell, TableRow, Paper, MenuItem, IconButton, TextField } from '@mui/material';
import { Badge, styled, Tabs, Tab, AppBar } from '@mui/material';
import { withStyles } from '@mui/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import CustomTablePagination from '../Shared/CustomTablePagination';
import useTranslation from '../../hooks/useTranslation'

const CouponsTable = ({ counts, switchTab, tab, coupons, page, changePage, updateCoupon, deleteCoupon }) => {
  const { t } = useTranslation()
  const StyledTableCell = withStyles((theme) => ({
    head: {
      fontWeight: 'bold'
    },
    body: {
      fontSize: 14,
      paddingTop: '1%',
      paddingBottom: '1%'
    },
  }))(TableCell);

  const CounterBadge = styled(Badge)(() => ({
    '& .MuiBadge-badge': {
      right: -12,
      top: 8,
      padding: '0 4px',
    },
  }))



  const tabs = [
    { value: 'total', label: (<CounterBadge overlap="rectangular" badgeContent={counts['total']} color="primary">{t('labels.discount.total')}</CounterBadge>) },
    { value: 'ready', label: (<CounterBadge overlap="rectangular" badgeContent={counts['ready']} color="primary">{t('labels.discount.ready')}</CounterBadge>) },
    { value: 'sent', label: (<CounterBadge overlap="rectangular" badgeContent={counts['sent']} color="primary">{t('labels.discount.sent')}</CounterBadge>) },
    { value: 'used', label: (<CounterBadge overlap="rectangular" badgeContent={counts['used']} color="primary">{t('labels.discount.used')}</CounterBadge>) },
    { value: 'expired', label: (<CounterBadge overlap="rectangular" badgeContent={counts['expired']} color="primary">{t('labels.discount.expired')}</CounterBadge>) },
  ]

  return (
    <div>
      <AppBar position="static" color='default'>
        <Tabs
          value={tab}
          onChange={switchTab}
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            '& .MuiButtonBase-root': {
              padding: '10px 25px'
            }
          }} >
          {tabs.map((_tab) => (<Tab key={_tab.value} value={_tab.value} label={_tab.label} />))}
        </Tabs>
      </AppBar>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell>#</StyledTableCell>
              <StyledTableCell>{t('labels.discount.code')}</StyledTableCell>
              <StyledTableCell>{t('labels.discount.status')}</StyledTableCell>
              <StyledTableCell>{t('labels.discount.delete')}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {coupons.map(coupon => (
              <TableRow key={coupon.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>{coupon.id}</TableCell>
                <StyledTableCell>{coupon.code}</StyledTableCell>
                <StyledTableCell>
                  <TextField
                    value={coupon.status}
                    variant='outlined'
                    label="Status"
                    size='small'
                    select
                    fullWidth
                    onChange={event => updateCoupon(coupon.id, event.target.value)} >
                    <MenuItem value='ready'>Ready</MenuItem>
                    <MenuItem value='sent'>Sent</MenuItem>
                    <MenuItem value='used'>Used</MenuItem>
                    <MenuItem value='expired'>Expired</MenuItem>
                  </TextField>
                </StyledTableCell>
                <StyledTableCell>
                  <IconButton
                    variant='contained'
                    color='error'
                    aria-label="delete"
                    disabled={counts.total < 2}
                    size="medium"
                    onClick={() => { if (window.confirm(t('labels.are_you_sure'))) deleteCoupon(coupon.id) }} >
                    <DeleteIcon />
                  </IconButton>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <CustomTablePagination
          count={counts[tab]}
          page_no={page}
          onPageChange={changePage}
          rowsPerPage={global.constants.perPage.coupons} />
      </TableContainer>
    </div>
  )
}

CouponsTable.propTypes = {
  coupons: PropTypes.array.isRequired,
  counts: PropTypes.object.isRequired,
  tab: PropTypes.string.isRequired,
  switchTab: PropTypes.func.isRequired,
  changePage: PropTypes.func.isRequired,
  updateCoupon: PropTypes.func.isRequired,
  deleteCoupon: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired
}

export default CouponsTable
