import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography, Divider, Button } from '@mui/material'
import BackButton from './BackButton'
import useTranslation from '../../hooks/useTranslation'

const EditPageUpperContainer = ( { actionType, page, onSave, extraActions, allExtraActions }) => {
  const { t } = useTranslation()

  return (
    <>
      <Grid container alignItems={'center'} justifyContent={'space-between'}>
        <Grid item xs={8} md={extraActions ? 6 : 8}>
          <Typography gutterBottom variant="h3" component="h2">
            { t(actionType) + ' ' + page }
          </Typography>
        </Grid>
        <Grid container item xs={4} md={extraActions ? 6 : 4} justifyContent={'end'} spacing={1}>
          {extraActions && actionType === 'Edit'  && <Grid item>
            {extraActions()}
          </Grid>
          }
          <Grid item>
            <BackButton/>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              onClick={onSave}>
              { actionType === 'New' ? t('labels.create') : t('labels.save') }
            </Button>
          </Grid>
          {allExtraActions  && <Grid item>
            {allExtraActions()}
          </Grid>
          }
        </Grid>
      </Grid>
      <Divider width={'100%'} sx={{ marginBottom: '20px', marginTop: '10px' }}/>
    </>
  )
}

EditPageUpperContainer.propTypes = {
  actionType: PropTypes.string.isRequired,
  page: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  extraActions: PropTypes.func
}

export default EditPageUpperContainer
