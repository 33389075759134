import { combineReducers } from 'redux'
import store from './store'
import frontpage from './frontpage'
import categories from './categories'
import currencies from './currencies'
import discounts from './discounts'
import errors from './errors'
import products from './products'
import autocomplete from './autocomplete'
import pages from './pages'
import orders from './orders'
import teams from './teams'
import users from './users'
import collections from './collections'
import userProfile from './userProfile'
import menus from './menus'
import notifications from './notification'
import userGroups from './userGroups'

const rootReducer = combineReducers({
  store,
  frontpage,
  categories,
  collections,
  currencies,
  discounts,
  errors,
  products,
  autocomplete,
  pages,
  orders,
  teams,
  users,
  menus,
  notifications,
  user: userProfile,
  userGroups
})

export default rootReducer
