import React, { Component } from 'react'
// import PropTypes from 'prop-types'

import { AuthApi } from '../api/authApi'
import AuthStorage from '../services/authStorage'
const Auth = new AuthStorage()

class AuthContainer extends Component {
  constructor(props) {
    super(props)
    this.state = { status: 'loading' }
  }
  componentWillMount() {
    // Check if store is already authenticated
    // 1. token exists in localStorage
    // 2. token is not expired
  }
  componentDidMount() {
    // Authenticate store
    try {
      // 1. Get access token
      const access = this.props.match.params.access
      const metaStore = this.props.match.params.meta_store
      const storeId = this.props.match.params.store_id
      if (metaStore) {
        AuthApi.loginMetaStore({ access, meta_store: true, store_id: storeId }).then(res => {
          // 4. Save store data to localStorage like token for further API calls and store info to display
          const { token, store, user } = res.data
          Auth.setToken(token)
          Auth.setStore(store)
          Auth.setUser(user)
          // 5. If everything goes fine then redirect to / path
          this.props.history.push('/admin/store')
        }).catch(err => {
          console.log('Error inside TRY:', err)
          this.setState({ status: 'error' })
          return
        })
      }
      else {
        // 2. Call Mstore auth API to register or fetch store with token
        AuthApi.login({access}).then(res => {
          // 4. Save store data to localStorage like token for further API calls and store info to display
          const { token, store, user } = res.data
          Auth.setToken(token)
          Auth.setStore(store)
          Auth.setUser(user)
          // 5. If everything goes fine then redirect to / path
          this.props.history.push('/admin/store')
        }).catch(err => {
          console.log('Error inside TRY:', err)
          this.setState({ status: 'error' })
          return
        })
      }
    } catch (error) {
      console.log('Error try: ', error)

      this.setState({ status: 'error' })
    }
  }

  render() {
    const ErrorPage = (
      <div>
        <p>Authentication failed. Invalid access token!</p>
      </div>
    )
    const Loading = (
      <div>
        <p>Authenticating...</p>
      </div>
    )

    return this.state.status === 'loading' ? Loading : ErrorPage
  }
}

export default AuthContainer
