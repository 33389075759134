import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import EditEmailCampaign from "../components/EmailCampaign/editEmailCampaign";
import { EmailCampaignApi } from "../api/emailCampaignApi";
import { useDispatch } from 'react-redux'
import { setSuccessNotification } from '../actions/notificationAction'
import useTranslation from '../hooks/useTranslation'

const EditEmailCampaignContainer = (props) => {
  const [saving, setSaving] = useState(false)
  const [formErrors, setFormErrors] = useState({})
  const [emailCampaign, setEmailCampaign] = useState({
    id: 0,
    name: '',
    subject: '',
    body: '',
    to: '',
    email_sent: false
  })
  const dispatch = useDispatch()
  const history = useHistory();
  const params = useParams();
  const { t } = useTranslation()

  useEffect(() => {
    fetchEmailCampaign();
  }, [])

  const fetchEmailCampaign = async () => {
    const emailCampaignId = params.hasOwnProperty('id') ? + params.id : 0
    if (emailCampaignId > 0) {
      const response = await EmailCampaignApi.getEmailCampaign(emailCampaignId)
      if ( response.status === 200 ) {
        setEmailCampaign(response.data)
      }
    }
  }

  const saveEmailCampaign = async (event, updatedEmailCampaign) => {
    if (event) event.preventDefault();
    setFormErrors({});
    if (formIsValid()) {
      setSaving(true);
      const campaignToSave = updatedEmailCampaign || emailCampaign;
      if (campaignToSave.id > 0) {
        const response = await EmailCampaignApi.updateEmailCampaign(campaignToSave.id, campaignToSave);
        setEmailCampaign(response.data)
        dispatch(setSuccessNotification({ message: t('response_messages.email_campaign_updated_successfully')}));
      } else {
        const response = await EmailCampaignApi.createEmailCampaign(campaignToSave);
        setEmailCampaign(response.data)
        dispatch(setSuccessNotification({ message: t('response_messages.email_campaign_created_successfully')}));
        history.push(`/admin/email-campaigns/edit/${response.data.id}`);
      }
    }
  };

  const duplicateEmailCampaign = async (originalEmailCampaign) => {
    setSaving(true);
    const newEmailCampaign = { ...originalEmailCampaign, email_sent: false };
    try {
      const response = await EmailCampaignApi.createEmailCampaign(newEmailCampaign);
      dispatch(setSuccessNotification({ message: t('response_messages.email_campaign_duplicated_successfully')}));
      history.push(`/admin/email-campaigns/edit/${response.data.id}`);
    } catch (error) {
      console.error("Error duplicating email campaign:", error);
    } finally {
      setSaving(false);
    }
  };
  
  const formIsValid = () => {
    let formValid = true
    if (emailCampaign.subject === '') {
      setFormErrors({...formErrors, name: "can't be blank"})
      formValid = false
    }
    return formValid
  }


  return (
    <EditEmailCampaign
      emailCampaign={emailCampaign}
      validationErrors={formErrors}
      isSaving={saving}
      onSave={saveEmailCampaign}
      handleEmailCampaign={setEmailCampaign}
      duplicateEmailCampaign={duplicateEmailCampaign}
    />
  )
}

export default EditEmailCampaignContainer
