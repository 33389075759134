import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {bindActionCreators} from 'redux'
import * as menuActions from '../../actions/menuActions';
import { getMenus } from "../../actions/menuActions";
import { useDispatch, connect } from "react-redux";
import ListingPage from '../../components/Shared/ListingPage';
import { ListTable } from '../../components/Shared/ListingTable';
import Menu from '../../components/Menu/menu';
import useTranslation from '../../hooks/useTranslation'

const MenuContainer = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation()
  const menuTypes = { "side_menu": "Side Menu", "footer_right": "Footer(right)", "footer_left": "Footer(left)" };
  const tableHeading = [
    '#', 
    t('labels.menus.menus'), 
    'Type', 
    t('labels.menus.displayName'), 
    t('labels.menus.enabled')
  ];
  const tableContent = props.menus.data.map((menu, index) => (
    <Menu key={menu.id} {...menu} menuTypes={menuTypes} index={index + 1}/>
  ));

  useEffect(() => {
    dispatch(getMenus())
  }, [])

  return (
    <ListingPage title={t('labels.menus.menus')} table={(<ListTable tableHeadings={tableHeading} tableContent={tableContent} />)}/>
    )
}

MenuContainer.propTypes = {  
  menus: PropTypes.object.isRequired
}

const mapStateToProps = (state, ownProps) => {
  return { menus: state.menus }
}

const mapDispatchToProps = dispatch => {
  return { actions: bindActionCreators(menuActions, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuContainer)