import React from 'react';
import { Link } from 'react-router-dom';
import {List, ListItem, ListItemText, ListItemAvatar, ListItemSecondaryAction, IconButton} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ImageThumbnail from '../Shared/ImageThumbnail';

export default function CollectionProductImageList({ products, addProductToCollection }) {

  const styles = {
    root: {
      width: '100%',
    },
    large: {
      borderRadius: 0
    },
    margin:{
      marginLeft: '10px',
    }
  };

  return (
    <List sx={styles.root}>
      { products?.map((product) => (
        <ListItem component={Link} to={`/admin/products/edit/${product.id}`} divider={true} key={product.id}>
          <ListItemAvatar>
            <ImageThumbnail image={product?.images[0]?.image} height='50px' width='50px' />
          </ListItemAvatar>
          <ListItemText sx={styles.margin} primary={product.name} />
          <ListItemSecondaryAction onClick={() => {if(window.confirm('Are you sure?')) addProductToCollection(product.id, 'remove')}}>
            <IconButton color="error" component='span' edge="end" aria-label="delete">
              <DeleteIcon/>
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  );
}
