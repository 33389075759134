import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Grid, Typography } from '@mui/material'
import { ProductApi } from '../api/productApi'
import { setSuccessNotification } from '../actions/notificationAction'
import { SortableProductList } from '../shared-components'
import useTranslation from '../hooks/useTranslation'

const ProductPositioningContainer = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [products, setProducts] = useState([])

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await ProductApi.getProducts({ all: true, sort_by: 'Position ascending' })
        if (response.status === 200) {
          setProducts(response.data.data)
        }
      } catch (error) {
        console.error('Error fetching products:', error)
      }
    }
    fetchProducts()
  }, [])

  const savePositions = async () => {
    const updatedPositions = products.map((product, index) => ({
      id: product.id,
      position: index + 1,
    }))

    try {
      const response = await ProductApi.updateProductPositions(updatedPositions)
      if (response.status === 200) {
        dispatch(setSuccessNotification({ message: t('response_messages.positions_updated_successfully') }))
      }
    } catch (error) {
      console.error('Error updating product positions:', error)
    }
  }

  const handleMoveToPosition = (product, position) => {
    const pos = parseInt(position, 10)
    if (product && !isNaN(pos) && pos > 0 && pos <= products.length) {
      const newProducts = products.filter((p) => p.id !== product.id)
      newProducts.splice(pos - 1, 0, product)
      setProducts(newProducts)
    }
  }

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} md={6} lg={6} xl={7}>
        <Typography variant="h3" component="div">
          {t('labels.reorder_products')}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {t('labels.reorder_helper')}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6} lg={6} xl={5} container justifyContent="flex-end" alignItems="center">
        <Button
          sx={{ marginRight: '2%', borderColor: 'lightgrey', float: 'right' }}
          variant="outlined"
          href='/admin/products'
        >
          {t('labels.back_to_products')}
        </Button>
        <Button variant="contained" color="primary" onClick={savePositions}>
          {t('labels.save')}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <SortableProductList
          products={products}
          setProducts={setProducts}
          handleMoveToPosition={handleMoveToPosition}
        />
      </Grid>
      <Grid item xs={12} container justifyContent="space-between" alignItems="center">
        <Button
          sx={{ marginRight: '2%', borderColor: 'lightgrey', float: 'right' }}
          variant="outlined"
          href='/admin/products'
        >
          {t('labels.back_to_products')}
        </Button>
        <Button variant="contained" color="primary" onClick={savePositions}>
          {t('labels.save')}
        </Button>
      </Grid>
    </Grid>
  )
}

export default ProductPositioningContainer