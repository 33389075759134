import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { TableRow, TableCell, IconButton} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import ViewInStoreButton from '../Shared/ViewInStoreButton';

const Page = props => (
  <TableRow key={props.id}>
    <TableCell component="th" scope="row"><Link to={`/admin/pages/${props.id}/edit`}>{props.index}</Link></TableCell>
      <TableCell component="th" scope="row"><Link to={`/admin/pages/${props.id}/edit`}> {props.name}</Link></TableCell>
      <TableCell component="th" scope="row">{ props.visible && '✔'}</TableCell>
      <TableCell align="left">
        <IconButton color="error" size="small" onClick={() => { if (window.confirm('Are you sure?')) props.deletePage() } }>
          <DeleteIcon />
        </IconButton>
        <ViewInStoreButton path={`/pages/${props.slug}`} />
      </TableCell>
  </TableRow>
)

Page.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
  image: PropTypes.string,
  content: PropTypes.string,
  deletePage: PropTypes.func.isRequired
}

export default Page
