import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'

const MenuPreview = ({ links, menu }) => {

  const sideMenu = (menu.type == 'side_menu')
  const store = JSON.parse(localStorage.getItem('store'))

  const styles = {
    menuContainer: {
      padding: '15px',
      width: '100%',
      height: '100%',
      background: (sideMenu ? '#fff' : store.footer_bg_color),
      borderRadius: '1px',
      boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
      // display: 'inline-block',
      color: (sideMenu ? '#000' : store.footer_text_color)
    }
  }
  return (
    <div>
      {sideMenu ? (<SideMenuPreview menu={menu} links={links} styles={styles} />) : (<FooterMenuPreview menu={menu} links={links} styles={styles} />)}
    </div>
  )
}

const SideMenuPreview = ({ menu, links, styles }) => {
  return (
    <Grid item xs={12}>
      <div sx={styles.menuContainer} >
        <h2>{'Webstore Title'}</h2>
        <br/>
        <div sx={styles.menuDiv} >
          {menu.show_name &&(<><h3>{menu.show_name ? menu.name : 'Side Menu'}</h3><br/></>)}
          <Grid item xs={12} md={8}>
            {links.map((link, index) => {
              return (<React.Fragment key={index}><h3 sx={{ fontWeight: 'normal' }}>{link.name}</h3><hr/></React.Fragment>)
            })}
          </Grid>
        </div>
      </div>
    </Grid>
  )
}

const FooterMenuPreview = ({ menu, links, styles }) => {
  let i = 0, j = Math.ceil((links.length/2)), rows_left = [], rows_right = [];
  if (links.length) {
    while(i < Math.ceil(links.length/2)) {
      rows_left.push(<div key={i}>{links[i].name}</div>);
      i++;
    }
    while(j < links.length) {
      rows_right.push(<div key={j}>{links[j].name}</div>);
      j++;
    }
  }

  const FooterRow = ({ isVertical }) => (
    <Grid container>
      <Grid item xs={isVertical ? 12 : 3}>
        {rows_left}
      </Grid>
      <Grid item xs={isVertical ? 12 : 3}>
        {rows_right}
      </Grid>
    </Grid>
  );

  // For vertical layout
  let actualFooterLeftRow = <FooterRow isVertical={true} />;

  // For horizontal layout
  let actualFooterRightRow = <FooterRow isVertical={false} />;
  
  let DummyFooterRow = ({ isVertical }) =>  (
    <Grid container item={true} xs={12}><br/>
      <Grid item xs={isVertical ? 12 : 3}>
        <div>link1</div>
        <div>link2</div>
      </Grid>
      <Grid item xs={isVertical ? 12 : 3}>
        <div>link3</div>
        <div>link4</div>
      </Grid>
    </Grid>
  )
  return (
    <Grid container item={true} xs={12} sx={styles.menuContainer}>
      {menu.type == 'footer_right' ? 
        <React.Fragment key={menu.type}>
          <Grid container item xs={6}>
            <Grid item xs={12} style={{ marginBottom: '20px' }}>{'Footer Left'}</Grid>
            <Grid item xs={12}><DummyFooterRow isVertical={true}/></Grid>
          </Grid>
          <Grid container item xs={6}>
            <Grid item xs={12}>{menu.show_name ? menu.name : 'Footer Right'}</Grid>
            <Grid item xs={12}>{actualFooterRightRow}</Grid>
          </Grid>
        </React.Fragment>
        :
        <React.Fragment key={menu.type}>
          <Grid container item xs={6}>
            <Grid item xs={12} style={{ marginBottom: '20px' }}>
              {menu.show_name ? menu.name : 'Footer Left'}
            </Grid>
            <Grid item xs={12}>{actualFooterLeftRow}</Grid>
          </Grid>
          <Grid container item xs={6}>
            <Grid item xs={12}>{'Footer Right'}</Grid>
            <Grid item xs={12}><DummyFooterRow isVertical={false}/></Grid>
          </Grid>
        </React.Fragment>}
    </Grid>
  )
}

MenuPreview.propTypes = {
  links: PropTypes.array.isRequired,
  menu: PropTypes.object.isRequired
}

SideMenuPreview.propTypes = {
  links: PropTypes.array.isRequired,
  menu: PropTypes.object.isRequired,
  styles: PropTypes.object.isRequired
}

FooterMenuPreview.propTypes = {
  links: PropTypes.array.isRequired,
  menu: PropTypes.object.isRequired,
  styles: PropTypes.object.isRequired
}

export default MenuPreview
