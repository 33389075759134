import { PageApi } from "../api/pageApi";

// Create
export const CREATE_PAGE = '[Mstore] CREATE_PAGE'
export const CREATE_PAGE_SUCCESS = '[Mstore] CREATE_PAGE_SUCCESS'
export const CREATE_PAGE_ERROR = '[Mstore] CREATE_PAGE_ERROR'


// Read
export const GET_PAGES = '[Mstore] GET_PAGES'
export const GET_PAGES_SUCCESS = '[Mstore] GET_PAGES_SUCCESS'
export const GET_PAGES_ERROR = '[Mstore] GET_PAGES_ERROR'


export const GET_PAGE = '[Mstore] GET_PAGE'
export const GET_PAGE_SUCCESS = '[Mstore] GET_PAGE_SUCCESS'
export const GET_PAGE_ERROR = '[Mstore] GET_PAGE_ERROR'

// Update
export const UPDATE_PAGE = '[Mstore] UPDATE_PAGE'
export const UPDATE_PAGE_SUCCESS = '[Mstore] UPDATE_PAGE_SUCCESS'
export const UPDATE_PAGE_ERROR = '[Mstore] UPDATE_PAGE_ERROR'

// Delete
export const DELETE_PAGE = '[Mstore] DELETE_PAGE'
export const DELETE_PAGE_SUCCESS = '[Mstore] DELETE_PAGE_SUCCESS'
export const DELETE_PAGE_ERROR = '[Mstore] DELETE_PAGE_ERROR'

// Create
export const createPage = (page) => {
  return (dispatch, getState) => {
    dispatch({
      type: CREATE_PAGE
    })

    PageApi.createPage(page).then(res => {
      dispatch(createPageSuccess(res.data))
    })
  }
}

export const createPageSuccess = (page) => {
  return {
    type: CREATE_PAGE_SUCCESS,
    payload: page
  }
}

export const createPageFailure = (error) => {
  return {
    type: CREATE_PAGE_ERROR,
    payload: error
  }
}

//Read
export const getPages = (page_no, per_page) => {
  return (dispatch, getState) => {
    dispatch({
      type: GET_PAGES
    })

    PageApi.getPages(page_no, per_page).then(res => {
      dispatch(getPagesSuccess(res.data))
    })
  }
}

export const getPagesSuccess = (data) => {
  return {
    type: GET_PAGES_SUCCESS,
    payload: data
  }
}

export const getPagesFailure = (error) => {
  return {
    type: GET_PAGES_ERROR,
    payload: error
  }
}

export const getPage = (id) => {
  return (dispatch, getState) => {
    dispatch({
      type: GET_PAGE
    })

    PageApi.getPage(id).then(res => {
      dispatch(getPageSuccess(res.data))
    })
  }
}

export const getPageSuccess = (page) => {
  return {
    type: GET_PAGE_SUCCESS,
    payload: page
  }
}

export const getPageFailure = (error) => {
  return {
    type: GET_PAGE_ERROR,
    payload: error
  }
}

//Update
export const updatePage = (id, page) => {
  return (dispatch, getState) => {
    //Multiple actions can be sent usign the Redux-Thunk middleware
    dispatch({
      type: UPDATE_PAGE,
      payload: page
    })
    PageApi.updatePage(id, page).then(res => {
      dispatch(updatePageSuccess(res.data))
    })
  }
}

export const updatePageSuccess = (page) => {
  return {
    type: UPDATE_PAGE_SUCCESS,
    payload: page
  }
}


//Delete
export const deletePage = (page) => {
  return (dispatch, getState) => {
    dispatch({
      type: DELETE_PAGE,
      payload: page
    })

    PageApi.removePage(page).then(res => {
      if (res.status === 204) {
        dispatch(deletePageSuccess(page))
      }
    })
  }
}

export const deletePageSuccess = (page) => {
  return {
    type: DELETE_PAGE_SUCCESS,
    payload: page
  }
}