import { CollectionApi } from "../api/collectionApi"

// Create
export const CREATE_COLLECTION = '[Mstore] CREATE_COLLECTION'
export const CREATE_COLLECTION_SUCCESS = '[Mstore] CREATE_COLLECTION_SUCCESS'
export const CREATE_COLLECTION_ERROR = '[Mstore] CREATE_COLLECTION_ERROR'


// Read
export const GET_COLLECTIONS = '[Mstore] GET_CATEGORIES'
export const GET_COLLECTIONS_SUCCESS = '[Mstore] GET_CATEGORIES_SUCCESS'
export const GET_COLLECTIONS_ERROR = '[Mstore] GET_CATEGORIES_ERROR'


export const GET_COLLECTION = '[Mstore] GET_COLLECTION'
export const GET_COLLECTION_SUCCESS = '[Mstore] GET_COLLECTION_SUCCESS'
export const GET_COLLECTION_ERROR = '[Mstore] GET_COLLECTION_ERROR'

// Update
export const UPDATE_COLLECTION = '[Mstore] UPDATE_COLLECTION'
export const UPDATE_COLLECTION_SUCCESS = '[Mstore] UPDATE_COLLECTION_SUCCESS'
export const UPDATE_COLLECTION_ERROR = '[Mstore] UPDATE_COLLECTION_ERROR'

// Delete
export const DELETE_COLLECTION = '[Mstore] DELETE_COLLECTION'
export const DELETE_COLLECTION_SUCCESS = '[Mstore] DELETE_COLLECTION_SUCCESS'
export const DELETE_COLLECTION_ERROR = '[Mstore] DELETE_COLLECTION_ERROR'

export const RESET_CREATED = '[Mstore] RESET_CREATED'
export const RESET_UPDATED = '[Mstore] RESET_UPDATED'


// Create
export const createCollection = collection => {
  return (dispatch, getState) => {
    dispatch({
      type: CREATE_COLLECTION
    })

    CollectionApi.createCollection(collection).then(res => {
      dispatch(createCollectionSuccess(res.data))
    })
  }
}

export const createCollectionSuccess = COLLECTION => {
  return {
    type: CREATE_COLLECTION_SUCCESS,
    payload: COLLECTION
  }
}

export const createCollectionFailure = error => {
  return {
    type: CREATE_COLLECTION_ERROR,
    payload: error
  }
}

//Read
export const getCollections = (params) => {
  return (dispatch, getState) => {
    dispatch({
      type: GET_COLLECTIONS
    })
    CollectionApi.getCollections(params).then(res => {
      dispatch(getCollectionsSuccess(res.data))
    })
  }
}

export const getCollectionsSuccess = data => {
  return {
    type: GET_COLLECTIONS_SUCCESS,
    payload: data
  }
}

export const getCollectionsFailure = error => {
  return {
    type: GET_COLLECTIONS_ERROR,
    payload: error
  }
}

export const getCollection = id => {
  return (dispatch, getState) => {
    dispatch({
      type: GET_COLLECTION
    })

    CollectionApi.getCollection(id).then(res => {
      dispatch(getCollectionSuccess(res.data))
    })
  }
}

export const getCollectionSuccess = collection => {
  return {
    type: GET_COLLECTION_SUCCESS,
    payload: collection
  }
}

export const getCollectionFailure = error => {
  return {
    type: GET_COLLECTION_ERROR,
    payload: error
  }
}

export const resetCreated = () => {
  return {
    type: RESET_CREATED
  }
}

export const resetUpdated= () => {
  return {
    type: RESET_UPDATED,
  }
}

//Update
export const updateCollection = (id, collection) => {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_COLLECTION,
      payload: collection
    })
    CollectionApi.updateCollection(id, collection).then(res => {
      dispatch(updateCollectionSuccess(res.data))
    })
  }
}

export const updateCollectionSuccess = collection => {
  return {
    type: UPDATE_COLLECTION_SUCCESS,
    payload: collection
  }
}

//Delete
export const deleteCollection = collection => {
  return (dispatch, getState) => {
    dispatch({
      type: DELETE_COLLECTION,
      payload: collection
    })

    CollectionApi.removeCollection(collection).then(res => {
      if (res.status === 200) {
        dispatch(deleteCollectionSuccess(collection))
      }
    })
  }
}

export const deleteCollectionSuccess = collection => {
  return {
    type: DELETE_COLLECTION_SUCCESS,
    payload: collection
  }
}
