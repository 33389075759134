/**
 * Author: TM
 * This class provides an interface with localStorage
 * Used to store and retreive authentication data for localStorage
 */

import jwt_decode from 'jwt-decode'

class AuthStorage {
  loggedIn() {
    // Checks if there is a saved token and it's still valid
    const token = this.getToken() // GEtting token from localStorage
    return !!token && !this.isTokenExpired(token) // handwaiving here
  }

  isTokenExpired(token) {
    try {
      const decoded = jwt_decode(token);
      if (decoded.exp < Date.now() / 1000) { // Checking if token is expired. N
        return true
      }
      else
        return false
    }
    catch (err) {
      return false
    }
  }

  setToken(idToken) {
    // Saves user token to localStorage
    localStorage.setItem('auth_token', idToken)
  }

  getToken() {
    // Retrieves the user token from localStorage
    return localStorage.getItem('auth_token')
  }

  logout() {
    // Clear user token and profile data from localStorage
    localStorage.removeItem('auth_token')
    localStorage.removeItem('store')
    localStorage.removeItem('user')
  }

  getPermissions() {
    try {
      const token = this.getToken();
      return jwt_decode(token)?.user_group_permissions;
    } catch (error) {
      return false;
    }
  }

  getProfile() {
    // Using jwt-decode npm package to decode the token
    try {
      return jwt_decode(this.getToken())
    } catch (error) {
      return false;
    }
  }

  setStore(store) {
    localStorage.setItem('store', JSON.stringify(store))
  }

  getStore() {
    return JSON.parse(localStorage.getItem('store'))
  }

  setUser(user) {
    localStorage.setItem('user', JSON.stringify(user))
  }

  getUser() {
    return JSON.parse(localStorage.getItem('user'))
  }

  isSuperUser() {
    const user = this.getUser()
    return user.role === 'superadmin'
  }

  getStoreUrl(path) {
    let store = JSON.parse(localStorage.getItem('store'))
    let storeUrl = (store.primary_domain ? `http://${store.primary_domain}` : `http://${process.env.REACT_APP_STORE_HOST}/${store.store_url}`)
    if(path){
      storeUrl += path
    }
    return storeUrl
  }
}

export default AuthStorage
