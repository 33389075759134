import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from "react-redux";
import { Grid, Modal, Divider, Button, Fade, Backdrop, Paper, IconButton, TextField } from '@mui/material'
import Alert from '@mui/material/Alert'
import CloseIcon from '@mui/icons-material/Close'

import { UserApi } from '../../api/userApi'
import {setSuccessNotification} from "../../actions/notificationAction";
import useTranslation from '../../hooks/useTranslation'

const styles = {
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    width: { xs: '95%', sm: '70%', md: '60%', lg: '50%' },
    boxShadow: 24
  },
  tableContainer: {
    overflow: 'scroll',
    maxHeight: 300,
    padding: '2%', 
    borderRadius: '5px'
  },
  container: {
    padding: '2%'
  }
}

const Invite = props => {
  const initialState = {email: '', balance: 0, discount: ''}
  const [invite, setInvite] = useState(initialState)
  const [inviting, setInviting] = useState(false)
  const [error, setError] = useState({})
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const sendInvite = e => {
    e.preventDefault()

    if(invite.email.length) {
      setInviting(true)
      UserApi.sendInvite(invite)
        .then(res => {
          if(res.data.status === 200) {
            setError(
              {
                name: 'User Exists',
                message: res.data.message,
                type: 'warning'
              }
            )
          }
          else {
            if(props.usersByTab.invited && props.tabCounts.invited < props.perPage) {
              let _usersByTab = {...props.usersByTab}
              _usersByTab.invited.push(res.data.user)
              props.setUsersByTab(_usersByTab)
            }
            props.setTabCounts({ ...props.tabCounts, invited: props.tabCounts.invited + 1 })
            setInvite(initialState)
            dispatch(setSuccessNotification({ message: t('response_messages.invitation_sent_successfully') }))
          }
          setInviting(false)
        })
        .catch(err => {
          setError({
            name: 'Internal server error',
            message: 'Something went wrong while sending invite to user. Please try again later!',
            type: 'danger'
          })
          setInviting(false)
        })
    }
  }

  const clearErrors = () => {
    setError({})
  }

  return (
    <Modal
      open={props.open}
      onClose={props.onCloseItemModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }} >
      <Fade in={props.open}>
        <Paper sx={styles.modal}>
          <Grid container alignItems="center">
            <Grid item xs={11} sx={{paddingLeft: '2%'}}>
              <h3>Invite Users</h3>
            </Grid>
            <Grid item xs={1}>
              <IconButton sx={{float: 'right'}} onClick={props.onCloseItemModal}><CloseIcon /></IconButton>
            </Grid>
            <Divider width='100%' />
            {error.message && error.message.length && (
              <Grid container item xs={12} sx={styles.container}>
                <Alert severity="warning" onClose={clearErrors} sx={{width: '100%'}}>
                  {error.message}
                </Alert>
              </Grid>
            )}
            <Grid container sx={styles.container} spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  required
                  label="Email"
                  name="email"
                  value={invite.email}
                  placeholder="user@example.com"
                  onChange={e =>setInvite({...invite, email: e.target.value})}
                  helperText="Input user email address"
                  fullWidth />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  required
                  label="Balance"
                  type="number"
                  name="balance"
                  value={invite.balance}
                  onChange={e =>setInvite({...invite, balance: e.target.value})}
                  helperText="Add initial account balance (kr)"
                  fullWidth />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Discount"
                  name="discount"
                  value={`${invite.discount}`}
                  onChange={e =>setInvite({...invite, discount: e.target.value})}
                  helperText="Send a discount code to welcome user e.g Free delivery"
                  fullWidth />
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" color="primary" disabled={inviting} onClick={sendInvite}>Send invitation email</Button>
              </Grid>
            </Grid>
            <Divider width="100%" />
            <Grid item sx={styles.container} xs={12} sm={12} textAlign="end">
              <Button variant='outlined' onClick={props.onCloseItemModal}>Close</Button>
            </Grid>
          </Grid>
        </Paper>
      </Fade>
    </Modal>
  )
}
Invite.propTypes = {  
  open: PropTypes.bool.isRequired,
  onCloseItemModal: PropTypes.func.isRequired,
  usersByTab: PropTypes.object.isRequired,
  tabCounts: PropTypes.object.isRequired,
  setUsersByTab: PropTypes.func.isRequired,
  setTabCounts: PropTypes.func.isRequired,
  perPage: PropTypes.number.isRequired
}

export default Invite
