import { DiscountApi } from '../api/discountApi'

// Create
export const CREATE_DISCOUNT = '[Mstore] CREATE_DISCOUNT'
export const CREATE_DISCOUNT_SUCCESS = '[Mstore] CREATE_DISCOUNT_SUCCESS'
export const CREATE_DISCOUNT_ERROR = '[Mstore] CREATE_DISCOUNT_ERROR'


// Read
export const GET_DISCOUNTS = '[Mstore] GET_DISCOUNTS'
export const GET_DISCOUNTS_SUCCESS = '[Mstore] GET_DISCOUNTS_SUCCESS'
export const GET_DISCOUNTS_ERROR = '[Mstore] GET_DISCOUNTS_ERROR'


export const GET_DISCOUNT = '[Mstore] GET_DISCOUNT'
export const GET_DISCOUNT_SUCCESS = '[Mstore] GET_DISCOUNT_SUCCESS'
export const GET_DISCOUNT_ERROR = '[Mstore] GET_DISCOUNT_ERROR'

// Update
export const UPDATE_DISCOUNT = '[Mstore] UPDATE_DISCOUNT'
export const UPDATE_DISCOUNT_SUCCESS = '[Mstore] UPDATE_DISCOUNT_SUCCESS'
export const UPDATE_DISCOUNT_ERROR = '[Mstore] UPDATE_DISCOUNT_ERROR'

// Delete
export const DELETE_DISCOUNT = '[Mstore] DELETE_DISCOUNT'
export const DELETE_DISCOUNT_SUCCESS = '[Mstore] DELETE_DISCOUNT_SUCCESS'
export const DELETE_DISCOUNT_ERROR = '[Mstore] DELETE_DISCOUNT_ERROR'

export const SET_DISCOUNT_CREATED = '[Mstore] SET_CREATED'

// Create
export const createDiscount = discount => {
  return (dispatch, getState) => {
    dispatch({
      type: CREATE_DISCOUNT
    })

    DiscountApi.createDiscount(discount).then(res => {
      dispatch(createDiscountSuccess(res.data))
    })
  }
}

export const createDiscountSuccess = discount => {
  return {
    type: CREATE_DISCOUNT_SUCCESS,
    payload: discount
  }
}

export const createDiscountFailure = error => {
  return {
    type: CREATE_DISCOUNT_ERROR,
    payload: error
  }
}

//Read
export const getDiscounts = (params) => {
  return (dispatch, getState) => {
    dispatch({
      type: GET_DISCOUNTS
    })

    DiscountApi.getDiscounts(params).then(res => {
      dispatch(getDiscountsSuccess(res.data))
    })
  }
}

export const getDiscountsSuccess = data => {
  return {
    type: GET_DISCOUNTS_SUCCESS,
    payload: data
  }
}

export const getDiscountsFailure = error => {
  return {
    type: GET_DISCOUNTS_ERROR,
    payload: error
  }
}


export const getDiscount = id => {
  return (dispatch, getState) => {
    dispatch({
      type: GET_DISCOUNT
    })

    DiscountApi.getDiscount(id).then(res => {
      dispatch(getDiscountSuccess(res.data))
    })
  }
}


export const getDiscountSuccess = discount => {
  return {
    type: GET_DISCOUNT_SUCCESS,
    payload: discount
  }
}

export const getDiscountFailure = error => {
  return {
    type: GET_DISCOUNT_ERROR,
    payload: error
  }
}

//Update
export const updateDiscount = (id, discount) => {
  return (dispatch, getState) => {
    //Multiple actions can be sent usign the Redux-Thunk middleware
    dispatch({
      type: UPDATE_DISCOUNT,
      payload: discount
    })
    DiscountApi.updateDiscount(id, discount).then(res => {
      dispatch(updateDiscountSuccess(res.data))
    })
  }
}
export const updateDiscountSuccess = discount => {
  return {
    type: UPDATE_DISCOUNT_SUCCESS,
    payload: discount
  }
}


//Delete
export const deleteDiscount = discount => {
  return (dispatch, getState) => {
    dispatch({
      type: DELETE_DISCOUNT,
      payload: discount
    })
    DiscountApi.removeDiscount(discount).then(res => {
      if (res.status === 204) {
        dispatch(deleteDiscountSuccess(discount))
      } else {
        dispatch(deleteDiscountError(res.data))
      }
    })
  }
}
export const deleteDiscountSuccess = discount => {
  return {
    type: DELETE_DISCOUNT_SUCCESS,
    payload: discount
  }
}
export const deleteDiscountError = data => {
  return {
    type: DELETE_DISCOUNT_ERROR,
    payload: data
  }
}

export const setCreated = (created) => {
  return {
    type: SET_DISCOUNT_CREATED,
    payload: created
  }
}
