import React, { useState, useEffect } from 'react'
import { PropTypes } from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as categoryActions from '../actions/categoryActions'
import { CategoryApi } from '../api/categoryApi'
import EditCategory from '../components/Category/editCategory'
import * as notificationActions from '../actions/notificationAction'
import useTranslation from '../hooks/useTranslation'

const EditCategoryContainer = (props) => {
  const [category, setCategory] = useState(props.category)
  const [formErrors, setFormErrors] = useState({})
  const [saving, setSaving] = useState(false)
  const { t } = useTranslation()

  const fetchCategory = async (categoryId) => {
    const response = await CategoryApi.getCategory(categoryId)
    if(response) setCategory(response.data)
  }

  useEffect(() => {
    if(props.category.id > 0) {
      fetchCategory(props.category.id)
    }
  }, [])

  const updateCategoryState = event => {
    const field = event.target.name
    let _category = category
    let _formErrors = formErrors
    if (_formErrors.hasOwnProperty(field)) delete(_formErrors[field])
    setFormErrors({..._formErrors})
    switch (event.target.type) {
      case 'checkbox':
        if(_category[field] === false) {
          _category[field] = true
        } else if(_category[field] === true) {
          _category[field] = false
        } else {
          _category[field] = false
        }
        break
      case 'file':
        _category[field] = event.target.files[0]
        _category[`new_${field}`] = true
        event.target.files[0] && (_category[`${field}_preview_url`] = URL.createObjectURL(event.target.files[0]))
        break
      default:
        _category[field] = event.target.value
    }
    setCategory({..._category})
  }

  const formIsValid = () => {
    let _formErrors = formErrors
    let formValid = true
    if (category.name === '') {
      _formErrors.name = 'Name can\'t be blank'
      formValid = false
    }
    setFormErrors(_formErrors)
    return formValid
  }

  const saveCategory = async (event) => {
    event.preventDefault()
    setFormErrors({})
    if (formIsValid()) {
      setSaving(true)
      let formData = new FormData()
      for (const [field, value] of Object.entries(category)) {
        if( value ) {
          formData.append(field, value)
        } else if ( value === false || value ==='') {
          // In case of checkbox which is unchecked || if text field is set to empty
          formData.append(field, value)
        }
      }
      try {
        if(category.id > 0) {
          await CategoryApi.updateCategory(category.id, formData)
          props.notifyActions.setSuccessNotification({ message: t('response_messages.category_updated_successfully') })
        } else {
          const response = await CategoryApi.createCategory(formData)
          props.notifyActions.setSuccessNotification({ message: t('response_messages.category_created_successfully') })
          setCategory(response.data)
          props.history.push(`/admin/categories/edit/${response.data.id}`)
        }
      }
      catch(error) {
        props.notifyActions.setErrorNotification({ message: error.response.data })
      }
    }
  }

  const removeImage = (field) => {
    setCategory({ ...category, [`_delete_${field}`]: true });
  };

  return (
    <EditCategory
      category={category}
      validationErrors={formErrors}
      isSaving={saving}
      onSave={saveCategory}
      removeImage={(field) => removeImage(field)}
      onChange={updateCategoryState}
      onInformationChange={info => setCategory({...category, information: info})} />
  )
}

const emptyCategory = () => {
  return {
    id: 0, name: '', description: '', image: '', information: '', show_on_homepage: false, visible: false,
    show_in_top_menu: false, show_in_side_menu: false, show_in_footer: false, text_color: '', background_image: ''
  }
}

EditCategoryContainer.propTypes = {  
  category: PropTypes.object.isRequired
}

const mapStateToProps = (state, ownProps) => {
  let category = emptyCategory()
  const catId = Number(ownProps.match.params.id)
  if (catId > 0) {
    category.id = catId
    if ( state.categories.data.length > 0 ) {
      category = {...category, ...state.categories.data.find(cat => cat.id === catId)}
    }
  }
  return {
    category: category,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(categoryActions, dispatch),
    notifyActions: bindActionCreators(notificationActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditCategoryContainer)
