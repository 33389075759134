import * as userActions from '../actions/userActions'
import initialState from './initialState'

const users = (state = initialState.users, action) => {
  let newState
  switch (action.type) {
    case userActions.GET_USERS:
      return {
        ...state,
        loading: true
      }
    case userActions.GET_USERS_SUCCESS:
      return {
        ...action.payload,
        loading: false
      }
    case userActions.GET_USER:
      return {
        ...state,
        loading: true
      }
    case userActions.GET_USER_SUCCESS:
      let indexOfCategoryToGet = state.data.findIndex(({ id }) => id === action.payload.id)
      let newStateAfterGet = {...state}
      if( indexOfCategoryToGet === -1) {
        newStateAfterGet.data.push(action.payload)
      } else {
        newStateAfterGet.data[indexOfCategoryToGet] = action.payload
      }
      return newStateAfterGet
    case userActions.CREATE_USER:
      return {
        ...state,
        updating: true
      }
    case userActions.CREATE_USER_SUCCESS:
      let newCatsState = {...state}
      newCatsState.data.push(action.payload)
      newCatsState.created = true
      return newCatsState
    case userActions.UPDATE_USER:
      return {
        ...state,
        updating: true
      }
    case userActions.UPDATE_USER_SUCCESS:
      let indexOfCatToUpdate = state.data.findIndex(({ id }) => id === action.payload.id)
      newState = {...state}
      newState.data[indexOfCatToUpdate] = action.payload
      return newState
    case userActions.DELETE_USER:
      return {
        ...state
      }
    case userActions.DELETE_USER_SUCCESS:
      let indexOfCatToDelete = state.data.findIndex(({ id }) => id === action.payload.id)
      newState = {...state}
      newState.data.splice(indexOfCatToDelete, 1)
      return newState
    default:
      return state
  }
}

export default users

// import * as userActions from '../actions/userActions'
// import initialState from './initialState'

// const user = (state = initialState.user, action) => {
//   switch (action.type) {
//     case userActions.GET_USER:
//       return {
//         ...state,
//         loading: true
//       }
//     case userActions.GET_USER_SUCCESS:
//       return {
//         ...state,
//         ...action.payload,
//         loading: false
//       }
//     case userActions.UPDATE_USER:
//       return {
//         ...state,
//         updating: true
//       }
//     case userActions.UPDATE_USER_SUCCESS:
//       return {
//         ...state,
//         ...action.payload,
//         updating: false
//       }
//     default:
//       return state
//   }
// }

// export default user
