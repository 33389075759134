import React from 'react'
import PropTypes from 'prop-types'
import { Grid, TextField, Paper, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SiteLinks from '../siteLinks';
import InfoIcon from '@mui/icons-material/Info';
import MoveIcon from '@mui/icons-material/DragIndicator';
import useTranslation from '../../hooks/useTranslation'

const Link = (props) => {
  const { t } = useTranslation()
  return (
    <Paper className={props.classes.paper} elevation={2}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={1}>
          <IconButton {...props.attributes} {...props.listeners} size='large' title="Re-order"><MoveIcon /></IconButton>
        </Grid>
        <Grid item xs={4} style={{marginTop: '1%'}}>
          <TextField
            label="Name"
            name="name"
            value={props.link.name}
            placeholder="Name"
            onChange={props.updateLinkState}
            variant='outlined'
            fullWidth />
        </Grid>
        <Grid item xs={6} style={{marginTop: '1%'}}>
          <SiteLinks
            selectedModuleOption={props.selectedModuleOption}
            selectedModule={props.selectedModule}
            urlOnChange={props.urlOnChange}
            listBackButtonOnClick={props.listBackButtonOnClick}
            search={props.search}
            searchOnChange={props.searchOnChange}
            currentUrlOptions={props.currentUrlOptions}
            handleOnListClicked={props.handleOnListClicked}
            filteredOptions={props.filteredOptions}
            nestedMenu={props.nestedMenu}
            showOptions={props.showOptions}
            setShowOptions={props.setShowOptions}
          />
        </Grid>
        <Grid item xs={1}>
          <IconButton variant='contained' color='error' aria-label="delete" size="large" onClick={() => { if (window.confirm(t('labels.are_you_sure'))) props.removeLink() }}><DeleteIcon /></IconButton>
        </Grid>
        {!props.link.visible && (
          <Grid container className={props.classes.visibleInfoContainer}>
            <InfoIcon color='action'/>
            <span className={props.classes.visibleInfoText}>This link will not be visible as the assoicated resource is not public.</span>
          </Grid>
        )}
      </Grid>
    </Paper>
  )
}

Link.propTypes = {
  link: PropTypes.object.isRequired,
  showOptions: PropTypes.bool.isRequired,
  nestedMenu: PropTypes.bool.isRequired,
  selectedModuleOption: PropTypes.object.isRequired,
  selectedModule: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired,
  currentUrlOptions: PropTypes.array.isRequired,
  setShowOptions: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  moduleIcons: PropTypes.object.isRequired,
  filteredOptions: PropTypes.array.isRequired,
  listeners: PropTypes.object.isRequired,
  attributes: PropTypes.object.isRequired,
  updateLinkState: PropTypes.func.isRequired,
  removeLink: PropTypes.func.isRequired,
  listBackButtonOnClick: PropTypes.func.isRequired,
  handleOnListClicked: PropTypes.func.isRequired,
  urlOnChange: PropTypes.func.isRequired,
  searchOnChange: PropTypes.func.isRequired
}
export default Link
