export default {
  store: {},
  frontpage: {},
  categories: {
    data: [],
    count: 0,
    page_no: 1,
    created: false,
    loading: false,
    updated: false
  },
  collections: {
    data: [],
    count: 0,
    page_no: 1,
    created: false,
    loading: false,
    updated: false
  },
  discounts: {
    data: [],
    count: 0,
    page_no: 1,
    created: false,
    loading: false,
    updated: false
  },
  currencies: {
    data: []
  },
  products: {
    data: [],
    count: 0,
    page_no: 1,
    loading: false,
    created: false,
    updated: false
  },
  menus: {
    data: [],
    count: 0,
    page_no: 1,
    loading: false
  },
  autocomplete: {
    query: '',
    suggestions: []
  },
  pages: {
    data: [],
    count: 0,
    page_no: 1,
    loading: false
  },
  orders: {
    data: [],
    count: 0,
    page_no: 1,
    loading: false
  },
  users: {
    data: [],
    count: 0,
    page_no: 1,
    loading: false
  },
  teams: {
    data: [],
    count: 0,
    page_no: 1,
    loading: false
  },
  user: {
    shipping_addresses: [],
    invoice_addresses: []
  },
  errors: {
    errors: [],
    isOpen: false
  },
  notifications: {
    message: '',
    open: false,
    notificationType: ''
  },
  userGroups: {
    data: [],
    count: 0,
    page_no: 1,
    formErrors: {}
  }
}
