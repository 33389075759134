import {HttpClient} from './httpClient' 
import {API_URL} from './RestAPI'
import apiHeaders from './apiHeaders'

const getWaitlistByProductVariant = (variant_id) => {
  return HttpClient.get(
    `${API_URL}/product-variants/${variant_id}/waitlist`,
    apiHeaders()
  )
}

const sendWaitlistEmail = (variant_id) => {
  return HttpClient.get(
    `${API_URL}/product-variants/${variant_id}/send-waitlist-emails`,
    apiHeaders()
  )
}

const WaitlistApi = { getWaitlistByProductVariant, sendWaitlistEmail }

export { WaitlistApi }
