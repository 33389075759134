import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from "react-redux";
import {bindActionCreators} from 'redux'
import { connect } from 'react-redux'
import * as pageActions from '../actions/pageActions';
import Page from '../components/Page/page';
import { getSerialNumber, changePage, handleChangeRowsPerPage } from '../utils/sharedFunctions'
import ListingPage from '../components/Shared/ListingPage';
import { Button } from '@mui/material';
import { ListTable } from '../components/Shared/ListingTable';
import { useHistory } from 'react-router-dom'
import {getUserPreference} from "../utils/localStorageHelper/localStorageMethods";
import { setSuccessNotification } from "../actions/notificationAction";
import useTranslation from '../hooks/useTranslation'

const PageContainer = (props) => {
  const pagesPerPage = getUserPreference( 'page_size', 'pages')
  const { pages, actions } = props;
  const { data, count } = pages
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState( pagesPerPage !== '' ? pagesPerPage : global.constants.perPage.default)
  const history = useHistory()
  const dispatch = useDispatch();
  const { t } = useTranslation()

  useEffect(() => {
    actions.getPages({ page: page, per_page: perPage})
  }, [page, perPage])

  // new
  const newPage = () => {
    history.push('/admin/pages/new')
  }

  // Delete
  const deletePage = page => {
    actions.deletePage(page)
    dispatch(setSuccessNotification({ message: t('response_messages.pages.deleted') }))
  }

  const actionButtons = (
    <Button
      color="primary"
      variant="contained"
      sx={{float: 'right', textTransform: 'none'}}
      onClick={newPage}>
        {t('labels.create_page')}
    </Button>
  )
  
  const tableContent = pageTableContent(deletePage, data, page, perPage)
  return (
    <ListingPage
      title={t('labels.menu.pages')}
      actions={actionButtons}
      table={
        <ListTable
          tableHeadings={tableContent.headings}
          tableContent={tableContent.body}
          count={count}
          page_no={page}
          rowsPerPage={perPage}
          onPageChange={(event, page_no) => changePage(event, page_no, setPage)}
          handleChangeRowsPerPage={(event) => handleChangeRowsPerPage(event, setPage, setPerPage, 'page_size', 'pages')}
          rowsControl={true} />
      } />
  )
}

const pageTableContent = (deletePage, pages=[], page_no, per_page) => {
  const { t } = useTranslation()
  const pageTableBody = pages?.map((page, i) => (
    <Page key={page?.id} {...page} deletePage={() => deletePage(page)} index={getSerialNumber(page_no, per_page, i)}/>
  ));
  return { body: pageTableBody, headings: ['Id', t('labels.name'), t('labels.public'), t('labels.actions')] }
}

PageContainer.propTypes = {  
  pages: PropTypes.object.isRequired
}

const mapStateToProps = (state, ownProps) => {
  return {
    pages: state.pages
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(pageActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PageContainer)
