import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, Paper, Fab, IconButton, Fade, TextField, Button, Divider } from '@mui/material'
import Add from '@mui/icons-material/Add'
import Close from '@mui/icons-material/Close'
import Address from './Address'
import { UserAddressesApi } from '../../api/userAddresses'
import useTranslation from '../../hooks/useTranslation'

const style = {
  paper: {
    padding: '20px',
    marginTop: '20px'
  },
}

const Addresses = props => {
  const [deliveryAddresses, setDeliveryAddresses] = useState(props.user.delivery_addresses || [])
  const [invoiceAddresses, setInvoiceAddresses] = useState(props.user.invoice_addresses || [])
  const [open, setOpen] = useState(false)
  const [address, setAddress] = useState({})
  const [addressErrors, setAddressErrors] = useState({})
  const { t } = useTranslation()
  
  useEffect(() => {
    setDeliveryAddresses(props.user.delivery_addresses || [])
    setInvoiceAddresses(props.user.invoice_addresses || [])
  }, [props.user])
  
  const addAddress = type => {
    setAddress({
      addressable_type: type
    })
    setOpen(true)
  }
  
  const onChange = e => {
    setAddress({...address, [e.target.name]: e.currentTarget.value})
    let errors = {...addressErrors}
    if (errors.hasOwnProperty(e.target.name)){
      delete errors[e.target.name]
    } else if (e.target.name === 'address_one' && errors.hasOwnProperty('address')){
      delete errors['address']
    }
    setAddressErrors(errors)
  }

  const saveAddress = e => {
    let formValid = true;
    let errors = {...addressErrors};
    ['email', 'phone', 'name', 'address_one', 'postcode', 'city', 'country'].forEach((requiredKey)=>{
      if (!address.hasOwnProperty(requiredKey)){
        formValid = false
        errors = {...errors, [requiredKey]: `Please enter ${requiredKey === 'address_one' ? 'address' : requiredKey} to save address`}
      }
    })
    setAddressErrors({...errors})
    e.preventDefault()
    if (formValid){
      if(address.id) {
        UserAddressesApi.updateAddress(props.user.id, address.id, address)
          .then(res => {
            if(res.status === 200) {
              if(res.data.addressable_type === 'user_delivery_address') {
                const index = deliveryAddresses.findIndex(a => a.id === res.data.id)
                const _addresses = [...deliveryAddresses]
                _addresses[index] = res.data
                setDeliveryAddresses(_addresses)
              } else {
                const index = invoiceAddresses.findIndex(a => a.id === res.data.id)
                const _addresses = [...invoiceAddresses]
                _addresses[index] = res.data
                setDeliveryAddresses(_addresses)
              }
              setAddress({}) // set current address to empty
              setOpen(false) // close form
            }
          })
      } else {
        UserAddressesApi.createAddress(props.user.id, address)
          .then(res => {
            console.log('Res', res)
            if(res.status === 201 || res.status === 200) {
              res.data.addressable_type === 'user_delivery_address' ?
                setDeliveryAddresses([...deliveryAddresses, res.data])
                :
                setInvoiceAddresses([...invoiceAddresses, res.data])
            }
            setAddress({}) // set current address to empty
            setOpen(false) // close form
          })
      }
    }
  }

  const editAddress = add => {
    setAddress(add)
    setOpen(true)
  }

  const deleteAddress = addr => {
    if (addr.addressable_type === 'user_delivery_address') {
      const addresses = deliveryAddresses.filter(a => a.id !== addr.id)
      UserAddressesApi.removeAddress(props.user.id, addr.id)
        .then( res => {
          setDeliveryAddresses(addresses)
        } )
    } else {
      const addresses = invoiceAddresses.filter(a => a.id !== addr.id)
      UserAddressesApi.removeAddress(props.user.id, addr.id)
        .then( res => {
          setInvoiceAddresses(addresses)
        } )
    }

  }

  const addressForm = (
    <Paper sx={style.paper} >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Contact Name"
            name="name"
            value={address.name || ''}
            onChange={onChange}
            error={addressErrors.hasOwnProperty('name')}
            helperText={addressErrors['name']}
            fullWidth
            size="small" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Email"
            name="email"
            value={address.email || ''}
            error={addressErrors.hasOwnProperty('email')}
            helperText={addressErrors['email']}
            placeholder="use@example.com"
            onChange={onChange}
            fullWidth
            size="small" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Company Name"
            name="company_name"
            value={address.company_name || ''}
            placeholder="Example AB"
            onChange={onChange}
            fullWidth
            size="small" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Organisation No."
            name="organisation_number"
            value={address.organisation_number || ''}
            placeholder="XXXXXXX"
            onChange={onChange}
            fullWidth
            size="small" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Phone"
            name="phone"
            error={addressErrors.hasOwnProperty('phone')}
            helperText={addressErrors['phone']}
            value={address.phone || ''}
            onChange={onChange}
            fullWidth
            size="small" />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            label="Address"
            name="address_one"
            value={address.address_one || ''}
            error={addressErrors.hasOwnProperty('address_one')}
            helperText={addressErrors['address_one']}
            placeholder="house, street"
            onChange={onChange}
            fullWidth
            size="small" />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            label="Address 2"
            name="address_two"
            value={address.address_two || ''}
            onChange={onChange}
            fullWidth
            size="small" />
        </Grid>
        <Grid item xs={12} sm={4}>    
          <TextField
            label="Postcode"
            name="postcode"
            error={addressErrors.hasOwnProperty('postcode')}
            helperText={addressErrors['postcode']}
            value={address.postcode || ''}
            placeholder="Postcode"
            onChange={onChange}
            fullWidth
            size="small" />
        </Grid>
        <Grid item xs={12} sm={4}>    
          <TextField
            label="City"
            name="city"
            error={addressErrors.hasOwnProperty('city')}
            helperText={addressErrors['city']}
            value={address.city || ''}
            placeholder="City"
            onChange={onChange}
            fullWidth
            size="small" />
        </Grid>
        <Grid item xs={12} sm={4}>    
          <TextField
            label="Country"
            name="country"
            error={addressErrors.hasOwnProperty('country')}
            helperText={addressErrors['country']}
            value={address.country || ''}
            placeholder="Country"
            onChange={onChange}
            fullWidth
            size="small" />
        </Grid>
        {
          address.addressable_type === 'user_invoice_address' &&
          <Grid item xs={12} sm={12}>
            <TextField
              label="Reference"
              name="reference"
              value={address.reference || ''}
              placeholder="Reference"
              helperText="Used for accounting purposes"
              onChange={onChange}
              fullWidth
              size="small" />
          </Grid>
        }
        <Grid item xs={12} sm={12}>
          <TextField
            label="Instructions"
            name="instructions"
            value={address.instructions || ''}
            helperText={address.addressable_type === 'user_invoice_address' ? 'Any additional info.' : 'Additional info. like building entrance code etc.'}
            onChange={onChange}
            fullWidth
            size="small" />
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={saveAddress}> Save</Button>
        </Grid>
        <Grid item>
          <Button variant="outlined" onClick={() => setOpen(false)}> Cancel</Button>
        </Grid>
      </Grid>
    </Paper>
  )

  return (
    <Grid container spacing={2}>
      <Grid item sm={6}>
        <Paper sx={style.paper} elevation={2}>
          <Grid container spacing={1} alignItems='center' justifyContent='space-between'>
            <Grid item sm={6}>
              <h3>{t('labels.shipping_addresses')}</h3>
            </Grid>
            <Grid item sm={2} className="text-right">
              { open && address.addressable_type === 'user_delivery_address'  ? <IconButton color="default" onClick={() => setOpen(false)} ><Close /></IconButton>
                :
                <Fab color="primary" size="small" onClick={() => addAddress('user_delivery_address')}><Add /></Fab>
              }
            </Grid>
          </Grid>
          <Divider />
          {open && address.addressable_type === 'user_delivery_address' && <Fade in={open}>
            {addressForm}
          </Fade>
          }
          {deliveryAddresses.map(a => <Address key={a.id} address={a} onEdit={editAddress} onDelete={deleteAddress} />)}
        </Paper>
      </Grid>
      <Grid item sm={6}>
        <Paper sx={style.paper} elevation={2}>
          <Grid container spacing={1} alignItems='center' justifyContent={'space-between'}>
            <Grid item sm={6}>
              <h3>{t('labels.invoice_addresses')}</h3>
            </Grid>
            <Grid item sm={2} className="text-right">
              { open && address.addressable_type === 'user_invoice_address' ? <IconButton color="default" onClick={() => setOpen(false)} ><Close /></IconButton>
                :
                <Fab color="primary" size="small" onClick={() => addAddress('user_invoice_address')}><Add /></Fab>
              }
            </Grid>
          </Grid>
          <Divider />
          {open && address.addressable_type === 'user_invoice_address'  && <Fade in={open}>
            {addressForm}
          </Fade>
          }
          {invoiceAddresses.map(a => <Address key={a.id} address={a} onEdit={editAddress} onDelete={deleteAddress} />)}
        </Paper>
      </Grid>
    </Grid>
  )
}

Addresses.propTypes = {
  user: PropTypes.object.isRequired
}

export default Addresses
