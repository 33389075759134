import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField, MenuItem, Autocomplete, CircularProgress, Typography, Button } from '@mui/material';
import FieldGroup from '../fieldGroup';
import { EmailTemplateApi } from '../../api/emailTemplatesApi';
import EditPageUpperContainer from '../Shared/EditPageHeader';
import EditPageLowerContainer from '../Shared/EditPageLowerContainer';
import EmailEditor from '../Shared/EmailEditor';
import { UserApi } from '../../api/userApi';
import EmailTemplatePreview from '../Shared/EmailTemplatePreview';
import useTranslation from '../../hooks/useTranslation'

const EditEmailCampaign = ({ emailCampaign, validationErrors, isSaving, onSave, handleEmailCampaign, duplicateEmailCampaign }) => {
  const { t } = useTranslation()
  const actionType = ( emailCampaign?.hasOwnProperty('id') && emailCampaign.id > 0 ) ? t('labels.edit') : t('labels.new')
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState('custom');
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedRole, setSelectedRole] = useState('all');
  const [selectedUsers, setSelectedUsers] = useState([]);


  const fetchData = async () => {
    setLoading(true);

    try {
      const templatesResponse = await EmailTemplateApi.getEmailTemplates({ restricted: true });
      setEmailTemplates(templatesResponse.data.data);
      const users = await getUsers('all')
      if (emailCampaign.to) {
        const preSelected = users.filter(user => emailCampaign.to?.includes(user.email));
        setSelectedUsers(preSelected);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  }

  const getUsers = async (userRole) => {
    const usersResponse = await UserApi.getUsersByRole({ userRole: userRole });
    const users = usersResponse.data.users.map(user => ({ email: user.email }))
    setUsers(users);
    return users;
  }

  useEffect(() => {
    fetchData();
  }, [emailCampaign.to]);

  const handleTemplateChange = useCallback((event) => {
    const value = event.target.value;
    setSelectedTemplate(value);  
    const selectedEmailTemplate = emailTemplates.find(template => template.id === value);
    if (value === 'custom') {
        handleEmailCampaign({ ...emailCampaign, body: '' });
    } else if (selectedEmailTemplate) {
      handleEmailCampaign({ ...emailCampaign, body: selectedEmailTemplate.body });
    }
  }, [emailTemplates, emailCampaign, handleEmailCampaign]);

  const handleUserSelectionChange = (event, value) => {
    const isSelectAllSelected = value.some(user => user.email === 'Select All');
    const selectedUsers = isSelectAllSelected ? users : value;
    const userEmails = selectedUsers.map(user => user.email);
    handleEmailCampaign({ ...emailCampaign, to: userEmails });
    setSelectedUsers(selectedUsers);
  };

  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append('image', file);

    try {
      const response = await EmailTemplateApi.addImage(formData)
      return response.data;
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  }
  

  const leftFirstContent = () => (
    <>
    <Grid item xs={12} md={12}>
        <TextField
          select
          label={t('labels.select_role')}
          value={selectedRole}
          onChange={(event) => {
            setSelectedRole(event.target.value);
            getUsers(event.target.value);
          }}
          helperText={t('labels.filter_users')}
          variant="outlined"
          fullWidth
          margin="normal"
        >
          <MenuItem value="all">{t('labels.all_users')}</MenuItem>
          <MenuItem value="manager">{t('labels.manager')}</MenuItem>
          <MenuItem value="staff">{t('labels.staff')}</MenuItem>
        </TextField>
    </Grid>
        <Grid item xs={12} md={12}>
            <Autocomplete
                multiple
                options={[{ email: 'Select All', key: 'selectAllOption' }, ...users.map(user => ({ ...user, key: user.id }))]}
                disableCloseOnSelect
                getOptionLabel={(option) => option.email}
                value={selectedUsers}
                getOptionDisabled={(option) =>
                    selectedUsers.some((user) => user.email === option.email)
                }
                renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.key}>
                    {option.email}
                    </li>
                )}
                renderInput={(params) => (
                    <TextField
                    {...params}
                    variant="outlined"
                    label={t('labels.to')}
                    placeholder={t('labels.select_users')}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                        <>
                            {loading && <CircularProgress color="inherit" size={20} />}
                            {params.InputProps.endAdornment}
                        </>
                        ),
                    }}
                    />
                )}
                onChange={handleUserSelectionChange}
            />
      </Grid>
      <Grid item xs={12} md={9}>
        <FieldGroup
          id="templateName"
          type="text"
          label={t('labels.name')}
          name="name"
          placeholder={t('labels.name')}
          value={emailCampaign.name}
          onChange={(event) => handleEmailCampaign({...emailCampaign, [event.target.name]: event.target.value})}
        />
        <br></br>
        <TextField
          name="email_templates"
          label={t('labels.select_template')}
          value={selectedTemplate}
          onChange={handleTemplateChange}
          variant='outlined'
          select
          fullWidth
        >
          {emailTemplates.map(template => (
            <MenuItem key={template.id} value={template.id}>{template.name}</MenuItem>
          ))}
          <MenuItem value="custom">{t('labels.custom')}</MenuItem>
        </TextField>
        <FieldGroup
          id="templateSubject"
          type="text"
          label={t('placeholders.subject')}
          name="subject"
          placeholder={t('placeholders.subject')}
          value={emailCampaign.subject}
          onChange={(event) => handleEmailCampaign({...emailCampaign, [event.target.name]: event.target.value})}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <EmailEditor value={emailCampaign.body} onChange={(newBody) => handleEmailCampaign({...emailCampaign, body: newBody})} label={t('labels.body')} uploadImage={uploadImage} />
      </Grid> 
    </>
  )

  const rightFirstContent = () => (
    <>
        <Typography variant="h6">{t('labels.preview')}</Typography>
        <EmailTemplatePreview content={emailCampaign.body} />
    </>
  )

  const handleDuplicateButtonClick = () => {
    if (window.confirm(t('labels.campaign_duplication'))) {
      duplicateEmailCampaign(emailCampaign);
    }
  };

  const extraActions = () => (
    <Button color="primary" variant="outlined" onClick={handleDuplicateButtonClick}>{t('labels.duplicate')}</Button>
  )

  const emailActions = () => (
    <>
    {!emailCampaign.email_sent && (<Button color="primary" variant="contained" 
        onClick={() => { 
            if (window.confirm(t('labels.email_action'))) {
                const updatedEmailCampaign = { ...emailCampaign, email_sent: true };
                onSave(null, updatedEmailCampaign);
            } }}>{t('labels.save_and_send_email')}</Button>)}
            </>
  )

  return (
    <div>
      <EditPageUpperContainer actionType={actionType} page={t('labels.email_campaign')} onSave={onSave} extraActions={extraActions} allExtraActions={emailActions} />
        <form onSubmit={onSave}>
          <EditPageLowerContainer
            leftContent={ [leftFirstContent] }
            rightContent={ [rightFirstContent] }
            actionType={actionType}
            onSave={onSave}
          />
        </form> 
    </div>
)}

EditEmailCampaign.propTypes = {
  emailCampaign: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired
}

export default EditEmailCampaign
