import React, { useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from "react-redux";
import PropTypes from 'prop-types'
import {bindActionCreators} from 'redux'
import { connect } from 'react-redux'
import { Button } from '@mui/material'
import * as discountActions from '../actions/discountActions';
import Discount from '../components/Discount/discount'
import ListingPage from '../components/Shared/ListingPage'
import { ListTable } from '../components/Shared/ListingTable'
import { getSerialNumber } from '../utils/sharedFunctions'
import ListTableActions from "../components/Shared/ListingTable/listTableActions";
import { CSVLink } from "react-csv";
import { DiscountApi } from "../api/discountApi";
import { discountCSVHeaders } from "../config/csvHeaders";
import { discountSorting } from "../config/sortingLabels";
import useListingActions from '../hooks/useListingActions'
import { actionBtn } from '../styles'
import { setSuccessNotification } from "../actions/notificationAction";
import useTranslation from '../hooks/useTranslation'

const DiscountContainer = (props) => {
  const { actions } = props
  const [dataForExport, setDataForExport] = useState([])
  const csvButtonRef = useRef(null)
  const history = useHistory()
  const dispatch = useDispatch();
  const { t } = useTranslation()

  const {
    data,
    count,
    page,
    perPage,
    searchTerm,
    setData,
    sortOptionSelected,
    handlerForSearchingAndSortingChange,
  } = useListingActions('discounts', DiscountApi.getDiscounts);

  const deleteDiscount = (discount) => {
    actions.deleteDiscount(discount)
    setData(data.filter(dis => dis.id !== discount.id))
    dispatch(setSuccessNotification({ message: t('response_messages.discounts.deleted') }))
  }

  let exportCSV = async () => {
    const response = await DiscountApi.getDiscounts({ page: 1, per_page: perPage, q: searchTerm, sort_by: sortOptionSelected, export_records: true })
    if (response.data?.data?.length > 0) {
      setDataForExport(response.data.data)
      csvButtonRef.current.link.click()
    }
  }

  const actionButtons = (
    <>
      <CSVLink
        data={dataForExport}
        headers={discountCSVHeaders}
        filename={`discounts-${(new Date()).toLocaleDateString().replace(', ', '-')}.csv`}
        className='hidden'
        ref={csvButtonRef}
        target='_blank'/>
      <Button variant="outlined" sx={actionBtn}
              onClick={exportCSV}>{t('labels.exportToCsv')}</Button>
      <Button
        color="primary"
        variant="contained"
        sx={actionBtn}
        onClick={() => history.push('/admin/discounts/new')}>
        Create Discount
      </Button>
    </>
  )

  const tableActions = (
    <ListTableActions
      searchTerm={searchTerm}
      searchText='Search Discounts'
      sortOptionSelected={sortOptionSelected}
      sortOptions={discountSorting}
      translateText={t}
      getData={handlerForSearchingAndSortingChange}
    />
  )

  const tableContent = discountTable(deleteDiscount, data, page, perPage)

  return (
    <ListingPage
      title={t('labels.menu.discounts')}
      actions={actionButtons}
      table={
        <ListTable
          tableHeadings={tableContent.headings}
          tableContent={tableContent.body}
          tableActions={tableActions}
          count={count}
          page_no={page}
          rowsPerPage={perPage}
          onPageChange={(event, pageNo) => handlerForSearchingAndSortingChange('page', pageNo + 1)}
          handleChangeRowsPerPage={(event) => handlerForSearchingAndSortingChange('pageSize', event.target.value)}
          rowsControl={true} />
      } />
  )
}

const discountTable = (deleteDiscount, discounts=[], page_no, per_page) => {
  const { t } = useTranslation()
  const discountBody = discounts.map((discount, index) => (
    <Discount key={discount.id} {...discount} deleteDiscount={() => deleteDiscount(discount)} index={getSerialNumber(page_no, per_page, index)}/>
  ));
  return { body: discountBody, headings: ['Id', t('labels.name'), t('labels.single_use'), t('labels.value'), t('labels.expires'), t('labels.active'), t('labels.actions')] }
}

DiscountContainer.propTypes = {  
  discounts: PropTypes.object.isRequired
}

const mapStateToProps = (state, ownProps) => {
  return {
    discounts: state.discounts,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(discountActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DiscountContainer)
