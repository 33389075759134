import * as menuActions from '../actions/menuActions'
import initialState from './initialState'

const menus = (state = initialState.menus, action) => {
  switch (action.type) {
    case menuActions.GET_MENUS:
      return {
        ...state,
        loading: true
      }
    case menuActions.GET_MENUS_SUCCESS:
      return {
        ...action.payload,
        loading: false
      }
    case menuActions.GET_MENU:
      return {
        ...state,
        loading: true
      }
    case menuActions.GET_MENU_SUCCESS:
      let indexOfMenuToGet = state.data.findIndex(({ id }) => id === action.payload.id)
      let newStateAfterGet = {...state}
      if(indexOfMenuToGet === -1) {
        newStateAfterGet.data.push(action.payload)
      } else {
        newStateAfterGet.data[indexOfMenuToGet] = action.payload
      }
      return newStateAfterGet
    case menuActions.SEARCH_MENU:
      return {
        ...state,
        loading: true
      }
    case menuActions.SEARCH_MENU_SUCCESS:
      let newStateAfterSearch = {...state}
      newStateAfterSearch.searched_product = action.payload
      return newStateAfterSearch
    case menuActions.UPDATE_MENU:
      return {
        ...state,
        updating: true
      }
    case menuActions.UPDATE_MENU_SUCCESS:
      let indexOfMenuToUpdate = state.data.findIndex(({ id }) => id === action.payload.id)
      let newState = {...state, updating: false}
      newState.data[indexOfMenuToUpdate] = action.payload
      return newState
    default:
      return state
  }
}

export default menus
