import { UserGroupApi } from "../api/userGroupApi"

// Create
export const CREATE_USER_GROUP = '[Mstore] CREATE_USER_GROUP'
export const CREATE_USER_GROUP_SUCCESS = '[Mstore] CREATE_USER_GROUP_SUCCESS'
export const CREATE_USER_GROUP_ERROR = '[Mstore] CREATE_USER_GROUP_ERROR'


// Read
export const GET_USER_GROUPS = '[Mstore] GET_USER_GROUPS'
export const GET_USER_GROUPS_SUCCESS = '[Mstore] GET_USER_GROUPS_SUCCESS'
export const GET_USER_GROUPS_ERROR = '[Mstore] GET_USER_GROUPS_ERROR'

// Get
export const GET_USER_GROUP = '[Mstore] GET_USER_GROUP'
export const GET_USER_GROUP_SUCCESS = '[Mstore] GET_USER_GROUP_SUCCESS'
export const GET_USER_GROUP_ERROR = '[Mstore] GET_USER_GROUP_ERROR'

// Search
export const SEARCH_USER_GROUP = '[Mstore] SEARCH_USER_GROUP'
export const SEARCH_USER_GROUP_SUCCESS = '[Mstore] SEARCH_USER_GROUP_SUCCESS'
export const SEARCH_USER_GROUP_ERROR = '[Mstore] SEARCH_USER_GROUP_ERROR'

// Update
export const UPDATE_USER_GROUP = '[Mstore] UPDATE_USER_GROUP'
export const UPDATE_USER_GROUP_SUCCESS = '[Mstore] UPDATE_USER_GROUP_SUCCESS'
export const UPDATE_USER_GROUP_ERROR = '[Mstore] UPDATE_USER_GROUP_ERROR'

// Delete
export const DELETE_USER_GROUP = '[Mstore] DELETE_USER_GROUP'
export const DELETE_USER_GROUP_SUCCESS = '[Mstore] DELETE_USER_GROUP_SUCCESS'
export const DELETE_USER_GROUP_ERROR = '[Mstore] DELETE_USER_GROUP_ERROR'

//Reset
export const RESET_FLAGS = '[Mstore] RESET_FLAGS'

// Create
export const createUserGroup = (userGroup, setGroupCreated) => {
  return (dispatch, getState) => {
    dispatch({
      type: CREATE_USER_GROUP
    })

    UserGroupApi.createUserGroup(userGroup).then(res => {
      dispatch(createUserGroupSuccess(res.data));
      setGroupCreated(true);
    })
    .catch(error => {
      dispatch(createUserGroupFailure(error?.response ?? error))
    })
  }
}

export const createUserGroupSuccess = userGroup => {
  return {
    type: CREATE_USER_GROUP_SUCCESS,
    payload: userGroup
  }
}

export const createUserGroupFailure = error => {
  return {
    type: CREATE_USER_GROUP_ERROR,
    payload: error
  }
}

// Index
export const getUserGroups = (page, perPage) => {
  return (dispatch, getState) => {
    dispatch({
      type: GET_USER_GROUPS
    })

    UserGroupApi.getUserGroups(page, perPage).then(res => {
      dispatch(getUserGroupsSuccess(res.data))
    })
    .catch(error => {
      dispatch(getUserGroupsFailure(error))
    })
  }
}

export const getUserGroupsSuccess = data => {
  return {
    type: GET_USER_GROUPS_SUCCESS,
    payload: data
  }
}

export const getUserGroupsFailure = error => {
  return {
    type: GET_USER_GROUPS_ERROR,
    payload: error
  }
}

// Show
export const getUserGroup = id => {
  return (dispatch, getState) => {
    dispatch({
      type: GET_USER_GROUP
    });

    UserGroupApi.getUserGroup(id).then(res => {
      dispatch(getUserGroupSuccess(res.data))
    })
    .catch(error => {
      dispatch(getUserGroupFailure(error))
    });
  }
}

export const getUserGroupSuccess = userGroup => {
  return {
    type: GET_USER_GROUP_SUCCESS,
    payload: userGroup
  }
}

export const getUserGroupFailure = error => {
  return {
    type: GET_USER_GROUP_ERROR,
    payload: error
  }
}

//Update
export const updateUserGroup = (userGroup, setGroupUpdated) => {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_USER_GROUP
    })

    UserGroupApi.updateUserGroup(userGroup).then(res => {
      dispatch(updateUserGroupSuccess(res.data))
      setGroupUpdated(true);
    })
    .catch(error => {
      dispatch(updateUserGroupFailure(error?.response ?? error))
    })
  }
}

export const updateUserGroupSuccess = userGroup => {
  return {
    type: UPDATE_USER_GROUP_SUCCESS,
    payload: userGroup
  }
}

export const updateUserGroupFailure = error => {
  return {
    type: UPDATE_USER_GROUP_ERROR,
    payload: error
  }
}

// Delete

export const deleteUserGroup = (userGroupId) => {
  return (dispatch, getState) => {
    dispatch({
      type: DELETE_USER_GROUP
    })

    UserGroupApi.deleteUserGroup(userGroupId).then(res => {
      dispatch(deleteUserGroupSuccess(res.data))
    })
    .catch(error => {
      dispatch(deleteUserGroupFailure(error?.response ?? error))
    })
  }
}

export const deleteUserGroupSuccess = userGroup => {
  return {
    type: DELETE_USER_GROUP_SUCCESS,
    payload: userGroup
  }
}

export const deleteUserGroupFailure = error => {
  return {
    type: DELETE_USER_GROUP_ERROR,
    payload: error
  }
}

// Reset
export const resetUserGroupFlags = () => {
  return {
    type: RESET_FLAGS,
  }
}
