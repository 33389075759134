import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import EditEmailTemplate from "../components/EmailTemplate/editEmailTemplate";
import { EmailTemplateApi } from "../api/emailTemplatesApi";
import { useDispatch } from 'react-redux'
import { setSuccessNotification } from '../actions/notificationAction'
import useTranslation from '../hooks/useTranslation'

const EditEmailTemplateContainer = (props) => {
  const [formErrors, setFormErrors] = useState({})
  const [emailTemplate, setEmailTemplate] = useState({
      id: 0,
      name: '',
      subject: '',
      body: '',
      type: 'Custom',
      system_email: false
  })
  const dispatch = useDispatch()
  const history = useHistory();
  const params = useParams();
  const { t } = useTranslation()

  useEffect(() => {
    fetchEmailTemplate();
  }, [])

  const fetchEmailTemplate = async () => {
    const emailTemplateId = params.hasOwnProperty('id') ? + params.id : 0
    if (emailTemplateId > 0) {
      const response = await EmailTemplateApi.getEmailTemplate(emailTemplateId)
      if ( response.status === 200 ) {
        setEmailTemplate(response.data)
      }
    }    
  }

  const saveEmailTemplate = async (event) => {
    event.preventDefault()
    setFormErrors({})
    await EmailTemplateApi.updateEmailTemplate(emailTemplate.id, emailTemplate)
    dispatch(setSuccessNotification({ message: t('response_messages.email_template_updated_successfully') }))
  }
  
  const formIsValid = () => {
    let formValid = true
    if (emailTemplate.subject === '') {
      setFormErrors({...formErrors, name: "can't be blank"})
      formValid = false
    }
    return formValid
  }

  return (
    <EditEmailTemplate
      emailTemplate={emailTemplate}
      validationErrors={formErrors}
      onSave={saveEmailTemplate}
      handleEmailTemplate={setEmailTemplate}
    />
  )
}

export default EditEmailTemplateContainer
