import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route } from 'react-router-dom'
import ShowOrderFulfillmentCenterContainer from '../containers/showOrderFulfillmentCenterContainer'

const OrderFulfillmentCenter = ({ match }) => (
  <Switch>
    <Route path={`${match.path}/:id`} component={ShowOrderFulfillmentCenterContainer} />
  </Switch>
)

OrderFulfillmentCenter.propTypes = {  
  match: PropTypes.object,
}

export default OrderFulfillmentCenter
