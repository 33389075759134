import { HttpClient } from './httpClient' 
import { API_URL } from './RestAPI'
import apiHeaders from './apiHeaders'
import { setUrlForGetRequest } from './sharedFunctions'

//Setting the email templates URI
const EmailCampaign_API = `${API_URL}/email-campaigns`

// Create
const createEmailCampaign = emailCampaign => {
  return HttpClient.post(
    `${EmailCampaign_API}/create`,
    emailCampaign,
    apiHeaders()
  )
}

// Read
const getEmailCampaigns = (params, dependent = {}) => {
  let url = setUrlForGetRequest(EmailCampaign_API, params)
  return HttpClient.get(url, apiHeaders(dependent))
}

// Get
const getEmailCampaign = id => {
  return HttpClient.get(
    `${EmailCampaign_API}/${id}`,
    apiHeaders()
  )
}

// Update
const updateEmailCampaign = (id, emailCampaign) => {
  return HttpClient.put(
    `${EmailCampaign_API}/${id}/update`,
    emailCampaign,
    apiHeaders()
  )
}

// Delete
const removeEmailCampaign= emailCampaign => {
    return HttpClient.delete(
      `${EmailCampaign_API}/${emailCampaign.id}`,
      apiHeaders()
    )
  }

//Encapsulating in a JSON object
const EmailCampaignApi = {getEmailCampaigns, createEmailCampaign, getEmailCampaign, updateEmailCampaign, removeEmailCampaign}

export {EmailCampaignApi}