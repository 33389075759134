import * as collectionActions from '../actions/collectionActions'
import initialState from './initialState'

const collections = (state = initialState.collections, action) => {
  let newState
  switch (action.type) {
    case collectionActions.GET_COLLECTIONS:
      return {
        ...state,
        loading: true
      }
    case collectionActions.GET_COLLECTIONS_SUCCESS:
      return {
        ...action.payload,
        loading: false,
        created: state.created,
        updated: state.updated
      }
    case collectionActions.GET_COLLECTION:
      return {
        ...state,
        loading: true
      }
    case collectionActions.GET_COLLECTION_SUCCESS:
      let indexOfCollectionToGet = state.data.findIndex(({ id }) => id === action.payload.id)
      let newStateAfterGet = {...state}
      if( indexOfCollectionToGet === -1) {
        newStateAfterGet.data.push(action.payload)
      } else {
        newStateAfterGet.data[indexOfCollectionToGet] = action.payload
      }
      return newStateAfterGet
    case collectionActions.CREATE_COLLECTION:
      return {
        ...state,
        updating: true
      }
    case collectionActions.CREATE_COLLECTION_SUCCESS:
      let newCatsState = {...state}
      newCatsState.data.push(action.payload)
      newCatsState.created = true
      return newCatsState
    case collectionActions.UPDATE_COLLECTION:
      return {
        ...state,
        updating: true
      }
    case collectionActions.UPDATE_COLLECTION_SUCCESS:
      let indexOfCatToUpdate = state.data.findIndex(({ id }) => id === action.payload.id)
      newState = {...state}
      newState.data[indexOfCatToUpdate] = action.payload
      newState.updated = true
      return newState
    case collectionActions.DELETE_COLLECTION:
      return {
        ...state
      }
    case collectionActions.DELETE_COLLECTION_SUCCESS:
      let indexOfCatToDelete = state.data.findIndex(({ id }) => id === action.payload.id)
      newState = {...state}
      newState.data.splice(indexOfCatToDelete, 1)
      return newState
    case collectionActions.RESET_CREATED:
      return {
        ...state,
        created: false
      }
    case collectionActions.RESET_UPDATED:
      return {
        ...state,
        updated: false
      }
    default:
      return state
  }
}

export default collections
