import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@mui/material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import AuthStorage from '../../services/authStorage'
import useTranslation from '../../hooks/useTranslation'

const Auth = new AuthStorage()

const styles = {
  default: {
    paddingRight: '3px',
    paddingLeft: '5px',
    fontSize: '12px'
  },
  navBar: {
    color: 'white',
    fontSize: '12px',
    borderColor: 'white',
    marginRight: '1%',
    '&:focus': {
      borderColor: 'white',
      color: 'white'
    },
    '&:hover': {
      borderColor: 'white',
      color: 'black',
      backgroundColor: '#EFEFEF'
    }
  }
}

const ViewInStoreButton = ({ path, navBar }) => {
  const storeUrl = Auth.getStoreUrl(path)
  const { t } = useTranslation()
  return (
    <Button
      size='small'
      variant='outlined'
      href={storeUrl}
      sx={navBar ? styles.navBar : styles.default}
      target="blank" >
      {navBar ? t('labels.view_store') : 'View in store'}
      <OpenInNewIcon sx={{marginLeft: '3px', fontSize: '14px'}} />
    </Button>
  )
}

ViewInStoreButton.propTypes = {
  path: PropTypes.string,
  navBar: PropTypes.bool,
}

export default ViewInStoreButton