import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Box, Avatar, Card, CardContent, Typography } from '@mui/material'
import UsersIcon from '@mui/icons-material/Group'
import SalesIcon from '@mui/icons-material/AttachMoney'
import ProductIcon from '@mui/icons-material/Ballot'
import OrderIcon from '@mui/icons-material/ShoppingCart'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import NoGrowthIcon from '@mui/icons-material/Remove'
import useTranslation from '../../hooks/useTranslation'

const Tile = ({ title, object, module, storeCurrency }) => {
  const { t } = useTranslation()
  const styles = {
    tileCard: {
      height: '100%'
    },
    typographyTitle: {
      fontSize: '0.85rem',
      fontWeight: '550',
      letterSpacing: '0.5px',
      lineHeight: '2.5'
    },
    growthText: {
      fontSize: 'small',
      paddingLeft: '10%'
    }
  }

  const growthIcon = (difference) => {
    if(difference > 0) {
      return <ArrowUpwardIcon style={{ color: 'green', position: 'absolute' }} fontSize='medium'/>
    }
    else if(difference < 0) {
      return <ArrowDownwardIcon style={{ color: 'red', position: 'absolute' }} fontSize='medium'/>
    }
    else {
      return <NoGrowthIcon style={{ color: 'grey', position: 'absolute' }} fontSize='medium'/>
    }
  }

  const moduleIcons = (module) => {
    switch (module) {
      case global.constants.labels.product:
        return <Avatar style={{backgroundColor: '#D14343'}}><ProductIcon fontSize="medium" /></Avatar>
      case global.constants.labels.order:
        return <Avatar style={{backgroundColor: '#FFB020'}}><OrderIcon fontSize="medium" /></Avatar>
      case global.constants.labels.profit:
        return <Avatar style={{backgroundColor: '#5048E5'}}><SalesIcon fontSize="medium" /></Avatar>
      case global.constants.labels.user:
        return <Avatar style={{backgroundColor: '#14B8A6'}}><UsersIcon fontSize="medium" /></Avatar>
    }
  }

  return (
    <Grid item xs={12} sm={6} lg={3} xl={3}>
      <Card elevation={3} sx={styles.tileCard}><CardContent>
        <Grid container spacing={3}>
          <Grid item xs={9}>
            <Typography
              variant='overline' 
              sx={styles.typographyTitle}
              color="textSecondary"
              gutterBottom>
              {title}
            </Typography>
            <Typography variant="h3" component="div">{storeCurrency ? `${object.displayValue} ${storeCurrency.symbol}` : object.displayValue}</Typography>
          </Grid>
          <Grid item xs={3}>{moduleIcons(module)}</Grid>
        </Grid>
        {object.difference !== undefined && (
          <Box>
            {growthIcon(object.difference)}
            <Typography variant="caption" color='textSecondary' sx={styles.growthText}>{Math.abs(object.difference)}% {t('labels.since_last_month')}</Typography>
          </Box>
        )}
      </CardContent></Card>
    </Grid>
  )
}

Tile.propTypes = {
  title: PropTypes.string.isRequired,
  object: PropTypes.object.isRequired,
  module: PropTypes.string.isRequired,
  storeCurrency: PropTypes.object
}

export default Tile