/* eslint-disable indent */
import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Card, Typography, List, ListItem, Divider, Button, CardHeader } from '@mui/material'
import { Link } from 'react-router-dom'
import ArrowRight from '@mui/icons-material/ArrowRight'
import ImageThumbnail from '../Shared/ImageThumbnail'
import useTranslation from '../../hooks/useTranslation'

const TopProducts = ({ topProducts, classes }) => {
  const { t } = useTranslation()
  return (
    <Grid item xs={12} md={6} lg={4} xl={3}>
      <Card elevation={3} >
        <CardHeader title={t('labels.top_products')} />
        <List sx={classes.topProductsList}>
          {topProducts.map((topProduct) => (
            <React.Fragment key={topProduct.id}>
              <Divider />
              <ListItem>
                <ImageThumbnail image={topProduct.image} height='50px' width='50px' />
                &nbsp;&nbsp;
                <Link to={`/admin/products/edit/${topProduct.id}`}>{topProduct.name}</Link>
              </ListItem>
              <Typography variant="caption" sx={classes.topProductsText}>{topProduct.total_quantity} {topProduct.total_quantity > 1 ? t('labels.items') : t('labels.item')} {t('labels.sold_in_last_month')}</Typography>
            </React.Fragment>)
          )}
          <Divider />
          <Grid item textAlign='right'>
            <Button size="large" sx={classes.viewAllBtn} variant="text" href="/admin/products">View all<ArrowRight/></Button>
          </Grid>
        </List>
      </Card>
    </Grid>
  )
}

TopProducts.propTypes = {
  topProducts: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired
}

export default TopProducts