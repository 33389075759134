import * as discountActions from '../actions/discountActions'
import initialState from './initialState'

const discounts = (state = initialState.discounts, action) => {
  switch (action.type) {
    case discountActions.GET_DISCOUNTS:
      return {
        ...state,
        loading: true
      }
    case discountActions.GET_DISCOUNTS_SUCCESS:
      return {
        ...action.payload,
        loading: false,
        created: state.created,
        updated: state.updated
      }
    case discountActions.GET_DISCOUNT:
      return {
        ...state,
        loading: true
      }
    case discountActions.GET_DISCOUNT_SUCCESS:
      let indexOfCategoryToGet = state.data.findIndex(({ id }) => id === action.payload.id)
      let newStateAfterGet = {...state}
      if( indexOfCategoryToGet === -1) {
        newStateAfterGet.data.push(action.payload)
      } else {
        newStateAfterGet.data[indexOfCategoryToGet] = action.payload
      }
      return newStateAfterGet
    case discountActions.CREATE_DISCOUNT:
      return {
        ...state,
        updating: true
      }
    case discountActions.CREATE_DISCOUNT_SUCCESS:
      let newCatsState = {...state}
      newCatsState.data.push(action.payload)
      newCatsState.created = true
      return newCatsState
    case discountActions.UPDATE_DISCOUNT:
      return {
        ...state,
        updating: true
      }
    case discountActions.UPDATE_DISCOUNT_SUCCESS:
      let indexOfCatToUpdate = state.data.findIndex(({ id }) => id === action.payload.id)
      let newState = {...state}
      newCatsState.updated = true
      newState.data[indexOfCatToUpdate] = action.payload
      return newState
    case discountActions.DELETE_DISCOUNT:
      return {
        ...state
      }
    case discountActions.DELETE_DISCOUNT_SUCCESS:
      let indexOfCatToDelete = state.data.findIndex(({ id }) => id === action.payload.id)
      let newDiscountState = {...state}
      newDiscountState.data.splice(indexOfCatToDelete, 1)
      return newDiscountState
    case discountActions.SET_DISCOUNT_CREATED:
      return {
        ...state,
        created: action.payload
      }
    default:
      return state
  }
}

export default discounts
