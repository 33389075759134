import React, { Component } from 'react'
import SplashImagePreview from './splashImagePreview'
import { ProductApi } from '../../api/productApi'
import { CategoryApi } from '../../api/categoryApi'
import { PageApi } from '../../api/pageApi'
import SiteLinks from '../siteLinks'
import CategoryIcon from '@mui/icons-material/Category'
import PageIcon from '@mui/icons-material/MenuBook'
import ProductIcon from '@mui/icons-material/Ballot'
import { Grid, FormGroup, FormControl , FormHelperText, MenuItem, Select, TextField , Input, InputLabel, FormLabel, Paper, Box } from '@mui/material';
import ImageThumbnail from '../Shared/ImageThumbnail'
import TextEditor from '../Helpers/TextEditor'
import ButtonPositionRadio from '../Shared/ButtonPositionRadio'
import RightImagePanel from '../Shared/UploadImageWidget';

const styles = {
  paper: {
    padding: '5%',
  },
  leftPanel: {
    maxHeight: '100vh',
    overflow: 'auto'
  }
}

class SplashImage extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      splashImage: this.props.splash_image,
      textColorPicker: false,
      buttonColorPicker: false,
      urlOptions: {
        'Category': [],
        'Product': [],
        'Page': [] 
      },
      search: '',
      filteredOptions: {},
      nestedMenu: false,
      currentUrlOptions: [],
      selectedModuleOption: {
        label: this.props.splash_image.url_module_name !== '' ? this.props.splash_image.url_resource_name : this.props.splash_image.url,
        value: this.props.splash_image.url,
        module_name: this.props.splash_image.url_module_name
      },
      selectedModule: this.props.splash_image.url_module_name,
      showOptions: false,
    }
    this.updateSplashImageState = this.updateSplashImageState.bind(this)
  }

  fetchProducts = async () => (await ProductApi.getProducts({ visible: true }))
  fetchCategories = async () => (await CategoryApi.getCategories({ visible: true }))
  fetchPages = async () => (await PageApi.getPages({ visible: true }))

  componentDidMount() {
    Promise.all([this.fetchCategories(), this.fetchProducts(), this.fetchPages()]).then((values) => {
      let _urlOptions = { 'Category': values[0].data.data, 'Product': values[1].data.data, 'Page': values[2].data.data }
      this.setState({urlOptions: _urlOptions});
      this.resetDefaultOptions()
    });
  }

  listBackButtonOnClick = (event) => {
    this.setState({ nestedMenu: false})
    this.setState({ search: ''})
    this.resetDefaultOptions();
  }

  resetDefaultOptions = () => {
    this.setState({
      currentUrlOptions: ( Object.keys(this.state.urlOptions).map((option) => 
        ({ label: option, value: option, image: (
          option == 'Category' ? 
          <CategoryIcon/> :
          (option == 'Product'
          ? 
          <ProductIcon/> 
          : <PageIcon/>
          ))
        })
      ))
    })
  }

  setProducts = () => {
    this.setState({ currentUrlOptions: (this.state.urlOptions['Product'].map((product) => {
      return { 
        label: product.name,
        value: ("/" + product.category_slug + "/" + product.slug),
        image: (product.images.length > 0 ? product.images[0].image : null )
      }
    }))
  })}

  setCategories = () => {
    this.setState({currentUrlOptions: (this.state.urlOptions['Category'].map((category) => (
      { label: category.name,
        value: ("/" + category.slug),
        image: (category.image ? category.image : null) }
    )))
    })}

  setPages = () => {
    this.setState({ currentUrlOptions: (this.state.urlOptions['Page'].map((page) => ({ label: page.name, value: ("/pages/" + page.slug) })))})
  }

  handleOnListClicked = (event) => {
    if(!this.state.nestedMenu) {
      let option = event.target.dataset.value;
      if(option === 'Product') {
        this.setProducts()
      } else if(option === 'Category') {
        this.setCategories()
      } else if(option === 'Page') {
        this.setPages()
      }
      this.setState({ nestedMenu: true })
      this.setState({ search: '' });
      this.setState({ selectedModule: (option) });
    }
    else {
      this.setState({ showOptions: false})
      this.setState({ selectedModuleOption: ({ label: event.target.innerText, value: event.target.dataset.value, module_name: this.state.selectedModule })})
      let _splashImage = {...this.state.splashImage, url_resource_name: event.target.innerText, url: event.target.dataset.value, url_module_name: this.state.selectedModule }
      this.props.onUpdate(_splashImage)
      this.setState({splashImage: _splashImage })
    }
  }

  urlOnChange = (event) => {
    this.setState({ selectedModuleOption: ({ label: event.target.value, value: event.target.value, module_name: '' })})
    let splashImage = { ...this.state.splashImage, url: event.target.value, url_resource_name: event.target.value, url_module_name: '' }
    this.props.onUpdate(splashImage)
    this.setState({splashImage: splashImage})
  }

  searchOnChange = (event) => {
    this.setState({search: event.target.value})
    if(event.target.value != '') {
      this.setState({ filteredOptions: (this.state.currentUrlOptions.filter((option) => (
        option.value.toLowerCase().includes(event.target.value.toLowerCase())
      )))
    })
    }
  }

  updateSplashImageState = (event, fieldName) => {
    const field = event.target?.name || fieldName;
    let splashImage = { ...this.state.splashImage }
    if (!event.target) {
      splashImage[field] = event
    } else {
      switch (event.target.type) {
        case 'file':
          splashImage[field] = event.target.files[0]
          event.target.files[0] && (splashImage.image_preview_url = URL.createObjectURL(event.target.files[0]))
          break
        default:
          splashImage[field] = event.target.value
      }
    }
    this.props.onUpdate(splashImage)
    return this.setState({splashImage: splashImage})
  }

  updateSplashTextColor = (name, value) => {
    let splashImage = { ...this.state.splashImage }
    splashImage[name] = value
    this.props.onUpdate(splashImage)
    return this.setState({splashImage: splashImage})
  }

  deleteImage = () => {
    this.updateSplashImageState({ target: { name: '_delete_image', value: true } });
    let splashImage = { ...this.state.splashImage, image: '', image_file: '', image_preview_url: '', _delete_image: true };
    this.setState({ splashImage });
  }

  render() {
    const { t } = this.props;
    return (
      <>
      <FormGroup className={(this.state.splashImage._delete ? 'hidden' : '')} style={{marginBottom: '20px'}}>
        <Grid container spacing={2}>
          {(this.state.splashImage.image_preview_url || this.state.splashImage.image) && (
            <Grid item md={12} xs={12}>
              <SplashImagePreview splashImage={this.state.splashImage}/>
            </Grid>
          )}
          <Grid item md={12} xs={12}>
            <Grid container direction="row" spacing={2}>
              <Grid container item md={8} xs={12}>
                <Grid item md={12} xs={12}>
                  <FormGroup style={{ marginTop: '20px' }}>
                    <TextField
                      id="outlined-basic"
                      label={t('labels.name')}
                      variant="outlined"
                      name="name"
                      value={this.state.splashImage.name || ''}
                      onChange={this.updateSplashImageState}
                    />
                    <FormHelperText style={{ marginBottom: '5px' }}>{t('labels.name_helper')}</FormHelperText>
                  </FormGroup>
                </Grid>
                <Grid container direction="row" spacing={1}>
                  <Grid item md={12} xs={12}>
                    <FormGroup style={{marginTop: '20px'}}>
                      <InputLabel>{t('labels.headline_text')}</InputLabel>
                      <TextEditor name="headline_text" rows={10} value={this.state.splashImage.headline_text || ''} onChange={this.updateSplashImageState} />
                      <FormHelperText style={{ marginBottom: '5px' }}>{t('labels.headline_helper')}</FormHelperText>
                  </FormGroup>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormGroup style={{marginTop: '20px'}}>
                      <FormControl variant="outlined">
                      <InputLabel id="select-text-color-label">{t('labels.text_color')}</InputLabel>
                      <Select
                        labelId="select-text-color-label"
                        name='text_color'
                        value={this.state.splashImage.text_color || ''}
                        label="Text Color"
                        onChange={this.updateSplashImageState}
                        style={{minWidth: '100%'}}
                      >
                        <MenuItem value={'black'}>Black</MenuItem>
                        <MenuItem value={'white'}>White</MenuItem>
                      </Select>
                      </FormControl>
                      <FormHelperText style={{ marginBottom: '5px' }}>{t('labels.color_helper')}</FormHelperText>
                    </FormGroup>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={4} xs={12}>
                <Paper sx={styles.paper} elevation={3}>
                  <RightImagePanel object={this.state.splashImage} onChange={this.updateSplashImageState} field="image_file" deleteImage={this.deleteImage}/>
                </Paper>
                <Box mt={2}>
                    <ButtonPositionRadio
                      label="Select Splash Image Size"
                      options={[
                        { value: 'small', label: 'Small' },
                        { value: 'medium', label: 'Medium' },
                        { value: 'large', label: 'Large' },
                      ]}
                      value={this.state.splashImage.size}
                      onChange={this.updateSplashImageState}
                      name="size"
                      layout="vertical"
                    />
                </Box>
              </Grid>
            </Grid>
            <Grid container direction="row" alignItems='center' spacing={2}>
              <Grid item md={6} xs={12}>
                <FormGroup style={{ marginTop: '20px' }}>
                  <TextField
                    id="outlined-basic"
                    label={t('labels.button_text')}
                    variant="outlined"
                    name="button_text"
                    value={this.state.splashImage.button_text || ''}
                    onChange={this.updateSplashImageState}
                  />
                </FormGroup>
              </Grid>
              <Grid item md={6} xs={12}>
                <FormGroup style={{marginTop: '20px'}}>
                <FormLabel>{t('labels.button_url')}</FormLabel>
                  <SiteLinks
                    type='splashImage'
                    selectedModuleOption={this.state.selectedModuleOption}
                    urlOnChange={this.urlOnChange}
                    listBackButtonOnClick={this.listBackButtonOnClick}
                    search={this.state.search}
                    searchOnChange={this.searchOnChange}
                    currentUrlOptions={this.state.currentUrlOptions}
                    handleOnListClicked={this.handleOnListClicked}
                    filteredOptions={this.state.filteredOptions}
                    nestedMenu={this.state.nestedMenu}
                    setShowOptions={(value) => this.setState({showOptions: value})}
                    showOptions={this.state.showOptions}
                    setShowOptions={ (value) => { this.setState({ showOptions: value }) }}
                    splashImage={this.state.splashImage}
                  />
                  <FormHelperText style={{ marginBottom: '5px' }}>{t('labels.url_helper')}</FormHelperText>
                </FormGroup>
              </Grid>
              <Grid item md={6} xs={12}>
                <ButtonPositionRadio
                  label="Select Button Position"
                  options={[
                    { value: 'left', label: 'Left' },
                    { value: 'center', label: 'Center' },
                    { value: 'right', label: 'Right' },
                  ]}
                  value={this.state.splashImage.button_position}
                  onChange={this.updateSplashImageState}
                  name="button_position"
                  layout="horizontal"
                />
              </Grid>
            </Grid>
          </Grid>
         </Grid>
      </FormGroup>
      </>
    )
  }
}

export default SplashImage
