import React from 'react'
import PropTypes from 'prop-types'

import InputLabel from '@material-ui/core/InputLabel'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/styles'
import useTranslation from '../../hooks/useTranslation'

const useStyles = store => makeStyles((theme) => {
  const normalFontsFamily = 'NormalFonts'
  let boldFontsFamily = normalFontsFamily
  if(store.bold_fonts) {
    boldFontsFamily = 'BoldFonts'
  }
  return ({
    container: {
      padding: '15px',
      width: '100%',
      height: '100%',
      background: '#fff',
      borderRadius: '1px',
      boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
      display: 'inline-block',
    },
    textContainer: {
      borderRadius: '2px',
      color: store.text_color,
      fontFamily: `'${normalFontsFamily}', 'Helvetica Neue', 'inherit'`
    },
    primaryButton: {
      backgroundColor: store.primary_color,
      color: store.secondary_color,
      borderRadius: '2px',
      padding: '10px 20px',
      marginTop: '10px',
      fontFamily: 'inherit',
      '&:hover': {
        backgroundColor: store.primary_color,
      }
    },
    h1: {
      fontFamily: `'${boldFontsFamily}', 'Helvetica Neue', 'inherit'`
    },
    h2: {
      fontFamily: `'${boldFontsFamily}', 'Helvetica Neue', 'inherit'`
    },
    thumb: {
      height: 'auto',
      maxWidth: '100%',
    }
  })
})


const ThemePreview = (props) => {
  const { t } = useTranslation()
  const classes = useStyles(props.store)()
  // @import '${props.store.normal_fonts}';
  const boldFonts = (props.store.bold_fonts ? `
      @font-face {
        font-family: 'BoldFonts';
        font-style: normal;
        font-weight: normal;
        src: local('BoldFonts'), url('${props.store.bold_fonts}');
      }
    `
    : 
    ''
  )
  const fontsCSS = `
    @font-face {
      font-family: 'NormalFonts';
      font-style: normal;
      font-weight: normal;
      src: local('NormalFonts'), url('${props.store.normal_fonts}');
    }
    ${boldFonts}
  `
  return(
    <>
      <style>{props.store.use_custom_fonts && props.store.normal_fonts ? fontsCSS : ''}</style>
      <InputLabel>{t('labels.theme_settings.theme_preview')}</InputLabel>
      <div className={classes.container}>
        <div className={classes.textContainer}>
          <h1 className={classes.h1}>{t('labels.theme_settings.heading1')}</h1>
          {t('labels.theme_settings.preview_text')}
          <hr/>
          <h2 className={classes.h1}>{t('labels.theme_settings.heading2')}</h2>
          {t('labels.theme_settings.button_preview_text')}
          <Button variant='contained' className={classes.primaryButton}>{t('labels.theme_settings.button_example')}</Button>
        </div>
      </div>
    </>
  )
}

ThemePreview.propTypes = {
  store: PropTypes.object.isRequired
}

export default ThemePreview
