import React from "react";
import { PropTypes } from 'prop-types'
import { Grid, Button, Typography } from "@mui/material";
import FileUpload from "./Form/FileUpload";
import useTranslation from '../../hooks/useTranslation'

const RightImagePanel = ({ object, onChange, deleteImage, title, field, description, size }) => {
  const image = (field === 'background_image') ? object[field] : (field ? object[field] || object['image'] : object.image);
  const _delete_image = (field ? object[`_delete_${field}`] : object._delete_image)
  const new_image = (field ? object[`new_${field}`] : object.new_image)
  const image_preview_url = field ? object[field === 'image_file' ? 'image_preview_url' : `${field}_preview_url`] : object.image_preview_url;
  const { t } = useTranslation()

  const styles = {
    image: {
      maxHeight: (size === 'medium' ? '100px' : '200px')
    }
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography gutterBottom variant="h5" component="h2">
          {title ? title : t('labels.image')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {description && (
          <Typography variant="subtitle1" gutterBottom component="div">
            {description}
          </Typography>
        )}
      </Grid>
      { (( image_preview_url || image) && (!_delete_image || new_image)) ?
        <Grid container xs={12} item>
          <Grid item xs={12}>
            <img style={styles.image} src={image_preview_url ? `${image_preview_url}` : `${image}`} alt='' />
          </Grid>
        </Grid>
        : 
        <></>
      }
      <Grid item xs={7} sm={5} md={8}>
        <FileUpload onChange={onChange} name={field ? field : 'image'}/>
      </Grid>
      {(deleteImage && (image_preview_url || image) && !_delete_image) &&
        <Grid item xs={5} md={4}>
          <Button variant="outlined" color="error" component="label" onClick={() => { if (window.confirm(global.constants.labels.are_you_sure)) deleteImage() } }>
            {t('labels.remove')}
          </Button>
        </Grid>
      }
    </Grid>
  )
}

RightImagePanel.propTypes = { 
  object: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  deleteImage: PropTypes.func,
  title: PropTypes.string,
  field: PropTypes.string,
  description: PropTypes.string,
  size: PropTypes.string
}

export default RightImagePanel