module.exports = global.constants = Object.freeze({
  controllers: {
    Stores: 'Stores',
    FrontPage: 'FrontPage',
    Categories: 'Categories',
    Discounts: 'Discounts',
    Coupons: 'Coupons',
    Products: 'Products',
    ProductImages: 'ProductImages',
    ProductVariants: 'ProductVariants',
    Pages: 'Pages',
    Orders: 'Orders',
    Users: 'Users',
    Transactions: 'Transactions',
    Addresses: 'Addresses',
    UserGroups: 'UserGroups',
    Collections: 'Collections',
    Menus: 'Menus',
    Variants: 'Variants',
    EmailTemplates: 'EmailTemplates',
    ProductsReorder: 'ProductsReorder',
  },
  actions: {
    create: 'create',
    update: 'update',
    show: 'show',
    destroy: 'destroy',
    retrieve: 'retrieve',
    list: 'list',
    index: 'index',
    addRemovePermission: 'addRemovePermission',
    sendChrismasGift: 'sendChrismasGift',
    currentUser: 'currentUser',
    invited: 'invited',
    deleteInvite: 'deleteInvite',
    sendInvite: 'sendInvite',
    remove: 'remove'
  },
  dependentSource: {
    product: 'Product',
    userGroup: 'UserGroup',
    discount: 'Discount',
    collection: 'Collection',
    order: 'Order'
  },
  discount_type_values: {
    free_delivery: 'free_delivery',
    fixed_amount: 'fixed_amount',
    percentage: 'percentage'
  },
  labels: {
    notify_waitlist: 'Notify waitlist',
    notify_waitlist_help: 'Sends an email to all the customers who registered for this product.',
    percentage: 'Percentage',
    fixed_amount: 'Amount',
    free_delivery: 'Free Delivery',
    are_you_sure: 'Are you sure?',
    authentication: 'authentication',
    password_protection: 'password_protection',
    login_require_mandatory: 'Login Require (Mandatory)',
    login_require_optional: 'Login Require (Optional)',
    no_authentication: 'No Authentication',
    enable_account_registration: 'enable_account_registration',
    auto_account_approval: 'auto_account_approval',
    no_authentication_code: '0',
    password_protected_code: '1',
    ip_restricted_code: '2',
    addLinkLabel: 'Click on the + button to add links.',
    user_registration: 'User Registration',
    auto_approve_registered_accounts: 'Auto Approve Registered Accounts',
    product: 'Product',
    order: 'Order',
    profit: 'Profit',
    user: 'User',
    options: 'Options',
    exportToCsv: 'Export to CSV',
    active: 'Active',
    unapproved: 'Unapproved',
    invited: 'Invited',
    removed: 'Removed',
    add: 'Add',
    edit: 'Edit',
    new: 'New',
    select: 'Select',
    image: 'Image',
    close: 'Close',
    variant: 'Variant',
    submit: 'Submit',
    document: 'Document',
    artwork: 'Artwork',
    done: 'Done',
    discard: 'Discard',
    proof_sheet: 'Proof Sheet',
    allow_back_orders_label: 'Continue selling when out of stock',
    no_variant_options_available: 'No variant options available.',
    bundle_product: 'Bundle Product',
    unit_price: 'Unit Price',
    price: 'Price',
    unit_price_helptext: 'This price should only be used to display price of single product. Only Variant prices will be used for order.',
    bundle_option_headline: 'You can add multiple bundle options under this, i.e., 5-Pack, 10-Pack, 20-Pack, and the system will create bundles for all the variants of this product; the price for each bundle can be set on each variant below.',
    variants_as_bundle: 'This will add a special option for bundling the product. Useful when selling products in bulk i.e you might want to sell pens only in bulk instead of a single pen.',
    is_bundle_product: 'bundle product?',
    bundle: 'Bundle',
    sku_multiple_help: 'SKU multiple determines how many SKU units need to be shipped to fulfil this bundle, especially useful when an SKU has more than one items packed in it. A bundle of 20 pens will need only 4 SKUs, when one SKU is package of 5 pens.',
    variant_option_headline: 'If this product has multiple options, like different sizes or colors, click on the Add button to add variant options.',
    variant_headline: 'Product variants added on the basis of options provided.',
    unknown: 'Unknown',
    restrictedContent: 'Cannot modify restricted content',
    invite_user: 'Do you want to invite users via email?',
    email_template_placeholder: 'Start typing...',
    create_email_template: 'Create Email Template',
    email_templates: 'Email Templates',
    email_template: 'Email Template',
    body: 'Body',
    send_email: 'Send Email',
    email_menu: 'Emails',
    search_email_templates: 'Search Templates',
    search_users: 'Search Users',
    send_email: 'Send Emails',
    email_input: 'Enter Email Details',
    preview: 'Preview',
    select_template: 'Select Email Template',
    search_email_templates: 'Search Templates',
    unchecked_public: 'When unchecked, all the menu links will be hidden on the store.',
    category_display: 'Will be displayed on landing page after the splash images section.',
    collection_display: 'Will be displayed on landing page after the categories section.',
    invite_user: 'Do you want to invite users via email?',
    email_campaign: 'Email Campaign',
    search_email_campaigns: 'Search Campaigns',
    create_email_campaign: 'Create Email Campaign',
    email_campaigns: 'Email Campaigns',
    email_action: 'It will send email to all selected users. Do you want to continue?',
    campaign_duplication: 'It will copy all the data to a new campaign. Do you want to continue?',
    save_and_send_email: 'Save and Send Email',
    duplicate: 'Duplicate',
    custom: 'Custom',
    select_users: 'Select users',
    to: 'To',
    all_users: 'All Users',
    manager: 'Manager',
    staff: 'Staff',
    filter_users: 'Filter users by role',
    select_role: 'Select Role'
  },
  response_messages: {
    coupon_deleted_successfully: 'Coupon deleted successfully.',
    category_created_successfully: 'Category created successfully',
    category_updated_successfully: 'Category updated successfully',
    category_deleted_successfully: 'Category deleted successfully',
    store_updated_successfully: 'Store updated successfully.',
    user_updated_successfully: 'User updated successfully.',
    user_created_successfully: 'User created successfully.',
    user_deleted_successfully : 'User deleted successfully.',
    user_deleted_failed : 'Something went wrong while deleting the user. Please try again later!',
    order_updated_successfully: 'Order updated successfully.',
    order_created_successfully: 'Order created successfully.',
    user_added_to_user_group: 'User has been added to user group.',
    user_successfully_approved: 'User has been successfully approved.',
    invitation_sent_successfully: 'Invitation sent successfully.',
    invitation_delete_failure: 'Something went wrong while deleting the invite. Please try again later!',
    user_removed_to_user_group: 'User has been removed from user group.',
    password_protection_label: 'Put your online store behind a password or restrict IP Addresses to control access.',
    password_protection_field_label: 'Set a password or allow IP Address.',
    user_registration_help_block: 'Allow users to register account.',
    auto_approve_account_help_block: 'Auto approve accounts upon registration. If unchecked, you\'ll need to manually approve registered users before they can login.',
    order_tracking_success: 'Order Tracking Successfully Updated.',
    order_tracking_email_success: 'Information Saved and Email is sent to customer successfully.',
    no_variant_options_label: 'No Available Variant Options.',
    email_template_created_successfully: 'Email Template created successfully',
    email_template_updated_successfully: 'Email Template updated successfully',
    email_template_deleted_successfully: 'Email Template deleted successfully',
    email_campaign_created_successfully: 'Email Campaign created successfully',
    email_campaign_duplicated_successfully: 'Email Campaign duplicated successfully',
    email_campaign_updated_successfully: 'Email Campaign updated successfully',
    email_campaign_deleted_successfully: 'Email Campaign deleted successfully',
    products: {
      duplicate_created: 'Duplicate of the product created successfully',
      created: 'Product is created successfully',
      updated: 'Product is updated successfully',
      deleted: 'Product deleted successfully'
    },
    variants: {
      added: 'Variant successfully added.',
      already_exists: 'Variant already exists.'
    },
    email_templates: {
      email_successfully_sent: 'Emails sent successfully.',
    },
    collections: {
      created: 'Collection is created successfully',
      updated: 'Collection is updated successfully',
      deleted: 'Collection deleted successfully',
      deleted_failed : 'Something went wrong while deleting the collection. Please try again later!'
    },
    discounts: {
      created: 'Discount is created successfully',
      updated: 'Discount is updated successfully',
      deleted: 'Discount deleted successfully',
      deleted_failed : 'Something went wrong while deleting the discount. Please try again later!'
    },
    pages: {
      created: 'Page is created successfully',
      updated: 'Page is updated successfully',
      deleted: 'Page deleted successfully',
      deleted_failed : 'Something went wrong while deleting the page. Please try again later!'
    }
  },
  error_messages: {
    variant_stock: {
      quantity: 'Please enter quantity to add stock',
      incoming_inventory_date: 'Please select date for incoming stock'
    },
    email_templates: {
      email_template: 'Please select an email template',
      custom_email_templae: 'Subject and body cannot be empty for a custom template',
      email_successfully_sent: 'Emails sent successfully.',
      email_error: 'Something went wrong while sending emails. Please try again later!'
    }
  },
  perPage: {
    default: 20,
    coupons: 5
  },
  placeholders: {
    name: 'Name',
    subject: 'Subject',
    type: 'Type'
  }
})
