import React from 'react'
import PropTypes from 'prop-types'
import LinksContainer from '../../containers/Menu/linksContainer'
import MenuPreview from './menuPreview'
import { Grid, Divider, Checkbox, TextField, MenuItem, Paper, Fab, Button } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { makeStyles } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
import useTranslation from '../../hooks/useTranslation'

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingRight: 0,
    width: '100%',
    border: "1px solid lightgrey",
  },
  containerBody: {
    padding: theme.spacing(3),
  },
  containerHeading: {
    backgroundColor: "#f5f5f5",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

const EditMenu = ({ menu, validationErrors, newLink, updateLink, onSave, onChange, setLinks, sortLinks, updating, listingTypes }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return(
    <Grid container spacing={5} alignItems="start">
      <Grid container item={true} spacing={3} xs={12} md={9} lg={9} xl={9}>
        <Paper className={classes.paper}>
          <Grid container item={true} spacing={3} className={classes.containerBody}>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <TextField
                id="menuName"
                label={t('labels.name')}
                name="name"
                value={menu.name}
                placeholder={t('labels.name')}
                onChange={onChange}
                variant='outlined'
                fullWidth />
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <TextField
                id="menuDescription"
                label={t('labels.description')}
                name="description"
                value={menu.description}
                placeholder={t('labels.description') }
                onChange={onChange}
                variant='outlined'
                disabled
                multiline
                fullWidth />
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <TextField
                id="menuType"
                name="type"
                value={menu.type}
                variant='outlined'
                label={t('labels.menus.type')}
                onChange={onChange}
                disabled
                helperText={t('labels.menus.menuPositionsHelper')}
                select
                fullWidth >
                {Object.keys(listingTypes).map(key => (<MenuItem key={key} value={key}>{listingTypes[key]}</MenuItem>))}
              </TextField>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid container item={true} spacing={3} xs={12} md={3} lg={3} xl={3}>
        <Paper className={classes.paper}>
          <Grid container item={true} className={classes.containerBody}>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <h4>{t('labels.menus.status')}</h4>
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <FormControlLabel
                control={<Checkbox checked={menu.enabled} onChange={onChange} name="enabled" />}
                label={t('labels.menus.enabled')} />
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <FormControlLabel
                control={<Checkbox checked={menu.show_name} onChange={onChange} name="show_name" />}
                label={t('labels.menus.displayName')} />
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid container item={true} spacing={3} xs={12} md={9} lg={9} xl={9}>
        <Paper className={classes.paper}>
          <Grid container item={true} xs={12} md={12} lg={12} xl={12} className={classes.containerHeading}>
            <Grid item xs={12} md={8} lg={8} xl={8}>
              <h4>{t('labels.menus.links')}</h4>
            </Grid>
            <Grid item xs={12} md={4} lg={4} xl={4}>
              <Fab size="medium" className="pull-right" color="primary" onClick={newLink}><AddIcon fontSize="medium" /></Fab>
            </Grid>
          </Grid>
          <Divider width="100%"/>
          <Grid item xs={12} md={12} lg={12} xl={12} className={classes.containerBody}>
            <LinksContainer links={menu.links} onUpdate={updateLink} sortLinks={sortLinks} setLinks={setLinks} />
            {menu.links.filter((link) => (link._delete !== true)).length < 1 && t('labels.menus.addLinkLabel')}
          </Grid>
        </Paper>
      </Grid>

      <Grid container item={true} spacing={3} xs={12} md={9} lg={9} xl={9}>
        <Paper className={classes.paper}>
          <Grid item xs={12} md={12} lg={12} xl={12} className={classes.containerHeading}>
            <h4>{t('labels.menus.menuPreview')}</h4>
          </Grid>
          <Divider width="100%"/>
          <Grid item xs={12} md={12} lg={12} xl={12} className={classes.containerBody}  style={{maxHeight: 500, overflow: 'auto'}}>
            <MenuPreview links={menu.links.filter((link) => (link._delete !== true && link.visible))} menu={menu}/>
          </Grid>
        </Paper>
      </Grid>

      <Grid container item xs={12} md={12} lg={12} xl={12} >
        <Button
          disabled={updating}
          variant='contained'
          onClick={onSave}
          color="primary" >
          {updating ? t('labels.menus.saving') : t('labels.menus.save')}
        </Button>
      </Grid>
    </Grid>
  )
}

EditMenu.propTypes = {
  menu: PropTypes.object.isRequired,
  validationErrors: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  updateLink: PropTypes.func.isRequired,
  listingTypes: PropTypes.object.isRequired,
  sortLinks: PropTypes.func.isRequired,
  newLink: PropTypes.func.isRequired,
  setLinks: PropTypes.func.isRequired,
  updating: PropTypes.bool
}

export default EditMenu