import React, {useEffect, useState} from 'react'
import { useDispatch } from 'react-redux';
import { Grid, TextField, Button } from '@mui/material';
import CouponsTable from './couponsTable'
import { DiscountApi } from '../../api/discountApi'
import { setSuccessNotification, setErrorNotification, setInfoNotification } from '../../actions/notificationAction';
import useTranslation from '../../hooks/useTranslation'

const Coupons = props => {
  const [createError, setCreateError] = useState(false)
  const [tab, setTab] = useState('total')
  const [page, setPage] = useState(1)
  const [coupons, setCoupons] = useState({})
  const [newCoupons, setNewCoupons] = useState({coupons_to_create: '', prefix: ''})
  const [creating, setCreating] = useState(false)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const initialCounts = () => {
    return {
      'total': 0, 'ready': 0, 'used': 0, 'sent': 0, 'expired': 0
    }
  }

  const [counts, setCounts] = useState(initialCounts)

  const changePage = (event, pageNo) => {
    setPage(pageNo + 1)
  }

  const switchTab = (event, value) => {
    setTab(value)
    setPage(1)
    if(!coupons[value]){
      fetchCoupons(value)
    }
  }

  const fetchCoupons = async (status) => {
    let result = await DiscountApi.getDiscountCoupons(props.discountId, page, status)
    if(result.statusText === 'OK' || result.status === 200) {
      let _coupons = coupons
      _coupons[status] = result.data.coupons
      setCoupons({..._coupons})
      let resultCounts = result.data.counts
      let _counts = initialCounts()
      let totalCount = 0
      resultCounts.forEach((count) => {
        _counts[count.status] = parseInt(count.count)
        totalCount += parseInt(count.count)
      })
      _counts["total"] = totalCount
      setCounts({..._counts})
    }
  }
  
  useEffect(() => {
    fetchCoupons(tab)
  }, [page])

  const createCoupons = async (e) => {
    e.preventDefault()
    if(newCoupons.coupons_to_create <= 0) {
      setCreateError(true)
      return
    }
    setCreating(true)
    try {
      const result = await DiscountApi.createCoupons(props.discountId, newCoupons)
      if(coupons[tab].length < 5 && (tab === 'ready' || tab === 'total')) {
        let _coupons = {...coupons}
        _coupons[tab] = [..._coupons[tab], ...result.data.data.slice(0, 5 - coupons[tab].length)]
        setCoupons(_coupons)
      }
      let _counts = counts
      _counts['total'] = _counts['total'] + parseInt(newCoupons.coupons_to_create)
      _counts['ready'] = _counts['ready'] + parseInt(newCoupons.coupons_to_create)
      setCounts({..._counts})
      dispatch(setSuccessNotification({ message: 'Coupon Created Successfully.' }))
      setNewCoupons({coupons_to_create: '', prefix: ''})
      setCreating(false)
    }
    catch (err) {
      dispatch(setErrorNotification({ message: err.response.data }))
      setCreating(false)
    }
  }

  const updateCoupon = async (couponId, status) => {
    const couponList = [...coupons[tab]]
    const index = couponList.findIndex(c => c.id === couponId)
    const coupon = couponList[index]
    try {
      await DiscountApi.updateCoupon(props.discountId, couponId, {...coupon, status: status})
      let _coupons = {...coupons}
      delete coupons[status]
      setCoupons(_coupons)
      fetchCoupons(tab)
    }
    catch(error) {
      dispatch(setErrorNotification({ message: error.response.data }))
    }
  }

  const deleteCoupon = async (id) => {
    if (counts.total > 1) {
      try{
        await DiscountApi.deleteCoupon(props.discountId, id)
        dispatch(setSuccessNotification({ message: t('response_messages.coupon_deleted_successfully') }))
        fetchCoupons(tab)
      }
      catch(error) {
        dispatch(setErrorNotification({ message: error.response.data.message }))
      }
    }
    else {
      dispatch(setInfoNotification({ message: 'Coupons cannot be empty.' }))
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={12} xl={12}>
        <h4>{t('labels.discount.coupons')}</h4>
      </Grid>
      <Grid item xs={6} md={6} lg={6} xl={6}>
        <TextField
          name="prefix"
          label={t('labels.discount.prefix')}
          value={newCoupons.prefix}
          placeholder={`output: ${newCoupons.prefix}xxxxx`}
          onChange={(e) => setNewCoupons({...newCoupons, prefix: e.target.value})}
          variant='outlined'
          helperText={t('labels.discount.prefix_helper')}
          fullWidth />
      </Grid>
      <Grid item xs={6} md={6} lg={6} xl={6}>
        <TextField
          error={createError}
          id="newcoupons"
          name="prefix"
          label={t('labels.discount.count')}
          value={newCoupons.coupons_to_create}
          placeholder={t('labels.discount.count_helper')}
          onChange={(e) => setNewCoupons({...newCoupons, coupons_to_create: e.target.value})}
          variant='outlined'
          helperText={t('labels.discount.count_helper')}
          type="number" 
          fullWidth />
      </Grid>
      <Grid item xs={12} md={12} lg={12} xl={12}>
        <Button variant="contained" color="primary" disabled={creating} onClick={(event) => createCoupons(event)}>{t('labels.create')}</Button>
      </Grid>
      <Grid item xs={12} md={12} lg={12} xl={12}>
        <CouponsTable
          tab={tab}
          switchTab={switchTab}
          coupons={coupons[tab] || []}
          counts={counts}
          page={page}
          updateCoupon={updateCoupon}
          deleteCoupon={deleteCoupon}
          changePage={changePage} />
      </Grid>
    </Grid>
  )
}

export default Coupons
