import React, { useState } from 'react';
import PropTypes from 'prop-types'
import { FormGroup, FormControlLabel, Grid, Checkbox, Typography, FormHelperText, InputLabel, Button, Card, CardContent, TextField } from '@mui/material';
import FieldGroup from '../fieldGroup'
import TextEditor from '../Helpers/TextEditor'
import EditPageUpperContainer from '../Shared/EditPageHeader'
import EditPageLowerContainer from '../Shared/EditPageLowerContainer';
import useTranslation from '../../hooks/useTranslation'
import AuthStorage from '../../services/authStorage'


const EditPage = props => {
  const actionType = props.page?.id ? 'Edit' : 'New'
  const { t } = useTranslation()
  const Auth = new AuthStorage()
  const storeUrl = Auth.getStoreUrl(`/pages/${props.page.slug || ''}`)
  const [copied, setCopied] = useState(false)

  const handleCopy = () => {
    navigator.clipboard.writeText(storeUrl)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      })
  }

  const leftFirstContent = () => (
    <>
      <Grid item xs={12} md={12}>
        <FieldGroup
          id="pageName"
          type="text"
          label={t('labels.name')}
          name="name"
          value={props.page.name}
          validationState={props.validationErrors.hasOwnProperty('name') ? 'error' : null}
          placeholder={t('labels.name')}
          onChange={props.onChange} />
      </Grid>
      <Grid item xs={12}>
        <FormGroup style={{paddingTop: 20}}>
          <InputLabel>{t('labels.content')}</InputLabel>
          <TextEditor value={props.page.content} onChange={props.onContentChange} />
        </FormGroup>
      </Grid>
    </>
  )

  const rightFirstContent = () => (
    <>
      <Grid item xs={12} md={12}>
        <Typography gutterBottom variant="h5" component="h2">
          {t('labels.visibility')}
        </Typography>
      </Grid>
      <Grid item xs={4} md={12}  sx={{ height: '30px' }}>
        <FormGroup>
          <FormControlLabel control={<Checkbox name="visible" color="primary" checked={!!props.page.visible} onChange={props.onChange} />} label={t('labels.public')} />
        </FormGroup>
      </Grid>
      <FormHelperText>{t('labels.page_helper')}</FormHelperText>
    </>
  )

  const rightSecondContent = () => (
    <>
      {props.page?.id ? (
        <CardContent>
          <Typography variant="h6">{t('labels.page_link')}</Typography>
          <TextField
            value={storeUrl}
            variant="outlined"
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
          <Button
            variant="text"
            color="primary"
            sx={{ marginTop: 2 }}
            onClick={handleCopy}
          >
            {copied ? t('labels.copied') : t('labels.copy_link')}
          </Button>
        </CardContent>
      ) : null}
    </>
  )

  return (
    <div>
      <EditPageUpperContainer actionType={actionType} page={'Page'} onSave={props.onSave} />
      <form>
        <EditPageLowerContainer
          leftContent={[leftFirstContent]}
          rightContent={[rightFirstContent, rightSecondContent]}
          actionType={actionType}
          onSave={props.onSave}
        />
      </form>
    </div>
  )
}

EditPage.propTypes = {
  page: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onContentChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
}

export default EditPage
