import React from 'react'
import { Switch, Route } from 'react-router-dom'
import EmailCampaignContainer from '../containers/emailCampaignContainer'
import EditEmailCampaignContainer from '../containers/editEmailCampaignContainer'

export default ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}`} component={EmailCampaignContainer} />
    <Route path={`${match.path}/new`} component={EditEmailCampaignContainer} />
    <Route path={`${match.path}/edit/:id`} component={EditEmailCampaignContainer} />
  </Switch>
)
