import React from 'react'
import { Switch, Route } from 'react-router-dom'
import OrderContainer from '../containers/orderContainer'
import ShowOrderContainer from '../containers/showOrderContainer'
import EditOrderContainer from '../containers/editOrderContainer'

export default ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}`} component={OrderContainer} />
    <Route exact path={`${match.path}/new`} component={EditOrderContainer} />
    <Route path={`${match.path}/:id/edit`} component={EditOrderContainer} />
    <Route path={`${match.path}/:id`} component={ShowOrderContainer} />
  </Switch>
)
