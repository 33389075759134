import { UserApi } from '../api/userApi'

// Create
export const CREATE_USER = '[Mstore] CREATE_USER'
export const CREATE_USER_SUCCESS = '[Mstore] CREATE_USER_SUCCESS'
export const CREATE_USER_ERROR = '[Mstore] CREATE_USER_ERROR'


// Read
export const GET_USERS = '[Mstore] GET_USERS'
export const GET_USERS_SUCCESS = '[Mstore] GET_USERS_SUCCESS'
export const GET_USERS_ERROR = '[Mstore] GET_USERS_ERROR'


export const GET_USER = '[Mstore] GET_USER'
export const GET_CURRENT_USER = '[Mstore] GET_CURRENT_USER'
export const GET_USER_SUCCESS = '[Mstore] GET_USER_SUCCESS'
export const GET_USER_ERROR = '[Mstore] GET_USER_ERROR'

// Update
export const UPDATE_USER = '[Mstore] UPDATE_USER'
export const UPDATE_USER_SUCCESS = '[Mstore] UPDATE_USER_SUCCESS'
export const UPDATE_USER_ERROR = '[Mstore] UPDATE_USER_ERROR'

// Delete
export const DELETE_USER = '[Mstore] DELETE_USER'
export const DELETE_USER_SUCCESS = '[Mstore] DELETE_USER_SUCCESS'
export const DELETE_USER_ERROR = '[Mstore] DELETE_USER_ERROR'

// Create
export const createUser = user => {
  return (dispatch, getState) => {
    dispatch({
      type: CREATE_USER
    })

    UserApi.createUser(user).then(res => {
      dispatch(createUserSuccess(res.data))
    })
  }
}

export const createUserSuccess = user => {
  return {
    type: CREATE_USER_SUCCESS,
    payload: user
  }
}

export const createUserFailure = error => {
  return {
    type: CREATE_USER_ERROR,
    payload: error
  }
}

//Read
export const getUsers = (page, per_page) => {
  return (dispatch, getState) => {
    dispatch({
      type: GET_USERS
    })

    UserApi.getUsers(page, per_page).then(res => {
      dispatch(getUsersSuccess(res.data))
    })
  }
}

export const getUsersSuccess = data => {
  return {
    type: GET_USERS_SUCCESS,
    payload: data
  }
}

export const getUsersFailure = error => {
  return {
    type: GET_USERS_ERROR,
    payload: error
  }
}


export const getUser = id => {
  return (dispatch, getState) => {
    dispatch({
      type: GET_USER
    })

    UserApi.getUser(id).then(res => {
      dispatch(getUserSuccess(res.data))
    })
  }
}

export const getCurrentUser = () => {
  return (dispatch, getState) => {
    dispatch({
      type: GET_CURRENT_USER
    })

    UserApi.getCurrentUser().then(res => {
      dispatch(getUserSuccess(res.data))
    })
  }
}


export const getUserSuccess = user => {
  return {
    type: GET_USER_SUCCESS,
    payload: user
  }
}

export const getUserFailure = error => {
  return {
    type: GET_USER_ERROR,
    payload: error
  }
}

//Update
export const updateUser = (id, user) => {
  return (dispatch, getState) => {
    //Multiple actions can be sent usign the Redux-Thunk middleware
    dispatch({
      type: UPDATE_USER,
      payload: user
    })
    UserApi.updateUser(id, user).then(res => {
      dispatch(updateUserSuccess(res.data))
    })
  }
}
export const updateUserSuccess = user => {
  return {
    type: UPDATE_USER_SUCCESS,
    payload: user
  }
}


//Delete
export const deleteUser = user => {
  return (dispatch, getState) => {
    dispatch({
      type: DELETE_USER,
      payload: user
    })

    UserApi.removeUser(user).then(res => {
      if (res.status === 204) {
        dispatch(deleteUserSuccess(user))
      } else {
        dispatch(deleteUserFailure(res.data))
      }
    }).catch(err => {
      console.log(err.response)
      dispatch(deleteUserFailure(err.response.data))
    })
  }
}
export const deleteUserSuccess = user => {
  return {
    type: DELETE_USER_SUCCESS,
    payload: user
  }
}

export const deleteUserFailure = error => {
  return {
    type: DELETE_USER_ERROR,
    payload: error
  }
}
// import { UserApi } from '../api/userApi'

// export const GET_USER = '[Mstore] GET_USER'
// export const GET_USER_SUCCESS = '[Mstore] GET_USER_SUCCESS'
// export const GET_USER_ERROR = '[Mstore] GET_USER_ERROR'

// // Update
// export const UPDATE_USER = '[Mstore] UPDATE_USER'
// export const UPDATE_USER_SUCCESS = '[Mstore] UPDATE_USER_SUCCESS'
// export const UPDATE_USER_ERROR = '[Mstore] UPDATE_USER_ERROR'

// export const getUser = () => {
//   return (dispatch, getState) => {
//     dispatch({
//       type: GET_USER
//     })

//     UserApi.getUser().then(res => {
//       dispatch(getUserSuccess(res.data))
//     })
//   }
// }

// export const getUserSuccess = user => {
//   return {
//     type: GET_USER_SUCCESS,
//     payload: user
//   }
// }

// export const getUserFailure = error => {
//   return {
//     type: GET_USER_ERROR,
//     payload: error
//   }
// }

// //Update
// export const updateUser = user => {
//   return (dispatch, getState) => {
//     //Multiple actions can be sent using the Redux-Thunk middleware
//     dispatch({
//       type: UPDATE_USER,
//       payload: user
//     })
//     UserApi.updateUser(user).then(res => {
//       dispatch(updateUserSuccess(res.data))
//     })
//   }
// }

// export const updateUserSuccess = user => {
//   return {
//     type: UPDATE_USER_SUCCESS,
//     payload: user
//   }
// }
