import React from 'react'
import PropTypes from 'prop-types'
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material'

const ButtonPositionRadio = ({ value, onChange, label, options, name, layout }) => (
  <FormControl component="fieldset">
    <FormLabel>{label}</FormLabel>
    <RadioGroup
      row={layout === 'horizontal'}
      value={value}
      onChange={(e) => onChange(e.target.value, name)}
    >
      {options.map((option) => (
        <FormControlLabel 
          key={option.value} 
          value={option.value} 
          control={<Radio />} 
          label={option.label} 
        />
      ))}
    </RadioGroup>
  </FormControl>
)

ButtonPositionRadio.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  name: PropTypes.string.isRequired,
  layout: PropTypes.oneOf(['horizontal', 'vertical']),
}

ButtonPositionRadio.defaultProps = {
  label: 'Button Position',
  layout: 'horizontal',
}

export default ButtonPositionRadio