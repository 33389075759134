import * as pageActions from '../actions/pageActions'
import initialState from './initialState'

const pages = (state = initialState.pages, action) => {
  let newState
  switch (action.type) {
    case pageActions.GET_PAGES:
      return {
        ...state,
        loading: true
      }
    case pageActions.GET_PAGES_SUCCESS:
      return {
        ...action.payload,
        loading: false
      }
    case pageActions.GET_PAGE:
      return {
        ...state,
        loading: true
      }
    case pageActions.GET_PAGE_SUCCESS:
      let indexOfPageToGet = state.data.findIndex(({ id }) => id === action.payload.id)
      let newStateAfterGet = {...state}
      if( indexOfPageToGet === -1) {
        newStateAfterGet.data.push(action.payload)
      } else {
        newStateAfterGet.data[indexOfPageToGet] = action.payload
      }
      return newStateAfterGet
    case pageActions.CREATE_PAGE:
      return {
        ...state,
        updating: true
      }
    case pageActions.CREATE_PAGE_SUCCESS:
      let newPagesState = {...state}
      newPagesState.data.push(action.payload)
      return newPagesState
    case pageActions.UPDATE_PAGE:
      return {
        ...state,
        updating: true
      }
    case pageActions.UPDATE_PAGE_SUCCESS:
      let indexOfPageToUpdate = state.data.findIndex(({ id }) => id === action.payload.id)
      newState = {...state}
      newState.data[indexOfPageToUpdate] = action.payload
      return newState
    case pageActions.DELETE_PAGE:
      return {
        ...state
      }
    case pageActions.DELETE_PAGE_SUCCESS:
      let indexOfPageToDelete = state.data.findIndex(({ id }) => id === action.payload.id)
      newState = {...state}
      newState.data.splice(indexOfPageToDelete, 1)
      return newState
    default:
      return state
  }
}

export default pages
