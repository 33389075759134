import React from 'react'
import PropTypes from 'prop-types'

import { Paper, Box, IconButton } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'

const style = {
  paper: {
    marginTop: '20px',
    padding: '20px',
  },
}

const Address = props => {
  return (
    <Paper sx={style.paper}>
      <Box textAlign="right">
        <IconButton color="primary" size="small" onClick={() => props.onEdit(props.address)}><EditIcon /></IconButton>
        <IconButton color="error" size="small" onClick={() => window.confirm('Are you sure?') && props.onDelete(props.address) }><DeleteIcon /></IconButton>
      </Box>
      {props.address.name ? <div>{`${props.address.name}, `}</div> : ''}
      {props.address.email ? <div>{`${props.address.email}, `}{props.address.phone ? `${props.address.phone}` : ''}</div> : ''}
      {props.address.company_name ? <div>{`${props.address.company_name} `}{props.address.organisation_number ? `(${props.address.organisation_number})` : ''}</div> : ''}
      {props.address.address_one ? `${props.address.address_one}, ` : ''}
      {props.address.address_two ? `${props.address.address_two}, ` : ''}
      {props.address.postcode ? `${props.address.postcode}, ` : ''}
      {props.address.city ? `${props.address.city}, ` : ''}
      {props.address.country ? `${props.address.country} ` : ''}
      {props.address.reference ? `, ${props.address.reference} ` : ''}
    </Paper>
  )
}

Address.propTypes = {
  address: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
}

export default Address
