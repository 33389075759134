/* eslint-disable no-undef */
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import TextEditor from '../Helpers/TextEditor'
import FooterSettings from './footerSettings'
import ThemeSettings from './themeSettings'
import FieldGroup from '../fieldGroup'
import { Grid, Paper, Typography, Divider, InputAdornment, TextField, MenuItem, FormControlLabel, Checkbox } from '@mui/material'
import CheckIcon from '@mui/icons-material/CheckCircleOutline'
import Alert from '@mui/material/Alert'
import UploadImageWidget from '../Shared/UploadImageWidget'
import IconTextField from '../Shared/IconTextField'
import useTranslation from '../../hooks/useTranslation'

const styles = {
  helperText: {
    fontWeight: '400',
    fontSize: '0.75rem',
    color: 'rgba(0, 0, 0, 0.6)',
    marginLeft: '14px',
    display: 'block'
  },
  headingContainer: {
    padding: '1%',
    backgroundColor: '#f5f5f5'
  }
}

const EditStore = props => {
  const { t } = useTranslation()
  const alertMsg = props.store.updating ? (
    <Alert severity="info">Updating settings...</Alert>
  ) : (
    <div></div>
  )
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>{alertMsg}</Grid>
      <Grid container item xs={12}>
        <Paper sx={{width: '100%'}}>
          <Grid container>
            <Grid item xs={12} sx={styles.headingContainer}>
              <h4>{t('labels.store_settings.general_settings')}</h4>
            </Grid>
            <Divider width="100%" />
            <Grid container item sx={{padding: '1%'}}>
              <Grid container item spacing={1} direction="column" xs={12} md={8} sx={{paddingRight: '2%'}}>
                <Grid item>
                  <FieldGroup
                    id="title_tag"
                    label={t('labels.store_settings.title_tag')}
                    name="title_tag"
                    value={props.store.title_tag}
                    placeholder={t('labels.store_settings.title_tag')}
                    onChange={props.onChange} />
                </Grid>
                <Grid item>
                  <FieldGroup
                    id="seo_description"
                    label={t('labels.store_settings.seo_description')}
                    name="seo_description"
                    value={props.store.seo_description}
                    multiline
                    onChange={props.onChange}
                    helperText={t('labels.store_settings.seo_description')} />
                </Grid>
                <Grid item>
                  <b>{t('labels.store_settings.footer_text')}</b>
                  <TextEditor
                    value={props.store.footer_text}
                    onChange={props.onFooterChange} />
                  <Typography variant='p' sx={styles.helperText}>{t('labels.store_settings.footer_text')}</Typography>
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={<Checkbox checked={props.store.enable_seo} onChange={props.onChange} name="enable_seo" />}
                    label={t('labels.store_settings.enable_seo')} />
                  <Typography variant='p' sx={styles.helperText}>{t('labels.store_settings.enable_seo')}</Typography>
                </Grid>
              </Grid>
              <Grid container item spacing={2} direction="column" xs={12} md={4}>
                <Grid container item spacing={2}>
                  <Grid item xs={12}>
                    <UploadImageWidget
                      object={props.store}
                      onChange={props.onChange}
                      deleteImage={props.removeLogo}
                      title={t('labels.store_settings.logo')}
                      field="logo"
                      size="medium" />
                  </Grid>
                </Grid>
                <Grid item container spacing={2}>
                  <Grid item xs={12}>
                    <UploadImageWidget
                      object={props.store}
                      onChange={props.onChange}
                      deleteImage={props.removeFavicon}
                      title={t('labels.store_settings.favicon')}
                      field="favicon"
                      description={t('labels.store_settings.icon_helper')}
                      size="medium" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid container item xs={12}>
        <Paper sx={{width: '100%'}}>
          <Grid container>
            <Grid item xs={12} sx={styles.headingContainer}>
              <h4>{t('labels.store_settings.language_options')}</h4>
            </Grid>
            <Divider width="100%" />
            <Grid container spacing={2} sx={{padding: '2%'}}>
              <Grid item>
                <TextField
                  select
                  name="language"
                  value={props.store.language}
                  label={t('labels.language')}
                  placeholder="select"
                  helperText={t('labels.language_helper')}
                  onChange={props.onChange}>
                  <MenuItem value="en">{t('labels.english')}</MenuItem>
                  <MenuItem value="sv">{t('labels.swedish')}</MenuItem>
                </TextField>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid container item xs={12}>
        <Paper sx={{width: '100%'}}>
          <Grid container>
            <Grid item xs={12} sx={styles.headingContainer}>
              <h4>{t('labels.store_settings.domains')}</h4>
            </Grid>
            <Divider width="100%" />
            <Grid container sx={{padding: '1%'}}>
              <Grid container item spacing={2} direction="column" xs={12} md={6} sx={{paddingRight: '2%'}}>
                <Grid item>
                  <TextField
                    id="store_url"
                    label={t('labels.store_settings.store_url')}
                    name="store_url"
                    value={props.store.store_url}
                    placeholder="URL"
                    onBlur={props.validateStoreUrl} 
                    onChange={props.onChange}
                    helperText={props.store.store_url ? (
                      <a href={`http://${process.env.REACT_APP_STORE_HOST}/${props.store.store_url}`} target="blank">{`${process.env.REACT_APP_STORE_HOST}/${props.store.store_url}`}</a>
                    ) : (null)}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          { props.urlValid && (<CheckIcon />)}
                        </InputAdornment>
                      ),
                    }} />
                </Grid>
                <Grid item>
                  <TextField
                    id="primary_domain"
                    label={t('labels.store_settings.custom_domain')}
                    name="primary_domain"
                    value={props.store.primary_domain}
                    placeholder="yourdomain.com"
                    onChange={props.onChange}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          { props.urlValid && (<CheckIcon />)}
                        </InputAdornment>
                      ),
                    }} />
                  {props.store.primary_domain ? (
                    <span>
                        Visit Store: {' '} 
                      <a href={`http://${props.store.primary_domain}`} target="blank">{`${props.store.primary_domain}`}</a>
                    </span>
                  ) : (null)
                  }
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={<Checkbox checked={props.store.is_live} onChange={props.onChange} name="is_live" />}
                    label={t('labels.store_settings.shop_is_live')} />
                  <Typography variant='p' sx={styles.helperText}>{t('labels.store_settings.shop_is_live')}</Typography>
                </Grid>
              </Grid>
              <Grid container item spacing={2} direction="column" xs={12} md={6}>
                <Grid item>
                  <TextField
                    select
                    name="authentication"
                    value={props.store.authentication}
                    label={t('labels.store_settings.authentication')}
                    helperText={t('response_messages.password_protection_label')}
                    placeholder="select"
                    onChange={props.onChange}>
                    <MenuItem value="0">{t('labels.no_authentication')}</MenuItem>
                    <MenuItem value="1">{t('labels.login_require_mandatory')}</MenuItem>
                    <MenuItem value="2">{t('labels.login_require_optional')}</MenuItem>
                  </TextField>
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={<Checkbox checked={props.store.enable_account_registration} disabled={props.store.authentication === parseInt(t('labels.no_authentication_code'))} onChange={props.onChange} name="enable_account_registration" />}
                    label={t('labels.user_registration')} />
                  <Typography variant='p' sx={styles.helperText}>{t('response_messages.user_registration_help_block')}</Typography>
                </Grid>
                {props.store.enable_account_registration && (<Grid item>
                  <FormControlLabel
                    control={<Checkbox checked={props.store.auto_account_approval} disabled={!props.store.enable_account_registration} onChange={props.onChange} name="auto_account_approval" />}
                    label={t('labels.auto_approve_registered_accounts')} />
                  <Typography variant='p' sx={styles.helperText}>{t('response_messages.auto_approve_account_help_block')}</Typography>
                </Grid>)}
                <Grid item>
                  <TextField
                    select
                    fullWidth
                    name="password_protection"
                    label={t('labels.store_settings.password_protection')}
                    value={props.store.password_protection}
                    placeholder="select"
                    helperText={t('response_messages.password_protection')}
                    onChange={props.onChange}>
                    <MenuItem value="0">{t('labels.no')}</MenuItem>
                    <MenuItem value="1">{t('labels.password')}</MenuItem>
                    <MenuItem value="2">{t('labels.ip_restriction')}</MenuItem>
                  </TextField>
                </Grid>
                {(parseInt(props.store.password_protection) === parseInt(t('labels.password_protected_code')) || parseInt(props.store.password_protection) === 2) ? (
                  <Grid item>
                    <TextField
                      fullWidth
                      type="text"
                      name="access_key"
                      value={props.store.access_key}
                      label={props.store.password_protection === 2 ? 'IP Address' : 'Password'}
                      pattern="^([0-9]{1,3}\.){3}[0-9]{1,3}$"
                      onChange={props.onChange} />
                    <Typography variant='p' sx={styles.helperText}>{t('response_messages.password_protection_field_label')}</Typography>
                  </Grid>
                ) : (null)
                }
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid container item xs={12}>
        <Paper sx={{width: '100%'}}>
          <Grid container>
            <Grid item xs={12} sx={styles.headingContainer}>
              <h4>{t('labels.store_settings.contact_details')}</h4>
            </Grid>
            <Divider width="100%" />
            <Grid container spacing={2} sx={{padding: '2%'}}>
              <Grid item xs={6}>
                <TextField
                  label={t('labels.store_settings.company_name')}
                  name="company_name"
                  value={props.store.company_name || ''}
                  onChange={props.onChange}
                  fullWidth/>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label={t('labels.store_settings.store_name')}
                  name="name"
                  value={props.store.name || ''}
                  onChange={props.onChange}
                  fullWidth/>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label={t('labels.store_settings.address')}
                  name="address"
                  value={props.store.address || ''}
                  onChange={props.onChange}
                  fullWidth/>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label={t('labels.store_settings.organization_number')}
                  name="organisation_number"
                  value={props.store.organisation_number || ''}
                  onChange={props.onChange}
                  fullWidth/>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label={t('labels.store_settings.phone')}
                  name="phone"
                  value={props.store.phone || ''}
                  onChange={props.onChange}
                  fullWidth/>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label={t('labels.store_settings.contact_email')}
                  name="email"
                  value={props.store.email || ''}
                  onChange={props.onChange}
                  fullWidth/>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid container item xs={12}>
        <Paper sx={{width: '100%'}}>
          <Grid container>
            <Grid item xs={12} sx={styles.headingContainer}>
              <h4>{t('labels.store_settings.payment_options')}</h4>
            </Grid>
            <Divider width="100%" />
            <Grid container direction="column" spacing={2}>
              <Grid container sx={{padding: '3%'}} spacing={2}>
                <Grid container item xs={12} md={6} spacing={0}>
                  <Grid item xs={12}>
                    <label>{t('labels.store_settings.payment_options')}</label>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={<Checkbox checked={props.store.credit_card} onChange={props.onChange} name="credit_card" />}
                      label={t('labels.store_settings.credit_card')} />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={<Checkbox checked={props.store.invoice} onChange={props.onChange} name="invoice" />}
                      label={t('labels.store_settings.invoice')} />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={<Checkbox checked={props.store.free} onChange={props.onChange} name="free" />}
                      label={t('labels.store_settings.free')} />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={<Checkbox checked={props.store.discount_payment === true} onChange={props.onChange} name="discount_payment" />}
                      label={t('labels.store_settings.discount_voucher')} />
                  </Grid>
                </Grid>
                <Grid container item xs={12} md={6} spacing={2} >
                  <Grid item xs={12}>
                    <TextField
                      label={t('labels.store_settings.currency')}
                      select
                      fullWidth
                      name="currencyCode"
                      value={props.store.currencyCode || ''}
                      onChange={props.onChange}>
                      { props.currencies.map(c => <MenuItem key={c.id} value={c.isoCode}>{t(c.name)}</MenuItem>) }
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={<Checkbox checked={props.store.prices_include_vat === true} onChange={props.onChange} name="prices_include_vat" />}
                      label={t('labels.store_settings.include_vat')} />
                    <Typography variant='p' sx={styles.helperText}>{t('labels.store_settings.vat_helper')}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="vat"
                      label={t('labels.store_settings.vat_percentage')}
                      value={`${props.store.vat}`}
                      placeholder=""
                      onChange={props.onChange}
                      fullWidth />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={<Checkbox checked={props.store.show_vat_on_listings === true} onChange={props.onChange} name="show_vat_on_listings" />}
                      label={t('labels.store_settings.show_vat')} />
                    <Typography variant='p' sx={styles.helperText}>{t('labels.store_settings.listing_helper')}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid container item xs={12}>
        <Paper sx={{width: '100%'}}>
          <Grid container>
            <Grid item xs={12} sx={styles.headingContainer}>
              <h4>{t('labels.store_settings.shipping')}</h4>
            </Grid>
            <Divider width="100%" />
            <Grid container spacing={2} sx={{padding: '2%'}}>
              <Grid item>
                <FormControlLabel
                  control={<Checkbox checked={!props.store.same_invoice_address} onChange={props.onChange} name="same_invoice_address" />}
                  label={t('labels.store_settings.billing_address_checkout')} />
                <Typography variant='p' sx={styles.helperText}>{t('labels.store_settings.billing_address_helper')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={t('labels.store_settings.order_copy_email')}
                  name="order_email"
                  value={props.store.order_email || ''}
                  helperText={t('labels.store_settings.order_helper') + `email1@${props.store.store_url}.com, email2@${props.store.store_url}.com`}
                  placeholder={t('labels.store_settings.order_copy_email')}
                  onChange={props.onChange}
                  fullWidth />
              </Grid>
              <Grid item xs={12} md={7}>
                <IconTextField
                  label={t('labels.store_settings.freight_per_order')}
                  name="freight_per_order"
                  value={props.store.freight_per_order || 0}
                  onChange={props.onChange}
                  fullWidth={true}
                  icon="kr"
                />
              </Grid>
              <Grid item xs={12} md={7}>
                <IconTextField
                  label={t('labels.store_settings.freight_outside_sweden')}
                  name="freight_outside_sweden"
                  value={props.store.freight_outside_sweden || 0}
                  onChange={props.onChange}
                  fullWidth={true}
                  icon="kr"
                />
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <ThemeSettings onChange={props.onChange} store={props.store} styles={styles} />
      <FooterSettings onChange={props.onChange} store={props.store} />
    </Grid>
  )
}

EditStore.propTypes = {
  store: PropTypes.object.isRequired,
  currencies: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  validationErrors: PropTypes.object.isRequired,
  validateStoreUrl: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  showSecondaryColorPicker: PropTypes.bool,
  urlValid: PropTypes.bool,
  onColorFocus: PropTypes.func,
  onColorBlur: PropTypes.func,
  onPrimaryColorChange: PropTypes.func,
  onSecondaryColorChange: PropTypes.func,
  showPrimaryColorPicker: PropTypes.bool,
  onFooterChange: PropTypes.func,
  removeLogo: PropTypes.func.isRequired,
  removeFavicon: PropTypes.func.isRequired
}

export default EditStore