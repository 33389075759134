import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { TableCell, TableRow } from '@mui/material';

const Menu = (props) => (
  <TableRow
    key={props.id}
    sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
    <TableCell component="th" scope="row">
      <Link to={`/admin/menus/edit/${props.id}`}>{props.index}</Link>
    </TableCell>
    <TableCell  align="left">
      <Link to={`/admin/menus/edit/${props.id}`}>{props.name}</Link>
    </TableCell>
    <TableCell align="left">{props.menuTypes[props.type]}</TableCell>
    <TableCell align="left">{props.show_name ? '✔' : ''}</TableCell>
    <TableCell align="left">{props.enabled ? '✔' : ''}</TableCell>
  </TableRow>
)

Menu.propTypes = {
  id: PropTypes.number.isRequired,
  menuTypes: PropTypes.object.isRequired,
  name: PropTypes.string,
  type: PropTypes.string,
  enabled: PropTypes.bool,
  show_name: PropTypes.bool
}

export default Menu
