const actionBtn = {
  textTransform: 'none',
  marginTop: '2%',
  marginLeft: '10px',
  float: 'right'
};

const indexColumn = {
  width: '50px'
}

export { actionBtn, indexColumn }
