import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { TableCell, TableRow, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const UserGroup = props => (
  <TableRow key={props.id}>
    <TableCell component="th" scope="row"><Link to={`/admin/userGroups/${props.id}`}>{props.index}</Link></TableCell>
    <TableCell component="th" scope="row"><Link to={`/admin/userGroups/${props.id}`}> {props.name}</Link></TableCell>
    <TableCell>
      <IconButton color="error" size="small" onClick={() => { if (window.confirm('Are you sure?')) props.onDeleteUserGroupClicked() } }>
        <DeleteIcon />
      </IconButton>
    </TableCell>
  </TableRow>
)

UserGroup.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
}

export default UserGroup
