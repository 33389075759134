import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import EditCollection from "../components/Collection/editCollection";
import { CollectionApi } from "../api/collectionApi";
import { createCollection, updateCollection } from "../actions/collectionActions";


const EditCollectionContainer = () => {
  const [saving, setSaving] = useState(false)
  const [page, setPage] = useState(0)
  const [formErrors, setFormErrors] = useState({})
  const [collection, setCollection] = useState({
      id: 0,
      name: '',
      description: '',
      image: '',
      show_on_homepage: false,
      visible: false,
      information: '',
      text_color: '',
      background_image: ''
  })
  const collections = useSelector( state => state.collections )

  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch()

  useEffect(() => {
    fetchCollection();
  }, [])

  const fetchCollection = async () => {
    const collectionId = params.hasOwnProperty('id') ? + params.id : 0
    if (collectionId > 0) {
      const response = await CollectionApi.getCollection(collectionId)
      if ( response.status === 200 ) {
        setCollection(response.data)
        setPage(collections.page_no)
      }
    }    
  }

  const saveCollection = (event) => {
    event.preventDefault()
    setFormErrors({})
    if (formIsValid()) {
      setSaving(true)
      let formData = new FormData()
      for (const [field, value] of Object.entries(collection)) {
        formData.append(field, value)
      }
      if( collection.id > 0) {
        dispatch(updateCollection(collection.id, formData))
      } else {
        dispatch(createCollection(formData))
      }
      history.push(`/admin/collections?page=2`)
    }
  }
  
  const formIsValid = () => {
    let formValid = true
    if (collection.name === '') {
      setFormErrors({...formErrors, name: "can't be blank"})
      formValid = false
    }
    return formValid
  }

  const removeImage = () => {
    setCollection({ ...collection, _delete_image: true })
  }

  const updateCategoryState = event => {
    const _collection = {...collection}
    if(event.target) {
      let target = event.target.name
      if ( event.target.type === 'file' && event.target.files[0] !== undefined){
        _collection[target] = event.target.files[0]
        _collection[`new_${target}`] = true
        event.target.files[0] && (_collection[`${target}_preview_url`] = URL.createObjectURL(event.target.files[0]))
      } else if (event.target.type === 'checkbox') {
        _collection[target] = event.target.checked
      } else {
        _collection[target] = event.target.value
      }
      setCollection({..._collection})
    } else {
      setCollection({...collection, information: event})
    }
  }
      
  return (
    <EditCollection 
      collection={collection}
      validationErrors={formErrors}
      isSaving={saving}
      onSave={saveCollection}
      removeImage={removeImage}
      onChange={updateCategoryState}
    />
  )
}

export default EditCollectionContainer
