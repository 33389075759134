import {HttpClient} from './httpClient' 
import {API_URL} from './RestAPI'
import apiHeaders from './apiHeaders'
import {setUrlForGetRequest} from './sharedFunctions'

//Setting the discounts URI
const DISCOUNT_API = `${API_URL}/discounts`

// Create
const createDiscount = discount => {
  return HttpClient.post(
    `${DISCOUNT_API}/create`,
    discount,
    apiHeaders()
  )
}

// Read
const getDiscounts = (params, dependent = {}) => {
  let url = setUrlForGetRequest(DISCOUNT_API, params)
  return HttpClient.get(url, apiHeaders(dependent))
}

// Get
const getDiscount = id => {
  return HttpClient.get(
    `${DISCOUNT_API}/${id}`,
    apiHeaders()
  )
}

// Update
const updateDiscount = (id, discount) => {
  return HttpClient.put(
    `${DISCOUNT_API}/${id}/update`,
    discount,
    apiHeaders()
  )
}

// Delete
const removeDiscount = discount => {
  return HttpClient.delete(
    `${DISCOUNT_API}/${discount.id}`,
    apiHeaders()
  )
}

const getDiscountCoupons = (discount_id, page, status) => {
  return HttpClient.get(
    `${DISCOUNT_API}/${discount_id}/coupons?page=${page}&status=${status}`,
    apiHeaders({ 'dependent-source': global.constants.dependentSource.discount })
  )
}

const createCoupons = (discount_id, new_coupons) => {
  return HttpClient.post(
    `${DISCOUNT_API}/${discount_id}/coupons`,
    new_coupons,
    apiHeaders({ 'dependent-source': global.constants.dependentSource.discount })
  )
}

const updateCoupon = (discount_id, coupon_id, coupon) => {
  return HttpClient.put(
    `${DISCOUNT_API}/${discount_id}/coupons/${coupon_id}`,
    coupon,
    apiHeaders({ 'dependent-source': global.constants.dependentSource.discount })
  )
}

const deleteCoupon = (discount_id, coupon_id) => {
  return HttpClient.delete(
    `${DISCOUNT_API}/${discount_id}/coupons/${coupon_id}`,
    apiHeaders({ 'dependent-source': global.constants.dependentSource.discount })
  )
}

const getDefaultCoupon = (discount_id) => {
  return HttpClient.get(
    `${DISCOUNT_API}/${discount_id}/defaultCoupon`,
    apiHeaders({ 'dependent-source': global.constants.dependentSource.discount })
  )
}

//Encapsulating in a JSON object
const DiscountApi = {getDiscounts, createDiscount, getDiscount, updateDiscount, removeDiscount, getDiscountCoupons, createCoupons, updateCoupon, deleteCoupon, getDefaultCoupon}

export {DiscountApi}
