import React from 'react'
import { Switch, Route } from 'react-router-dom'
import UserContainer from '../containers/userContainer'
import EditUserContainer from '../containers/editUserContainer'
import AccountBalance from '../components/User/accountBalance'
import InviteUsers from '../components/User/invite'

export default ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}`} component={UserContainer} />
    <Route path={`${match.path}/invite`} component={InviteUsers} />
    <Route path={`${match.path}/new`} component={EditUserContainer} />
    <Route path={`${match.path}/edit/:id`} component={EditUserContainer} />
    <Route path={`${match.path}/:id/balance`} component={AccountBalance} />
  </Switch>
)
