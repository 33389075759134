import {HttpClient} from './httpClient' 
import {API_URL} from './RestAPI'
import apiHeaders from './apiHeaders'

//Setting the pages API URI
const PAGE_API = `${API_URL}/pages`

// Create
const createPage = page => {
  return HttpClient.post(
    `${PAGE_API}/create`,
    page,
    apiHeaders()
  )
}

// Read
const getPages = (params) => {
  let url = `${PAGE_API}?`
  Object.keys(params).forEach(key => {
    if(params[key]){
      url += `&${key}=${params[key]}`
    }
  })
  return HttpClient.get(url, apiHeaders())
}

// Get
const getPage = id => {
  return HttpClient.get(
    `${PAGE_API}/${id}`,
    apiHeaders()
  )
}

// Update
const updatePage = (id, page) => {
  return HttpClient.put(
    `${PAGE_API}/${id}/update`,
    page,
    apiHeaders()
  )
}

// Delete
const removePage = page => {
  return HttpClient.delete(
    `${PAGE_API}/${page.id}`,
    apiHeaders()
  )
}


//Encapsulating in a JSON object
const PageApi = { getPages, createPage, getPage, updatePage, removePage }

export { PageApi }
