import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, FormGroup, Alert, Button } from '@mui/material'

import './style.css'
import FieldGroup from '../fieldGroup'
import { AuthApi } from '../../api/authApi'
import AuthStorage from '../../services/authStorage'
const Auth = new AuthStorage()

const Home = props => {
  const [creds, setCreds] = useState({ email: '', password: '' })
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const handleLogin = e => {
    e.preventDefault()
    if (creds.email && creds.password) {
      setLoading(true)
      AuthApi.userLogin(creds)
        .then(res => {
          setLoading(false)
          if ((res.statusText==='OK' || res.statusText==='') && res.data.status==='OK') {
            const { token, store, user } = res.data
            Auth.setToken(token)
            Auth.setStore(store)
            Auth.setUser(user)
            // If everything goes fine then redirect to / path
            props.history.push('/admin')
          } else {
            setError(res.errorMessage)
          }
        }).catch(error => {
          setLoading(false)
          console.log(error, error.response)
          if(error.response && error.response.status === 401) {
            setError(error.response.data.errorMessage)
          } else {
            setError('Something went wrong, please try again later!')
          }
        })
    }
  }

  return (
    <Grid sx={{ flexGrow: 1 }} justifyContent="center" container spacing={2}>
      <Grid item xs={11} sm={6} md={5} xl={3}>
        <Grid container justifyContent="center" sx={{ boxShadow: '0 5px 5px 5px #f1f1f1', borderRadius: '4px', padding: '10% 4% 15%', marginTop: '10%'}}>
          <Grid item xs={12} md={10}>
            <h3>Store Admin - Login</h3>
            <hr/>
            <form onSubmit={handleLogin}>
              { error && <Alert color='error'>{error}</Alert> }
              <FieldGroup
                id="email"
                type="email"
                label="Email"
                name="email"
                className="input-lg"
                required
                value={creds.email}
                placeholder="admin@yourstore.com"
                onChange={e => { setCreds({ ...creds, email: e.currentTarget.value }) }} />
              <FieldGroup
                id="password"
                type="password"
                label="Password"
                name="password"
                className="input-lg"
                required
                value={creds.password}
                placeholder="*********"
                onChange={e => { setCreds({ ...creds, password: e.currentTarget.value }) }} />
              <FormGroup style={{paddingTop: 20}}>
                <Button
                  type="submit"
                  variant='contained'
                  size='large'
                  fullWidth
                  disabled={loading}
                  // onClick={handleLogin}
                >
                  Login
                </Button>
              </FormGroup>
            </form>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
Home.propTypes = {
  history: PropTypes.object.isRequired
}
export default Home
