import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import ShowCollection from "../components/Collection/showCollection";
import { CollectionApi } from "../api/collectionApi"
import { ProductApi } from "../api/productApi";
import { setSuccessNotification } from "../actions/notificationAction";
import { useDispatch } from "react-redux";
import ListingPage from "../components/Shared/ListingPage";
import { Button } from "@mui/material";


const ShowCollectionContainer = () => {
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    const [searchRequestStatus, setSearchRequestStatus] = useState('pending')
    const [collection, setCollection ] = useState({})
    const [searchedProducts, setSearchedProducts] = useState([])
    const [collectionProductsIds, setCollectionProductIds] = useState([])
    
    const getCollectionFromApi = async (collectionId) => {
        const response = await CollectionApi.getCollection(collectionId)
        if (response.status === 200){
            setCollection(response.data)
            setCollectionProductIds(response.data?.products?.map( product => product.id))
        } else {
          console.log(response.error)
        }
    }

    const fetchProducts = (event, term) => {
      event.preventDefault();
      ProductApi.searchProducts(term, 0, true, { "dependent-source": global.constants.dependentSource.collection })
        .then(res => {
          if(res.status===200) {
            if (res.data.data.length === 0){
              setSearchedProducts(res.data.data)
              setSearchRequestStatus('Not Found')
            } else {
              const toBeSelected = res.data.data.filter((product) => (!collectionProductsIds.includes(product.id)))
              setSearchedProducts(toBeSelected)
              if(toBeSelected.length === 0) {
                setSearchRequestStatus('Not Found')
              }
            }  
          }
        })
    }

    const addProductToCollection = async (productId, actionType) => {
      let temp = collectionProductsIds
      if (actionType === 'add') {
        temp.push(productId)
      } else {
        temp = temp.filter(id => id !== productId)
      }
      const response = await CollectionApi.addProductsToCollection({productIds: temp, collectionId: collection.id})
      if (response?.status === 200){
        if (actionType === 'add'){
          dispatch(setSuccessNotification({ message: 'Product is added to the Collection' }))
          setSearchedProducts(searchedProducts.filter(product => product.id !== productId))
        } else {
          dispatch(setSuccessNotification({ message: 'Product is removed from the Collection' }))
        }
        getCollectionFromApi(collection.id)
      } else {
         console.log(response?.error)
      }
    }

    useEffect(()=>{
      const collectionId = params.hasOwnProperty('id') ? + params.id : 0
      if (collectionId){
        getCollectionFromApi(collectionId)
      }  
    }, [])

    const content = ()=>{
      return (
        <ShowCollection
          collection={collection}
          fetchProducts={fetchProducts}
          searchedProducts={searchedProducts}
          collectionProductsIds={collectionProductsIds}
          addProductToCollection={addProductToCollection}
          searchRequestStatus={searchRequestStatus}
        />
      )
    }

    const actions = ()=>{
     return(
      <Button
        color="primary"
        variant="contained"
        className="pull-right"
        onClick={()=>history.push(`/admin/collections/edit/${collection?.id}`)}>
          Edit
      </Button>
     )

    }
    
    return (
      <ListingPage title={collection?.name} actions={actions()} table={content()}/>
    )
}

export default ShowCollectionContainer
