import {HttpClient} from './httpClient' 
import {API_URL} from './RestAPI'
import apiHeaders from './apiHeaders'
import {setUrlForGetRequest} from './sharedFunctions'

//Setting the email templates URI
const EmailTemplate_API = `${API_URL}/email-templates`

// Create
const createEmailTemplate = emailTemplate => {
  return HttpClient.post(
    `${EmailTemplate_API}/create`,
    emailTemplate,
    apiHeaders()
  )
}

// Read
const getEmailTemplates = (params, dependent = {}) => {
  let url = setUrlForGetRequest(EmailTemplate_API, params)
  return HttpClient.get(url, apiHeaders(dependent))
}

// Get
const getEmailTemplate = id => {
  return HttpClient.get(
    `${EmailTemplate_API}/${id}`,
    apiHeaders()
  )
}

// Update
const updateEmailTemplate = (id, emailTemplate) => {
  return HttpClient.put(
    `${EmailTemplate_API}/${id}/update`,
    emailTemplate,
    apiHeaders()
  )
}

// Delete
const removeEmailTemplate = emailTemplate => {
  return HttpClient.delete(
    `${EmailTemplate_API}/${emailTemplate.id}`,
    apiHeaders()
  )
}

const addImage = emailTemplate => {
  return HttpClient.post(
    `${EmailTemplate_API}/add-image`,
    emailTemplate,
    apiHeaders()
  )
}

const removeImages = emailTemplate => {
  return HttpClient.post(
    `${EmailTemplate_API}/remove-image`,
    emailTemplate,
    apiHeaders()
  )
}

//Encapsulating in a JSON object
const EmailTemplateApi = {getEmailTemplates, createEmailTemplate, getEmailTemplate, updateEmailTemplate, removeEmailTemplate, addImage, removeImages}

export {EmailTemplateApi}