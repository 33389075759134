import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { TableCell, TableRow } from '@mui/material';
import { indexColumn } from '../../styles'
import useTranslation from '../../hooks/useTranslation'

const EmailCampaign = ({ id, name, subject, email_sent,  deleteEmailCampaign, index }) => {
  const { t } = useTranslation()

  return (
    <TableRow key={id}>
      <TableCell component="th" scope="row" sx={indexColumn}><Link to={`/admin/email-campaigns/edit/${id}`}>{index}</Link></TableCell>
      <TableCell component="th" scope="row"><Link to={`/admin/email-campaigns/edit/${id}`}> {name}</Link></TableCell>
      <TableCell component="th" scope="row"> {subject} </TableCell>
      <TableCell align="left">
        <Link to={`/admin/email-campaigns/edit/${id}`}>
          <IconButton color="primary" size="small"><EditIcon /></IconButton>
        </Link>
        {!email_sent && (
          <IconButton color="error" size="small" onClick={() => { if (window.confirm(t('labels.are_you_sure'))) deleteEmailCampaign() } }>
            <DeleteIcon />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  )
}

EmailCampaign.propTypes = {
  id: PropTypes.number.isRequired,
  subject: PropTypes.string,
}

export default EmailCampaign
