/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import { Grid, Paper } from '@mui/material'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Badge from '@mui/material/Badge'
import ListTableActions from "./ListingTable/listTableActions";
import { userSorting } from "../../config/sortingLabels";
import useTranslation from '../../hooks/useTranslation'

const styles = {
  badge: { padding: '0 4px' },
  grid: { width: '100%' },
  tabs: { backgroundColor: '#F5F5F5' }
}

const tabCounter = (count) => {
  return <Badge sx={styles.badge} badgeContent={count} color="primary" />
}

const CustomTabPanel = ({ tableActionsState, currentTab, tabLabels, tabContents, handleTabChange, tabCounts, paginationComponent, handleTableFiltering }) => {
  const [searchTerm, setSearchTerm] = useState(tableActionsState.searchTerm)
  const [sortOptionSelected, setSortOptionSelected] = useState(tableActionsState.sorting)
  const sortOptions = userSorting
  const { t } = useTranslation()

  useEffect(()=>{
    setSearchTerm(tableActionsState.searchTerm)
    setSortOptionSelected(tableActionsState.sorting)
  },[tableActionsState])

  const handleFiltering = (type, value) => {
    let searchValue = type === 'search' ? value : searchTerm
    let sortValue = type === 'sort' ? value : sortOptionSelected
    handleTableFiltering(type, value, { q: searchValue, sort_by: sortValue, tab: currentTab })
  }

  const tableActions = (
    <ListTableActions
      searchText={t('labels.search_users')}
      searchTerm={searchTerm}
      sortOptionSelected={sortOptionSelected}
      getData={handleFiltering}
      sortOptions={sortOptions}
      translateText={t}
    />
  )

  return (
    <Grid container sx={styles.width}>
      <Grid container alignItems={'center'}>
        <Grid item xs={7}>
          {tableActions}
        </Grid>
        <Grid item xs={5}>
          {paginationComponent}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          component={Paper}
          variant='fullWidth'
          sx={styles.tabs} >
          {tabLabels && Object.keys(tabLabels).map(key => (<Tab icon={tabCounts && tabCounter(tabCounts[key])} iconPosition="end" key={key} label={tabLabels[key]} value={key} />))}
        </Tabs>
      </Grid>
      <Grid item xs={12}>
        {tabContents && tabContents[currentTab]}
      </Grid>
      <Grid container item xs={12} justifyContent='flex-end'>
        {paginationComponent}
      </Grid>
    </Grid>
  )
}

CustomTabPanel.propTypes = {
  tabContents: PropTypes.object.isRequired,
  tabLabels: PropTypes.object.isRequired,
  currentTab: PropTypes.string.isRequired,
  handleTabChange: PropTypes.func.isRequired,
  tabCounts: PropTypes.object,
  paginationComponent: PropTypes.element
}

export default CustomTabPanel
