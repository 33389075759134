const getRestrictedKeywords = (emailType) => {
    switch (emailType) {
      case 'UserInvite':
        return ['user_email', 'user_password'];
      case 'NotifyWaitlist':
        return ['product_name', 'variant_name'];  
      default:
        return [];
    }
  };
  export default getRestrictedKeywords