import React, { Component } from 'react';
import { connect } from 'react-redux'
import {bindActionCreators} from 'redux'
import { PropTypes } from 'prop-types'
import * as userActions from '../actions/userActions'
import EditUser from '../components/User/editUser'
import AuthStorage from '../services/authStorage'

const Auth = new AuthStorage();

class EditUserContainer extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      user: this.props.user,
      teams: [],
      saving: false,
      isEditing: false
    }
    this.saveUser = this.saveUser.bind(this)
    this.updateUserState = this.updateUserState.bind(this)
  }

  componentDidMount() {
    if(this.props.user.id > 0) {
      this.props.actions.getUser(this.props.user.id)
      // Feature is disabled atm. 
      // TeamApi.getTeams(-1)
      //   .then(res => {
      //     this.setState({...this.state, teams: res.data.data})
      //   })
    } else if (this.props.match.path.indexOf('profile') >= 0) {
      this.props.actions.getCurrentUser()
    }
  }

  componentWillReceiveProps({ user }) {
    this.setState({user: user})
  }

  updateUserState = (event) => {
    const field = event.target.name
    const user = this.state.user
    switch (event.target.type) {
      case 'checkbox':
        if(user[field] === false) {
          user[field] = true
        } else if(user[field] === true) {
          user[field] = false
        } else {
          user[field] = false
        }
        break
      case 'file':
        user[field] = event.target.files[0]
        break
      default:
        user[field] = event.target.value
    }
    return this.setState({user: user})
  }
  
  saveUser = (event) => {
    event.preventDefault()
    this.setState({ saving: true })
    if(this.state.user.id > 0) {
      this.props.actions.updateUser(this.state.user.id, this.state.user)
    } else {
      this.props.actions.createUser(this.state.user)
    }
    this.setState({ saving: false })
    if (this.props.match.path.indexOf('profile') < 0) {
      this.props.history.push('/admin/users')
    }
    else {
      Auth.setUser(this.state.user)
    }
  }

  render() {
    return (
      <div>
        <EditUser 
          user={this.state.user}
          saving={this.state.saving}
          teams={this.state.teams}
          userProfile={this.props.match.path.indexOf('profile') >= 0}
          onSave={this.saveUser} 
          onChange={this.updateUserState}/> 
      </div>
    )
  }
}

const emptyUser = () => {
  return {
    name: '', email: '', password: '', size: '', shipping_name: '', shipping_address_one: '', shipping_address_two: '',
    shipping_postcode: '', shipping_city: '', shipping_country: '', invoice_name: '', invoice_reference: '', onboard: true,
    invoice_address_one: '', invoice_address_two: '', invoice_postcode: '', invoice_city: '', invoice_country: '', role: 'staff', language: 'en'
  }
}

EditUserContainer.propTypes = {  
  user: PropTypes.object.isRequired
}

const mapStateToProps = (state, ownProps) => {
  let user = emptyUser()

  if (ownProps.match.params.id) {  
    const userId = Number(ownProps.match.params.id)
    if (userId > 0) {
      user.id = userId
      if ( state.users.data.length > 0 ) {
        user = {...user, ...state.users.data.find(cat => cat.id === userId)}
      }
    }
  } else if (ownProps.match.path.indexOf('profile') >= 0) {
    if (Object.keys(state.user).length > 0 && !state.user.loading) {
      user = {...user, ...state.user}
    }
  }
  return { user }

}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(userActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditUserContainer)
