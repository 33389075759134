import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { Button } from '@mui/material';
import * as userGroupActions from '../../actions/userGroupActions';
import ListingPage from '../../components/Shared/ListingPage';
import { getSerialNumber, handleChangeRowsPerPage } from '../../utils/sharedFunctions'
import UserGroup from '../../components/UserGroup/userGroup';
import { ListTable } from '../../components/Shared/ListingTable';
import { setSuccessNotification } from '../../actions/notificationAction';
import useTranslation from '../../hooks/useTranslation'

const UserGroupContainer = (props) => {
  // Destructing props
  const { userGroups, actions, formErrors } = props;
  const { data, count } = userGroups;
  const dispatch = useDispatch();
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(global.constants.perPage.default)
  const history = useHistory();
  const { t } = useTranslation()

  useEffect(() => {
    actions.getUserGroups(page, perPage);
  }, [page, perPage]);

  useEffect(()=>{
    if (Object.keys(formErrors).length > 0){
      Object.keys(formErrors).map((fieldName) => {
        dispatch(setSuccessNotification({ message: formErrors[fieldName] }))
      })
      actions.resetUserGroupFlags()

    }
  },[formErrors])

  const handleNewUserGroupClicked = () => {
    history.push('/admin/userGroups/new');
  }

  const handleOnDeleteUserGroupClicked = (id) => {
    actions.deleteUserGroup(id);
  }

  // For pagination links
  const changePage = (event, page_no) => {
    setPage(page_no + 1)
  }

  const dataActions = (
    <Button
      color="primary"
      variant="contained"
      sx={{textTransform: 'none', float: 'right'}}
      onClick={handleNewUserGroupClicked}>
        {t('labels.create_group')}
    </Button>
  )

  const userGroupTable = () => {
    const userGroupTableBody = data?.map((group, index) => (
      <UserGroup key={group.id} {...group} onDeleteUserGroupClicked={() => handleOnDeleteUserGroupClicked(group.id)}
        index={getSerialNumber(page, perPage, index)} 
      />
  
    ));
    return { body: userGroupTableBody, heading: [t('labels.productInventory.id'), t('labels.productInventory.name'), ''] }
  }

  const tableContent = userGroupTable()

  return (
    <ListingPage
      title={t('labels.menu.user_groups')}
      actions={dataActions}
      table={
        <ListTable
          tableHeadings={tableContent.heading}
          tableContent={tableContent.body}
          count={count ?? 0}
          page_no={page}
          rowsPerPage={perPage}
          onPageChange={(event, page_no) => changePage(event, page_no, setPage)}
          handleChangeRowsPerPage={(event) => handleChangeRowsPerPage(event, setPage, setPerPage, 'page_size', 'user_groups')}
          rowsControl={true} />
      } />
  )
}

UserGroupContainer.propTypes = {  
  userGroups: PropTypes.object.isRequired,
  formErrors: PropTypes.object
}

const mapStateToProps = (state, ownProps) => {
  const formErrors = state.userGroups.formErrors;
  return {
    userGroups: state.userGroups,
    formErrors: formErrors
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(userGroupActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserGroupContainer)
