import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ColorPicker from '../Helpers/ColorPicker'
import ThemePreview from './themePreview'
import { Grid, Paper, Divider, TextField, InputAdornment, FormControlLabel, Checkbox, Typography } from '@mui/material'
import useTranslation from '../../hooks/useTranslation'

export default function ThemeSettings (props) {
  
  const [primaryColorPicker, setPrimaryColorPicker] = useState(false)
  const [secondaryColorPicker, setSecondaryColorPicker] = useState(false)
  const [textColorPicker, setTextColorPicker] = useState(false)
  const { t } = useTranslation()

  return (
    <Grid container item xs={12} sx={{paddingTop: '2%'}}>
      <Paper sx={{width: '100%'}}>
        <Grid container>
          <Grid item xs={12} sx={{padding: '1%', backgroundColor: '#f5f5f5'}}>
            <h4>{t('labels.theme_settings.theme_settings')}</h4>
          </Grid>
          <Divider width="100%" />
          <Grid container spacing={2} sx={{padding: '2%'}}>
            <Grid container direction="column" spacing={2} item xs={12} md={6}>
              <Grid item>
                <TextField
                  label={t('labels.theme_settings.primary_color')}
                  helperText={t('labels.theme_settings.primary_color_helper')}
                  name="primary_color"
                  value={props.store.primary_color || '#000'}
                  placeholder="#000"
                  onChange={props.onChange}
                  onFocus={() => setPrimaryColorPicker(true)}
                  fullWidth
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      paddingLeft: 0
                    },
                    '& label': {
                      marginLeft: '22px',
                    },
                    '& fieldset': {
                      paddingLeft: '32px',
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment  
                        sx={{
                          backgroundColor: props.store.primary_color,
                          padding: '27.5px 14px',
                          borderTopLeftRadius: (theme) =>
                            theme.shape.borderRadius + 'px',
                          borderBottomLeftRadius: (theme) =>
                            theme.shape.borderRadius + 'px'
                        }}
                        position="start">
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="start">
                        {primaryColorPicker ? (
                          <ColorPicker right="50%" color={props.store.primary_color} onChangeComplete={color => props.onChange({target: {name: 'primary_color', value: color.hex}})} onClose={() => setPrimaryColorPicker(false)} />
                        ) : (null)
                        }
                      </InputAdornment>
                    ),
                  }} />
              </Grid>
              <Grid item>
                <TextField
                  label={t('labels.theme_settings.secondary_color')}
                  helperText={t('labels.theme_settings.secondary_color_helper')}
                  name="secondary_color"
                  value={props.store.secondary_color || '#000'}
                  placeholder="#000"
                  onChange={props.onChange}
                  onFocus={() => setSecondaryColorPicker(true)}
                  fullWidth
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      paddingLeft: 0
                    },
                    '& label': {
                      marginLeft: '22px',
                    },
                    '& fieldset': {
                      paddingLeft: '32px',
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment  
                        sx={{
                          backgroundColor: props.store.secondary_color,
                          padding: '27.5px 14px',
                          borderTopLeftRadius: (theme) =>
                            theme.shape.borderRadius + 'px',
                          borderBottomLeftRadius: (theme) =>
                            theme.shape.borderRadius + 'px'
                        }}
                        position="start">
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="start">
                        {secondaryColorPicker ? (
                          <ColorPicker right="50%" color={props.store.secondary_color} onChangeComplete={color => props.onChange({target: {name: 'secondary_color', value: color.hex}})} onClose={() => setSecondaryColorPicker(false)} />
                        ) : (null)
                        }
                      </InputAdornment>
                    ),
                  }} />
              </Grid>
              <Grid item>
                <TextField
                  label={t('labels.theme_settings.text_color')}
                  helperText={t('labels.theme_settings.text_color_helper')}
                  name="text_color"
                  value={props.store.text_color || '#000'}
                  placeholder="#000"
                  onChange={props.onChange}
                  onFocus={() => setTextColorPicker(true)}
                  fullWidth
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      paddingLeft: 0
                    },
                    '& label': {
                      marginLeft: '22px',
                    },
                    '& fieldset': {
                      paddingLeft: '32px',
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment  
                        sx={{
                          backgroundColor: props.store.text_color,
                          padding: '27.5px 14px',
                          borderTopLeftRadius: (theme) =>
                            theme.shape.borderRadius + 'px',
                          borderBottomLeftRadius: (theme) =>
                            theme.shape.borderRadius + 'px'
                        }}
                        position="start">
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment sx={{right: '50%'}} position="start">
                        {textColorPicker ? (
                          <ColorPicker right="50%" color={props.store.text_color} onChangeComplete={color => props.onChange({target: {name: 'text_color', value: color.hex}})} onClose={() => setTextColorPicker(false)} />
                        ) : (null)
                        }
                      </InputAdornment>
                    ),
                  }} />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={<Checkbox checked={props.store.use_custom_fonts === true} onChange={props.onChange} name="use_custom_fonts" />}
                  label={t('labels.theme_settings.use_custom_fonts')} />
                <Typography variant='p' sx={props.styles.helperText}>{t('labels.theme_settings.use_custom_fonts_helper')}</Typography>
              </Grid>
              {
                props.store.use_custom_fonts && (
                  <>
                    <Grid item>
                      <TextField
                        label={t('labels.theme_settings.normal_fonts')}
                        name="normal_fonts"
                        value={props.store.normal_fonts_name}
                        fullWidth
                        inputProps={
                          { readOnly: true, }
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <input type="file" name="normal_fonts" onChange={props.onChange} />
                            </InputAdornment>
                          )}} />
                    </Grid>
                    <Grid item>
                      <TextField
                        label={t('labels.theme_settings.bold_fonts')}
                        name="bold_fonts"
                        value={props.store.bold_fonts_name}
                        fullWidth
                        inputProps={
                          { readOnly: true, }
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <input type="file" name="bold_fonts" onChange={props.onChange} />
                            </InputAdornment>
                          )}} />
                    </Grid>
                  </>
                )
              }
            </Grid>
            <Grid container direction="column" item xs={12} md={6}>
              <Grid item>
                <ThemePreview store={props.store} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}

ThemeSettings.propTypes = {
  store: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  styles: PropTypes.object.isRequired
}