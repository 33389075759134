import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { TableCell, TableRow } from '@mui/material';
import { indexColumn } from '../../styles'
import useTranslation from '../../hooks/useTranslation'

const EmailTemplate = ({ id, name, subject, type, system_email,  deleteEmailTemplate, index }) => {
  const { t } = useTranslation()

  return (
    <TableRow key={id}>
      <TableCell component="th" scope="row" sx={indexColumn}><Link to={`/admin/email-templates/edit/${id}`}>{index}</Link></TableCell>
      <TableCell component="th" scope="row"><Link to={`/admin/email-templates/${id}`}> {name || "untitled"}</Link></TableCell>
      <TableCell component="th" scope="row"> {subject || "untitled"} </TableCell>
      <TableCell component="th" scope="row">{type}</TableCell>
      <TableCell component="th" scope="row">{ system_email && '✔' }</TableCell>
      <TableCell align="left">
        <Link to={`/admin/email-templates/${id}`}>
          <IconButton color="primary" size="small"><VisibilityIcon /></IconButton>
        </Link>
        <Link to={`/admin/email-templates/edit/${id}`}>
          <IconButton color="primary" size="small"><EditIcon /></IconButton>
        </Link>
        {!system_email && (
          <IconButton color="error" size="small" onClick={() => { if (window.confirm(t('labels.are_you_sure'))) deleteEmailTemplate() } }>
            <DeleteIcon />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  )
}

EmailTemplate.propTypes = {
  id: PropTypes.number.isRequired,
  subject: PropTypes.string,
  deleteEmailTemplate: PropTypes.func.isRequired
}

export default EmailTemplate
