import React, { useEffect, useState } from "react";
import { TableCell, TableRow } from "@mui/material";
import {Link} from "react-router-dom";
import ListingThumbnail from "../Shared/ListingThumbnail";
import AddStockModal from "./addStockModal";
import WaitlistByProductVariant from "../Waitlist/waitlistByProductVariant"

const ProductInventory = ({productVariant, index}) => {
  const [addStock, setAddStock] = useState(false)
  const [updatedQuantity, setUpdatedQuantity] = useState(productVariant.quantity)
  const [updatedIncomingStock, setUpdatedIncomingStock] = useState(productVariant.incoming_stock_quantity)
  const [showWaitlist, setShowWaitlist] = useState(false)

  useEffect(()=>{
    setUpdatedQuantity(productVariant.quantity)
    setUpdatedIncomingStock(productVariant.incoming_stock_quantity)
  },[productVariant])

  const handleVariantQuantityAdded = (quantity, incomingStock) => {
    if (incomingStock) {
      setUpdatedIncomingStock(+updatedIncomingStock + +quantity)
    } else {
      setUpdatedQuantity(+updatedQuantity + +quantity)
    }
  }

  const handleWaitlistOnClick = (event) => {
    event.preventDefault()
    setShowWaitlist(true)
  }

  return (
    <TableRow key={productVariant.id}>
      {
        addStock &&
        <AddStockModal productVariant={productVariant} variantId={productVariant.id} handleVariantQuantityAdded={handleVariantQuantityAdded} closeModal={() => setAddStock(false)} />
      }
      {showWaitlist &&
        <WaitlistByProductVariant variantId={productVariant.id} allowBackorders={productVariant.allow_backorders} closeModal={() => setShowWaitlist(false)} />
      }
      <TableCell component="th" scope="row"><Link
        to={`/admin/products/edit/${productVariant?.product?.id}`}>{index}</Link></TableCell>
      <ListingThumbnail
        image={productVariant.product?.images?.length > 0 ? productVariant.product?.images?.sort((a, b) => a.position - b.position)[0].image : ''}/>
      <TableCell component="th" scope="row"><Link
        to={`/admin/products/edit/${productVariant.product.id}`}>
        <div>{productVariant.product?.name}</div>
        <div>{productVariant.name}</div>
      </Link></TableCell>
      <TableCell>{productVariant.sku}</TableCell>
      <TableCell>{ updatedIncomingStock }</TableCell>
      <TableCell>{ updatedQuantity}</TableCell>
      <TableCell>{productVariant.waitlist_count > 0 ? <Link to='' onClick={handleWaitlistOnClick}>{productVariant.waitlist_count}</Link> : 0}</TableCell>
      <TableCell><div style={{ cursor: 'pointer' }} onClick={() => setAddStock(true)}><a>Add Stock</a></div></TableCell>
    </TableRow>
  )
}
export default ProductInventory;
