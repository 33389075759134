import * as frontpageActions from '../actions/frontpageActions'
import initialState from './initialState'

const frontpage = (state = initialState.frontpage, action) => {
  switch (action.type) {
    case frontpageActions.GET_FRONTPAGE:
      return {
        ...state,
        loading: true
      }
    case frontpageActions.GET_FRONTPAGE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false
      }
    case frontpageActions.UPDATE_FRONTPAGE:
      return {
        ...state,
        updating: true
      }
    case frontpageActions.UPDATE_FRONTPAGE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        updating: false
      }
    default:
      return state
  }
}

export default frontpage