import {HttpClient} from './httpClient' 
import {API_URL} from './RestAPI'
import apiHeaders from './apiHeaders'

//Setting the users URI
const USER_API = `${API_URL}/users`

// Create
const createAddress = (userId, address) => {
  return HttpClient.post(
    `${USER_API}/${userId}/addresses/create`,
    address,
    apiHeaders()
  )
}

// Read
const getAddresses = (userId, page = 1) => {
  return HttpClient.get(
    `${USER_API}/${userId}/addresses?page=${page}`,
    apiHeaders()
  )
}

// Get
const getAddress = (userId, id) => {
  return HttpClient.get(
    `${USER_API}/${userId}/addresses/${id}`,
    apiHeaders()
  )
}

// Update
const updateAddress = (userId, id, address) => {
  return HttpClient.put(
    `${USER_API}/${userId}/addresses/${id}/update`,
    address,
    apiHeaders()
  )
}

// Delete
const removeAddress = (userId, id) => {
  return HttpClient.delete(
    `${USER_API}/${userId}/addresses/${id}`,
    apiHeaders()
  )
}

//Encapsulating in a JSON object
const UserAddressesApi = {getAddresses, createAddress, getAddress, updateAddress, removeAddress}

export {UserAddressesApi}
