import {HttpClient} from './httpClient' 
import {API_URL} from './RestAPI'
import apiHeaders from './apiHeaders'

//Setting the store API URI
const FRONTPAGE_API = `${API_URL}/front-page`
const SPLASHIMAGE_API = `${API_URL}/splash-images`

// Get
const getFrontpage = () => {
  return HttpClient.get(
    `${FRONTPAGE_API}/retrieve`,
    apiHeaders()
  )
}

// Update
const updateFrontpage = frontpage => {
  return HttpClient.put(
    `${FRONTPAGE_API}/update`,
    frontpage,
    apiHeaders()
  )
}

// Get Splash Images
const getSplashImages = () => {
  return HttpClient.get(
    `${SPLASHIMAGE_API}`,
    apiHeaders()
  )
}

// Create Splash image
const createSplashImage = image => {
  return HttpClient.post(
    `${SPLASHIMAGE_API}/create`,
    image,
    apiHeaders()
  )
}

// Update Splash image
const updateSplashImage = (id, image) => {
  return HttpClient.put(
    `${SPLASHIMAGE_API}/${id}/update`,
    image,
    apiHeaders()
  )
}

// Delete Splash image
const removeSplashImage = image => {
  return HttpClient.delete(
    `${SPLASHIMAGE_API}/${image.id}`,
    apiHeaders()
  )
}

//Encapsulating in a JSON object
const FrontpageApi = {getFrontpage, updateFrontpage, createSplashImage, updateSplashImage, removeSplashImage, getSplashImages}

export {FrontpageApi}