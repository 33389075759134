import React from 'react'
import { Switch, Route } from 'react-router-dom'
import CategoryContainer from '../containers/categoryContainer'
import EditCategoryContainer from '../containers/editCategoryContainer'

export default ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}`} component={CategoryContainer} />
    <Route path={`${match.path}/new`} component={EditCategoryContainer} />
    <Route path={`${match.path}/edit/:id`} component={EditCategoryContainer} />
  </Switch>
)
