import React from 'react'
import PropTypes from 'prop-types'

export default function ImageThumbRect({image, styles = { height: '30px', width: '40px'}}) {
  return <div style={{ ...styles, backgroundSize: 'cover', backgroundPosition: 'center', backgroundImage: `url(${image})` }}></div>
}

ImageThumbRect.propTypes = {
  image: PropTypes.string.isRequired,
  styles: PropTypes.object
}
