import {HttpClient} from './httpClient'
import {API_URL} from './RestAPI'
import apiHeaders from './apiHeaders'

const HOME_API = `${API_URL}/home`

const dashboard = () => {
  return HttpClient.get(
    `${HOME_API}/dashboard`,
    apiHeaders()
  )
}

const ordersChart = (selectedOption = 0, dependent = {}) => {
  return HttpClient.get(
    `${HOME_API}/ordersChart?selectedOption=${selectedOption}`,
    apiHeaders(dependent)
  )
}

const HomeApi = { dashboard, ordersChart }

export {HomeApi}
