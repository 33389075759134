const quantitySorting = {
  'labels.sorting.quantity_low_high': 'Quantity Ascending',
  'labels.sorting.quantity_high_low': 'Quantity Descending',
}

const nameSorting = {
  'labels.sorting.name_az': 'Name Ascending',
  'labels.sorting.name_za': 'Name Descending'
}

const createdAtSorting = {
  'labels.sorting.latest': 'latest',
  'labels.sorting.oldest': 'oldest'
}

const totalSorting = {
  'labels.sorting.total_low_high': 'Total Ascending',
  'labels.sorting.total_high_low': 'Total Descending',
}

const expireSorting = {
  'labels.sorting.expire_low_high': 'Expires Ascending',
  'labels.sorting.expire_high_low': 'Expires Descending',
}

export const collectionSorting = Object.assign({}, createdAtSorting, nameSorting);

export const userSorting = Object.assign({}, createdAtSorting, nameSorting);

export const categorySorting = Object.assign({}, createdAtSorting, nameSorting);

export const productSorting = Object.assign({}, createdAtSorting, nameSorting);

export const discountSorting = Object.assign({}, createdAtSorting, expireSorting);

export const orderSorting = Object.assign({}, createdAtSorting, totalSorting);

export const productVariantSorting = Object.assign({}, createdAtSorting, nameSorting, quantitySorting);

export const emailTemplateSorting = Object.assign({}, createdAtSorting);