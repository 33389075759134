import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import { Col } from 'react-bootstrap'
import { PropTypes } from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as userGroupActions from '../../actions/userGroupActions'
import FormUserGroup from '../../components/UserGroup/formUserGroup'
import FormFlashMessage from '../../components/Helpers/formFlashMessage';
import { UserGroupApi } from "../../api/userGroupApi"

const FormUserGroupContainer = (props) => {
  //Destructuring props
  const { userGroupId, actions, formErrors } = props;

  // Defining States
  const [userGroup, setUserGroup] = useState({ name: '' });
  const [page, setPage] = useState(1);
  const [groupCreated, setGroupCreated] = useState(false);
  const [groupUpdated, setGroupUpdated] = useState(false);

  // Hooks
  const history = useHistory();

  useEffect(() => {
    // Only if Edit Request
    if(userGroupId){
      const fetchUserGroup = (userGroupId) => {
        UserGroupApi.getUserGroup(userGroupId).then(res => {
          setUserGroup(res.data.userGroup);
        })
      }
      if(userGroupId) fetchUserGroup(userGroupId);
    }
  }, []);

  // Changing and setting page number or userGroup (in case of edit)
  useEffect(() => {
    if(props.userGroup) setUserGroup(props.userGroup);
    if(props.page) setPage(props.page);
  }, [props.userGroup, page]);

  useEffect(() => {
    if(groupCreated){
      history.push('/admin/userGroups');
      setGroupCreated(false);
    }
    if(groupUpdated){
      history.push('/admin/userGroups');
      setGroupUpdated(false);
    }
  }, [groupCreated, groupUpdated]);

  const handleOnFieldValueChanged = (event) => {
    const updatedName = event.target.value
    const updatedUserGroup = { ...userGroup, name: updatedName };
    setUserGroup(updatedUserGroup);
  }

  const handleOnSaveBtnClicked = (event) => {
    event.preventDefault();
    if(userGroup?.id){
      actions.updateUserGroup(userGroup, setGroupUpdated);
    }else{
      actions.createUserGroup(userGroup, setGroupCreated);
    }
  }

  return (
    <div>
      {/* {
        formErrors &&
        <Col xs={12} md={12}>
          <FormFlashMessage formErrors={formErrors} />
        </Col>
      } */}
      <FormUserGroup 
        userGroup={userGroup}
        onFieldValueChanged={handleOnFieldValueChanged}
        onSaveBtnClicked={handleOnSaveBtnClicked}
      />  
    </div>
  );
}

FormUserGroupContainer.propTypes = {  
  userGroupId: PropTypes.number,
  formErrors: PropTypes.object
}

const mapStateToProps = (state, ownProps) => {
  const formErrors = state.userGroups.formErrors;
  const userGroupId = Number(ownProps.match.params?.id);
  return {
    userGroupId: userGroupId,
    formErrors: formErrors,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(userGroupActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormUserGroupContainer);
