import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Button, TableRow, IconButton, TableCell } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import useTranslation from '../../hooks/useTranslation'

const User = props => {
  const { t } = useTranslation()
  const styles = {
    removeBtn: {
      outline: "none !important"
    }
  }

  return (
    <TableRow key={props.id}>
      <TableCell sx={props.styles.tableBody}><Link to={`/admin/users/edit/${props.id}`}>{props.index}</Link></TableCell>
      <TableCell sx={props.styles.tableBody}><Link to={`/admin/users/edit/${props.id}`}>{props.name}</Link></TableCell>
      <TableCell sx={props.styles.tableBody}>{props.email}</TableCell>
      <TableCell sx={props.styles.tableBody}>{props.role}</TableCell>
      <TableCell sx={props.styles.tableBody}>{props.onboard ? 'Yes' : 'No'}</TableCell>
      {/* <TableCell sx={props.styles.tableBody}>{props.status}</TableCell> */}
      <TableCell sx={props.styles.tableBody}><Link to={`/admin/userGroups/${props?.userGroup?.id}`}>{props?.userGroup?.name}</Link></TableCell>
      <TableCell sx={props.styles.tableBody} align="right">{props.status === 'unapproved' && <Button sx={styles.removeBtn} variant="contained" color="info" size="small" onClick={() => props.approveUser()}>Approve</Button>}</TableCell>
      <TableCell sx={props.styles.tableBody} align="right"><IconButton sx={styles.removeBtn} variant="contained" color="error" size="small" onClick={() => { if (window.confirm(t('labels.are_you_sure'))) props.deleteUser() }}><DeleteIcon/></IconButton></TableCell>
    </TableRow>
  )
}

User.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
  email: PropTypes.string,
  shipping_name: PropTypes.string,
  role: PropTypes.string,
  onboard: PropTypes.bool,
  team: PropTypes.object,
  deleteUser: PropTypes.func,
  styles: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired
}

export default User
