import { MenuApi } from "../api/menuApi"
import { setSuccessNotification } from "../actions/notificationAction";

// Read
export const GET_MENUS = '[Mstore] GET_MENUS'
export const GET_MENUS_SUCCESS = '[Mstore] GET_MENUS_SUCCESS'
export const GET_MENUS_ERROR = '[Mstore] GET_MENUS_ERROR'

// Get
export const GET_MENU = '[Mstore] GET_MENU'
export const GET_MENU_SUCCESS = '[Mstore] GET_MENU_SUCCESS'
export const GET_MENU_ERROR = '[Mstore] GET_MENU_ERROR'

// Search
export const SEARCH_MENU = '[Mstore] SEARCH_MENU'
export const SEARCH_MENU_SUCCESS = '[Mstore] SEARCH_MENU_SUCCESS'
export const SEARCH_MENU_ERROR = '[Mstore] SEARCH_MENU_ERROR'

// Update
export const UPDATE_MENU = '[Mstore] UPDATE_MENU'
export const UPDATE_MENU_SUCCESS = '[Mstore] UPDATE_MENU_SUCCESS'
export const UPDATE_MENU_ERROR = '[Mstore] UPDATE_MENU_ERROR'

//Read
export const getMenus = page => {
  return (dispatch, getState) => {
    dispatch({
      type: GET_MENUS
    })
    MenuApi.getMenus(page).then(res => {
      dispatch(getMenusSuccess(res.data))
    })
    .catch(error => {
      dispatch(getMenusFailure(error))
    })
  }
}

export const getMenusSuccess = data => {
  return {
    type: GET_MENUS_SUCCESS,
    payload: data
  }
}

export const getMenusFailure = error => {
  return {
    type: GET_MENUS_ERROR,
    payload: error
  }
}

//Get
export const getMenu = id => {
  return (dispatch, getState) => {
    dispatch({
      type: GET_MENU
    })

    MenuApi.getMenu(id).then(res => {
      dispatch(getMenuSuccess(res.data))
    })
  }
}

export const getMenuSuccess = menu => {
  return {
    type: GET_MENU_SUCCESS,
    payload: menu
  }
}

export const getMenuFailure = error => {
  return {
    type: GET_MENU_ERROR,
    payload: error
  }
}

// Update
export const updateMenu = (id, menu) => {
  return (dispatch, getState) => {
    //Multiple actions can be sent usign the Redux-Thunk middleware
    dispatch({
      type: UPDATE_MENU,
      payload: menu
    })
    MenuApi.updateMenu(id, menu).then(res => {
      dispatch(setSuccessNotification({ message: 'Menu updated successfully' }))
      dispatch(updateMenuSuccess(res.data))
    })
  }
}
export const updateMenuSuccess = menu => {
  return {
    type: UPDATE_MENU_SUCCESS,
    payload: menu
  }
}
