import React, { useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Button } from '@mui/material';
import { CSVLink } from "react-csv";
import { ProductApi } from "../api/productApi";
import { setSuccessNotification } from '../actions/notificationAction'
import { getSerialNumber } from '../utils/sharedFunctions'
import { productSorting } from "../config/sortingLabels";
import { productCSVHeaders } from "../config/csvHeaders";
import useListingActions from '../hooks/useListingActions'
import { actionBtn } from '../styles'
import { Product, ListingPage, ListTableActions } from '../shared-components';
import useTranslation from '../hooks/useTranslation'

const ProductContainer = () => {
  const [dataForExport, setDataForExport] = useState([])
  const csvButtonRef = useRef(null)
  const dispatch = useDispatch()

  const history = useHistory()

  const {
    data,
    count,
    page,
    perPage,
    searchTerm,
    setData,
    sortOptionSelected,
    handlerForSearchingAndSortingChange,
  } = useListingActions('products', ProductApi.getProducts );
  const { t } = useTranslation()

  let getProducts = async (query='',sort_by='Name ascending', exportRows=false ) => {
    const response = await ProductApi.getProducts({ page: page, per_page: perPage, q: query, sort_by: sort_by, export_records: exportRows })
    return response.data.data
  }

  const newProduct = () => history.push('/admin/products/new')

  const deleteProduct = product => {
    ProductApi.removeProduct(product).then(res => {
      if (res.status === 204) {
        setData(data.filter(prod => prod.id !== product.id))
        dispatch(setSuccessNotification({ message: t('response_messages.products.deleted') }))
      }
    })
  }

  const exportCSV = async () => {
    const dataToExport = await getProducts(searchTerm, sortOptionSelected, true )
    dataToExport?.map((product)=>{
      product.variants = product.variants.map(variant => variant.name).join(', ')
      product.category = product.category_slug
      product.createdAt = new Date(product.createdAt).toLocaleString('en-US')
    })
    if (dataToExport.length > 0) {
      setDataForExport(dataToExport)
      csvButtonRef.current.link.click()
    }
  }

  const tableActions = (
    <ListTableActions
      searchTerm={searchTerm}
      searchText={t('labels.search_products')}
      sortOptionSelected={sortOptionSelected}
      getData={handlerForSearchingAndSortingChange}
      translateText={t}
      sortOptions={productSorting}
    />
  )

  const handleReorder = (productId) => { history.push(`/admin/products/reorder`);};

  const buttons = (
    <>
      <CSVLink
        data={dataForExport}
        headers={productCSVHeaders}
        filename={`products-${(new Date()).toLocaleDateString().replace(', ', '-')}.csv`}
        className='hidden'
        ref={csvButtonRef}
        target='_blank'/>
      <Button variant="outlined" sx={actionBtn}
              onClick={handleReorder}>{t('labels.reorder_products')}</Button>  
      <Button variant="outlined" sx={actionBtn}
              onClick={exportCSV}>{t('labels.exportToCsv')}</Button>
      <Button variant="contained" sx={actionBtn} onClick={newProduct}>{t('labels.create_product')}</Button>
    </>
  )

  const tableContent = productTableContent(deleteProduct, data, page, perPage)

  return (
    <ListingPage
      title={t('labels.menu.products')}
      actions={buttons}
      tableContent={tableContent} tableActions={tableActions} count = {count} page= {page}  perPage = {perPage} handlerForSearchingAndSortingChange ={handlerForSearchingAndSortingChange}  />
  )
}

const productTableContent = (deleteProduct, products=[], page_no, per_page) => {
  const history = useHistory()
  const { t } = useTranslation()
  const handleEdit = (productId) => { history.push(`/admin/products/edit/${productId}`);};
  const productBody = products.map( (product,index) => (
    <Product product={product} deleteProduct={() => deleteProduct(product)} index={getSerialNumber(page_no, per_page, index)} handleEdit={handleEdit} />
  ))
  return { productBody: productBody, productHeading: [t('labels.id'), '', t('labels.name'), t('labels.article_no'), t('labels.category'), t('labels.public'), t('labels.action')] }
}

export default ProductContainer