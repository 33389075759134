import React, { useState, useEffect, useRef } from 'react';
import { Modal, Fade, Paper, Button, Backdrop, Divider, Grid, IconButton, FormControlLabel, Checkbox, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { setSuccessNotification, setErrorNotification } from '../../../actions/notificationAction';
import useTranslation from '../../../hooks/useTranslation'

const styles = {
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    width: { xs: '95%', sm: '70%', md: '60%', lg: '50%' },
    boxShadow: 24
  },
  container: {
    padding: '2%'
  },
  actionBtn: {
    textTransform: 'none',
    marginRight: '2%',
  },
  container: {
    padding: '2%',
    flexDirection: 'column',
    alignItems: 'left',
  },
  inputFile: {
    paddingLeft: '10px',
    display: 'none'
  },
  fileBtn: {
    textTransform: 'none',
    marginTop: '2%',
    float: 'left',
    marginBottom: '2%',
    marginRight: '2%'
  },
  selectedFile: {
    paddingTop: '2%'
  },
  stats: {
    color: 'darkblue'
  }
}
const UploadCsvModal = (props) => {
  const { title, modalState, setModalState, apiFunction } = props;
  const [file, setFile] = useState(null);
  const [responseStats, setResponseStats] = useState('');
  const [isInviteChecked, setInviteChecked] = useState(false);
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation()

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === 'text/csv') {
      setFile(file)
      uploadFile(file, true)
    } else {
      setResponseStats('Please select a valid CSV file.');
    }
  };

  const apiCall = async (apiFunction, formData) => {
    try {
      const response = await apiFunction(formData);
      if (response.status === 200) {
        return { success: true, data: response.data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      return { success: false, message: 'Something went wrong. Please try again later!' };
    }
  };

  const uploadFile = async (selectedFile, isUpload = false) => {
    setModalState(isUpload);
    setLoading(true);
    const formData = new FormData();
    formData.append("file", file ? file : selectedFile);
    formData.append("inviteUser", isInviteChecked);
    formData.append("justStats", isUpload);
    await apiCall(apiFunction, formData).then((result) => {
      setLoading(false);
      if (result.success) {
        !isUpload &&  dispatch(setSuccessNotification({ message: result.data.message })) && setFile(null) && setResponseStats('')
        setResponseStats(result.data.message)
      } else {
        setResponseStats(result.message);
        dispatch(setErrorNotification({ message: result.message }))
      }
    });
  };

  return (
    <Modal
      open={modalState}
      onClose={() => setModalState(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={modalState}>
        <Paper sx={styles.modal}>
          <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <Grid container alignItems="center">
            <Grid item xs={11} sx={{paddingLeft: '2%'}}>
              <h3>{title}</h3>
            </Grid>
            <Grid item xs={1}>
              <IconButton sx={{float: 'right'}} onClick={() => setModalState(false)}><CloseIcon /></IconButton>
            </Grid>
            <Divider width='100%' />
            <Grid item xs={12} md={12}>
              <div style={styles.container}>
                <input ref={fileInputRef} style={styles.inputFile} type="file" onChange={handleFileChange} accept=".csv" />
                <Button variant="outlined" sx={styles.fileBtn} color="primary" onClick={() => fileInputRef.current.click()} >Choose CSV File</Button> 
                {file && (
                  <div>
                    <p style={styles.selectedFile}>{file.name}</p>
                    <br></br>
                    <p style={styles.stats}>{responseStats}</p>
                    <FormControlLabel
                      control={<Checkbox checked={isInviteChecked} onChange={(e) => setInviteChecked(e.target.checked)} />}
                      label={t('labels.invite_user')}
                    />
                  </div>
                )}
              </div>
            </Grid>
            <Divider width="100%" />
            <Grid item sx={styles.container} xs={12} sm={12} textAlign="end">
              <Button variant="contained" sx={styles.actionBtn} color="primary" onClick={uploadFile} disabled={!file}>Upload</Button>
            </Grid>
          </Grid>
        </Paper>
      </Fade>
    </Modal>
  );
}

UploadCsvModal.propTypes = {
  apiFunction: PropTypes.func.isRequired
}

export default UploadCsvModal;