import React, { Component } from 'react';
import { connect } from 'react-redux'
import {bindActionCreators} from 'redux'
import { PropTypes } from 'prop-types'
import * as frontpageActions from '../actions/frontpageActions'
import EditFrontpage from '../components/Frontpage/editFrontpage'
import * as notificationActions from "../actions/notificationAction";

class EditFrontpageContainer extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      frontpage: this.props.frontpage,
      saving: false,
      isEditing: false
    }
    this.saveFrontpage = this.saveFrontpage.bind(this)
    this.updateFrontpageState = this.updateFrontpageState.bind(this)
  }

  componentDidMount() {
    this.props.actions.getFrontpage()
  }

  componentWillReceiveProps({ frontpage }) {
    this.setState({frontpage: frontpage})
  }

  addSplashImage = () => {
    const frontpage = { ...this.state.frontpage }
    let newSplashImage = emptySplashImage()
    newSplashImage._id = Math.max(...frontpage.splash_images.map(v => v._id | v.id), 0) + 1
    newSplashImage.position = Math.max(...frontpage.splash_images.map(v => v.position), 0) + 1
    frontpage.splash_images.push(newSplashImage)
    return this.setState({ frontpage: frontpage })
  }

  updateSplashImage = splashImage => {
    const frontpage = { ...this.state.frontpage }
    let indexOfSplashImageToUpdate = frontpage.splash_images.findIndex(({ id }) => id === splashImage.id)
    frontpage.splash_images[indexOfSplashImageToUpdate] = splashImage
    this.setState({ frontpage: frontpage })
    this.syncSplashImageSize(splashImage.size)
  }

  syncSplashImageSize = (size) => {
    const frontpage = { ...this.state.frontpage }
    const updatedSplashImages = frontpage.splash_images.map(image => ({
      ...image,
      size
    }))
    this.setState({ frontpage: { ...frontpage, splash_images: updatedSplashImages } })
  }

  deleteImage = () => this.setState({
    frontpage: {...this.state.frontpage, image: '', _delete_image: true}
  })

  updateFrontpageState = (event, fieldName) => {
    const field = fieldName || event.target.name;
    const page = {...this.state.frontpage};
    if (fieldName) {
      page[field] = event;
    } else {
      switch (event.target.type) {
        case 'file':
          page[field] = event.target.files[0];
          event.target.files[0] && (page.image_preview_url = URL.createObjectURL(event.target.files[0]));
          break;
        case 'checkbox':
          page[field] = !page[field];
          break;
        default:
          page[field] = event.target.value;
      }
    }
    return this.setState({ frontpage: page });
  }

  updateStaticContentVisibility = (value) => {
    const page = {...this.state.frontpage}
    page.show_header = value
    return this.setState({frontpage: page})
  }

  sortSplashImages = splashImages => {
    const page = {...this.state.frontpage}
    page.splash_images = splashImages
    return this.setState({ frontpage: page })
  }

  saveFrontpage = async (event) => {
    event.preventDefault()
    this.setState({ saving: true })

    const { frontpage } = this.state
    let frontpageData = new FormData()
    let images = []

    Object.entries(frontpage).forEach(([field, value]) => {
      if (field === 'splash_images') {
        value.forEach(image => {
          let imageData = new FormData()
          Object.entries(image).forEach(([key, val]) => {
            if (key === 'image_file') {
              imageData.append('image', val)
            } else {
              imageData.append(key, val)
            }
          })
          if (image.id) {
            imageData.append('id', image.id)
          }
          images.push(imageData)
        })
      } else {
        frontpageData.append(field, value ?? '')
      }
    })

    try {
      const response = await this.props.actions.updateFrontpage(frontpageData, images)
      const { allSplashImages } = response
      const updatedFrontpage = { ...frontpage, splash_images: allSplashImages }
      this.setState({ frontpage: updatedFrontpage, saving: false })
      this.props.notification.setSuccessNotification({ message: 'Front Page is updated successfully.' })
    } catch (error) {
      this.setState({ saving: false })
      this.props.notification.setErrorNotification({ message: 'Failed to update the Front Page.' })
    }
  }

  render() {
    return (
      <div>
        <EditFrontpage 
          frontpage={this.state.frontpage}
          newSplashImage={this.addSplashImage}
          updateSplashImage={this.updateSplashImage}
          isSaving={this.state.saving}
          onSave={this.saveFrontpage} 
          deleteImage={this.deleteImage}
          onChange={this.updateFrontpageState}
          updateStaticContentVisibility={this.updateStaticContentVisibility}
          sortSplashImages={this.sortSplashImages}/> 
      </div>
    )
  }
}

const emptyFrontpage = () => {
  return {
    header: '',
    show_header: false,
    text: '',
    image: '',
    splash_images:[emptySplashImage()],
  }
}

const emptySplashImage = () => {
  return { _id: 0, image: '', image_file: '', url: '', button_text: '', headline_text: '', text_color: 'white', visible: true, name: '', selectedModuleOption: '', button_position: 'left', size: 'large' }
}

EditFrontpageContainer.propTypes = {  
  frontpage: PropTypes.object.isRequired
}

const mapStateToProps = state => {
  let frontpage = emptyFrontpage()
  if (Object.keys(state.frontpage).length > 0 && !state.frontpage.loading) {
    frontpage = state.frontpage
  }
  return { frontpage: frontpage }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(frontpageActions, dispatch),
    notification: bindActionCreators( notificationActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditFrontpageContainer)