import { TeamApi } from '../api/teamApi'

// Create
export const CREATE_TEAM = '[Mstore] CREATE_TEAM'
export const CREATE_TEAM_SUCCESS = '[Mstore] CREATE_TEAM_SUCCESS'
export const CREATE_TEAM_ERROR = '[Mstore] CREATE_TEAM_ERROR'


// Read
export const GET_TEAMS = '[Mstore] GET_TEAMS'
export const GET_TEAMS_SUCCESS = '[Mstore] GET_TEAMS_SUCCESS'
export const GET_TEAMS_ERROR = '[Mstore] GET_TEAMS_ERROR'


export const GET_TEAM = '[Mstore] GET_TEAM'
export const GET_TEAM_SUCCESS = '[Mstore] GET_TEAM_SUCCESS'
export const GET_TEAM_ERROR = '[Mstore] GET_TEAM_ERROR'

// Update
export const UPDATE_TEAM = '[Mstore] UPDATE_TEAM'
export const UPDATE_TEAM_SUCCESS = '[Mstore] UPDATE_TEAM_SUCCESS'
export const UPDATE_TEAM_ERROR = '[Mstore] UPDATE_TEAM_ERROR'

// Delete
export const DELETE_TEAM = '[Mstore] DELETE_TEAM'
export const DELETE_TEAM_SUCCESS = '[Mstore] DELETE_TEAM_SUCCESS'
export const DELETE_TEAM_ERROR = '[Mstore] DELETE_TEAM_ERROR'

// Create
export const createTeam = team => {
  return (dispatch, getState) => {
    dispatch({
      type: CREATE_TEAM
    })

    TeamApi.createTeam(team).then(res => {
      dispatch(createTeamSuccess(res.data))
    })
  }
}

export const createTeamSuccess = team => {
  return {
    type: CREATE_TEAM_SUCCESS,
    payload: team
  }
}

export const createTeamFailure = error => {
  return {
    type: CREATE_TEAM_ERROR,
    payload: error
  }
}

//Read
export const getTeams = page => {
  return (dispatch, getState) => {
    dispatch({
      type: GET_TEAMS
    })

    TeamApi.getTeams(page).then(res => {
      dispatch(getTeamsSuccess(res.data))
    })
  }
}

export const getTeamsSuccess = data => {
  return {
    type: GET_TEAMS_SUCCESS,
    payload: data
  }
}

export const getTeamsFailure = error => {
  return {
    type: GET_TEAMS_ERROR,
    payload: error
  }
}


export const getTeam = id => {
  return (dispatch, getState) => {
    dispatch({
      type: GET_TEAM
    })

    TeamApi.getTeam(id).then(res => {
      dispatch(getTeamSuccess(res.data))
    })
  }
}


export const getTeamSuccess = team => {
  return {
    type: GET_TEAM_SUCCESS,
    payload: team
  }
}

export const getTeamFailure = error => {
  return {
    type: GET_TEAM_ERROR,
    payload: error
  }
}

//Update
export const updateTeam = (id, team) => {
  return (dispatch, getState) => {
    //Multiple actions can be sent usign the Redux-Thunk middleware
    dispatch({
      type: UPDATE_TEAM,
      payload: team
    })
    TeamApi.updateTeam(id, team).then(res => {
      dispatch(updateTeamSuccess(res.data))
    })
  }
}
export const updateTeamSuccess = team => {
  return {
    type: UPDATE_TEAM_SUCCESS,
    payload: team
  }
}


//Delete
export const deleteTeam = team => {
  return (dispatch, getState) => {
    dispatch({
      type: DELETE_TEAM,
      payload: team
    })

    TeamApi.removeTeam(team).then(res => {
      if (res.status === 204) {
        dispatch(deleteTeamSuccess(team))
      }
    })
  }
}
export const deleteTeamSuccess = team => {
  return {
    type: DELETE_TEAM_SUCCESS,
    payload: team
  }
}
