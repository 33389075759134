import * as storeActions from '../actions/storeActions'
import initialState from './initialState'

const currencies = (state = initialState.currencies, action) => {
  switch (action.type) {
    case storeActions.GET_CURRENCIES:
      return {
        ...state,
        loading: true
      }
    case storeActions.GET_CURRENCIES_SUCCESS:
      return {
        ...action.payload,
        loading: false
      }
    default:
      return state
  }
}

export default currencies
