import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Grid, Typography, Divider } from '@mui/material'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { UserTransactionsApi } from '../../api/userTransactionsApi'
import CreditAccount from './creditAccount'
import StatusChip from '../Helpers/StatusChip'

const AccountBalance = props => {
  const newTransaction = {user_id: props.match.params.id, value: '', type: 'debit', note: ''}
  const [transactions, setTransactions] = useState([])
  const [transaction, setTransaction] = useState({...newTransaction})
  const [saving, setSaving] = useState(false)
  const [balance, setBalance] = useState(0.00)

  useEffect(() => {
    let didCancel = false // to cancel side effects
    UserTransactionsApi.getTransactions(props.match.params.id)
      .then(result => {
        if(!didCancel) {
          // state update goes here
          if(result.data.data.length) {
            setBalance(result.data.data[0].new_balance)
          }
          setTransactions(result.data.data)
        }
      })
  
    return () => { didCancel = true }
  }, [])
  
  const deleteTransaction = id => {
    UserTransactionsApi.removeTransaction(props.match.params.id, id)
      .then(res => {
        setTransactions(transactions.filter(t => t.id !== id))
      })
  }

  const handleChange = e => {
    setTransaction({...transaction, [e.target.name]: e.target.value})
  }
  const handleSave = e => {
    e.preventDefault()
    setSaving(true)
    UserTransactionsApi.createTransaction(props.match.params.id, transaction)
      .then(res => {
        if(res.status === 201) {
          setSaving(false)
          setBalance(res.data.new_balance)
          setTransactions([res.data, ...transactions])
          setTransaction({...newTransaction})
        }
      })
      .catch(e => {
        setSaving(false)
      })
  }
  return (
    <Grid container>
      <Grid container item xs={12} alignItems="center">
        <Grid item xs={12} md={8}>
          <Typography variant="h3">User Balance - {balance} kr</Typography>
        </Grid>
        <Grid item xs={12} md={4} textAlign="right">
          <a href={`/admin/users/edit/${props.match.params.id}`} style={{fontSize: '1.5em', color: '#000', textDecoration: 'underline'}}><span></span>Back to user</a>
        </Grid>
      </Grid>
      <Divider width="100%" sx={{marginBottom: '2%'}} />
      <CreditAccount transaction={transaction} onChange={handleChange} onSave={handleSave} saving={saving}/>
      <Grid container item xs={12}>
        <TableContainer >
          <Table sx={{ minWidth: 650, marginTop: '2%' }} aria-label="simple table" >
            <TableHead>
              <TableRow style={{backgroundColor: '#F9F9F9'}}>
                <TableCell align="left"><h5>Type</h5></TableCell>
                <TableCell align="left"><h5>Amount</h5></TableCell>
                <TableCell align="left"><h5>Order</h5></TableCell>
                <TableCell align="left"><h5>Created by</h5></TableCell>
                <TableCell align="left"><h5>Created at</h5></TableCell>
                <TableCell align="left"><h5>Note</h5></TableCell>
                <TableCell align="left"><h5>Current Balance</h5></TableCell>
                <TableCell align="left"><h5>New Balance</h5></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions.map(t => (
                <TableRow
                  key={t.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                  <TableCell align="left"><StatusChip status={t.type} /></TableCell>
                  <TableCell align="left">{t.value}</TableCell>
                  <TableCell align="left">{t.order && t.order.id}</TableCell>
                  <TableCell align="left">{t.creator && t.creator.name}</TableCell>
                  <TableCell align="left">{t.createdAt}</TableCell>
                  <TableCell align="left">{t.note}</TableCell>
                  <TableCell align="left">{t.current_balance}</TableCell>
                  <TableCell align="left">{t.new_balance}</TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell align="right" colSpan={8} sx={{background: '#f5f5f5', paddingRight: '6%'}}>
                  {`Current Balance: ${balance} kr`}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}


AccountBalance.propTypes = {
  match: PropTypes.object.isRequired,
}

export default AccountBalance
