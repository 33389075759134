import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Typography, CardHeader, TextField, FormGroup, Button, Paper, Card, CardContent, Box } from '@mui/material';
import grey from '@mui/material/colors/grey'
import SearchIcon from '@mui/icons-material/Search';
import ImageThumbnail from '../Shared/ImageThumbnail'

import '../Product/Product.css'
import CollectionProductImageList from './collectionProductsList';
import { listingProductImageUrl } from '../../utils/sharedFunctions'

export default function ShowCollection({collection, fetchProducts, searchedProducts, collectionProductsIds, addProductToCollection, searchRequestStatus}) {
  const [term, setTerm] = useState('')

  const styles = {
    root: {
      cursor: 'defualt',
    },
    sideCard: {
      maxHeight: '400px',
      overflowY: 'auto',
      marginTop: '10px'
    },
    media: {
      height: 200,
    },
    productListContainer:{
      maxHeight: '400px',
      overflowY: 'auto'
    },
    imageContainer: {
      height: '200px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: grey[100]
    },
    image: {
      maxWidth: '100%',
      maxHeight: '100%',
      width: 'auto',
      height: 'auto'
    }
  };

  const onMediaFallback = (event) => {
    event.target.style.backgroundColor = grey[100]
    event.target.style.textIndent = '-100000px'
  }

  return (
    <Grid container spacing={2}>
      <Grid item md={8} xs={12}>
        <Paper sx={styles.root}>
          <CardContent>
            <div style={styles.imageContainer}><img onError={(event)=>onMediaFallback(event)} src={collection.image} style={styles.image}></img></div>
            <Typography gutterBottom variant="h5" component="h2" mt={1}>
              {collection.name}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p"> 
              {collection.description}
            </Typography>
            <Typography gutterBottom variant="h5" color="textSecondary" component="h2" style={{marginTop: '10px'}}>Products</Typography>
              { collection?.products?.length > 0 ?
                <div style={styles.productListContainer}>
                  <CollectionProductImageList products={collection?.products} addProductToCollection={addProductToCollection}/>
                </div>
                :
                <Typography variant="body2" color="textSecondary" component="p">No Products have been added to this collection</Typography>
              } 
            </CardContent>
          </Paper>
      </Grid>
      <Grid item md={4} xs={12}>
        <Card xs={12} md={12}>
          <CardHeader title={<h4>Collections</h4>}/>
          <CardContent xs={12} md={12}>
              <Grid container spacing={1} pr={1}>
                <Grid item xs={9} md={8} lg={9} xl={10}>
                  <FormGroup>
                    <TextField
                      id="Product"
                      type="text"
                      placeholder="Search products"
                      label="Search"
                      name="term"
                      variant="outlined"
                      size="small"
                      value={term}
                      onChange={e => setTerm(e.currentTarget.value)} />  
                  </FormGroup>
                </Grid>
                <Grid item xs={3} md={4} lg={3} xl={2}>
                  <Button variant="outlined" size='large' color="primary" onClick={(event)=>fetchProducts(event,term)}>
                    <SearchIcon/>
                  </Button>
                </Grid>
              </Grid>
                { searchedProducts?.length > 0 ?
                  <Box xs={12} md={12} display="flex" flexDirection="column" sx={styles.sideCard}>
                    { searchedProducts.map( product => (
                      <Grid container mt={1} xs={12} md={12} item direction="row" key={product?.id} spacing={1} alignItems="center" justifyContent="space-between">
                        <Grid item container xs={9} md={10} lg={9} alignItems="center">
                          <Grid item xs={5} lg={3}>
                            <ImageThumbnail image={listingProductImageUrl(product)} height='50px' width='50px' />
                          </Grid>
                          <Grid item xs={7} lg={9} sx={{'wordBreak': 'break-all'}}>
                            <Link to={`/admin/products/edit/${product.id}`}>{product?.name}</Link>
                          </Grid>
                        </Grid>
                        <Grid container item xs={3} md={2} lg={3} justifyContent={"flex-end"}>
                          {
                          collectionProductsIds?.includes(product.id)
                          ?
                          <Button variant="outlined" color="primary" onClick={()=>addProductToCollection(product.id, 'remove')}>Remove</Button>
                          :
                          <Button variant="outlined" color="primary" onClick={()=>addProductToCollection(product.id, 'add')}>Add</Button> 
                          }  
                        </Grid>
                      </Grid>
                    ))}
                  </Box>
                  :
                  searchRequestStatus === 'Not Found' && <Grid item sx={{margin: '5% 0 0 1%'}}>No products found!</Grid>
                }
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}
