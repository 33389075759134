import {HttpClient} from './httpClient' 
import {API_URL} from './RestAPI'
import apiHeaders from './apiHeaders'

//Setting the store API URI
const STORE_API = `${API_URL}/store`
const CURRENCY_API = `${API_URL}/currencies`

const getCurrencies = () => {
  return HttpClient.get(
    `${CURRENCY_API}/`,
    apiHeaders()
  )
}

// Get
const getStore = () => {
  return HttpClient.get(
    `${STORE_API}/retrieve`,
    apiHeaders()
  )
}

// Update
const updateStore = (store) => {
  return HttpClient.put(
    `${STORE_API}/update`,
    store,
    apiHeaders()
  )
}

const validateStoreUrl = (payload) => {
  return HttpClient.post(
    `${STORE_API}/store-url`,
    payload,
    apiHeaders()
  )
}

//Encapsulating in a JSON object
const StoreApi = {getStore, updateStore, validateStoreUrl, getCurrencies}

export {StoreApi}
