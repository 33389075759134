import {HttpClient} from './httpClient' 
import {API_URL} from './RestAPI'
import apiHeaders from './apiHeaders'

//Setting the menus URI
const MENU_API = `${API_URL}/menus`

// Read
const getMenus = () => {
  return HttpClient.get(
    `${MENU_API}`,
    apiHeaders()
  )
}

// Read
const searchMenus = (q, page=0) => {
  return HttpClient.get(
    `${MENU_API}?q=${q}&page=${page}`,
    apiHeaders()
  )
}

// Get
const getMenu = id => {
  return HttpClient.get(
    `${MENU_API}/${id}`,
    apiHeaders()
  )
}

// Update
const updateMenu = (id, menu) => {
  return HttpClient.put(
    `${MENU_API}/${id}/update`,
    menu,
    apiHeaders()
  )
}

//Encapsulating in a JSON object
const MenuApi = {getMenus, searchMenus, getMenu, updateMenu}
export {MenuApi}
