import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import ShowEmailTemplate from "../components/EmailTemplate/showEmailTemplate";
import { EmailTemplateApi } from "../api/emailTemplatesApi"
import ListingPage from "../components/Shared/ListingPage";
import { Button } from "@mui/material";


const ShowEmailTemplateContainer = () => {
    const [emailTemplate, setEmailTemplate ] = useState({})
    const params = useParams();
    const history = useHistory();
    
    const getEmailTemplateFromApi = async (emailTemplateId) => {
        const response = await EmailTemplateApi.getEmailTemplate(emailTemplateId)
        if (response.status === 200){
            setEmailTemplate(response.data)
        } else {
          console.log(response.error)
        }
    }

    useEffect(()=>{
      const emailTemplateId = params.hasOwnProperty('id') ? + params.id : 0
      if (emailTemplateId){
        getEmailTemplateFromApi(emailTemplateId)
      }  
    }, [])

    const content = ()=>(
      <ShowEmailTemplate
        emailTemplate={emailTemplate}
      />
    )

    const actions = ()=>{
     return(
      <Button
        color="primary"
        variant="contained"
        className="pull-right"
        onClick={()=>history.push(`/admin/email-templates/edit/${emailTemplate?.id}`)}>
          Edit
      </Button>
     )

    }
    
    return (
      <ListingPage title={emailTemplate?.subject} actions={actions()} table={content()}/>
    )
}

export default ShowEmailTemplateContainer
