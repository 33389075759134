import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';

import * as userGroupActions from '../../actions/userGroupActions';
import ShowUserGroup from '../../components/UserGroup/showUserGroup';
import { UserGroupApi } from "../../api/userGroupApi";
import { UserApi } from "../../api/userApi";
import { PermissionApi } from "../../api/permissionApi";
import { CollectionApi } from "../../api/collectionApi";
import { setSuccessNotification, setErrorNotification } from "../../actions/notificationAction";


const ShowUserGroupContainer = (props) => {
  const { userGroupId, actions } = props;
  const [userGroup, setUserGroup] = useState({ name: '' }); // initial user group state
  const [permissions, setPermissions] = useState({});
  const [collections, setCollections] = useState([]);
  const [users, setUsers] = useState([]); // all existing users
  const [appliedPermissions, setAppliedPermissions] = useState([]);
  const [selectedUser, setSelectedUser] = useState(''); // Selected User from input form
  const [selectedCollection, setSelectedCollection] = useState(''); // Selected Colllection from input form

  // Define Permissions Visual Representation Hash
  const PERMISSION_LABELS = { list: 'View Listing', index: 'View Listing', create: 'Create', update: 'Update', retrieve: 'Show', destroy: 'Delete', show: 'Show', sendInvite: 'Send Invite', invited: 'Invited', deleteInvite: 'Delete Invite', addRemovePermission: 'Add/Remove Permission' }

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const fetchUserGroup = (userGroupId) => {
      UserGroupApi.getUserGroup(userGroupId).then(res => {
        setUserGroup(res.data.userGroup);
        if (res.data?.userGroup?.permissions){
          // keep applied permissions for reference
          setAppliedPermissions(res.data.userGroup.permissions.map((permission) => permission.id));
        }
      })
    }
    if(userGroupId) fetchUserGroup(userGroupId);

    const fetchUsers = () => {
      // Fetch only Managers for ACL. Dependent true for permission
      UserApi.getManagerAndStaffUsers({ "dependent-source": global.constants.dependentSource.userGroup }).then(userResponse => {
        setUsers(userResponse.data.data);
      });
    }
    fetchUsers();

    const fetchPermissions = () => {
      PermissionApi.getPermissions({ "dependent-source": global.constants.dependentSource.userGroup }).then(permissionResponse => {
        setPermissions(permissionResponse.data);
      });
    }
    fetchPermissions();

    const fetchCollections = () => {
      CollectionApi.getCollections({ "dependent-source": global.constants.dependentSource.userGroup }).then(collectionResponse => {
        setCollections(collectionResponse.data.data);
      });
    }
    fetchCollections();

  }, []);

  const handleOnDeleteUserGroupClicked = (id) => {
    actions.deleteUserGroup(id);
    history.push('/admin/userGroups');
  }

  const handleOnCheckBoxClicked = (event) => {
    let groupPermissionInfo = { "permission_id": parseInt(event.target.value), "id": userGroup.id };
    let checkboxStatus = event.target?.checked;
    if (checkboxStatus !== undefined && !checkboxStatus) groupPermissionInfo['remove'] = true;

    UserGroupApi.addRemovePermission(groupPermissionInfo).then((res) => {
      let updatedAppliedPermissions = [...appliedPermissions];
      if (checkboxStatus){
        updatedAppliedPermissions.push(res.data.permission_id);
      } else {
        updatedAppliedPermissions = updatedAppliedPermissions.filter((permissionId) => permissionId !== res.data.permission_id);
      }
      setAppliedPermissions(updatedAppliedPermissions);
      dispatch(setSuccessNotification({ message: res.data.message }));
    }).catch((error) => {
      // Logging of error should be handled
      dispatch(setErrorNotification({ message: error.response?.data?.error ?? error.message }));
    });
  }

  const addRemoveUserToUserGroup = (userId, userGroupId) => {
    UserApi.updateUser(userId, { user_group_id: userGroupId }).then(apiResponse => {
      let updatedUserGroup = { ...userGroup };
      let updatedUsers = [...users];
      if (userGroupId){
        // Remove users from existing users list
        updatedUsers = updatedUsers.filter((user) => user.id !== apiResponse.data.id);
        updatedUserGroup.users.push(apiResponse.data);
      } else {
        // Remove users from user group
        updatedUserGroup.users = updatedUserGroup.users.filter((user) => user.id !== apiResponse.data.id);
        // Extract ids to of existing users
        let existingUsersIds = updatedUsers.map((user) => user.id);
        if (!existingUsersIds.includes(apiResponse.data.id)) updatedUsers.push(apiResponse.data);
      }
      setUsers(updatedUsers);
      setUserGroup(updatedUserGroup);
      dispatch(setSuccessNotification({ message: (apiResponse.status === 200 && userGroupId ? global.constants.response_messages.user_added_to_user_group : global.constants.response_messages.user_removed_to_user_group) }));
    }).catch(error => {
      // Logging should be handled
      dispatch(setErrorNotification({ message: error.response?.data?.error ?? error.message }));
    });
  }

  const updateCollectionAccess = (collectionId, userGroupId, action = null) => {
    let apiParams = { id: userGroupId, collection_id: collectionId };
    if (action === global.constants.actions.remove) apiParams['remove'] = true;
    UserGroupApi.allowRestrictCollection(apiParams).then(apiResponse => {
      let updatedUserGroup = { ...userGroup };
      let updatedCollections = [...collections];
      if (action === global.constants.actions.remove) {
        // Remove collection from collection group
        let filteredCollections = [];
        let existingCollectionsIds = updatedCollections.map((collection) => collection.id);

        updatedUserGroup.collections.forEach((collection) => { 
          if (collection.id !== apiResponse.data.collection_id) {
            filteredCollections.push(collection);
          } else if (!existingCollectionsIds.includes(apiResponse.data.collection_id)) {
            // Extract ids of existing collection
            updatedCollections.push(collection);
          }
        });
        updatedUserGroup.collections = filteredCollections;
      } else {
        // Remove collection from existing collection list
        let filteredCollections = [];
        updatedCollections.forEach((collection) => { 
          if (collection.id !== apiResponse.data.collection_id){
            filteredCollections.push(collection);
          } else {
            updatedUserGroup.collections.push(collection);
          }
        });
        updatedCollections = filteredCollections;
      }
      setCollections(updatedCollections);
      setUserGroup(updatedUserGroup);
      dispatch(setSuccessNotification({ message: apiResponse.data.message }));
    }).catch(error => {
      dispatch(setErrorNotification({ message: error.response?.data?.error ?? error.message }));
      // Logging should be handled
    });
  }

  return (
    <>
      <ShowUserGroup 
        userGroup={userGroup} 
        existingUsers={users} 
        permissions={permissions} 
        appliedPermissions={appliedPermissions} 
        selectedUser={selectedUser} 
        setSelectedUser={setSelectedUser} 
        onAddRemoveUserToUserGroup={addRemoveUserToUserGroup} 
        onDeleteUserGroupClicked={handleOnDeleteUserGroupClicked} 
        onPermissionChange={handleOnCheckBoxClicked} 
        permissionLabels={PERMISSION_LABELS} 
        existingCollections={collections} 
        onUpdateCollectionAccess={updateCollectionAccess} 
        selectedCollection={selectedCollection} 
        setSelectedCollection={setSelectedCollection} 
      />
    </>
  )
}

ShowUserGroupContainer.propTypes = {
  userGroupId: PropTypes.number.isRequired
}

const mapStateToProps = (state, ownProps) => {
  const userGroupId = Number(ownProps.match.params.id)

  return {
    userGroupId: userGroupId
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(userGroupActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShowUserGroupContainer)
