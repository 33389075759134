import * as teamActions from '../actions/teamActions'
import initialState from './initialState'

const teams = (state = initialState.teams, action) => {
  switch (action.type) {
    case teamActions.GET_TEAMS:
      return {
        ...state,
        loading: true
      }
    case teamActions.GET_TEAMS_SUCCESS:
      return {
        ...action.payload,
        loading: false
      }
    case teamActions.GET_TEAM:
      return {
        ...state,
        loading: true
      }
    case teamActions.GET_TEAM_SUCCESS:
      let indexOfTeamToGet = state.data.findIndex(({ id }) => id === action.payload.id)
      let newStateAfterGet = {...state}
      if( indexOfTeamToGet === -1) {
        newStateAfterGet.data.push(action.payload)
      } else {
        newStateAfterGet.data[indexOfTeamToGet] = action.payload
      }
      return newStateAfterGet
    case teamActions.CREATE_TEAM:
      return {
        ...state,
        updating: true
      }
    case teamActions.CREATE_TEAM_SUCCESS:
      let newTeamsState = {...state}
      newTeamsState.data.push(action.payload)
      newTeamsState.created = true
      return newTeamsState
    case teamActions.UPDATE_TEAM:
      return {
        ...state,
        updating: true
      }
    case teamActions.UPDATE_TEAM_SUCCESS:
      let indexOfCatToUpdate = state.data.findIndex(({ id }) => id === action.payload.id)
      let newState = {...state}
      newState.data[indexOfCatToUpdate] = action.payload
      return newState
    case teamActions.DELETE_TEAM:
      return {
        ...state
      }
    case teamActions.DELETE_TEAM_SUCCESS:
      let indexOfCatToDelete = state.data.findIndex(({ id }) => id === action.payload.id)
      newState = {...state}
      newState.data.splice(indexOfCatToDelete, 1)
      return newState
    default:
      return state
  }
}

export default teams
