import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Grid, Button, Checkbox, Divider, TextField, MenuItem, FormControlLabel, FormHelperText } from '@mui/material';
import Alert from '@mui/material/Alert';
import Addresses from './Addresses';
import useTranslation from '../../hooks/useTranslation';

const styles = {
  headingContainer: {
    padding: '1%',
    backgroundColor: '#f5f5f5'
  }
};

const EditUser = props => {
  const { t } = useTranslation();
  const alertMsg = props.user.saving ? (
    <Alert severity="info">{t('labels.updateUser')}</Alert>
  ) : (
    <div></div>
  );

  return (
    <Grid container>
      <Grid item xs={12}>{alertMsg}</Grid>
      <Grid container item xs={12} alignItems="center">
        <Grid item xs={12} md={8}>
          { props.userProfile && <h1>{t('labels.editProfile')}</h1> }
          { !props.userProfile && <h1>{ props.user.hasOwnProperty('id') ? t('labels.editUser') : t('labels.newUser') }</h1> }
        </Grid>
        <Grid item xs={12} md={4} textAlign="right">
          { props.user.hasOwnProperty('id') && <a href={`/admin/users/${props.user.id}/balance`} style={{fontSize: '1.5em', color: '#000', textDecoration: 'underline'}}><span></span>{t('labels.balance')} - {props.user.balance} kr</a> }
        </Grid>
      </Grid>
      <Divider width="100%" sx={{marginBottom: '2%'}} />
      <Paper sx={{padding: '2%'}}>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              label={t('labels.name')}
              name="name"
              value={`${props.user.name}`}
              placeholder={t('labels.name')}
              onChange={props.onChange}
              fullWidth />
          </Grid>
          { !props.userProfile &&
          <Grid item xs={12} md={6}>
            <TextField
              select
              name="role"
              label={t('labels.role')}
              value={props.user.role}
              placeholder="select"
              onChange={props.onChange}
              fullWidth >
              <MenuItem value="staff">{t('labels.staff')}</MenuItem>
              <MenuItem value="manager">{t('labels.manager')}</MenuItem>
              <MenuItem value="superadmin">{t('labels.superadmin')}</MenuItem>
            </TextField>
          </Grid>
          }
          <Grid item xs={12} md={6}>
            <TextField
              label={t('labels.email')}
              name="email"
              value={props.user.email}
              placeholder={t('labels.email')}
              onChange={props.onChange}
              fullWidth />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label={t('labels.status')}
              name="status"
              value={props.user.status || ''}
              placeholder="select"
              onChange={props.onChange}
              select
              fullWidth >
              <MenuItem value={t('labels.active').toLowerCase()}>{t('labels.active')}</MenuItem>
              <MenuItem value={t('labels.unapproved').toLowerCase()}>{t('labels.unapproved')}</MenuItem>
              <MenuItem value={t('labels.removed').toLowerCase()}>{t('labels.removed')}</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label={t('labels.phone')}
              name="phone"
              value={props.user.phone || ''}
              placeholder=""
              onChange={props.onChange}
              fullWidth />
          </Grid>
          {/* <Grid item xs={12} md={6}>
            <TextField
              label="Size"
              name="size"
              value={props.user.size}
              placeholder="select"
              onChange={props.onChange}
              select
              fullWidth >
              <MenuItem value=""></MenuItem>
              <MenuItem value="XS">XS</MenuItem>
              <MenuItem value="S">S</MenuItem>
              <MenuItem value="M">M</MenuItem>
              <MenuItem value="L">L</MenuItem>
              <MenuItem value="XL">XL</MenuItem>
              <MenuItem value="XXL">XXL</MenuItem>
              <MenuItem value="XXXL">XXXL</MenuItem>
            </TextField>
          </Grid> */}
          <Grid item xs={12} md={6}>
            <TextField
              type="password"
              label={t('labels.password')}
              name="password"
              autoComplete="off"
              onFocus={e => { if(e.target.autocomplete){ console.log('onFocus', e.target.autocomplete); e.target.setAttribute('autocomplete', 'off')} }}
              value={props.user.password}
              onChange={props.onChange}
              fullWidth />
          </Grid>
          {/* {!props.userProfile &&
            <Grid item xs={12} md={6}>
              <TextField
                select
                fullWidth
                name="team_id"
                label="Team"
                value={props.user.team_id || ''}
                placeholder="select"
                onChange={props.onChange}>
                <MenuItem value=""></MenuItem>
                { props.teams.map(team => <MenuItem key={team.id} value={team.id}>{team.name}</MenuItem>) }
              </TextField>
            </Grid>
          } */}
          <Grid item xs={12} md={6}>
            <TextField
              select
              name="language"
              value={props.user.language}
              label={t('labels.language')}
              placeholder="select"
              helperText={t('labels.languageHelper')}
              onChange={props.onChange}>
              <MenuItem value="en">{t('labels.english')}</MenuItem>
              <MenuItem value="sv">{t('labels.swedish')}</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControlLabel
              control={<Checkbox checked={props.user.onboard === true} onChange={props.onChange} name="onboard" />}
              label={t('labels.onboardUser')} />
            <FormHelperText>{t('labels.onboardUserHelper')}</FormHelperText>
          </Grid>
          <Grid item xs={12} md={12}>
            <FormControlLabel
              control={<Checkbox checked={props.user.address_locked === true} onChange={props.onChange} name="address_locked" />}
              label={t('labels.restrictAddressChange')} />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={props.onSave} disabled={props.saving}>
              {props.user.hasOwnProperty('id') && props.user.id > 0 ? t('labels.save') : t('labels.create')}
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <Grid container item xs={12}>
        <Addresses user={props.user} />
      </Grid>
    </Grid>
  )
}

EditUser.propTypes = {
  user: PropTypes.object.isRequired,
  teams: PropTypes.array.isRequired,
  userProfile: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
}

export default EditUser