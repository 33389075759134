import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as orderActions from '../actions/orderActions';
import * as notificationActions from '../actions/notificationAction'
import { OrderFulfillmentCenterApi } from '../api/orderFulfillmentCenterApi';
import { OrderApi } from '../api/orderApi';
import { setSuccessNotification, setErrorNotification } from '../actions/notificationAction';
import { ShowFulfillmentOrder } from '../shared-components';
import useTranslation from '../hooks/useTranslation'

const ShowOrderFulfillmentCenterContainer = (props) => {
  const [order, setOrder] = useState(props.order)
  const storeCurrency = { name: 'Swedish Krone', symbol: 'kr', isoCode: 'sek' }
  const dispatch = useDispatch();
  const { t } = useTranslation()


  const fetchOrder = async (orderId) => {
    const response = await OrderFulfillmentCenterApi.getOrder(orderId)
    if(response){
      let _order = response.data
      setOrder({..._order})
    }
  }

  useEffect(() => {
    if (props.order.id > 0) {
      fetchOrder(props.order.id)
    }
  }, [])

  const updateOrderTracking = async (orderId, bodyData) => {
    return await OrderApi.updateOrderTracking(orderId, bodyData);
  };
  
  const deleteOrderTracking = async (trackingId) => {
    return await OrderApi.deleteOrderTracking(trackingId);
  };

  const responseMessages = {
    order_tracking_email_success: t('response_messages.fulfillment_orders.order_tracking_email_success'),
    order_tracking_success: t('response_messages.fulfillment_orders.order_tracking_success'),
    url_validation: t('response_messages.fulfillment_orders.url_validation'),
    error_message: t('response_messages.fulfillment_orders.error_message'),
    successfully_delete: t('response_messages.fulfillment_orders.successfully_delete'),
    error_deletetion: t('response_messages.fulfillment_orders.error_deletetion')
  };

  const handleSuccessNotification = (message) => {
    dispatch(setSuccessNotification({ message }));
  };
  
  const handleErrorNotification = (message) => {
    dispatch(setErrorNotification({ message }));
  };

  return (
    <ShowFulfillmentOrder
      order={order}
      storeCurrency={storeCurrency}
      updateOrderTracking={updateOrderTracking}
      deleteOrderTracking={deleteOrderTracking}
      onSuccessNotification={handleSuccessNotification}
      onErrorNotification={handleErrorNotification}
      responseMessages={responseMessages}
      />
  )
}


const emptyOrder = () => {
  return {
    shipping_name: '',
    shipping_city: '',
    shipping_country: '',
    payment_status: '',
    status: '',
    order_items: [],
    order_tracking: {},
    orderFulfillment: {}
  }
}

const mapStateToProps = (state, ownProps) => {
  let order = emptyOrder()
  const orderId = Number(ownProps.match.params.id)
  
  if (orderId > 0) {
    order.id = orderId
    if (state.orders.data.length > 0) {
      order = { ...order, ...state.orders.data.find(order => order.id === orderId) }
    }
  }
  return {
    order: order
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(orderActions, dispatch),
    notifyActions: bindActionCreators(notificationActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShowOrderFulfillmentCenterContainer)