import {HttpClient} from './httpClient' 
import {API_URL} from './RestAPI'
import apiHeaders from './apiHeaders'

//Setting the users URI
const USER_API = `${API_URL}/users`

// Create
const createTransaction = (userId, transaction) => {
  return HttpClient.post(
    `${USER_API}/${userId}/transactions/create`,
    transaction,
    apiHeaders()
  )
}

// Read
const getTransactions = (userId, page = 1) => {
  return HttpClient.get(
    `${USER_API}/${userId}/transactions?page=${page}`,
    apiHeaders()
  )
}

// Get
const getTransaction = (userId, id) => {
  return HttpClient.get(
    `${USER_API}/${userId}/transactions/${id}`,
    apiHeaders()
  )
}

// Update
const updateTransaction = (userId, id, transaction) => {
  return HttpClient.put(
    `${USER_API}/${userId}/transactions/${id}/update`,
    transaction,
    apiHeaders()
  )
}

// Delete
const removeTransaction = (userId, id) => {
  return HttpClient.delete(
    `${USER_API}/${userId}/transactions/${id}`,
    apiHeaders()
  )
}

//Encapsulating in a JSON object
const UserTransactionsApi = {getTransactions, createTransaction, getTransaction, updateTransaction, removeTransaction}

export {UserTransactionsApi}
