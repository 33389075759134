import React, {Component, useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import { Grid, FormGroup, TextField, Button, Paper, Typography, InputLabel} from '@mui/material';
import SplashImage from './splashImage'
import LeftPanelContainer from './leftPanelContainer';
import RightImagePanel from '../Shared/UploadImageWidget';
import useTranslation from '../../hooks/useTranslation'
import TextEditor from '../Helpers/TextEditor'

const styles = {
  paper: {
    padding: '5%',
  },
  leftPanel: {
    maxHeight: '100vh',
    overflow: 'auto'
  }
}


const EditFrontpage = ( props ) => {
  const { t } = useTranslation()
  const [selectedPreview,setSelectedPreview] = useState({
    selectedObject: '',
    type: ''
  })

  const removeSplashImage = (event, splashImageId) => {
    const imageToDelete = props.frontpage.splash_images.filter(splash_image => splash_image.id === splashImageId)
    imageToDelete[0]._delete = true
    props.updateSplashImage(imageToDelete[0])
  }

  const toggleVisisbility = (splashImage, isVisible) => {
    splashImage.visible = isVisible
    props.updateSplashImage(splashImage)
  }
  
  const handleListItemClick = (selectedObject, itemType) => {
    setSelectedPreview({ selectedObject: selectedObject, type: itemType })
  }

  useEffect(()=>{
    const newSplashImages = props.frontpage?.splash_images.filter((splash_image)=> Object.keys(splash_image).includes('_id') )
    if (newSplashImages.length > 0){
        const hello = newSplashImages.reduce((a, b) => a._id < b._id ? b : a)
        setSelectedPreview({ selectedObject: hello, type: 'splashImage' })
    }
  },[props.frontpage.splash_images.length])

  useEffect(()=>{
    setSelectedPreview({ selectedObject: '', type: 'staticContent' })
  },[])

    return (
      <div>
        <Grid container style={{marginTop: '20px'}} direction="row" alignItems="center" justifyContent='space-between'>
          <Typography variant="h3">{t('labels.homepage_settings')}</Typography>
          <div>
              <Button type="submit" variant="contained"  color="primary" onClick={props.onSave} disabled={props.frontpage.updating}>Save</Button>
          </div>
        </Grid>
        <hr/>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} sx={styles.leftPanel}>
            <LeftPanelContainer
              newSplashImage={props.newSplashImage}
              toggleSplashImageVisisbility={toggleVisisbility}
              removeSplashImage={removeSplashImage}
              handleListItemClick={handleListItemClick}
              updateStaticContentVisibility={props.updateStaticContentVisibility}
              selectedPreview={selectedPreview}
              frontpage={props.frontpage} 
              sortSplashImages={props.sortSplashImages}/>
          </Grid>
          <Grid item xs={12} md={8}>
            {
              selectedPreview?.type === "splashImage" ?
                <Paper sx={styles.paper} elevation={3}>
                  <SplashImage 
                   key={selectedPreview?.selectedObject?.id ? selectedPreview?.selectedObject?.id : selectedPreview.selectedObject._id}
                   splash_image={selectedPreview.selectedObject}
                   onUpdate={props.updateSplashImage}
                   t={t}
                  />
               </Paper>
               :
               <Grid container spacing={1}>
                 <Grid item md={8} xs={12}>
                   <Paper sx={styles.paper} elevation={3}>
                    <FormGroup>
                      <TextField
                        id="outlined-basic"
                        label="Title"
                        variant="outlined"
                        name="header"
                        value={props.frontpage.header}
                        onChange={props.onChange}/>
                    </FormGroup>
                    <FormGroup style={{marginTop: '20px'}}>
                      <InputLabel>{t('labels.description')}</InputLabel>
                      <TextEditor name="text" rows={10} value={props.frontpage.text} onChange={props.onChange} />
                    </FormGroup>
                  </Paper>
                 </Grid>    
                 <Grid item xs={12} md={4}>
                   <Paper sx={styles.paper} elevation={3}>
                     <RightImagePanel object={props.frontpage} onChange={props.onChange} deleteImage={props.deleteImage}/>
                   </Paper>
                 </Grid> 
               </Grid>
            }
          </Grid>
        </Grid>
      </div>
    )
  }

EditFrontpage.propTypes = {
  frontpage: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  deleteImage: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  newSplashImage: PropTypes.func.isRequired
}

export default EditFrontpage
