import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Card, Typography, MenuItem, TextField } from '@mui/material'
import LineChart from '../Helpers/LineChart'
import useTranslation from '../../hooks/useTranslation'

const OrdersChart = ({ ordersData, ordersChartOption, classes, handleOrderChartChange }) => {
  const { t } = useTranslation()
  let totalOrders = 0
  ordersData.forEach((orderData) => (totalOrders += parseInt(orderData.y)))
  return (
    <Grid item xs={12} md={12} lg={8} xl={9}>
      <Card elevation={3} sx={classes.ordersChartCard}>
        <Grid container>
          <Grid container sx={classes.ordersChartContainer} item={true} xs={12} md={12} lg={12} xl={12} alignItems="center">
            <Grid item xs={9}>
              <Typography variant='h5' color="textPrimary">{t('labels.total_orders')}</Typography>
            </Grid>
            <Grid item xs={3}>
              <TextField
                value={ordersChartOption}
                variant='outlined'
                label={t('labels.duration')}
                sx={{float: 'right'}}
                onChange={handleOrderChartChange}
                inputProps={{
                  sx: classes.ordersChartSelect
                }}
                select >
                <MenuItem value='7days'>{t('labels.last_7_days')}</MenuItem>
                <MenuItem value='last-month'>{t('labels.last_month')}</MenuItem>
                <MenuItem value='last-year'>{t('labels.last_year')}</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={9}>
              <Typography variant='h5' color="textSecondary">{totalOrders}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} lg={12} xl={12}>
            <LineChart data={ordersData} seriesName={t('labels.orders')} height="100%" title={t('labels.orders_over_time')} />
          </Grid>
        </Grid>
      </Card>
    </Grid>
  )
}

OrdersChart.propTypes = {
  ordersChartOption: PropTypes.string.isRequired,
  handleOrderChartChange: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  ordersData: PropTypes.array.isRequired
}
  
export default OrdersChart