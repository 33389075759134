import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, Paper, Button } from '@mui/material'
import { Typography, TableBody, Table, TableCell, TableContainer, TableHead, TableRow, IconButton } from '@mui/material'
import { CSVLink } from 'react-csv'
import ListingPage from '../Shared/ListingPage'
import CustomTabPanel from '../Shared/CustomTabPanel'
import CustomTablePagination from '../Shared/CustomTablePagination'
import { getSerialNumber } from '../../utils/sharedFunctions'
import DeleteIcon from '@mui/icons-material/Delete'
import User from './user'
import UploadCsvModal from '../Shared/CustomModal/uploadCsvModal';
import { UserApi } from '../../api/userApi'
import useTranslation from '../../hooks/useTranslation'

const styles = {
  actionBtn: {
    textTransform: 'none',
    marginTop: '2%',
    float: 'right'
  },
  tableHeading: {
    fontWeight: 'bold',
    fontSize: 14
  },
  tableBody: {
    fontSize: 14
  },
  container: {
    padding: '2%'
  }
}

const UsersListing = (props) => {
  const { fetchUsersForExport, usersForExport, csvLink } = props
  const { currentTab, tabCounts, page, perPage, usersByTab } = props
  const { handleInviteUser, handleCreateUser, handleTabChange, handleChangePage, handleChangeRowsPerPage, deleteInvited, approveUser, deleteUser } = props
  const [modalState, setModalState] = useState(false);
  const { t } = useTranslation()

  const tabLabels = {
    active: t('labels.active'),
    removed: t('labels.removed'),
    unapproved: t('labels.unapproved'),
    invited: t('labels.invited')
  }

  const descriptions = { 
    removed: t('labels.remove_helper_text'),
    unapproved: t('labels.approved_helper_text'),
    invited: t('labels.invited_helper_text')
  }

  const actionButtons = (
    <Grid container justifyContent="flex-end" spacing={1}>
      <Grid item>
        <Button variant="outlined" sx={styles.actionBtn} onClick={fetchUsersForExport}>{t('labels.exportToCsv')}</Button>
        <CSVLink
          data={usersForExport}
          filename={`users-${(new Date()).toLocaleDateString().replace(', ', '-')}.csv`}
          className='hidden'
          ref={csvLink}
          target='_blank' />
      </Grid>
      <Grid item>
        <UploadCsvModal modalState={modalState} title={t('labels.importUsers')} setModalState={setModalState} apiFunction={UserApi.importUsers} />
        <Button variant="outlined" sx={styles.actionBtn} color="primary" onClick={setModalState}> {t('labels.importUsers')}</Button>
      </Grid>
      <Grid item>
        <Button variant="contained" sx={styles.actionBtn} color="primary" onClick={handleInviteUser}>{t('labels.invite')}</Button>
      </Grid>
      <Grid item>
        <Button variant="contained" sx={styles.actionBtn} onClick={handleCreateUser}>{t('labels.createUser')}</Button>
      </Grid>
    </Grid>
  )


  const getDescription = (tab) => {
    if(descriptions[tab]) {
      return (
        <TableRow>
          <TableCell sx={{padding: '5px 1%'}} colSpan={9}>
            <Typography variant="subtitle1" gutterBottom component="div" sx={{color: 'gray'}}>
              {descriptions[tab]}
            </Typography>
          </TableCell>
        </TableRow>
      )
    }
  }

  const tableHeadings = (tab) => {
    return (tab === 'invited' ? [t('labels.user_table_headers.number'), t('labels.user_table_headers.email'), ''] 
      : [
        t('labels.user_table_headers.number'), 
        t('labels.user_table_headers.name'), 
        t('labels.user_table_headers.email'), 
        t('labels.user_table_headers.role'), 
        t('labels.user_table_headers.onboard'), 
        t('labels.user_table_headers.user_group'), 
        '', 
        ''
      ])
  }

  const tableBodyRows = (tab) => {
    if (!usersByTab[tab]) return

    if(tab === 'invited') {
      return usersByTab[tab].map((user, index) => (
        <TableRow key={user.id}>
          <TableCell sx={styles.tableBody}>{getSerialNumber(page, perPage, index)}</TableCell>
          <TableCell sx={styles.tableBody}>{user.email}</TableCell>
          <TableCell sx={styles.tableBody}><IconButton variant="contained" color="error" size="small" onClick={() => { if (window.confirm(global.constants.labels.are_you_sure)) deleteInvited(user.id) } }><DeleteIcon /></IconButton></TableCell>
        </TableRow>
      ))
    }
    else {
      return usersByTab[tab].map((user, index) => (
        <User
          key={user.id}
          {...user}
          deleteUser={() => deleteUser(user)}
          approveUser={() => approveUser(user.id)}
          styles={styles}
          index={getSerialNumber(page, perPage, index)} />
      ))
    }
  }

  const processTabContent = (tab) => {
    return (
      <TableContainer component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            {getDescription(tab)}
            <TableRow>
              {tableHeadings(tab).map((heading, index) => (<TableCell key={index} sx={styles.tableHeading}>{heading}</TableCell>))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableBodyRows(tab)}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }
  
  return (
    <ListingPage
      title={t('labels.menu.users')}
      actions={actionButtons}
      table={(
        <CustomTabPanel
          currentTab={currentTab}
          handleTableFiltering={props.handleTableFiltering}
          tableActionsState={props.tableActionsState}
          tabLabels={tabLabels}
          handleTabChange={handleTabChange}
          tabCounts={tabCounts}
          paginationComponent={(
            <CustomTablePagination
              count={tabCounts[currentTab] || 0}
              page_no={page}
              onPageChange={handleChangePage}
              rowsPerPage={perPage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              rowsControl={true} />
          )}
          tabContents={{
            active: processTabContent('active'),
            removed: processTabContent('removed'),
            unapproved: processTabContent('unapproved'),
            invited: processTabContent('invited'),
          }} />
      )} />
  )
}

UsersListing.propTypes = {
  page: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  currentTab: PropTypes.string.isRequired,
  usersForExport: PropTypes.array.isRequired,
  tabCounts: PropTypes.object,
  csvLink: PropTypes.object.isRequired,
  usersByTab: PropTypes.object.isRequired,
  deleteUser: PropTypes.func.isRequired,
  approveUser: PropTypes.func.isRequired,
  deleteInvited: PropTypes.func.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleTabChange: PropTypes.func.isRequired,
  handleInviteUser: PropTypes.func.isRequired,
  handleCreateUser: PropTypes.func.isRequired,
  fetchUsersForExport: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired
}

export default UsersListing