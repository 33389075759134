import React from "react";
import PropTypes from 'prop-types'
import { Grid, Paper, Button, Divider } from "@mui/material";
import useTranslation from '../../hooks/useTranslation'

const styles = {
  paper: {
    padding: '20px',
    width: '100%',
    border: "1px solid lightgrey",
  },
}

const EditPageLowerContainer = ( { leftContent, rightContent, onSave, actionType, leftWidth=8, rightWidth=4 } ) => {
  const { t } = useTranslation()

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={leftWidth}>
        {
          leftContent?.map((content, index)=>(
            <Paper key={index} sx={ { ...styles.paper, marginTop: index !== 0 && '20px' } }>
             {content()}
            </Paper>
          ))
        }
      </Grid>
      <Grid item xs={12} md={rightWidth}>
        {
          rightContent?.map((content, index)=>(
            <Paper key={index} sx={ { ...styles.paper, marginTop: index !== 0 && '20px' } }>
             {content()}
            </Paper>
          ))
        }
      </Grid>
      <Divider width={'100%'} sx={{ marginTop: '20px',marginBottom: '20px' }}/>
      <Button
        type="submit"
        color="primary"
        variant="contained"
        onClick={onSave}>
        { actionType === 'New' ? t('labels.create') : t('labels.save') }
      </Button>
    </Grid>
  )
}

EditPageLowerContainer.propTypes = {
  leftContent: PropTypes.array,
  rightContent: PropTypes.array,
  onSave: PropTypes.func,
  actionType: PropTypes.string
}

export default EditPageLowerContainer;
