import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  DndContext, 
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import {useSortable} from '@dnd-kit/sortable'
import {CSS} from '@dnd-kit/utilities'
import Link from '../../components/Menu/link'
import { ProductApi } from "../../api/productApi";
import { CategoryApi } from "../../api/categoryApi";
import { PageApi } from "../../api/pageApi";
import { makeStyles } from '@mui/styles';
import CategoryIcon from '@mui/icons-material/Category'
import PageIcon from '@mui/icons-material/MenuBook'
import ProductIcon from '@mui/icons-material/Ballot'
import { CollectionApi } from '../../api/collectionApi'
import CollectionsIcon from '@mui/icons-material/Collections';

const LinksContainer = ({ links, sortLinks, onUpdate, setLinks }) => {
  const [urlOptions, setUrlOptions] = useState(
    {'Category': [], 'Product': [], 'Page': [], 'Collection': [] }
  )

  useEffect(() => {
    const fetchProducts = async () => (await ProductApi.getProducts({ visible: true }))
    const fetchCategories = async () => (await CategoryApi.getCategories({ visible: true }))
    const fetchPages = async () => (await PageApi.getPages({ visible: true }))
    const fetchCollections = async () => (await CollectionApi.getCollections({ visible: true }))
    Promise.all([fetchCategories(), fetchProducts(), fetchPages(), fetchCollections()]).then((values) => {
      let _urlOptions = { 'Category': values[0].data.data, 'Product': values[1].data.data, 'Page': values[2].data.data, 'Collection': values[3].data.data }
      setUrlOptions(_urlOptions);
    });
  }, [])

  useEffect(() => {
    let didCancel = false // to cancel side effects
    if(!didCancel) {
      setLinks([...links])
    }
    return () => { didCancel = true }
  }, [links.length])
  
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )
  
  const handleLinkUpdate = link => {
    const _links = [...links]
    const indexOfLinkToUpdate = _links.findIndex(({ id, _id }) => id ? id === link.id : _id === link._id )    
    _links[indexOfLinkToUpdate] = link
    setLinks(_links)
    onUpdate(link)
  }

  const handleDragEnd = event => {
    const {active, over} = event
    if (active.id !== over.id) {
      const oldIndex = links.findIndex(link => link.id ?  link.id === active.id : link._id === active.id )
      const newIndex = links.findIndex(link => link.id ?  link.id === over.id : link._id === over.id )
      let reordered = arrayMove(links, oldIndex, newIndex)
      reordered = reordered.map((link, index) => { return {...link, position: index} })
      setLinks(reordered)
      sortLinks(reordered)
    }
  }
  return (
    <DndContext 
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd} >
      <SortableContext 
        items={links}
        strategy={verticalListSortingStrategy} >
        {links.map(link => (
          <SortableLink
            key={`item-${link.id ? link.id : link._id}`}
            _link={link}
            onUpdate={handleLinkUpdate}
            urlOptions={urlOptions} />
        ))}
      </SortableContext>
    </DndContext>
  )
}

const SortableLink = ({ _link, onUpdate, urlOptions }) => {
  const [link, setLink] = useState(_link)
  const [selectedModule, setSelectedModule] = useState(link.module_name);
  const [showOptions, setShowOptions] = useState(false);
  const [search, setSearch] = useState('');
  const [filteredOptions, SetFilteredOptions] = useState([]);
  const [nestedMenu, setNestedMenu] = useState(false)
  const [selectedModuleOption, setSelectedModuleOption] = useState({ label: (link.module_name !== '' ? link.resource_name : link.url), value: link.url, module_name: link.module_name });
  const [currentUrlOptions, setCurrentUrlOptions] = useState([])

  useEffect(() => {
    resetDefaultOptions()
  }, [])

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable(
    { id: link.id ? link.id : link._id },
  )

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(1),
      marginBottom: theme.spacing(2)
    },
    nameTextField: {
      paddingTop: '3.5%',
      paddingBottom: '3.5%'
    },
    urlTextField: {
      padding: '2.5%',
      marginLeft: (selectedModuleOption.module_name !== '' ? 20 : '')
    },
    urlTextFieldDiv: {
      position: 'relative',
      display: 'inline-block',
      width: '100%'
    },
    listDiv: {
      position: 'absolute',
      zIndex: 3,
      backgroundColor: 'white',
      border: "1px solid lightgray",
      borderTop: "none",
      borderRadius: 3,
      width: '44%'
    },
    moduleIcon: {
      position: 'absolute',
      left: 5,
      top: 7,
      height: 20
    },
    dropdownSearch: {
      width: '100%',
      padding: '2.5%'
    },
    externalLink: {
      position: 'absolute',
      right: 10,
      top: 7
    },
    visibleInfoContainer: {
      marginBottom: '2%',
      marginLeft: '3%',
      marginTop: '1%'
    },
    visibleInfoText: {
      marginLeft: '1%',
      color: '#737373'
    }
  }));
  const classes = useStyles()

  const moduleIcons = {
    'Category': (<CategoryIcon className={classes.moduleIcon}/>),
    'Product': (<ProductIcon className={classes.moduleIcon}/>),
    'Page': (<PageIcon className={classes.moduleIcon}/>),
    'Collection': (<CollectionsIcon className={classes.moduleIcon}/>)
  }

  const updateLinkState = (event) => {
    const field = event.target.name
    let _link = {...link}
    if(event.target.type === 'checkbox') {
      if(_link[field] === false) {
        _link[field] = true
      } else if(_link[field]) {
        _link[field] = false
      } else {
        _link[field] = false
      }
    } else {
      _link[field] = event.target.value
    }
    onUpdate(_link)
    return setLink({..._link})
  }

  const removeLink = () => {
    let _link = { ...link, _delete: true }
    onUpdate(_link)
    return setLink({..._link})
  }

  const listBackButtonOnClick = (event) => {
    setNestedMenu(false)
    setSearch('')
    resetDefaultOptions();
  }

  const resetDefaultOptions = () => {
    setCurrentUrlOptions(Object.keys(urlOptions).map((option) => ({ label: option, value: option, image: (option == 'Category' ? <CategoryIcon/> : (option == 'Product' ? <ProductIcon/> : option == 'Collection' ? <CollectionsIcon/> : <PageIcon/>)) })))
  }

  const setProducts = () => {
    setCurrentUrlOptions(urlOptions['Product'].map((product) => {
      return { 
        label: product.name,
        value: ("/" + product.category_slug + "/" + product.slug),
        image: (product.image.webp_image ? product.image.webp_image : product.image.image )
      }
    }))
  }

  const setCategories = () => {
    setCurrentUrlOptions(urlOptions['Category'].map((category) => (
      { label: category.name,
        value: ("/" + category.slug),
        image: (category.image ? category.image : null) }
    )))
  }

  const setPages = () => {
    setCurrentUrlOptions(urlOptions['Page'].map((page) => ({ label: page.name, value: ("/pages/" + page.slug) })))
  }

  const setCollections = () => {
    setCurrentUrlOptions(urlOptions['Collection'].map((collection) => (
      { label: collection.name,
        value: ("/collections/" + collection.slug),
        image: (collection.image ? collection.image : null) }
    )))
  }

  const handleOnListClicked = (event) => {
    if(!nestedMenu) {
      let option = event.target.dataset.value;
      if(option === 'Product') {
        setProducts()
      } else if(option === 'Category') {
        setCategories()
      } else if(option === 'Page') {
        setPages()
      } else if(option === 'Collection') {
        setCollections()
      }
      setNestedMenu(true)
      setSearch('');
      setSelectedModule(option);
    }
    else {
      setShowOptions(false)
      setSelectedModuleOption({ label: event.target.innerText, value: event.target.dataset.value, module_name: selectedModule })
      let _link = {...link, resource_name: event.target.innerText, url: event.target.dataset.value, module_name: selectedModule }
      onUpdate(_link)
      setLink({..._link})
    }
  }

  const urlOnChange = (event) => {
    setSelectedModuleOption({ label: event.target.value, value: event.target.value, module_name: '' })
    let _link = {...link, resource_name: event.target.value, url: event.target.value.replace(/(^\w+:|^)\/\//, ''), module_name: '' }
    onUpdate(_link)
    setLink({..._link})
  }

  const searchOnChange = (event) => {
    setSearch(event.target.value)
    if(event.target.value != '') {
      SetFilteredOptions(currentUrlOptions.filter((option) => {
        return option.value.toLowerCase().includes(event.target.value.toLowerCase())
      } ))
    }
  }

  return (
    <div ref={setNodeRef} style={style} >
      {link._delete !== true &&
        <Link
          link={link}
          attributes={attributes}
          listeners={listeners}
          showOptions={showOptions}
          search={search}
          filteredOptions={filteredOptions}
          nestedMenu={nestedMenu}
          selectedModuleOption={selectedModuleOption}
          selectedModule={selectedModule}
          currentUrlOptions={currentUrlOptions}
          setShowOptions={setShowOptions}
          updateLinkState={updateLinkState}
          removeLink={removeLink}
          listBackButtonOnClick={listBackButtonOnClick}
          handleOnListClicked={handleOnListClicked}
          urlOnChange={urlOnChange}
          searchOnChange={searchOnChange}
          moduleIcons={moduleIcons}
          classes={classes} />
      }
    </div>
  )
}

SortableLink.propTypes = {
  _link: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  urlOptions: PropTypes.object.isRequired
}

LinksContainer.propTypes = {
  links: PropTypes.array.isRequired,
  sortLinks: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  setLinks: PropTypes.func.isRequired
}

export default LinksContainer
