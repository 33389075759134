import React from 'react';
import { Grid } from '@mui/material';
import EmailTemplatePreview from '../Shared/EmailTemplatePreview';

const ShowEmailTemplate = ({emailTemplate}) => {

  return (
    <EmailTemplatePreview content={emailTemplate.body} />
  )
}

export default ShowEmailTemplate