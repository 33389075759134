import React from "react"
import { Route, Redirect } from "react-router-dom"
import AuthStorage from '../services/authStorage'
const Auth = new AuthStorage()

export default ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      Auth.loggedIn() ? (
        <Component {...props} store={Auth.getStore()} />
      ) : (
        <Redirect
          to={{
            pathname: '/',
            state: { from: props.location }
          }}
        />
      )
    }
  />
)
