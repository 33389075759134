import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as menuActions from '../../actions/menuActions'
import { MenuApi } from "../../api/menuApi";
import EditMenu from '../../components/Menu/editMenu'
import FormErrors from '../../components/Helpers/formFlashMessage'
import { Grid } from '@mui/material';
import EditPageUpperContainer from '../../components/Shared/EditPageHeader'

const EditMenuContainer = (props) => {
  const { menuId, actions } = props;
  const [menu, setMenu] = useState({
    id: 0, name: '', type: 'side_menu', enabled: false, query: '', show_name: false, description: '',
    links: []
  })
  const [updating, setUpdating] = useState(false)
  const [formErrors, setFormErrors] = useState({})
  const menuTypes = { "side_menu": "Side Menu", "footer_right": "Footer(right)", "footer_left": "Footer(left)" };

  useEffect(() => {
    if(menuId) {
      MenuApi.getMenu(menuId).then(res => {
        setMenu(res.data)
      })
    }
  }, [])

  useEffect(() => {
    setUpdating(updating)
  }, [updating])

  const emptyLink = (menu) => {
    return {
      _id: 0,
      name: '',
      url: '',
      position: (Math.max(...menu.links.map(link => link.position)) + 1),
      store_id: menu.store_id,
      module_name: '',
      resource_name: '',
      visible: true,
      _delete: false
    }
  }

  const setLinks = (links) => {
    let _menu = {...menu}
    _menu.links = [...links]
    setMenu(_menu)
  }

  const addLink = () => {
    let _menu = {...menu}
    let newLink = emptyLink(_menu)
    newLink._id = Math.max(..._menu.links.map(link => link._id | link.id), 0) + 1
    newLink.position = _menu.links?.reduce((a, b) => a.position > b.position ? a.position : b.position, 0 ) + 1
    _menu.links.push(newLink)
    setMenu(_menu)
  }

  const updateLink = link => {
    let _menu = {...menu}
    let indexOfLinkToUpdate = _menu.links.findIndex(({ id, _id }) => id ? id === link.id : _id === link._id )    
    _menu.links[indexOfLinkToUpdate] = link
    return setMenu(_menu)
  }

  const sortLinks = links => {
    let _menu = {...menu}
    _menu.links = links
    setMenu(_menu)
  }

  const updateMenuState = event => {
    const field = event.target.name
    let _menu = {...menu}
    let _formErrors = {...formErrors} 
    if (_formErrors.hasOwnProperty(field)) delete(_formErrors[field])
    switch (event.target.type) {
      case 'checkbox':
        if(_menu[field] === false) {
          _menu[field] = true
        } else if(_menu[field] === true) {
          _menu[field] = false
        } else {
          _menu[field] = false
        }
        break
      case 'file':
        _menu[field] = event.target.files[0]
        break
      default:
        _menu[field] = event.target.value
    }
    setFormErrors(_formErrors)
    setMenu(_menu)
  }

  const formIsValid = () => {
    let _formErrors = {...formErrors} 
    let formValid = true
    if (menu.name === '') {
      _formErrors.name = `Name can't be blank`
      formValid = false
    }
    if (menu.type === '') {
      _formErrors.type = `Type can't be blank`
      formValid = false
    }
    setFormErrors(_formErrors)
    return formValid
  }

  const resetValidationState = () => {
    setFormErrors({})
  }

  const saveMenu = event => {
    event.preventDefault()
    resetValidationState()
    if (formIsValid()) {
      let menuData = {}
      for (const [field, value] of Object.entries(menu)) {
        if (value) {
            menuData[field] = value
        } else if (value === false) {
            menuData[field] = value
        }
      }
      actions.updateMenu(menu.id, menuData)
    }
  }

  const actionType = menu.id > 0 ? 'Edit' : 'New'

  return (
    <Grid container spacing={3} alignItems="center">
      <EditPageUpperContainer actionType={actionType} page={'Menu'} onSave={saveMenu}/>
      <Grid item xs={12} md={12} lg={12} xl={12}>
        <FormErrors formErrors={formErrors} />
      </Grid>
      <Grid container item={true} xs={12} md={12} lg={12} xl={12}>
        <EditMenu
          menu={menu}
          validationErrors={formErrors}
          setLinks={setLinks}
          newLink={addLink}
          updateLink={updateLink}
          sortLinks={sortLinks}
          onSave={saveMenu}
          updating={updating}
          onChange={updateMenuState}
          listingTypes={menuTypes} />
      </Grid>
    </Grid>
  )
}

EditMenuContainer.propTypes = {  
  menuId: PropTypes.number.isRequired
}

const mapStateToProps = (state, ownProps) => {
  const menuId = Number(ownProps.match.params.id)
  return { menuId: menuId }
}

const mapDispatchToProps = dispatch => {
  return {  actions: bindActionCreators(menuActions, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditMenuContainer)
