import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { FormGroup, FormControlLabel, FormHelperText, Grid, Checkbox, TextField, IconButton, Button, Box, Link } from '@mui/material';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import InfoIcon from '@mui/icons-material/Info';

import { CustomModal } from '../../Shared/CustomModal';
import ImageThumbnail from '../../Shared/ImageThumbnail';
import AddStockModal from "../addStockModal";
import useTranslation from '../../../hooks/useTranslation'

const Variant = (props) => {
  const [variant, setVariant] = useState({...props.object})
  const [modalState, setModalState] = useState(false);
  const [selectedImage, setSelectedImage] = useState(undefined);
  const [selectedDocument, setSelectedDocument] = useState({});
  const inputFileField = useRef(null);
  const documentFileField = useRef(null);
  const [addStock, setAddStock] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    setVariant(props.object)
  }, [props.object])

  const updateVariantState = (event) => {
    const field = event.target.name
    let _variant = {...variant}
    if(event.target.type === 'checkbox') {
      if(_variant[field] === false) {
        _variant[field] = true
      } else if(_variant[field] === true) {
        _variant[field] = false
      } else {
        _variant[field] = false
      }
    } else if (event.target.type === 'file') {
      _variant[field] = event.target.files[0]
      if (field === 'image_path') {
        setSelectedImage(URL.createObjectURL(_variant[field]));
        setModalState(false)
      } else {
        setSelectedDocument({ name: _variant[field].name, url: URL.createObjectURL(_variant[field]) });
      }
    } else {
      _variant[field] = event.target.value
    }
    props.onUpdate(_variant)
    return setVariant({..._variant})
  }

  const setExistingImage = (image) => {
    setSelectedImage(image.image)
    let _variant = {...variant};
    _variant['image_path'] = `${image.image}?existing=true`;
    props.onUpdate(_variant);
    setModalState(false);
    return setVariant({..._variant})
  }

  const removeVariant = () => {
    const _variant = { ...variant }
    _variant._delete = true
    props.onUpdate(_variant)
    // return setVariant({ ..._variant })
  }

  const handleModalState = (state) => {
    setModalState(state)
  }

  const uploadNewImage = () => {
    inputFileField.current.click();
  }

  const uploadNewDocument = () => {
    documentFileField.current.click();
  }

  const renderVariantImagePreview = () => {
    if (selectedImage) {
      return <ImageThumbnail image={selectedImage} height='55px' width='55px' />
    }
    else if (variant?.image_path) {
      return <ImageThumbnail image={variant?.image_path} height='55px' width='55px' />
    } else {
      return <AddPhotoAlternateIcon />
    }
  }

  const renderVariantDocumentPreview = () => {
    if (selectedDocument?.url) {
      return <a href={selectedDocument.url} target='_blank'>{selectedDocument.name}</a>
    } else if (variant?.document_path) {
      return <a href={variant.document_path} target='_blank'>{t('labels.proof_sheet')}</a>
    }
  }

  const renderVariantModalBody = () => {
    return (
      <Box sx={{padding: 2}}>
        <div className="gallery">
          <input name='image_path' value="" type='file' id='file' ref={inputFileField} onChange={updateVariantState} style={{ display: 'none' }}/>
          <div className='new-image-container' onClick={uploadNewImage}><button type="button" className='new-image-button'>{t('labels.add')}</button></div>
          {props?.optionalParams['images']?.map((image, index) =><div key={index} className='new-image-container' onClick={() => setExistingImage(image)}>
            <img src={image.image} />
          </div>)}
        </div>
      </Box>
    )
  }

  const handleVariantQuantityAdded = (quantity, incoming_inventory) => {
    if (!incoming_inventory){
      let _variant = {...variant, quantity: parseInt(variant.quantity) + parseInt(quantity)}
      setVariant(_variant)
      props.onUpdate(_variant)
    }
  }

  return (
    <FormGroup style={{ border: '1px solid #f1f1f1', borderRadius: '5px', padding: '10px', boxShadow: '0px 3px 5px 0px #f1f1f1', marginTop: '10px'}} className={(variant._delete ? 'hidden' : '')} >
      <CustomModal modalState={modalState} title={`${t('labels.select')} ${t('labels.image')}`} handleModalState={handleModalState} modalBody={renderVariantModalBody()} />
      { addStock &&
        <AddStockModal productVariant={variant} variantId={variant.id} handleVariantQuantityAdded={handleVariantQuantityAdded} closeModal={()=>setAddStock(false)} />
      }
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <div onClick={() => handleModalState(true)} className={(!selectedImage && !variant?.image_path) ? 'image-preview' : 'image-display-preview'}>{renderVariantImagePreview()}</div>
        </Grid>
        <Grid item xs={6}>
          <TextField
            type="text"
            label="Name"
            name="name"
            fullWidth
            variant="outlined"
            disabled={true}
            value={variant.name}
            onChange={updateVariantState}/>
        </Grid>
        <Grid item xs={2}>
          <TextField
            type="number"
            min={0}
            label="Price"
            name="price"
            variant="outlined"
            value={variant.price}
            disabled={props.optionalParams.isThirdPartyFulfillment}
            onWheel={(e) => e.target.blur()}
            onChange={updateVariantState}/>
        </Grid>
        <Grid item xs={1}>
          <IconButton disabled={props.optionalParams.isThirdPartyFulfillment} color="error" size="medium" onClick={() => { if (window.confirm(t('labels.are_you_sure'))) removeVariant() }}>
            <DeleteIcon/>
          </IconButton>       
        </Grid>
        <Grid item xs={1}>
          <IconButton {...props.attributes} {...props.listeners} color="primary" size="medium" onClick={() => { if (window.confirm(t('labels.are_you_sure'))) removeVariant() }}>
            <DragIndicatorIcon/>
          </IconButton>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{paddingTop: (!selectedImage && !variant?.image_path) ? 0 : 0.8}}>
        <Grid item xs={3}>
          <FormGroup>
            <FormControlLabel control={<Checkbox name="manage_stock" color="primary" disabled={props.optionalParams.isThirdPartyFulfillment} checked={variant.manage_stock === true} onChange={updateVariantState} />} label="Track Inventory" />
          </FormGroup>
        </Grid>
        <Grid item xs={9} sx={{marginTop: 0.5}}>
          <input name='document_path' value="" type='file' id='document' ref={documentFileField} onChange={updateVariantState} style={{ display: 'none' }}/>
          <Button sx ={{marginRight: 2}} variant='contained' color="primary" size="small" onClick={uploadNewDocument}><UploadFileIcon /> {t('labels.artwork')}</Button><br/>
          {renderVariantDocumentPreview()}
        </Grid>
      </Grid>
      { !variant.manage_stock ? null : (
        <React.Fragment>
          <Grid container spacing={2} sx={{ paddingTop: 2 }} alignItems='center'>
            <Grid item xs={3}>
                <TextField
                  id="variantQuantity"
                  type="number"
                  disabled={props.optionalParams.isThirdPartyFulfillment}
                  min={0}
                  label="Current Stock"
                  name="quantity"
                  variant="outlined"
                  inputProps={{ readOnly: !props?.optionalParams['isVariantOptionsEditing'] }}
                  value={ variant.quantity || 0 }
                  onChange={updateVariantState}/>
            </Grid>
            {
              !props?.optionalParams['isVariantOptionsEditing'] &&
              <Grid item xs={2}>
                <Link><div style={{cursor: 'pointer'}} onClick={() => setAddStock(true)}>Add Stock</div></Link>
              </Grid>
            }
            <Grid item xs={7}>
              <FormControlLabel control={<Checkbox name="allow_backorders" color="primary" disabled={props.optionalParams.isThirdPartyFulfillment} checked={variant.allow_backorders === true} onChange={updateVariantState} />} label="Continue selling when out of stock" />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ paddingTop: 2 }}>
            <Grid item xs={3}>
              <TextField
                type="text"
                label="SKU"
                name="sku"
                variant="outlined"
                disabled={props.optionalParams.isThirdPartyFulfillment}
                value={variant.sku}
                onChange={updateVariantState}/>
            </Grid>
            <Grid item xs={3}>
              {props?.optionalParams['isBundle'] && <TextField
                type="number"
                label="SKU Multiple"
                name="sku_multiple"
                variant="outlined"
                disabled={props.optionalParams.isThirdPartyFulfillment}
                onWheel={(e) => e.target.blur()}
                value={variant.sku_multiple}
                onChange={updateVariantState}/>}
            </Grid>
            <Grid item xs={6}>
              {props?.optionalParams['isBundle'] && <Grid container spacing={2} sx={{padding: 1}}><Grid item xs={1}><InfoIcon/></Grid><Grid item xs={11}><FormHelperText>{t('labels.sku_multiple_help')}</FormHelperText></Grid></Grid>}
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </FormGroup>
  )
}

Variant.proptypes = {
  object: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired
}

export default Variant
