import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ProductApi } from '../../api/productApi'
import { Typography, Grid, Modal, Divider, Button, Fade, Backdrop, Paper, MenuItem, IconButton, TextField } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import ImageThumbnail from '../Shared/ImageThumbnail'

const classes = {
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    width: { xs: '95%', sm: '70%', md: '60%', lg: '45%' },
    boxShadow: 24
  },
  item: {
    paddingTop: '2%',
    paddingLeft: '2%',
    paddingBottom: '1%',
  },
  imageIcon: {
    color: 'lightgrey',
    height: '100%',
    width: '100%'
  }
}


const AddOrderItem = props => {
  const [term, setTerm] = useState('')
  const [products, setProducts] = useState([])

  const fetchProducts = () => {
    ProductApi.productsForOrders(term, { "dependent-source": global.constants.dependentSource.order })
      .then(res => {
        if(res.status===200) {
          setProducts(res.data)
        }
      })
  }

  return (
    <Modal
      open={props.open}
      onClose={props.onCloseItemModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }} >
      <Fade in={props.open}>
        <Paper sx={classes.modal}>
          <Grid container alignItems="center">
            <Grid item xs={11} sx={{paddingLeft: "2%"}}>
              <h3>Add new item to order</h3>
            </Grid>
            <Grid item xs={1}>
              <IconButton sx={{float: 'right'}} onClick={props.onCloseItemModal}><CloseIcon /></IconButton>
            </Grid>
            <Divider width="100%" />
            <Grid container sx={{padding: "2%"}} spacing={2} alignItems="center">
              <Grid item xs={9} sm={9}>
                <TextField
                  id="Product"
                  size="small"
                  type="text"
                  label="Search products"
                  placeholder="Search products"
                  name="term"
                  value={term}
                  fullWidth
                  onChange={e => setTerm(e.currentTarget.value)} />  
              </Grid>
              <Grid item xs={3} sm={3}>
                <Button variant='outlined' fullWidth onClick={fetchProducts}>Browse</Button>
              </Grid>
            </Grid>
            <Divider width="100%" />
            <Grid container sx={{overflow: 'scroll', maxHeight: 400}}>
              { products.map(product => (
                <React.Fragment key={product.id}>
                  <Item product={product} onAddItem={props.onAddItem} />
                  <Divider width="100%" />
                </React.Fragment>
              )) }
            </Grid>
            <Divider width="100%" />
            <Grid item sx={{padding: "2%"}} xs={12} sm={12}>
              <Button sx={{float: "right"}} variant='outlined' onClick={props.onCloseItemModal}>Close</Button>
            </Grid>
          </Grid>
        </Paper>
      </Fade>
    </Modal>
  )
}

const Item = props => {
  const [item, setItem] = useState({
    product_id: props.product.id,
    product: {...props.product, image: props.product.image?.image},
    variant: props.product.variants.length > 0 ? props.product.variants[0] : {},
    quantity: 1,
    vat: parseFloat(props.product.vat),
    freight: parseFloat(props.product.freight),
    price: props.product.variants.length > 0 ? props.product.variants[0].price : 0,
    variant_id: props.product.variants.length > 0 ? props.product.variants[0].id : null
  })

  const selectVaraint = e => {
    const variant = props.product.variants.find(v => v.id == e.target.value)
    setItem({...item, variant: variant, price: variant.price, variant_id: e.target.value})
  }
  return (
    <Grid container item xs={12} spacing={2} sx={classes.item} alignItems="center">
      <Grid item xs={6} sm={6} md={6}>
        <Grid container spacing={1} wrap="nowrap" alignItems="center">
          <Grid item>
            <ImageThumbnail image={item.product.image} width='100px' height='100px' />
          </Grid>
          <Grid item><Typography variant="subtitle1">{item.product.name}</Typography></Grid>
        </Grid>
      </Grid>
      <Grid item xs={3} sm={4} md={4}>
        <TextField
          name="variant"
          size="small"
          value={item.variant.id ?? ''}
          label="Variant"
          placeholder="select"
          select
          variant='outlined'
          fullWidth
          onChange={selectVaraint}>
          { props.product.variants.map(v => <MenuItem key={v.id} value={v.id}>{v.name && `${v.name} - `}{v.price}</MenuItem>) }
        </TextField>
      </Grid>
      <Grid item xs={1} sm={2} md={2}>
        <Button color="primary" variant='outlined' onClick={() => props.onAddItem(item)}>
          Add
        </Button>
      </Grid>
    </Grid>
  )
}
Item.propTypes = {
  product: PropTypes.object.isRequired,
  onAddItem: PropTypes.func.isRequired
}

AddOrderItem.propTypes = {
  open: PropTypes.bool.isRequired,
  onAddItem: PropTypes.func.isRequired,
  onCloseItemModal: PropTypes.func.isRequired
}

export default AddOrderItem
