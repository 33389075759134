import React, { useEffect, useState } from 'react'
import { Grid, Card, Typography, Divider } from '@mui/material'
import { HomeApi } from "../api/homeApi"
import AuthStorage from '../services/authStorage'
import { useAbility } from '../hooks/useAbility'
import Tile from '../components/Dashboard/tile'
import OrdersChart from '../components/Dashboard/ordersChart'
import TopProducts from '../components/Dashboard/topProducts'
import OrdersTable from '../components/Order/ordersTableWidget'
import useTranslation from '../hooks/useTranslation'

const Auth = new AuthStorage()
const { fetchRulesForUser } = useAbility();
const routeMapping = global.constants;

const classes = {
  gridContainer: {
    padding: '2%',
    marginBottom: 0
  },
  viewAllBtn: {
    paddingRight: 0,
    textTransform: 'none'
  },
  ordersChartSelect: {
    paddingTop: '5%',
    paddingBottom: '5%',
    float: 'right'
  },
  topProductsList: {
    bgcolor: 'background.paper'
  },
  topProductsText: {
    fontSize: 'small',
    paddingLeft: '6%',
    color: 'gray'
  },
  ordersChartCard: {
    padding: '1%'
  },
  ordersChartContainer: { padding: '2%'}
}

const DashboardContainer = () => {
  const [products, setProducts] = useState({ displayValue: 0, difference: 0 })
  const [users, setUsers] = useState({ displayValue: 0, difference: 0 })
  const [orders, setOrders] = useState({ latestOrders: [], displayValue: 0, difference: 0 })
  const [ordersData, setOrdersData] = useState([])
  const [topProducts, setTopProducts] = useState([])
  const [ordersChartOption, setOrdersChartOption] = useState('7days')
  const [sales, setSales] = useState({ displayValue: 0 })
  const ability = fetchRulesForUser(Auth.getPermissions(), Auth.getUser());
  const storeCurrency = Auth.getStore() ? Auth.getStore().defaultCurrency : { name: 'Swedish Krone', symbol: 'kr', isoCode: 'sek' }
  const ordersPermission = ability.can(routeMapping.actions.list, routeMapping.controllers.Orders)
  const usersPermission = ability.can(routeMapping.actions.list, routeMapping.controllers.Users)
  const productsPermission = ability.can(routeMapping.actions.list, routeMapping.controllers.Products)
  const { t } = useTranslation()

  const calculateDifference = (object) => {
    if(object.newCount === object.lastMonthCount) {
      return 0
    }
    else if(object.newCount === 0 && object.lastMonthCount > 0) {
      return -100
    }
    else if(object.newCount > 0 && object.lastMonthCount === 0) {
      return 100
    }
    else {
      return parseInt(((object.newCount - object.lastMonthCount) / object.lastMonthCount) * 100);
    }
  }

  const fetchData = async () => {
    const result = await HomeApi.dashboard()
    setProducts({ displayValue: result.data.products.totalCount, difference: calculateDifference(result.data.products) })
    setSales({ displayValue: result.data.totalSales })
    setOrders({ displayValue: result.data.orders.newCount, difference: calculateDifference(result.data.orders), latestOrders: result.data.orders.latestOrders })
    setUsers({ displayValue: result.data.users.newCount, difference: calculateDifference(result.data.users) })
    setTopProducts(result.data.topProducts)
  }

  const fetchOrdersChartData = async () => {
    const result = await HomeApi.ordersChart(ordersChartOption, { "dependent-source": t('dependentSource.order') })
    setOrdersData(result.data)
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    fetchOrdersChartData()
  }, [ordersChartOption])

  const handleOrderChartChange = (event) => {
    setOrdersChartOption(event.target.value)
  }

  return (
    <Grid container spacing={2}>
      {ordersPermission && <Tile title={t('labels.sales_last_month')} object={sales} module={t('labels.profit')} storeCurrency={storeCurrency} />}
      {ordersPermission && <Tile title={t('labels.orders_last_month')} object={orders} module={t('labels.order')} />}
      {usersPermission && <Tile title={t('labels.new_users')} object={users} module={t('labels.user')} />}
      {productsPermission && <Tile title={t('labels.active_products')} object={products} module={t('labels.product')} />}
      {ordersPermission &&
        <OrdersChart
          ordersData={ordersData}
          ordersChartOption={ordersChartOption}
          classes={classes}
          handleOrderChartChange={handleOrderChartChange} />}
      {ordersPermission && productsPermission && <TopProducts topProducts={topProducts} classes={classes} /> }
      {ordersPermission && (
        <Grid item xs={12} md={12} lg={8} xl={9}>
          <Card elevation={3}>
            <Grid item xs={12} sx={classes.gridContainer}><Typography variant='h5' color="textPrimary">{t('labels.latest_orders')}</Typography></Grid>
            <Divider />
            <OrdersTable orders={orders.latestOrders} storeCurrency={storeCurrency} dashboard={true} />
          </Card>
        </Grid>
      )}
    </Grid>
  )
}

export default DashboardContainer
