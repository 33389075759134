import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, FormControl, InputLabel, Typography, CircularProgress } from '@mui/material';
import ReactQuill, { Quill } from 'react-quill';
import ImageResize from 'quill-image-resize-module-react';
import 'react-quill/dist/quill.snow.css';
import { EmailTemplateApi } from '../../api/emailTemplatesApi';
Quill.register('modules/imageResize', ImageResize);
import useTranslation from '../../hooks/useTranslation'

const EmailEditor = ({ value, onChange, label, error, uploadImage }) => {
  const { t } = useTranslation()
  const quillRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [imageUrls, setImageUrls] = useState(new Set());

  const imageHandler = useCallback(() => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      if (file) {
        setIsLoading(true);
        const imageURL = await uploadImage(file);
        setImageUrls(new Set([...imageUrls, imageURL]));
        setIsLoading(false);
        const editor = quillRef.current.getEditor();
        const range = editor.getSelection(true);
        const [leaf, offset] = editor.getLeaf(range.index);
        const blot = Quill.find(leaf.domNode.parentNode);
        blot.format('align', 'center');
        editor.insertEmbed(range.index, 'image', imageURL);
      }
    };
  }, []);

  const handleImageDeletions = (currentContent) => {
    const currentImageUrls = new Set();
    const delta = currentContent.ops;
  
    delta.forEach((op) => {
      if (op.insert && op.insert.image) {
        currentImageUrls.add(op.insert.image);
      }
    });

    const removedUrls = [...imageUrls].filter(url => !currentImageUrls.has(url));
    if(removedUrls.length > 0) {
      EmailTemplateApi.removeImages({ removedUrls });
    }
  };

  const handleChange = useCallback((content, delta, source) => {
    if (source === 'user') {
      onChange(content, delta, source);
      const quill = quillRef.current.getEditor();
      const currentContent = quill.getContents();
      handleImageDeletions(currentContent);
    }
  }, [onChange, handleImageDeletions]);

  const modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ font: [] }],
        [{ align: ['right', 'center', 'justify'] }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link', 'image'],
        [{ color: [] }],
        [{ background: [] }],
      ],
      handlers: {
        'image': imageHandler,
      },
    },
    imageResize: {
      parchment: Quill.import('parchment'),
      modules: ['Resize', 'DisplaySize', 'Toolbar']
    }
  };
    
  return (
    <FormGroup>
      {label && <InputLabel>{label}</InputLabel>}
      <FormControl error={!!error}>
        {isLoading && (
          <div style={{ display: 'flex', justifyContent: 'center', margin: '10px 0' }}>
            <CircularProgress />
          </div>
        )}
        <ReactQuill
          ref={quillRef}
          modules={modules}
          theme="snow"
          value={value}
          onChange={handleChange}
          placeholder={t('labels.email_template_placeholder')}
        />
        {error && (
          <Typography variant="caption" color="error">
            {error}
          </Typography>
        )}
      </FormControl>
    </FormGroup>
  );
};

EmailEditor.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  error: PropTypes.string,
};

export default EmailEditor;