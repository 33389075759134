import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import FieldGroup from '../fieldGroup';
import EditPageUpperContainer from '../Shared/EditPageHeader';
import EditPageLowerContainer from '../Shared/EditPageLowerContainer';
import EmailEditor from '../Shared/EmailEditor';
import customEmailTemplateButtons from '../../config/customEmailTemplateButtons';
import getRestrictedKeywords from '../../utils/restrictedKeywords';
import EmailTemplatePreview from '../Shared/EmailTemplatePreview';
import { EmailTemplateApi } from '../../api/emailTemplatesApi';
import { debounce } from 'lodash';
import useTranslation from '../../hooks/useTranslation'

const EditEmailTemplate = ({ emailTemplate, onSave, handleEmailTemplate }) => {
  const { t } = useTranslation()
  const actionType = ( emailTemplate?.hasOwnProperty('id') && emailTemplate.id > 0 ) ? t('labels.edit') : t('labels.new')
  // const customEditorButtons = customEmailTemplateButtons(JSON.parse(localStorage.getItem('store')));
  const [error, setError] = useState("");

  const updateEmailTemplateState = event => {
    handleEmailTemplate({...emailTemplate, [event.target.name]: event.target.value})
  }

  const violatesRestrictions = (newContent, restrictedKeywords) => {
    return !restrictedKeywords.every(keyword => newContent.includes(keyword));
  };

  const saveChanges = debounce(async (content) => {
    if (emailTemplate.id) {
      await EmailTemplateApi.updateEmailTemplate(emailTemplate.id, { body: content });
    }
  }, 2000);

  const updateEmailTemplateBody = (content, delta, source) => {
    // Proceed only if the change was made by the user or via the API with non-empty content
    if ((source === 'user') || (source === 'api' && content && emailTemplate.body)) {
      const restrictedKeywords = getRestrictedKeywords(emailTemplate.type);
      if (restrictedKeywords.length > 0 && violatesRestrictions(content, restrictedKeywords)) {
        setError(t('labels.restrictedContent'))
      } else {
        handleEmailTemplate({...emailTemplate, body: content})
        saveChanges(content)
      }
    }
  };

  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append('image', file);

    try {
      const response = await EmailTemplateApi.addImage(formData)
      return response.data;
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  }

  const leftFirstContent = () => (
    <>
      <Grid item xs={12} md={9}>
      <FieldGroup
          id="templateName"
          type="text"
          label={t('placeholders.name')}
          name="name"
          placeholder={t('placeholders.name')}
          value={emailTemplate.name}
          onChange={updateEmailTemplateState}
        />
        <FieldGroup
          id="templateType"
          type="text"
          label={t('placeholders.type')}
          name="type"
          placeholder={t('placeholders.type ')}
          value={emailTemplate.type}
          onChange={updateEmailTemplateState}
          disabled={true}
        />
        <FieldGroup
          id="templateSubject"
          type="text"
          label={t('placeholders.subject')}
          name="subject"
          placeholder={t('placeholders.subject')}
          value={emailTemplate.subject}
          onChange={updateEmailTemplateState}
           />
      </Grid>
      <Grid item xs={12} md={12}>
        <EmailEditor value={emailTemplate.body} onChange={updateEmailTemplateBody} label={t('labels.body')} error={error} uploadImage={uploadImage} />
      </Grid> 
    </>
  )

  const rightFirstContent = () => (
    <EmailTemplatePreview content={emailTemplate.body} />
  )

  return (
    <div>
      <EditPageUpperContainer actionType={actionType} page={t('labels.email_template')} onSave={onSave} />
        <form onSubmit={onSave}>
          <EditPageLowerContainer
            leftContent={ [leftFirstContent] }
            rightContent={ [rightFirstContent] }
            actionType={actionType}
            onSave={onSave}
          />
        </form> 
    </div>
)}

EditEmailTemplate.propTypes = {
  emailTemplate: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired
}

export default EditEmailTemplate
