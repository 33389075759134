import React, { useRef } from "react";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button } from "@mui/material";
import { useSelector } from 'react-redux'
import { deleteCollection, resetCreated, resetUpdated } from "../actions/collectionActions";
import { setSuccessNotification } from "../actions/notificationAction";
import ListingPage from "../components/Shared/ListingPage";
import Collection from "../components/Collection/collection";
import { ListTable } from "../components/Shared/ListingTable";
import { getSerialNumber } from '../utils/sharedFunctions'
import ListTableActions from "../components/Shared/ListingTable/listTableActions";
import { CollectionApi } from "../api/collectionApi";
import { CSVLink } from "react-csv";
import {collectionCSVHeaders} from "../config/csvHeaders";
import {collectionSorting} from "../config/sortingLabels";
import useListingActions from '../hooks/useListingActions'
import { actionBtn } from '../styles'
import useTranslation from '../hooks/useTranslation'

const CollectionContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { created, updated } = useSelector(state => state.collections);
  const [dataForExport, setDataForExport] = useState([])
  const csvButtonRef = useRef(null)
  const { t } = useTranslation()

  const {
    data,
    count,
    page,
    perPage,
    searchTerm,
    setData,
    sortOptionSelected,
    handlerForSearchingAndSortingChange,
  } = useListingActions('collections', CollectionApi.getCollections );

  useEffect(()=>{
    if (created){
      dispatch(resetCreated())
      dispatch(setSuccessNotification({ message: t('response_messages.collections.created') }))
    }
  },[created])

  useEffect(()=>{
    if (updated){
      dispatch(resetUpdated())
      dispatch(setSuccessNotification({ message: t('response_messages.collections.updated') }))
    }
  },[updated])

  const deleteSingleCollection = (collection) => {
    dispatch(deleteCollection(collection))
    setData(data.filter(col => col.id !== collection.id))
    dispatch(setSuccessNotification({ message: t('response_messages.collections.deleted') }))
  }

  const collectionTableContent = () => {
    const collectionBody = data?.map((collection, index) => (
      <Collection key={collection.id} {...collection} deleteCollection={ () => deleteSingleCollection(collection)}
        index={getSerialNumber(page, perPage, index)}
      />
    ));
    return { body: collectionBody, heading: [t('labels.id'), '', t('labels.name'), t('labels.visible_on_homepage'), t('labels.public'), t('labels.action')] }
  }

  const exportCSV = async () => {
    const response = await CollectionApi.getCollections({ page: 1, per_page: perPage, q: searchTerm, sort_by: sortOptionSelected, export_records: true })
    if (response.data?.data?.length > 0){
      setDataForExport(response.data.data)
      csvButtonRef.current.link.click()
    }
  }

  const tableActions = (
    <ListTableActions
      searchText={t('labels.search_collections')}
      searchTerm={searchTerm}
      sortOptionSelected={sortOptionSelected}
      getData={handlerForSearchingAndSortingChange}
      translateText={t}
      sortOptions={collectionSorting}
    />
  )

  const actions = (
    <>
      <CSVLink
        data={dataForExport}
        headers={collectionCSVHeaders}
        filename={`collections-${(new Date()).toLocaleDateString().replace(', ', '-')}.csv`}
        className='hidden'
        ref={csvButtonRef}
        target='_blank'/>
      <Button variant="outlined" sx={actionBtn}
              onClick={exportCSV}>{t('labels.exportToCsv')}</Button>
      <Button
        color="primary"
        variant="contained"
        sx={actionBtn}
        onClick={() => history.push('/admin/collections/new')}>
        {t('labels.create_collection')}
      </Button>
    </>

  )

  const tableContent = collectionTableContent()

  return (
    <ListingPage
      title={t('labels.menu.collections')}
      actions={actions}
      table={
        <ListTable
          tableHeadings={tableContent.heading}
          tableContent={tableContent.body}
          tableActions={tableActions}
          count={count}
          page_no={page}
          rowsPerPage={perPage}
          onPageChange={(event, pageNo) => handlerForSearchingAndSortingChange('page', pageNo + 1)}
          handleChangeRowsPerPage={(event) => handlerForSearchingAndSortingChange('pageSize', event.target.value)}
          rowsControl={true} />
      }
    />
  )
}
  
export default CollectionContainer
