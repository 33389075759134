import * as userGroupActions from '../actions/userGroupActions'
import initialState from './initialState'

const userGroups = (state = initialState.userGroups, action) => {
  switch (action.type) {
    case userGroupActions.GET_USER_GROUPS:
      return {
        ...state,
      }
    case userGroupActions.GET_USER_GROUPS_SUCCESS:
      return {
        ...action.payload,
        formErrors: state?.formErrors ?? {}
      }
    case userGroupActions.GET_USER_GROUP:
      return {
        ...state,
      }
    case userGroupActions.GET_USER_GROUP_SUCCESS:
      let indexOfUserGroupToGet = state.data.findIndex(({ id }) => id === action.payload.id)
      let newStateAfterGet = { ...state }
      if(indexOfUserGroupToGet === -1) {
        newStateAfterGet.data.push(action.payload)
      } else {
        newStateAfterGet.data[indexOfUserGroupToGet] = action.payload
      }
      return newStateAfterGet
    case userGroupActions.CREATE_USER_GROUP:
      return {
        ...state,
      }
    case userGroupActions.CREATE_USER_GROUP_SUCCESS:
      let updatedUserGroupState = { ...state, formErrors: { success: 'Group has been created' } };
      updatedUserGroupState.data.push(action.payload);
      return updatedUserGroupState
    case userGroupActions.CREATE_USER_GROUP_ERROR:
      return { 
        ...state,
        formErrors: { field: action.payload?.data?.message ?? action.payload.message }
      }
    case userGroupActions.UPDATE_USER_GROUP:
      return {
        ...state,
      }
    case userGroupActions.UPDATE_USER_GROUP_SUCCESS:
      let indexOfUserGroupToUpdate = state.data.findIndex(({ id }) => id === action.payload.id);
      let newState = { ...state, formErrors: { success: 'Group has been updated' } }
      newState.data[indexOfUserGroupToUpdate] = action.payload
      return newState
    case userGroupActions.UPDATE_USER_GROUP_ERROR:
      return { 
        ...state,
        formErrors: { field: action.payload?.data?.message ?? action.payload.message }
      }
    case userGroupActions.DELETE_USER_GROUP:
      return {
        ...state
      }
    case userGroupActions.DELETE_USER_GROUP_SUCCESS:
      let indexOfUserGroupToDelete = state.data.findIndex(({ id }) => id === action.payload.id);
      let updatedState = { ...state, formErrors: { success: 'Group has been deleted' } }
      updatedState.data.splice(indexOfUserGroupToDelete, 1)
      return updatedState
    case userGroupActions.DELETE_USER_GROUP_ERROR:
      return { 
        ...state,
        formErrors: { field: action.payload?.data.message ?? action.payload.message }
      }
    case userGroupActions.RESET_FLAGS:
      return {
        ...state,
        isGroupCreated: false,
        isGroupUpdated: false,
        formErrors: {}
      }
    default:
      return state
  }
}

export default userGroups
