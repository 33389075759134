import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import DeleteIcon from '@mui/icons-material/Delete';
import { TableRow, TableCell, IconButton } from '@mui/material';

const Discount = props => {
  return(
    <TableRow key={props.id}>
      <TableCell component="th" scope="row"><Link to={`/admin/discounts/edit/${props.id}`}>{props.index}</Link></TableCell>
      <TableCell component="th" scope="row"><Link to={`/admin/discounts/edit/${props.id}`}> {props.name}</Link></TableCell>
      <TableCell component="th" scope="row">{ props.single_use && '✔'}</TableCell>
      <TableCell>{props.type === 'free_delivery' ? 'Free Delivery' :  (`${props.value}${ props.value && (props.type==='percentage' ? '%' : 'kr')}`)}</TableCell>
      <TableCell>{props.expires_at}</TableCell>
      <TableCell component="th" scope="row">{ props.active && '✔' }</TableCell>
      <TableCell align="left">
        <IconButton color="error" size="small" onClick={() => { if (window.confirm('Are you sure?')) props.deleteDiscount() } }>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

Discount.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  single_use: PropTypes.bool,
  expires_at: PropTypes.string,
  prefix: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  active: PropTypes.bool,
  deleteDiscount: PropTypes.func
}
export default Discount
