import React from 'react'
import { Switch, Route } from 'react-router-dom'
import EditEmailTemplateContainer from '../containers/editEmailTemplateContainer'
import EmailTemplateContainer from '../containers/emailTemplateContainer'
import ShowEmailTemplateContainer from '../containers/showEmailTemplateContainer'

export default ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}`} component={EmailTemplateContainer} />
    <Route path={`${match.path}/new`} component={EditEmailTemplateContainer} />
    <Route path={`${match.path}/edit/:id`} component={EditEmailTemplateContainer} />
    <Route path={`${match.path}/:id`} component={ShowEmailTemplateContainer} />
  </Switch>
)
