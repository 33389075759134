import {HttpClient} from './httpClient' 
import {API_URL} from './RestAPI'
import apiHeaders from './apiHeaders'
import {setUrlForGetRequest} from "./sharedFunctions";

//Setting the users URI
const USER_API = `${API_URL}/users`

// Create
const createUser = user => {
  return HttpClient.post(
    `${USER_API}/create`,
    user,
    apiHeaders()
  )
}

const sendInvite = data => {
  return HttpClient.post(
    `${USER_API}/send-invite`,
    data,
    apiHeaders()
  )
}

// Read

const getManagerAndStaffUsers = (dependent = {}) => {
  return HttpClient.get(
    `${USER_API}?userRole=superadmin&status=active&page=-1`,
    apiHeaders(dependent)
  )
}

const getUsers = (params) => {
  let url = setUrlForGetRequest(USER_API, params)
  return HttpClient.get(url, apiHeaders())
}

const getUsersForExport = (params) => {
  return HttpClient.get(
    `${USER_API}/users-for-export?q=${params.q}&sort_by=${params.sort_by}`,
    apiHeaders()
  )
}

const getInvited = (page, per_page) => {
  return HttpClient.get(
    `${USER_API}/invited?page=${page}&per_page=${per_page}`,
    apiHeaders()
  )
}

// Get
const getUser = id => {
  return HttpClient.get(
    `${USER_API}/${id}`,
    apiHeaders()
  )
}

const getCurrentUser = () => {
  return HttpClient.get(
    `${USER_API}/current`,
    apiHeaders()
  )
}

// Update
const updateUser = (id, user) => {
  return HttpClient.put(
    `${USER_API}/${id}/update`,
    user,
    apiHeaders()
  )
}

// Delete
const removeUser = user => {
  return HttpClient.delete(
    `${USER_API}/${user.id}`,
    apiHeaders()
  )
}

const deleteInvite = user => {
  return HttpClient.post(
    `${USER_API}/${user.id}/delete-invite`, {},
    apiHeaders()
  )
}

const importUsers = file => {
  return HttpClient.post(
    `${USER_API}/import-users`, file,
    apiHeaders()
  )
}

// Fetch Role Based Users
const getUsersByRole = (params) => {
  return HttpClient.get(
    `${USER_API}/role-based-users?userRole=${params.userRole}`,
    apiHeaders()
  )
}

//Encapsulating in a JSON object
const UserApi = {getManagerAndStaffUsers, getUsers, createUser, getUser, getCurrentUser, updateUser, removeUser, getInvited, sendInvite, deleteInvite, getUsersForExport, importUsers, getUsersByRole}

export {UserApi}
