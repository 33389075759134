import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Divider } from '@mui/material'
import Item from './item'

const OrderItems = props => (
  <Grid container item={true}>
    <Grid item xs={12} md={12} lg={12} xl={12} sx={{paddingLeft: "3%"}}>
      <h3>Order Items</h3>
    </Grid>
    <Divider width="100%" />
    <Grid item xs={12} md={12} lg={12} xl={12}>
      {props.order_items.map(order_item => {
        const fulfillmentCenter = props.orderFulfillments.find(f => f.productIds.includes(order_item.product.id))
        return (
          <React.Fragment key={order_item.id}>
            <Item 
              {...order_item}  
              storeCurrency={props.storeCurrency} 
              fulfillmentCenter={fulfillmentCenter}
            />
            <Divider width="100%" />
          </React.Fragment >
        );
      })}
    </Grid>
  </Grid>
)

OrderItems.propTypes = {
  order_items: PropTypes.array.isRequired,
  order: PropTypes.shape({
    discount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    coupon: PropTypes.object,
    freight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    store: PropTypes.object,
    net_total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    vat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    use_account_balance: PropTypes.bool,
    balance_used: PropTypes.string
  }),
  storeCurrency: PropTypes.object.isRequired
}

export default OrderItems