import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@mui/material";
import ListingPage from "../components/Shared/ListingPage";
import EmailCampaign from "../components/EmailCampaign/emailCampaign";
import { ListTable } from "../components/Shared/ListingTable";
import { getSerialNumber } from '../utils/sharedFunctions'
import ListTableActions from "../components/Shared/ListingTable/listTableActions";
import { EmailCampaignApi } from "../api/emailCampaignApi";
import {emailTemplateSorting} from "../config/sortingLabels";
import useListingActions from '../hooks/useListingActions'
import { actionBtn } from '../styles'
import useTranslation from '../hooks/useTranslation'

const EmailCampaignContainer = () => {
  const history = useHistory();

  const {
    data,
    count,
    page,
    perPage,
    searchTerm,
    setData,
    sortOptionSelected,
    handlerForSearchingAndSortingChange
  } = useListingActions('emailCampaigns', EmailCampaignApi.getEmailCampaigns );
  const { t } = useTranslation()

  const deleteEmailCampaign = async (emailCampaign) => {
    await EmailCampaignApi.removeEmailCampaign(emailCampaign)
    setData(data.filter(col => col.id !== emailCampaign.id))
  }

  const emailCampaignTableContent = () => {
    const emailCampaignBody = data?.map((emailCampaign, index) => (
      <EmailCampaign key={emailCampaign.id} {...emailCampaign} deleteEmailCampaign={ () => deleteEmailCampaign(emailCampaign)}
        index={getSerialNumber(page, perPage, index)}
      />
    ));
    return { body: emailCampaignBody, heading: ['Id', t('labels.name'),t('placeholders.subject'), t('labels.actions')] }
  }

  const  tableActions = (
    <ListTableActions
      searchText={t('labels.search_email_campaigns')}
      searchTerm={searchTerm}
      sortOptionSelected={sortOptionSelected}
      getData={handlerForSearchingAndSortingChange}
      translateText={t}
      sortOptions={emailTemplateSorting}
    />
  )

  const actions = (
    <Button
      color="primary"
      variant="contained"
      sx={actionBtn}
      onClick={() => history.push('/admin/email-campaigns/new')}>
      {t('labels.create_email_campaign')}
    </Button>
  )

  const tableContent = emailCampaignTableContent()

  return (
    <ListingPage
      title={t('labels.email_campaigns')}
      actions={actions}
      table={
        <ListTable
          tableHeadings={tableContent.heading}
          tableContent={tableContent.body}
          tableActions={tableActions}
          count={count}
          page_no={page}
          rowsPerPage={perPage}
          onPageChange={(event, pageNo) => handlerForSearchingAndSortingChange('page', pageNo + 1)}
          handleChangeRowsPerPage={(event) => handlerForSearchingAndSortingChange('pageSize', event.target.value)}
          rowsControl={true} />
      }
    />
  )
}
  
export default EmailCampaignContainer
