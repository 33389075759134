import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Home from '../components/Home'
import HomeRoute from './Home'
import AuthContainer from '../containers/authContainer'
import PrivateRoute from './PrivateRoute'
import Admin from './Admin'

export default () => (
  <Switch>
    <HomeRoute exact path='/' component={Home} />
    <Route exact path='/store-auth/:access' component={AuthContainer} />
    <Route exact path='/store-auth/:access/:meta_store/:store_id' component={AuthContainer} />
    <PrivateRoute path='/admin' component={Admin} />
  </Switch>
)
