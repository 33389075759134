import React from 'react';
import PropTypes from 'prop-types';
import 'react-quill/dist/quill.snow.css';

const EmailTemplatePreview = ({ content }) => {
  return (
    <div
      className="email-template-preview ql-editor"
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
};

EmailTemplatePreview.propTypes = {
  content: PropTypes.string.isRequired,
};

export default EmailTemplatePreview;