import React from 'react'
import PropTypes from 'prop-types'
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined'

const styles = {
  imageContainer: {
    border: 'solid 0.06rem #ddd',
    borderRadius: '0.25rem',
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden'
  },
  image:{
    display: 'block',
    width: 'auto',
    height: 'auto'
  }
}

const ImageThumbnail = ({ image, width, height, size = '40x40' }) => {
  // check if image contains ?d= and if not, add it
  if (image && !image.includes('?d=')) {
    image = image + `?d=${size}`
  }
  return (
    <div style={{...styles.imageContainer, width: width ?? styles.imageContainer.width, height: height ?? styles.imageContainer.height }}>
      {image ? <img style={styles.image} src={image}/> : <ImageOutlinedIcon style={styles.image}/> }
    </div>
  )
}

ImageThumbnail.propTypes = {
  image: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  // validate size to be NxN
  size: function(props, propName, componentName) {
    if (props[propName]) {
      const size = props[propName].split('x')
      if (size.length !== 2 || !size[0] || !size[1]) {
        return new Error(`Invalid prop ${propName} supplied to ${componentName}. ${propName} must be in format 100x100.`)
      }
    }
    return null
  }
}

export default ImageThumbnail
