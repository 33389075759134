import React from 'react'
import PropTypes from 'prop-types'
import { Grid, TextField, Checkbox, FormControl, FormLabel, RadioGroup, Radio, FormControlLabel, Button, InputAdornment } from '@mui/material';
import { styled } from '@mui/system';
import Coupons from './coupons'
import EditPageUpperContainer from '../Shared/EditPageHeader';
import EditPageLowerContainer from '../Shared/EditPageLowerContainer';
import AuthStorage from '../../services/authStorage';
import IconTextField from '../Shared/IconTextField'
import useTranslation from '../../hooks/useTranslation';

const Auth = new AuthStorage()

const EditDiscount = props => {
  const { t } = useTranslation();
  const storeCurrency = Auth.getStore() ? Auth.getStore().defaultCurrency : { name: 'Swedish Krone', symbol: 'kr', isoCode: 'sek' }
  const actionType = props.discount.id ? t('labels.discount.edit') : t('labels.discount.new')

  const leftFirstContent = () => (
    <Grid item xs={12} md={12} lg={12} xl={12} style={{marginTop: '1%'}}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={props.discount.multiple_coupons ? 12 : 6}>
          <TextField
            id="discountName"
            name="name"
            label={t('labels.discount.name')}
            value={props.discount.name}
            placeholder={t('labels.discount.name')}
            onChange={props.onChange}
            variant='outlined'
            fullWidth />
        </Grid>
        {!props.discount.multiple_coupons && (
          <Grid item xs={12} sm={6}>
            <TextField
              id="defautCoupon"
              name="defautCoupon"
              label={t('labels.discount.coupon_code')}
              value={props.discount.id ? props.defaultCoupon?.code || '' : props.discount.name.toLowerCase().replaceAll(' ', '')}
              placeholder={t('labels.discount.coupon_code')}
              onChange={props.defaultCouponOnChange}
              disabled={props.discount.id ? false : true}
              variant='outlined'
              fullWidth />
          </Grid>
        )}
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <TextField
            id="discountDescription"
            label={t('labels.discount.description')}
            name="description"
            value={props.discount.description}
            placeholder={t('labels.discount.description')}
            onChange={props.onChange}
            variant='outlined'
            multiline
            fullWidth />
        </Grid>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <Checkbox name="single_use" onChange={props.onChange} value={!!props.discount.single_use} checked={!!props.discount.single_use} />
          {t('labels.discount.single_use')}
        </Grid>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <TextField
            id="discountExpiresAt"
            type="date"
            label={t('labels.discount.expires_at')}
            name="expires_at"
            value={props.discount.expires_at}
            InputLabelProps={{ shrink: true }}
            onChange={props.onChange}
            variant='outlined'
            fullWidth />
        </Grid>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <FormControl>
            <FormLabel id="type-radio-buttons-group-label">{t('labels.discount.types')}</FormLabel>
            <RadioGroup
              aria-labelledby="type-radio-buttons-group-label"
              value={props.discount.type}
              onChange={props.onChange}
              name="type" >
              <FormControlLabel value={global.constants.discount_type_values.percentage} control={<Radio />} label={t('labels.discount.percentage')} />
              <FormControlLabel value={global.constants.discount_type_values.fixed_amount} control={<Radio />} label={t('labels.discount.fixed_amount')} />
              <FormControlLabel value={global.constants.discount_type_values.free_delivery} control={<Radio />} label={t('labels.discount.free_shipping')} />
            </RadioGroup>
          </FormControl>
        </Grid>
        { props.discount.type !== global.constants.discount_type_values.free_delivery && (
          <Grid item xs={12} md={12} lg={12} xl={12}>
            <IconTextField 
              id="discountValue"
              name='value'
              label={t('labels.discount.value')}
              value={props.discount.value}
              onChange={props.onChange}
              fullWidth={true}
              max={props.discount.type === global.constants.discount_type_values.percentage ? 100 : undefined}
              icon={props.discount.type === global.constants.discount_type_values.percentage ? '%' : storeCurrency.symbol} />
          </Grid>
        ) }
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox checked={props.discount.limit_no_of_items} onChange={props.onChange} name="limit_no_of_items" />}
              label={t('labels.discount.limit_number_of_items')} />
          </Grid>
          {props.discount.limit_no_of_items && (
            <Grid item xs={12}>
              <TextField
                id="discountValue"
                type="number"
                name='no_of_items'
                label={t('labels.discount.no_of_items')}
                value={props.discount.no_of_items}
                onChange={props.onChange}
                variant='outlined'
                helperText={t('labels.discount.no_of_items_helptext')}
                fullWidth />
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox checked={props.discount.active} onChange={props.onChange} name="active" />}
            label={t('labels.discount.active')} />
        </Grid>
        <Grid item xs={12} sx={{paddingTop: '0px !important'}}>
          <FormControlLabel
            control={<Checkbox checked={props.discount.multiple_coupons} onChange={props.onChange} name="multiple_coupons" />}
            label={t('labels.discount.multiple_coupons')} />
        </Grid>
      </Grid>
    </Grid>
  )

  const rightFirstContent = () => (
    <Grid item xs={12}>
      <Coupons discountId={props.discount.id} />
    </Grid>
  )

  const rightContent = actionType === t('labels.discount.edit') ? [rightFirstContent] : []

  return (
    <Grid container spacing={3}>
      <EditPageUpperContainer actionType={actionType} page={t('dependentSource.discount')} onSave={props.onSave} />
      <EditPageLowerContainer
        leftContent={ [leftFirstContent] }
        rightContent={props.discount.multiple_coupons ? rightContent : []}
        leftWidth={6}
        rightWidth={6}
        actionType={actionType}
        onSave={props.onSave}
      />
    </Grid>
  )
}

EditDiscount.propTypes = {
  discount: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  validationErrors: PropTypes.object,
  defaultCoupon: PropTypes.object
}

export default EditDiscount