import { HttpClient } from './httpClient' 
import { API_URL } from './RestAPI'
import apiHeaders from './apiHeaders'

//Setting the products URI
const USER_GROUP_API = `${API_URL}/user-groups`;

// Create
const createUserGroup = userGroup => {
  return HttpClient.post(
    `${USER_GROUP_API}/create`,
    userGroup,
    apiHeaders()
  )
}

// Index
const getUserGroups = (page, perPage) => {
  return HttpClient.get(
    `${USER_GROUP_API}?page=${page}&per_page=${perPage}`,
    apiHeaders()
  )
}

// Show
const getUserGroup = (id) => {
  return HttpClient.get(
    `${USER_GROUP_API}/${id}`,
    apiHeaders()
  )
}

// Update
const updateUserGroup = userGroup => {
  return HttpClient.put(
    `${USER_GROUP_API}/update`,
    userGroup,
    apiHeaders()
  )
}

// Delete
const deleteUserGroup = id => {
  return HttpClient.delete(
    `${USER_GROUP_API}/${id}`,
    apiHeaders()
  )
}

// Add and Remove Permission
const addRemovePermission = groupPermission => {
  return HttpClient.post(
    `${USER_GROUP_API}/add-remove-permission`,
    groupPermission,
    apiHeaders()
  )
}

// Allow and Restrict Collection
const allowRestrictCollection = groupCollection => {
  return HttpClient.post(
    `${USER_GROUP_API}/allow-restrict-collection`,
    groupCollection,
    apiHeaders()
  )
}

const UserGroupApi = { getUserGroups, createUserGroup, getUserGroup, updateUserGroup, deleteUserGroup, addRemovePermission, allowRestrictCollection }

export { UserGroupApi }
