import React, { useRef, useState } from "react";
import { Button } from '@mui/material'
import { CSVLink } from 'react-csv'

import ListingPage from "../components/Shared/ListingPage";
import { ListTable } from "../components/Shared/ListingTable";
import ListTableActions from '../components/Shared/ListingTable/listTableActions'
import ProductInventory from "../components/Product/productInventory";
import { ProductApi } from "../api/productApi";
import { getSerialNumber } from "../utils/sharedFunctions";
import { productVariantCSVHeaders } from "../config/csvHeaders";
import { productVariantSorting } from "../config/sortingLabels";
import useListingActions from '../hooks/useListingActions'
import { actionBtn } from '../styles'
import useTranslation from '../hooks/useTranslation'

const ProductInventoryContainer = () => {
  const [dataForExport, setDataForExport] = useState([])
  const csvButtonRef = useRef(null)

  const {
    data,
    count,
    page,
    perPage,
    searchTerm,
    sortOptionSelected,
    handlerForSearchingAndSortingChange,
  } = useListingActions('product_inventory', ProductApi.getProductVariants );
  const { t } = useTranslation()

  let getProductVariants = async (query='',sort_by='Name ascending' ) => {
    const products = await ProductApi.getProductVariants(
      { page: page, per_page: perPage, q: query, sort_by: sort_by, export_records: true })
    return products.data?.data
  }

  const exportCSV = async () => {
    const dataToExport = await getProductVariants(searchTerm, sortOptionSelected )
    dataToExport.map(productVariant => {
      productVariant.product_inventories = productVariant.product_inventories?.map((inventory)=> `Stock updated with ${inventory.quantity} at ${new Date(inventory.createdAt).toLocaleString('en-US')}\n`).join('')
      productVariant.product = productVariant.product?.name
      productVariant.createdAt = new Date(productVariant.createdAt).toLocaleString('en-US')
    })
    if (dataToExport.length > 0){
      setDataForExport(dataToExport)
      csvButtonRef.current.link.click()
    }
  }

  const tableContent = productTableContent(data, page, perPage)

  const tableActions = (
    <ListTableActions
      searchTerm={searchTerm}
      searchText={t('labels.searchText')}
      sortOptionSelected={sortOptionSelected}
      getData={handlerForSearchingAndSortingChange}
      translateText={t}
      sortOptions={productVariantSorting}
    />
  )
  const buttons = (
    <>
      <CSVLink
        data={dataForExport}
        headers={productVariantCSVHeaders}
        filename={`inventory-${(new Date()).toLocaleDateString().replace(', ', '-')}.csv`}
        className='hidden'
        ref={csvButtonRef}
        target='_blank'/>
      <Button variant="outlined" sx={actionBtn}
              onClick={exportCSV}>{t('labels.exportToCsv')}</Button>
    </>
  )

  return (
    <ListingPage
      title={t('labels.inventoryTitle')}
      actions={buttons}
      table={
        <ListTable
          tableHeadings={tableContent.productInventoryHeading}
          tableContent={tableContent.productInventoryBody}
          tableActions={tableActions}
          count={count}
          page_no={page}
          rowsPerPage={perPage}
          onPageChange={(event, pageNo) => handlerForSearchingAndSortingChange('page', pageNo + 1)}
          handleChangeRowsPerPage={(event) => handlerForSearchingAndSortingChange('pageSize', event.target.value)}
          rowsControl={true} />
      } />
  )
}

const productTableContent = (productVariants=[], page_no, per_page) => {
  const { t } = useTranslation()
  const productInventoryBody = productVariants.map((productVariant, index) => (
    <ProductInventory key={index} productVariant={productVariant} index={getSerialNumber(page_no, per_page, index)} />
  ))
  return {
    productInventoryBody: productInventoryBody,
    productInventoryHeading: [
      t('labels.productInventory.id'),
      "",
      t('labels.productInventory.name'),
      t('labels.productInventory.sku'),
      t('labels.productInventory.incoming'),
      t('labels.productInventory.available'),
      t('labels.productInventory.waitlist'),
      ""
    ]
  }
}

export default ProductInventoryContainer;