import React from 'react'
import { Switch, Route } from 'react-router-dom'
import PageContainer from '../containers/pageContainer'
import EditPageContainer from '../containers/editPageContainer'

export default ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}`} component={PageContainer} />
    <Route path={`${match.path}/new`} component={EditPageContainer} />
    <Route path={`${match.path}/:id/edit`} component={EditPageContainer} />
  </Switch>
)
