import { HttpClient } from './httpClient'
import { API_URL } from './RestAPI'
import apiHeaders from './apiHeaders'

const ORDER_FULFILLMENT_API = `${API_URL}/fulfillment-center-orders`

const getOrder = id => {
  return HttpClient.get(
    `${ORDER_FULFILLMENT_API}/${id}`,
    apiHeaders()
  )
}

const OrderFulfillmentCenterApi = {
  getOrder
}

export { OrderFulfillmentCenterApi }