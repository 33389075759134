import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import ImageThumbRect from './ImageThumbRect'
import 'react-quill/dist/quill.snow.css';

const SplashImagePreview = ({ splashImage }) => {

  const sizeStyles = {
    small: { height: '200px' },
    medium: { height: '400px' },
    large: { height: '100%' }
  }

  const style = {
    previewContainer: {
      position: 'relative',
      height: '600px',
      border: '2px solid #000',
      overflow: 'hidden'
    },
    previewImage: {
      height: sizeStyles[splashImage.size || 'large'].height,
      width: '100%'
    },
    previewTextContainer: {
      position: 'absolute',
      top: '22%',
      left: '7%',
      maxWidth: { xs: '100%', md: '80%' },
      display: 'flex',
      flexDirection: 'column'
    },
    simplePreviewButton: {
      color: `${splashImage.text_color}`,
      background: 'none',
      borderColor: `${splashImage.text_color}`,
      height: '40px'
    },
    previewButtonWhite: {
      backgroundColor: 'white !important',
      color: 'black !important'
    },
    previewButtonBlack: {
      background: 'black !important',
      color: 'white !important'
    },
    paper: {
      padding: '20px'
    },
  }

  const [blackButton, setBlackButton] = useState(false)
  const [whiteButton, setWhiteButton] = useState(false)

  const handleButtonHover = () => {
    splashImage.text_color === 'white' ? setWhiteButton(true) : setBlackButton(true)
  }

  const removeEffect = () => {
    setBlackButton(false)
    setWhiteButton(false)
  }

  const buttonAlignmentStyle = {
    left: { alignItems: 'flex-start' },
    center: { alignItems: 'center' },
    right: { alignItems: 'flex-end' },
  }[splashImage.button_position || 'left']

  return (
    <div style={style.previewContainer}>
      {(splashImage.image_preview_url || splashImage.image) &&
        <ImageThumbRect styles={style.previewImage} image={ splashImage.image_preview_url ? splashImage.image_preview_url : splashImage.image} />
      }
      <Box sx={{ ...style.previewTextContainer, ...buttonAlignmentStyle }}>
        <div style={{color: `${splashImage.text_color}`}} dangerouslySetInnerHTML={{ __html: splashImage.headline_text }} />
        {splashImage.button_text &&
          <button
            style={(whiteButton || blackButton) ? (whiteButton ? {...style.simplePreviewButton, ...style.previewButtonWhite} : {...style.simplePreviewButton, ...style.previewButtonBlack}) : style.simplePreviewButton} 
            onMouseEnter={handleButtonHover} 
            onClick={(e) => e.preventDefault()}
            onMouseLeave={removeEffect}>
            {splashImage.button_text}
          </button>
        }
      </Box>
    </div>
  )
}

SplashImagePreview.propTypes = {
  splashImage: PropTypes.object.isRequired
}

export default SplashImagePreview