import AuthStorage from '../../services/authStorage';

const Auth = new AuthStorage();

const combinations = (args) => {
  let result = [], max = args.length-1;
  function helper(arr, i) {
    for (let j=0, l=args[i]?.length; j<l; j++) {
      let arrClone = arr.slice(0);
      arrClone.push(args[i][j]);
      if (i==max)
          result.push(arrClone);
      else
          helper(arrClone, i+1);
    }
  }
  helper([], 0);
  return result;
}

const processProductDetails = (attributes) => {
  let productDetails = [];
  let storeId = Auth.getStore().id;
  attributes.forEach((attr) => {
    let productDetail = {};
    productDetail.store_id = storeId;
    productDetail.variant_attribute = { name: attr.variant_attribute, store_id: storeId, variant_option: { option: attr.option_name, position: attr.position, store_id: storeId } };
    productDetails.push(productDetail);
  });
  return productDetails;
}

const createVariantOptionsCombination = (variantOptions) =>{
  let optionCombinations = [];
  let shouldExcludeDefault = variantOptions.length > 1 && !variantOptions.some(vOption => vOption.variantOption === '');
  variantOptions?.filter((vOption) => !vOption?._delete && vOption.variantOption !== '' && (vOption.variantOption !== 'Default' || !shouldExcludeDefault)).forEach((option) => {
    let attributes = []
    option.variantAttributeValues.filter((vAttribute) => !vAttribute?._delete).forEach((attribute) => {
      if (attribute.attributeName !== '') attributes.push({ 'option_name': option.variantOption, 'position': option.position, 'variant_attribute': attribute.attributeName });
    });
    optionCombinations.push(attributes);
  });
  return optionCombinations;
}

const processVariantOptionsAndAttributes = (variants) => {
  let options = [];
  let variantAttribute = {};
  let option = {};
  for (let i = 0; i < variants.length; i++) {
    variants[i].product_details?.forEach((productDetail) => {
      variantAttribute = productDetail.variant_attribute
      option = options.find(opt => opt?.variantOption === variantAttribute?.variant_option?.optio || opt?.variantOption === variantAttribute?.variant_option?.option);
      let optionExists;
      if (option) optionExists = true;
      if (variantAttribute) {
        if (!option) {
          option = { id: variantAttribute?.variant_option?.id, position: (variantAttribute?.variant_option?.position || variantAttribute?.variant_option?.posit), variantOption: (variantAttribute?.variant_option?.optio || variantAttribute?.variant_option?.option), variantAttributeValues: [] };
        }
        let attributeExists = option.variantAttributeValues.filter((attr) => attr.attributeName === variantAttribute?.name);
        if (attributeExists.length === 0) option.variantAttributeValues.push({ id: variantAttribute?.id , attributeName: variantAttribute?.name });
        // Sort variant attributes based on ids
        option.variantAttributeValues = option.variantAttributeValues.sort((c,b) => c.id > b.id ? 1 : -1)
        if (!optionExists) options.push(option);
      }
    });
  }
  return options;
}

const processVariantsCombinations = (variants, variantOptions, handleAddNewVariant) => {
  let optionAttributes = createVariantOptionsCombination(variantOptions);
  const variantAttributeCombinations = combinations(optionAttributes);
  let _variants = [...variants];
  let variantsSize = Object.keys(_variants).length;
  let variantsToKeep = [];
  variantAttributeCombinations.forEach((attributes, index) => {
    let variantName = '';
    attributes.forEach((attribute, index) => variantName += (index === attributes.length - 1 ? attribute.variant_attribute : `${attribute.variant_attribute}/`));
    let variantsInitialIndex = 0;
    let splittedVariantName = variantName.split('/').filter(n => n);
    // Loop through each variant to find persisting and new
    if (variantsSize > 0) {
      while (variantsInitialIndex < variantsSize) {
        let splittedPersistedVariant = _variants[variantsInitialIndex].name.split('/').filter(n => n);
        let firstSplitCase = equateCollections(splittedVariantName, splittedPersistedVariant);
        let secondSplitCase = equateCollections(splittedPersistedVariant, splittedVariantName);
        if (!_variants[variantsInitialIndex]._delete && (firstSplitCase || secondSplitCase)) {
          if (!(firstSplitCase && secondSplitCase)) _variants[variantsInitialIndex].name = variantName;
          _variants[variantsInitialIndex].product_details = processProductDetails(attributes);
          variantsToKeep.push(_variants[variantsInitialIndex]);
        } else {
          let existingVariants = _variants.filter((variant) => equateCollections(variant.name.split('/').filter(n => n), splittedVariantName));
          if (existingVariants.length === 0) {
            variantsToKeep.push(handleAddNewVariant({ name: variantName, product_details: processProductDetails(attributes), price: _variants[index]?.price || _variants[variantsInitialIndex]?.price || 0 }));
            break;
          }
        }
        variantsInitialIndex++;
      }
    } else {
      variantsToKeep.push(handleAddNewVariant({ name: variantName, product_details: processProductDetails(attributes) }));
    }
  });
  return variantsToKeep
}

const equateCollections = (toArray, withArray) => {
  let toHash = prepareArrayToHash(toArray);
  let withHash = prepareArrayToHash(withArray);

  for(let ele in toArray){
    if (!withHash[toArray[ele]] || toHash[toArray[ele]] !== withHash[toArray[ele]]) {
      return false;
    } 
  }
  return true;
}

const prepareArrayToHash = (requiredArray) => {
  let newHash = {}
  for(let withEle in requiredArray){
    if (!newHash[requiredArray[withEle]]) newHash[requiredArray[withEle]] = 0;
    newHash[requiredArray[withEle]] += 1;
  }
  return newHash
}

export { combinations, processProductDetails, createVariantOptionsCombination, processVariantOptionsAndAttributes, processVariantsCombinations, equateCollections }
