import React, { useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { CSVLink } from "react-csv";
import { Button } from '@mui/material';
import { setSuccessNotification } from '../actions/notificationAction'
import Category from '../components/Category/category';
import ListingPage from '../components/Shared/ListingPage';
import { ListTable } from '../components/Shared/ListingTable';
import { getSerialNumber } from '../utils/sharedFunctions'
import ListTableActions from "../components/Shared/ListingTable/listTableActions";
import { CategoryApi } from "../api/categoryApi";
import { categoryCSVHeaders } from "../config/csvHeaders";
import { categorySorting } from "../config/sortingLabels";
import useListingActions from '../hooks/useListingActions'
import { actionBtn } from '../styles'
import useTranslation from '../hooks/useTranslation'

const CategoryContainer = (props) => {
  const { actions, notification } = props
  const [dataForExport, setDataForExport] = useState([])
  const csvButtonRef = useRef(null)
  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const {
    data,
    count,
    page,
    perPage,
    searchTerm,
    setData,
    sortOptionSelected,
    handlerForSearchingAndSortingChange,
  } = useListingActions('categories', CategoryApi.getCategories );

  let getCategories = async (query='', sort_by='Name ascending') => {
    const response = await CategoryApi.getCategories({ page: page, per_page: perPage, q: query, sort_by: sort_by, export_records: true })
    return response.data.data
  }

  const newCategory = () => {
    history.push('/admin/categories/new')
  }

  // Delete
  const deleteCategory = async (category) => {
    await CategoryApi.removeCategory(category)
    setData(data.filter(cat => category.id !== cat.id))
    dispatch(setSuccessNotification({ message: t('response_messages.category_deleted_successfully')}))
  }

  const tableContent = categoryTableContent(deleteCategory, data, page, perPage)

  const exportCSV = async () => {
    const dataToExport = await getCategories(searchTerm, sortOptionSelected )
    if (dataToExport.length > 0){
      setDataForExport(dataToExport)
      csvButtonRef.current.link.click()
    }
  }

  const tableActions = (
    <ListTableActions
      searchTerm={searchTerm}
      searchText={t('labels.search_categories')}
      sortOptionSelected={sortOptionSelected}
      sortOptions={categorySorting}
      translateText={t}
      getData={handlerForSearchingAndSortingChange}
    />
  )

  const buttons = (
    <>
      <CSVLink
        data={dataForExport}
        headers={categoryCSVHeaders}
        filename={`categories-${(new Date()).toLocaleDateString().replace(', ', '-')}.csv`}
        className='hidden'
        ref={csvButtonRef}
        target='_blank'/>
      <Button variant="outlined" sx={actionBtn} onClick={exportCSV}>{t('labels.exportToCsv')}</Button>
      <Button
        color="primary"
        sx={actionBtn}
        variant="contained"
        onClick={newCategory}>
        Create Category
      </Button>
    </>

  )

  return (
    <ListingPage
      title={t('labels.menu.categories')}
      actions={buttons}
      table={(<ListTable
        tableHeadings={tableContent.tableHeading}
        tableContent={tableContent.tableBody}
        tableActions={tableActions}
        count={count}
        page_no={page}
        onPageChange={(event, pageNo) => handlerForSearchingAndSortingChange('page', pageNo + 1)}
        handleChangeRowsPerPage={(event) => handlerForSearchingAndSortingChange('pageSize', event.target.value)}
        rowsPerPage={perPage}
        rowsControl={true} />
      )} />
  )
}

const categoryTableContent = (deleteCategory, categories=[], page_no, per_page) => {
  const { t } = useTranslation()
  const categoryBody = categories.map((category, i) => (
    <Category key={category.id} {...category} deleteCategory={() => deleteCategory(category)} index={getSerialNumber(page_no, per_page, i)}/>
  ));
  return { tableBody: categoryBody, tableHeading: [t('labels.id') , '',t('labels.name') ,t('labels.visible_on_homepage'), t('labels.public'), t('labels.action')] }
}

export default CategoryContainer
