import { HttpClient } from './httpClient'
import {API_URL} from './RestAPI'

//Setting the authentication URI
const AUTH_API = `${API_URL}/auth/login`
const USER_LOGIN_API = `${API_URL}/auth/user-login`
const METASTORE_LOGIN_API = `${API_URL}/auth/meta-store-login`

//Login with access key from metaStore
const loginMetaStore = (authData) => {
  return HttpClient.post(METASTORE_LOGIN_API, authData)
}

//Login with access key
const login = (authData) => {
  return HttpClient.post(AUTH_API, authData)
}
//Login with user credentials
const userLogin = (authData) => {
  return HttpClient.post(USER_LOGIN_API, authData)
}

//Encapsulating in a JSON object

const AuthApi = { login, userLogin, loginMetaStore }

export { AuthApi }
