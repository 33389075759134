import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import CloseIcon from '@mui/icons-material/Close'
import { Grid, Paper, Divider, Button, IconButton } from '@mui/material'
import SharedModal from '../Shared/modal'
import { WaitlistApi } from '../../api/waitlistApi'
import { TableContainer, Table, TableHead, TableBody, TableCell, TableRow } from '@mui/material'
import { setSuccessNotification, setErrorNotification } from '../../actions/notificationAction'
import { useDispatch } from 'react-redux'

const styles = {
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    width: { xs: '95%', sm: '70%', md: '60%', lg: '45%' },
    boxShadow: 24
  },
  headerContainer: { paddingLeft: '2%', paddingRight: '1%' }
}

const WaitlistByProductVariant = ({ variantId, allowBackorders, closeModal }) => {
  const [waitlist, setWaitlist] = useState([])
  const [sendingEmails, setSendingEmails] = useState(false)
  const dispatch = useDispatch()

  const fetchWaitlistByProductVariant = async (variantId) => {
    const response = await WaitlistApi.getWaitlistByProductVariant(variantId)
    setWaitlist(response.data)
  }

  const sendWaitlistEmail = async () => {
    try {
      setSendingEmails(true)
      await WaitlistApi.sendWaitlistEmail(variantId)
      fetchWaitlistByProductVariant(variantId)
      setSendingEmails(false)
      dispatch(setSuccessNotification({message: 'Emails sent successfully'}))
    }
    catch(error) {
      dispatch(setErrorNotification({message: error.response.data}))
    }
  }

  useEffect(() => {
    if(variantId) {
      fetchWaitlistByProductVariant(variantId)
    }
  }, [variantId])

  return (
    <SharedModal onCloseModal={closeModal}>
      <Paper sx={styles.modal}>
        <Grid container>
          <Grid container alignItems="center" sx={styles.headerContainer}>
            <Grid item xs={11} sx={styles.paddingLeft}>
              <h3>Waitlist</h3>
            </Grid>
            <Grid item xs={1} sx={styles.paddingRight}>
              <IconButton sx={{ float: 'right' }} onClick={closeModal}><CloseIcon /></IconButton>
            </Grid>
          </Grid>
          <Divider width='100%' />
          <Grid container>
            <TableContainer >
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow sx={{backgroundColor: '#F9F9F9'}}>
                    <TableCell><b>Email</b></TableCell>
                    <TableCell><b>Date</b></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {waitlist.map(waiting => (
                    <TableRow key={waiting.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell>{waiting.user_email}</TableCell>
                      <TableCell>{(new Date(waiting.createdAt)).toLocaleDateString('sv')}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Divider width="100%" />
          <Grid container spacing={1}justifyContent="flex-end" sx={{margin: '15px'}}>
            {allowBackorders && waitlist.length > 0 && (
              <Grid item>
                <Button variant="contained" color="primary" onClick={sendWaitlistEmail}>{sendingEmails ? 'Sending emails...' : 'Send emails' }</Button>
              </Grid>
            )}
            <Grid item>
              <Button variant="outlined" color="primary" onClick={closeModal}>Close</Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </SharedModal>
  )
}

WaitlistByProductVariant.propTypes = {
  variantId: PropTypes.number.isRequired,
  allowBackorders: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
}

export default WaitlistByProductVariant
