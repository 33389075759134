import React from 'react'
import { Switch, Route } from 'react-router-dom'
import UserContainer from '../containers/userContainer'
import EditUserContainer from '../containers/editUserContainer'

export default ({ match }) => (
  <Switch>
    <Route path={`${match.path}`} component={EditUserContainer} />
  </Switch>
)
