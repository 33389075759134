import React from "react";
import { Grid, TextField, IconButton, List, ListItem, Divider, InputAdornment } from '@mui/material';
import LinkIcon from '@mui/icons-material/InsertLink'
import CloseIcon from '@mui/icons-material/Close';
import BackIcon from '@mui/icons-material/ArrowBackIos';
import CategoryIcon from '@mui/icons-material/Category'
import PageIcon from '@mui/icons-material/MenuBook'
import ProductIcon from '@mui/icons-material/Ballot'
import ImageThumbnail from "./Shared/ImageThumbnail";
import CollectionsIcon from '@mui/icons-material/Collections';

const SiteLinks = (props) => {
  const classes = {
    urlTextField: {
      padding: '2.5%',
      marginLeft: (props.selectedModuleOption?.module_name !== '' ? 20 : ''),
      height: props.type === 'splashImage' && '31px'
    },
    urlTextFieldDiv: {
      position: 'relative',
      display: 'inline-block',
      width: '100%'
    },
    listDiv: {
      position: 'absolute',
      zIndex: 3,
      backgroundColor: 'white',
      border: "1px solid lightgray",
      borderTop: "none",
      borderBottomRightRadius: 3,
      borderBottomLeftRadius: 3,
      width: '100%',
      maxHeight: '400px',
      overflow: 'auto'
    },
    moduleIcon: {
      color: 'black'
    },
    dropdownSearch: {
      width: '100%',
      padding: '2.5%'
    },
    externalLink: {
      position: 'absolute',
      right: 10,
      top: props.type === 'splashImage' ? '31%' : 7
    }
  };

  const moduleIcons = {
    'Category': (<CategoryIcon sx={classes.moduleIcon}/>),
    'Product': (<ProductIcon sx={classes.moduleIcon}/>),
    'Page': (<PageIcon sx={classes.moduleIcon}/>),
    'Collection': (<CollectionsIcon sx={classes.moduleIcon}/>)
  }

  const dropdownOptions = (props.search == '' ? props.currentUrlOptions : props.filteredOptions)

  return (
    <>
      <Grid container item={true} xs={12} md={12} lg={12} xl={12} sx={classes.urlTextFieldDiv} >
        <TextField
          name="url"
          label="Url"
          value={(props.selectedModuleOption && props.selectedModuleOption['label'] !== 'undefined') ? props.selectedModuleOption['label'] : ''}
          url={props.selectedModuleOption ? props.selectedModuleOption['value'] : ''}
          placeholder="Url"
          onFocus={() => props.setShowOptions(true)}
          onChange={props.urlOnChange}
          variant='outlined'
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {(props.selectedModuleOption?.module_name !== '' && props.selectedModuleOption?.value != '') && moduleIcons[props.selectedModuleOption?.module_name]}
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="start">
                { props.selectedModuleOption?.module_name === '' && (<LinkIcon sx={classes.moduleIcon}/>)}
              </InputAdornment>
            ),
          }}
          fullWidth />
        {props.showOptions &&
          <List className="list-unstyled" sx={classes.listDiv} style={{cursor: 'pointer'}}>
            {props.nestedMenu && 
              <IconButton onClick={props.listBackButtonOnClick} style={{float: 'left'}} size='medium'><BackIcon /></IconButton>
            }
            <IconButton size="medium" style={{float: 'right'}} onClick={() => ( props.setShowOptions(false) )}><CloseIcon /></IconButton>
            <ListItem>
              <TextField
                value={props.search}
                label="Search"
                placeholder="Search"
                onChange={props.searchOnChange}
                variant='outlined'
                size="small"
                fullWidth />
            </ListItem>
            {dropdownOptions.map((urlOption) => (
              <React.Fragment key={urlOption.value}>
                <Divider/>
                <ListItem
                  onClick={props.handleOnListClicked}
                  data-value={urlOption.value} >
                    {!props.nestedMenu ? (
                      <span>{urlOption.image}</span>
                    ) : (props.selectedModule != 'Page' && <ImageThumbnail sx={{marginRight: '2%'}} image={urlOption.image} />)}
                    &nbsp;
                    {urlOption.label}
                </ListItem>
              </React.Fragment>
              )
            )}
          </List>
        }
      </Grid> 
    </>
  )
}

export default SiteLinks;
