import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Grid, TextField, Checkbox, TableBody, Table, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Autocomplete } from '@mui/material';
import { styled } from '@mui/system';
import { tableCellClasses } from '@mui/material/TableCell';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

const UserGroupDetail = ({ userGroup, existingUsers, permissions, onAddRemoveUserToUserGroup, selectedUser, appliedPermissions, setSelectedUser, onPermissionChange, permissionLabels, existingCollections, onUpdateCollectionAccess, selectedCollection, setSelectedCollection }) => { 

  const styles = {
    container: {
      marginRight: 100,
      paddingTop: 30,
      paddingBottom: 40
    },
    subContainer: {
      marginRight: 100,
    },
    removeBtn: {
      outline: "none !important"
    },
    permissionContainer: {
      paddingBottom: 5,
      marginLeft: 20
    },
    subModule: {
      paddingTop: 30
    },
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontWeight: 'bold',
      fontSize: 14,
      backgroundColor: theme.palette.action.hover
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({theme}) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }));

  const formatCollectionsList = (collections) => {
    if (collections.length === 0) return collections;

    // Extract existing collections
    let existingCollectionsIds = [];
    if (userGroup?.collections) {
      existingCollectionsIds = userGroup.collections.map((collection) => collection.id);
    }
    let collectionInfo = [];
    collections.forEach((collection) => { 
      if (!existingCollectionsIds.includes(collection.id)){
        collectionInfo.push({ label: collection.name, id: collection.id });
      }
    });
    return collectionInfo;
  }

  const formatUsersList = (usersList) => {
    if (usersList.length === 0) return usersList;
    
    // Extract existing users
    let existingUsersIds = [];
    if (userGroup?.users) {
      existingUsersIds = userGroup.users.map((user) => {
        return user.id;
      });
    }

    let userInfo = [];
    usersList.forEach(user => {
      // Filtering existing users
      if (!existingUsersIds.includes(user.id)){
        userInfo.push({ label: `${user.name} - ${user.email}`, id: user.id })
      }
    });
    return userInfo;
  }

  const onUserChange = (event, option) => {
    if (option?.id) onAddRemoveUserToUserGroup(option.id, userGroup.id);
  }

  const onRemoveUserClicked = (userId) => {
    if (userId) onAddRemoveUserToUserGroup(userId, null);
  }

  const onRemoveCollectionClicked = (collectionId) => {
    if (collectionId) onUpdateCollectionAccess(collectionId, userGroup.id, global.constants.actions.remove);
  }

  const onCollectionSelected = (event, option) => {
    if (option?.id) onUpdateCollectionAccess(option.id, userGroup.id);
  }

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12} md={7}>
          <Paper elevation={3} sx={{padding: '20px'}}>
            <div>
            <h3>Group Users</h3>
            <div style={styles.container}>
              <Autocomplete
                disablePortal={true}
                inputValue={selectedUser}
                onInputChange={(event, newValue, reason) => reason === 'reset' ? setSelectedUser('') : setSelectedUser(newValue)
                }
                onChange={onUserChange}
                options={formatUsersList(existingUsers)}
                getOptionLabel={(option) => option.label ? option.label : ''}
                renderInput={(params) => <TextField {...params} label="Add Users" variant="outlined" /> }
              />
            </div>
            <div style={styles.subContainer}>
              { userGroup?.users && userGroup.users.length > 0 ? 
                <TableContainer component={Paper}>
                <Table aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell sx={styles.styledTableCell}>#</StyledTableCell>
                      <StyledTableCell sx={styles.styledTableCell}>Name</StyledTableCell>
                      <StyledTableCell sx={styles.styledTableCell}>Email</StyledTableCell>
                      <StyledTableCell sx={styles.styledTableCell}></StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userGroup.users.map((user) => {
                      return(
                        <StyledTableRow key={user.id}>
                          <StyledTableCell sx={styles.styledTableCell}><Link to={`/admin/users/edit/${user.id}`}>{user.id}</Link></StyledTableCell>
                          <StyledTableCell sx={styles.styledTableCell}>{user.name}</StyledTableCell>
                          <StyledTableCell sx={styles.styledTableCell}>{user.email}</StyledTableCell>
                          <StyledTableCell sx={styles.styledTableCell} align="right"><IconButton sx={styles.removeBtn} variant="contained" color="error" size="small" onClick={() => onRemoveUserClicked(user.id)}><RemoveCircleIcon/></IconButton></StyledTableCell>
                        </StyledTableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer> : <div className='row'><div className="col-12 text-center"><b>No Users in the group!</b></div></div>}
            </div>
            </div>
          </Paper>
          <Paper elevation={3} sx={{padding: '20px', marginTop: '20px'}}>
            <div sx={styles.subModule}>
            <h3>Restricted Collections</h3>
            <div style={styles.container}>
              <Autocomplete
                disablePortal={true}
                inputValue={selectedCollection}
                onInputChange={(event, newValue, reason) => reason === 'reset' ? setSelectedCollection('') : setSelectedCollection(newValue)
                }
                onChange={onCollectionSelected}
                options={formatCollectionsList(existingCollections)}
                getOptionLabel={(option) => option.label ? option.label : ''}
                getOptionSelected={(option) => option.id}
                renderInput={(params) => <TextField {...params} label="Restrict Collections" variant="outlined" /> }
              />
            </div>
            <div style={styles.subContainer}>
              { userGroup?.collections && userGroup.collections.length > 0 ? 
                <TableContainer component={Paper}>
                <Table aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell sx={styles.styledTableCell}>#</StyledTableCell>
                      <StyledTableCell sx={styles.styledTableCell}>Name</StyledTableCell>
                      <StyledTableCell sx={styles.styledTableCell}></StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userGroup.collections.map((collection) => {
                      return(
                        <StyledTableRow key={collection.id}>
                          <StyledTableCell sx={styles.styledTableCell}><Link to={`/admin/collections/${collection.id}`}>{collection.id}</Link></StyledTableCell>
                          <StyledTableCell sx={styles.styledTableCell}>{collection.name}</StyledTableCell>
                          <StyledTableCell sx={styles.styledTableCell} align="right"><IconButton sx={styles.removeBtn} variant="contained" color="error" size="small" onClick={() => onRemoveCollectionClicked(collection.id)}><RemoveCircleIcon /></IconButton></StyledTableCell>
                        </StyledTableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer> : <div className='row'><div className="col-12 text-center"><b>No Collections restricted by the group!</b></div></div>}
            </div>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={5}>
          <Paper elevation={3} sx={{padding: '20px'}}>
            <h3>User Group Permissions</h3>
            <div>
              {
                Object.keys(permissions).map(subject_class => { 
                  let subjectPermissions = permissions[subject_class];
                  return(
                    <div style={styles.permissionContainer} key={subject_class}>
                      <h4>{subject_class}</h4>
                      <div className='row'>
                        { subjectPermissions.map((permission) => {
                          return (<div className={`col-md-6`} key={permission.id}><Checkbox onChange={onPermissionChange} value={permission.id} checked={appliedPermissions.includes(permission.id) ? true : false} />{permissionLabels[permission.action]}</div>)
                          })
                        }
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

UserGroupDetail.propTypes = {
  userGroup: PropTypes.object.isRequired,
  existingUsers: PropTypes.array.isRequired,
  permissions: PropTypes.object.isRequired,
  onAddRemoveUserToUserGroup: PropTypes.func.isRequired,
  appliedPermissions: PropTypes.array.isRequired,
  selectedUser: PropTypes.string.isRequired,
  setSelectedUser: PropTypes.func.isRequired,
  onPermissionChange: PropTypes.func.isRequired,
  permissionLabels: PropTypes.object.isRequired,
  existingCollections: PropTypes.array.isRequired,
  onUpdateCollectionAccess: PropTypes.func.isRequired,
  selectedCollection: PropTypes.string.isRequired,
  setSelectedCollection: PropTypes.func.isRequired
}

export default UserGroupDetail;
