import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, TextField, MenuItem, Button } from '@mui/material'
import useTranslation from '../../hooks/useTranslation'

const CreditAccount = props => {
  const [error, setError] = useState('')
  const { t } = useTranslation()

  const handleSave = (e) => {
    if (!props.transaction.value) {
      setError(t('error_messages.empty_amount'))
      return
    }
    setError('')
    props.onSave(e)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={2}>
        <TextField
          required
          select
          fullWidth
          label="Type"
          name="type"
          value={props.transaction.type}
          placeholder="select"
          onChange={props.onChange}
          size="small" >
          <MenuItem value=""></MenuItem>
          <MenuItem value="credit">Credit</MenuItem>
          <MenuItem value="debit">Debit</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={12} sm={2}>
        <TextField
          label="Amount"
          required
          name="value"
          value={props.transaction.value || ''}
          placeholder="1000"
          onChange={props.onChange}
          fullWidth
          size="small"
          error={!!error}
          helperText={error} />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          label="Note"
          name="note"
          size="small"
          value={props.transaction.note || ''}
          placeholder=""
          onChange={props.onChange}
          fullWidth />
      </Grid>
      <Grid item xs={12} sm={5}>
        <Button variant="contained" color="primary" disabled={props.saving} onClick={handleSave}>Submit</Button>
      </Grid>
    </Grid>
  )
}

CreditAccount.propTypes = {
  transaction: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired
}

export default CreditAccount
