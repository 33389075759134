import React from 'react'
import InboxIcon from '@mui/icons-material/Inbox'
import DashboardIcon from '@mui/icons-material/Dashboard'
import SettingsIcon from '@mui/icons-material/Settings'
import CategoryIcon from '@mui/icons-material/Category'
import DiscountIcon from '@mui/icons-material/Discount'
import AutoStoriesIcon from '@mui/icons-material/AutoStories'
import UserIcon from '@mui/icons-material/People'
import EmailIcon from '@mui/icons-material/EmailRounded'
import useTranslation from '../../hooks/useTranslation'
import routeMapping from '../../config/constants'

const useMenuConfig = () => {
  const { t } = useTranslation()

  const menuConfig = [
    {
      icon: <DashboardIcon />,
      title: t('labels.menu.dashboard'),
      to: '/admin',
      extraLinks: ['/admin/dashboard'],
      items: []
    },
    {
      icon: <InboxIcon />,
      title: t('labels.menu.orders'),
      to: '/admin/orders',
      active: false,
      ability: {
        action: routeMapping.actions.list, 
        controller: routeMapping.controllers.Orders
      }
    },
    {
      icon: <CategoryIcon />,
      title: t('labels.menu.products'),
      open: false,
      items: [
        {
          title: t('labels.menu.products'),
          to: '/admin/products',
          extraLinks: ['/admin/products/'],
          excludeLinks: ['/admin/products/inventory'],
          active: false,
          ability: {
            action: routeMapping.actions.list, 
            controller: routeMapping.controllers.Products
          }
        },
        {
          title: t('labels.menu.inventory'),
          to: '/admin/inventory',
          extraLinks: ['/admin/inventory/'],
          active: false,
          ability: {
            action: routeMapping.actions.list,
            controller: routeMapping.controllers.Inventory
          },
        },
        {
          title: t('labels.menu.categories'),
          to: '/admin/categories',
          extraLinks: ['/admin/categories/'],
          active: false,
          ability: {
            action: routeMapping.actions.list, 
            controller: routeMapping.controllers.Categories
          },
        },
        {
          title: t('labels.menu.collections'),
          to: '/admin/collections',
          extraLinks: ['/admin/collections/'],
          active: false,
          ability: {
            action: routeMapping.actions.list, 
            controller: routeMapping.controllers.Collections
          },
        }
      ]
    },
    {
      icon: <SettingsIcon />,
      title: t('labels.menu.settings'),
      open: false,
      items: [
        {
          title: t('labels.menu.store_settings'),
          to: '/admin/store',
          ability: {
            action: routeMapping.actions.retrieve, 
            controller: routeMapping.controllers.Stores
          },
          active: false
        },
        {
          title: t('labels.menu.homepage'),
          to: '/admin/frontpage',
          // ability: {
          //   action: routeMapping.actions.retrieve, 
          //   controller: routeMapping.controllers.FrontPage
          // },
          active: false
        },
        {
          title: t('labels.menu.menus'),
          to: '/admin/menus',
          extraLinks: ['/admin/menus/'],
          ability: {
            action: routeMapping.actions.list,
            controller: routeMapping.controllers.Menus
          },
          active: false
        }
      ]
    },
    {
      icon: <DiscountIcon />,
      title: t('labels.menu.discounts'),
      to: '/admin/discounts',
      ability: {
        action: routeMapping.actions.list, 
        controller: routeMapping.controllers.Discounts
      },
    },
    {
      icon: <UserIcon />,
      title: t('labels.menu.users'),
      open: false,
      items: [
        {
          title: t('labels.menu.users'),
          to: '/admin/users',
          extraLinks: ['/admin/users/'],
          active: false,
          ability: {
            action: routeMapping.actions.list,
            controller: routeMapping.controllers.Users
          }
        },
        {
          title: t('labels.menu.user_groups'),
          to: '/admin/userGroups',
          extraLinks: ['/admin/userGroups/'],
          ability: {
            action: routeMapping.actions.list, 
            controller: routeMapping.controllers.Discounts
          },
        }
      ]
    },
    {
      icon: <AutoStoriesIcon />,
      title: t('labels.menu.pages'),
      to: '/admin/pages',
      ability: {
        action: routeMapping.actions.list, 
        controller: routeMapping.controllers.Discounts
      },
    },
    {
      icon: <EmailIcon />,
      title: t('labels.menu.email_menu'),
      open: false,
      items: [
        {
          title: t('labels.menu.email_template'),
          to: '/admin/email-templates',
          active: false,
          ability: {
            action: routeMapping.actions.list, 
            controller: routeMapping.controllers.EmailTemplates
          }
        },
        {
          title: t('labels.menu.email_campaigns'),
          to: '/admin/email-campaigns',
          active: false,
          ability: {
            action: routeMapping.actions.list, 
            controller: routeMapping.controllers.EmailTemplates
          }
        }
      ]
    }
  ]

  return menuConfig
}

export default useMenuConfig