import React, { useState, useRef, useEffect } from 'react'
import { OrderApi } from '../api/orderApi'
import ListingPage from '../components/Shared/ListingPage'
import { ListTable } from '../components/Shared/ListingTable'
import Order from '../components/Order/order'
import AuthStorage from '../services/authStorage'
import { CSVLink } from 'react-csv'
import { Grid, Button, Autocomplete, TextField } from '@mui/material'
import ListTableActions from '../components/Shared/ListingTable/listTableActions'
import { orderSorting } from '../config/sortingLabels'
import useListingActions from '../hooks/useListingActions'
import { useHistory } from 'react-router-dom'
import useTranslation from '../hooks/useTranslation'

const styles = {
  actionBtn: {
    textTransform: 'none',
    marginTop: '2%',
    float: 'right'
  },
  autocomplete: {
    width: 150,
  }
}

const OrderContainer = (props) => {
  const [ordersForExport, setOrdersForExport] = useState([])
  const [statusFilter, setStatusFilter] = useState(() => {
    const savedStatusFilter = localStorage.getItem('statusFilter')
    return savedStatusFilter ? JSON.parse(savedStatusFilter) : null
  })
  const [paymentStatusFilter, setPaymentStatusFilter] = useState(() => {
    const savedPaymentFilter = localStorage.getItem('paymentStatusFilter')
    return savedPaymentFilter ? JSON.parse(savedPaymentFilter) : null
  })

  const { t } = useTranslation()
  const csvLink = useRef()
  const history = useHistory()
  const [statusOptions] = useState([
    { label: 'All', value: '' },
    { label: 'Processing', value: 'processing' },
    { label: 'Ready', value: 'ready' },
    { label: 'Shipped', value: 'shipped' },
    { label: 'Complete', value: 'complete' },
    { label: 'Cancelled', value: 'cancelled' },
  ])
  const [paymentStatusOptions] = useState([
    { label: 'All', value: '' },
    { label: 'Paid', value: 'paid' },
    { label: 'Unpaid', value: 'unpaid' },
  ])
  const handleExtraParameters = () => {
    const params = {}
    if (statusFilter?.value) params.status = statusFilter.value
    if (paymentStatusFilter?.value) params.payment_status = paymentStatusFilter.value
    return params
  }
  const {
    data,
    count,
    page,
    perPage,
    searchTerm,
    sortOptionSelected,
    handlerForSearchingAndSortingChange,
    fetchData,
  } = useListingActions('orders', OrderApi.getOrders, 'latest', handleExtraParameters);
  
  useEffect(() => {
    localStorage.setItem('statusFilter', JSON.stringify(statusFilter))
    localStorage.setItem('paymentStatusFilter', JSON.stringify(paymentStatusFilter))
    fetchData({
      page: 1,
      per_page: perPage,
      q: searchTerm,
      sort_by: sortOptionSelected,
      ...handleExtraParameters(),
    });
  }, [statusFilter, paymentStatusFilter]);

  const fetchOrdersForExport = async () => {
    const result = await OrderApi.getOrdersForExport({ q: searchTerm, sort_by: sortOptionSelected })
    const exportData = result.data.flatMap(order => {
      return [
        formatOrderRow(order),
        ...formatOrderItemsRows(order.order_items, order.id)
      ];
    });
    setOrdersForExport(exportData)
    csvLink.current.link.click()
  }
  
  const formatOrderRow = (order) => ({
    order_id: order.id,
    customer_name: order.user?.name,
    customer_email: order.user?.email,
    customer_contact: order.user?.phone,
    shipping_name: order.shipping_name,
    shipping_city: order.shipping_city,
    shipping_country: order.shipping_country,
    net_total: order.net_total,
    freight: order.freight,
    vat: order.vat,
    discount: order.discount,
    balance_used: order.balance_used,
    payment_status: order.payment_status,
    status: order.status,
    createdAt: (new Date(order.createdAt)).toLocaleDateString('sv'),
    product_name: '',
    product_price: '',
    variant_name: '',
    variant_price: '',
    variant_sku: '',
    quantity: '',
  })
  
  const formatOrderItemsRows = (orderItems, orderId) => {
    return orderItems.map(item => ({
      order_id: orderId,
      customer_name: '',
      customer_email: '',
      customer_contact: '',
      shipping_name: '',
      shipping_city: '',
      shipping_country: '',
      net_total: '',
      freight: '',
      vat: '',
      discount: '',
      balance_used: '',
      payment_status: '',
      status: '',
      createdAt: '',
      product_name: item.product.name,
      product_price: item.product.price,
      variant_name: item.variant?.name || 'No Variant',
      variant_price:  item?.variant?.price || '',
      variant_sku: item?.variant?.sku || '',
      quantity: item.quantity,
    }))
  }
  
  const newOrder = () => {
    history.push('/admin/orders/new')
  }

  const tableActions = (
    <ListTableActions
      searchTerm={searchTerm}
      searchText={t('labels.search_orders')}
      sortOptionSelected={sortOptionSelected}
      translateText={t}
      getData={handlerForSearchingAndSortingChange}
      sortOptions={orderSorting}
    />
  )

  const Auth = new AuthStorage()
  const storeCurrency = Auth.getStore() ? Auth.getStore().defaultCurrency : { name: 'Swedish Krone', symbol: 'kr', isoCode: 'sek' }

  const tableHeading = [
    t('labels.order'),
    t('labels.date'),
    t('labels.customer'),
    t('labels.city'),
    t('labels.country'),
    t('labels.total'),
    t('labels.payment'),
    '',
    ''
  ];

  const tableContent = data.map((order, index) => (
    <Order key={order.id} {...order} storeCurrency={storeCurrency} />
  ));

  const actionButtons = (
    <Grid container spacing={2} direction="column">
      <Grid container justifyContent="flex-end" spacing={1}>
        <Grid item>
          <Button variant="outlined" style={styles.actionBtn} onClick={fetchOrdersForExport}>
            {t('labels.exportToCsv')}
          </Button>
          <CSVLink
            data={ordersForExport}
            filename={`orders-${(new Date()).toLocaleDateString().replace(', ', '-')}.csv`}
            className="hidden"
            ref={csvLink}
            target="_blank"
          />
        </Grid>
        <Grid item>
          <Button variant="contained" sx={styles.actionBtn} onClick={newOrder}>
            {t('labels.create_order')}
          </Button>
        </Grid>
      </Grid>
  
      <Grid container spacing={1} justifyContent="flex-end" sx={{ marginTop: 2 }}>
        <Grid item>
          <Autocomplete
            disablePortal
            id="order-status"
            size="small"
            sx={styles.autocomplete}
            options={statusOptions}
            getOptionLabel={(option) => option.label}
            value={statusFilter}
            onChange={(event, newValue) => setStatusFilter(newValue)}
            renderInput={(params) => <TextField {...params} label="Order Status" size="small" />}
          />
        </Grid>
        <Grid item>
          <Autocomplete
            disablePortal
            id="payment-status"
            size="small"
            sx={styles.autocomplete}
            options={paymentStatusOptions}
            getOptionLabel={(option) => option.label}
            value={paymentStatusFilter}
            onChange={(event, newValue) => setPaymentStatusFilter(newValue)}
            renderInput={(params) => <TextField {...params} label="Payment Status" size="small" />}
          />
        </Grid>
      </Grid>
    </Grid>
  )  

  return (
    <ListingPage
      title={t('labels.orders')}
      actions={actionButtons} 
      table={(
        <ListTable
          tableHeadings={tableHeading}
          tableContent={tableContent}
          tableActions={tableActions}
          count={count}
          page_no={page}
          rowsControl={true}
          onPageChange={(event, pageNo) => handlerForSearchingAndSortingChange('page', pageNo + 1)}
          handleChangeRowsPerPage={(event) => handlerForSearchingAndSortingChange('pageSize', event.target.value)}
          rowsPerPage={perPage || t('perPage.orders')} />
      )} />
  )
}

export default OrderContainer