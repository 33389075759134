import { HttpClient } from './httpClient' 
import { API_URL } from './RestAPI'
import apiHeaders from './apiHeaders'

//Setting the products URI
const PERMISSION_API = `${API_URL}/permissions`;

// Index
const getPermissions = (dependent = {}) => {
  return HttpClient.get(
    PERMISSION_API,
    apiHeaders(dependent)
  )
};

const PermissionApi = { getPermissions }

export { PermissionApi }
