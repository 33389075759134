import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { TableCell, TableRow } from '@mui/material';

import ListingThumbnail from '../Shared/ListingThumbnail';

const Category = props => {
  return (
    <TableRow key={props.id}>
      <TableCell component="th" scope="row" width={'50px'}><Link to={`/admin/categories/edit/${props.id}`}>{props.index}</Link></TableCell>
      <ListingThumbnail image={props.image_thumbnail}/>
      <TableCell><Link to={`/admin/categories/edit/${props.id}`}>{props.name}</Link></TableCell>
      <TableCell>{props.show_on_homepage && '✔'}</TableCell>
      <TableCell>{props.visible && '✔' }</TableCell>
      <TableCell>
        <Link to={`/admin/categories/edit/${props.id}`}>
          <IconButton color="primary" size="small"><EditIcon /></IconButton>
        </Link>
        <IconButton color="error" size="small" onClick={() => { if (window.confirm('Are you sure?')) props.deleteCategory() } }><DeleteIcon /></IconButton>
      </TableCell>
    </TableRow>
  )
}

Category.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  image_thumbnail: PropTypes.string,
  information: PropTypes.string,
  show_on_homepage: PropTypes.bool,
  visible: PropTypes.bool,
  deleteCategory: PropTypes.func.isRequired
}

export default Category
