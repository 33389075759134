import React from "react";
import { Button } from "@mui/material";
import { useHistory } from "react-router-dom";
import useTranslation from '../../hooks/useTranslation'

const BackButton = () => {

  const history = useHistory()
  const { t } = useTranslation()

  return (
    <Button
      type="submit"
      color="primary"
      variant="outlined"
      onClick={history.goBack}>
      {t('labels.back')}
    </Button>
  )

}

export default BackButton