import React from 'react'
import { FormGroup, FormControl, InputLabel, Select, MenuItem, FormHelperText, Grid } from '@material-ui/core'
import useTranslation from '../../hooks/useTranslation'

const ColorDropdown = ({ text_color, onChange, options }) => {
  const { t } = useTranslation()
  return (
    <Grid item md={12} xs={4}>
      <FormGroup style={{marginTop: '20px'}}>
        <FormControl variant="outlined">
          <InputLabel id="select-text-color-label">{t('labels.text_color')}</InputLabel>
          <Select
            labelId="select-text-color-label"
            name='text_color'
            value={text_color || ''}
            label="Text Color"
            onChange={onChange}
            style={{minWidth: '100%'}}
          >
            {options.map(option => (
              <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormHelperText style={{ marginBottom: '5px' }}>Color will be used for Title and description text</FormHelperText>
      </FormGroup>
    </Grid>
  );
}

export default ColorDropdown