import * as userActions from '../actions/userActions'
import initialState from './initialState'

const userProfile = (state = initialState.user, action) => {
  switch (action.type) {
    case userActions.GET_CURRENT_USER:
      return {
        ...state,
        loading: true
      }
    case userActions.GET_USER_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false
      }
    case userActions.UPDATE_USER:
      return {
        ...state,
        updating: true
      }
    case userActions.UPDATE_USER_SUCCESS:
      return {
        ...state,
        ...action.payload,
        updating: false
      }
    default:
      return state
  }
}

export default userProfile
