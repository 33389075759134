import React from 'react'
import { Switch, Route } from 'react-router-dom'
import DiscountContainer from '../containers/discountContainer'
import EditDiscountContainer from '../containers/editDiscountContainer'

export default ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}`} component={DiscountContainer} />
    <Route path={`${match.path}/new`} component={EditDiscountContainer} />
    <Route path={`${match.path}/edit/:id`} component={EditDiscountContainer} />
  </Switch>
)
