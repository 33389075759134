import React from 'react'
import PropTypes from 'prop-types'
import { Chip } from '@mui/material'
import _ from 'lodash'

const StatusChip = ({ status }) => {
  const statusColor = (status) => {
    if(status === 'shipped' || status === 'complete' || status === 'paid' || status === 'debit'){
      return 'success'
    }
    else if(status === 'cancelled' || status === 'unpaid'){
      return 'error'
    }
    else if(status === 'credit'){
      return 'warning'
    }
    else{
      return 'info'
    }
  }

  return <Chip variant='outlined' color={statusColor(status.toLowerCase())} label={_.upperFirst(status)} />
}

StatusChip.propTypes = {
  status: PropTypes.string.isRequired,
}
  
export default StatusChip