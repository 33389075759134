import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from "react-router-dom"
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { UserApi } from '../api/userApi';
import * as notificationActions from "../actions/notificationAction"; 
import Grid from '@mui/material/Grid';
import Invite from '../components/User/invite'
import UsersListing from '../components/User/usersListing'
import { getUserPreference } from "../utils/localStorageHelper/localStorageMethods";
import { handleChangeRowsPerPage } from "../utils/sharedFunctions";
import useTranslation from '../hooks/useTranslation'

const UserContainer = (props) => {
  const productsPerPage = getUserPreference( 'page_size', 'users')
  const [tabCounts, setTabCounts] = useState({})
  const [inviteUsers, setInviteUsers] = useState(false)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState( productsPerPage !== '' ? productsPerPage : global.constants.perPage.default)
  const history = useHistory();
  const [usersForExport, setUsersForExport] = useState([])
  const csvLink = useRef()
  const { t } = useTranslation()

  const colsToRemove = [
    'shipping_name', 'shipping_address_one','shipping_address_two',
    'shipping_postcode', 'shipping_country', 'invoice_name',
    'invoice_address_two', 'invoice_address_one', 'invoice_postcode',
    'invoice_city', 'invoice_country', 'size', 'shipping_city'
  ]
  const [currentTab, setCurrentTab] = useState('active')
  const [usersByTab, setUsersByTab] = useState({
    active: undefined,
    removed: undefined,
    unapproved: undefined,
    invited: undefined
  })
  const [tableActionsState, setTableActionsState] = useState({search: '', sort: 'latest'})
  const commonFetchUsersParams = {
    page: page,
    per_page: perPage,
    q: tableActionsState.searchTerm,
    sort_by: tableActionsState.sortOptionSelected,
    tab: currentTab,
  };

  const fetchUsersForExport = async () => {
    const result = await UserApi.getUsersForExport({q: tableActionsState.searchTerm, sort_by: tableActionsState.sortOptionSelected})
    setUsersForExport(result.data.map(user => {
      let copy = {...user}
      colsToRemove.forEach(col => (delete copy[col]))
      return copy
    }))
    csvLink.current.link.click()
  }

  const fetchUsers = async (params) => {
    const result = await UserApi.getUsers(params)
    setTabCounts(result.data.counts)
    let _usersByTab = {...usersByTab}
    _usersByTab[params["tab"]] = result.data.data
    setUsersByTab(_usersByTab)
  }

  useEffect(() => {
    fetchUsers({ page: 1, per_page: perPage, q: tableActionsState.searchTerm, sort_by: tableActionsState.sortOptionSelected, tab: currentTab })
  }, [])

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue)
    setPage(1)
    if(!usersByTab[newValue]) {
      fetchUsers({ page: 1, per_page: perPage, q: tableActionsState.searchTerm, sort_by: tableActionsState.sortOptionSelected, tab: newValue })
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1)
    fetchUsers({ page: newPage + 1, per_page: perPage, q: tableActionsState.searchTerm, sort_by: tableActionsState.sortOptionSelected, tab: currentTab })
  }

  useEffect(() => {
    fetchUsers(commonFetchUsersParams)
  }, [page, perPage])

  // new
  const handleCreateUser = () => {
    history.push('/admin/users/new')
  }

  // Delete
  const deleteUser = async (user) => {
    try {
      const res = await UserApi.removeUser(user)
      if(res.status === 204) {
        let _usersByTab = {...usersByTab}
        let _tabCounts = {...tabCounts}
        if(tabCounts[currentTab] <= perPage) {
          _tabCounts[currentTab] -= 1
          _usersByTab[currentTab] = usersByTab[currentTab].filter(i => i.id !== user.id)
          setTabCounts(_tabCounts)
          setUsersByTab(_usersByTab)
        }
        else {
          await fetchUsers(commonFetchUsersParams)
        }
        if(usersByTab[currentTab]?.length === 1 && page > 0) {
          setPage(page - 1)
        }
        props.notifyActions.setSuccessNotification({ message: t('response_messages.user_deleted_successfully') })
      }
      else {
        props.notifyActions.setErrorNotification({ message: t('response_messages.user_deleted_failed') })
      }
    } catch (error) {
      if(error.response?.data?.errors && error.response.data.errors[0].error === 'SequelizeDatabaseError') {
        props.notifyActions.setErrorNotification({ message: error.response.data.errors[0].message, duration: 6000 })
      }
      else {
        props.notifyActions.setErrorNotification({ message: t('response_messages.user_deleted_failed'), notificationType: 'error' })
      }
    }
  }

  const deleteInvited = id => {
    UserApi.deleteInvite({id})
      .then(res =>{
        if(res.status===200) {
          if(tabCounts.invited <= perPage) {
            let _usersByTab = {...usersByTab}
            _usersByTab.invited = usersByTab.invited.filter(i => i.id !== id)
            setUsersByTab(_usersByTab)
          }
          else if(currentTab === 'invited'){
            fetchUsers(commonFetchUsersParams)
          }
          setTabCounts({...tabCounts, invited: tabCounts.invited - 1 })
          props.notifyActions.setSuccessNotification({ message: res.data.message })
        } else {
          props.notifyActions.setErrorNotification({ message: res.data.message })
        }
      }).catch(err => {
        props.notifyActions.setErrorNotification({ message: 'Something went wrong while deleting the invite. Please try again later!' })
      })
  }
  
  // Approve User
  const approveUser = async (userId) => {
    const approved_user = await UserApi.updateUser(userId, { status: global.constants.labels.active.toLowerCase() });
    if (approved_user) {
      let _usersByTab = {...usersByTab}
      let _tabCounts = {...tabCounts}
       _tabCounts.active = tabCounts.active + 1
      if(_usersByTab.active) {
        if(tabCounts.active <= perPage) {
          _usersByTab.active.push(approved_user.data)
        }
      }
      _tabCounts.unapproved = tabCounts.unapproved - 1
      if(_usersByTab.unapproved) {
        if(tabCounts.unapproved <= perPage) {
          _usersByTab.unapproved = _usersByTab.unapproved.filter(user => (user.id !== userId))
        }
        else {
          _usersByTab.unapproved = undefined
          await fetchUsers(commonFetchUsersParams)
        }
      }
      setUsersByTab(_usersByTab)
      setTabCounts(_tabCounts)
      props.notifyActions.setSuccessNotification({ message: t('response_messages.user_successfully_approved') })
    }
  }

  const handleInviteUser = () => {
    setInviteUsers(true)
  }

  const handleTableFiltering =  (type, value, params) => {
    setTableActionsState({...tableActionsState, [type]: value})
    setPage(1)
    fetchUsers({...params, page: 1, per_page: perPage })
  }

  return (
    <Grid container>
      <UsersListing
        currentTab={currentTab}
        tabCounts={tabCounts}
        page={page}
        perPage={perPage}
        usersByTab={usersByTab}
        fetchUsersForExport={fetchUsersForExport}
        usersForExport={usersForExport}
        csvLink={csvLink}
        tableActionsState={tableActionsState}
        handleTableFiltering={handleTableFiltering}
        handleInviteUser={handleInviteUser}
        handleCreateUser={handleCreateUser}
        handleTabChange={handleTabChange}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={(event) => handleChangeRowsPerPage(event, setPage, setPerPage, 'page_size', 'users')}
        deleteInvited={deleteInvited}
        approveUser={approveUser}
        deleteUser={deleteUser} />
      <Invite
        open={inviteUsers}
        usersByTab={usersByTab}
        setUsersByTab={setUsersByTab}
        tabCounts={tabCounts}
        setTabCounts={setTabCounts}
        perPage={perPage}
        onCloseItemModal={() => setInviteUsers(false)} />
    </Grid>
  )
}

UserContainer.propTypes = {  
  users: PropTypes.object.isRequired
}

const mapStateToProps = (state, ownProps) => {
  return {
    users: state.users
  }
}

const mapDispatchToProps = dispatch => {
  return {
    notifyActions: bindActionCreators(notificationActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserContainer)
