import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@mui/material";
import ListingPage from "../components/Shared/ListingPage";
import EmailTemplate from "../components/EmailTemplate/emailTemplate";
import { ListTable } from "../components/Shared/ListingTable";
import { getSerialNumber } from '../utils/sharedFunctions'
import ListTableActions from "../components/Shared/ListingTable/listTableActions";
import { EmailTemplateApi } from "../api/emailTemplatesApi";
import {emailTemplateSorting} from "../config/sortingLabels";
import useListingActions from '../hooks/useListingActions'
import { actionBtn } from '../styles'
import useTranslation from '../hooks/useTranslation'

const EmailTemplateContainer = () => {
  const history = useHistory();

  const {
    data,
    count,
    page,
    perPage,
    searchTerm,
    setData,
    sortOptionSelected,
    handlerForSearchingAndSortingChange
  } = useListingActions('emailTemplates', EmailTemplateApi.getEmailTemplates );
  const { t } = useTranslation()

  const deleteSingleEmailTemplate = async (emailTemplate) => {
    await EmailTemplateApi.removeEmailTemplate(emailTemplate)
    setData(data.filter(col => col.id !== emailTemplate.id))
  }

  const emailTemplateTableContent = () => {
    const emailTemplateBody = data?.map((emailTemplate, index) => (
      <EmailTemplate key={emailTemplate.id} {...emailTemplate} deleteEmailTemplate={ () => deleteSingleEmailTemplate(emailTemplate)}
        index={getSerialNumber(page, perPage, index)}
      />
    ));
    return { body: emailTemplateBody, heading: ['Id', t('labels.name'), t('placeholders.subject'), t('placeholders.type'), t('labels.system_generated'), t('labels.actions')] }
  }

  const handleCreateTemplate = async () => {
    const defaultTemplateData = {
      name: '',
      subject: '',
      body: '',
      type: 'Custom',
      system_email: false
    };
    try {
      const response = await EmailTemplateApi.createEmailTemplate(defaultTemplateData);
      history.push(`/admin/email-templates/edit/${response.data.id}`);
    } catch (error) {
      console.error('Error creating new email template:', error);
    }
  };

  const  tableActions = (
    <ListTableActions
      searchText={t('labels.search_email_templates')}
      searchTerm={searchTerm}
      sortOptionSelected={sortOptionSelected}
      getData={handlerForSearchingAndSortingChange}
      translateText={t}
      sortOptions={emailTemplateSorting}
    />
  )

  const actions = (
    <>
      <Button
        color="primary"
        variant="contained"
        sx={actionBtn}
        onClick={handleCreateTemplate}>
        {t('labels.create_email_template')}
      </Button>
    </>

  )

  const tableContent = emailTemplateTableContent()

  return (
    <ListingPage
      title={t('labels.email_templates')}
      actions={actions}
      table={
        <ListTable
          tableHeadings={tableContent.heading}
          tableContent={tableContent.body}
          tableActions={tableActions}
          count={count}
          page_no={page}
          rowsPerPage={perPage}
          onPageChange={(event, pageNo) => handlerForSearchingAndSortingChange('page', pageNo + 1)}
          handleChangeRowsPerPage={(event) => handlerForSearchingAndSortingChange('pageSize', event.target.value)}
          rowsControl={true} />
      }
    />
  )
}
  
export default EmailTemplateContainer
