import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';

import BackButton from '../Shared/BackButton';
import UserGroupDetail from './userGroupDetail';

const ShowUserGroup = ({ userGroup, existingUsers, permissions, onDeleteUserGroupClicked, onAddRemoveUserToUserGroup, selectedUser, appliedPermissions, setSelectedUser, onPermissionChange, permissionLabels, existingCollections, onUpdateCollectionAccess, selectedCollection, setSelectedCollection }) => (
  <div>
    <div>
      <h1>{`User Group - ${userGroup.name}`}
        <div style={{float: 'right'}}>
          <BackButton/>
          <Button href={`/admin/userGroups/${userGroup.id}/edit`} variant="contained" color="primary" sx={{marginLeft: '10px'}}>Edit</Button>
        </div>
      </h1><hr></hr>
    </div>
    <UserGroupDetail userGroup={userGroup} existingUsers={existingUsers} permissions={permissions} onAddRemoveUserToUserGroup={onAddRemoveUserToUserGroup} appliedPermissions={appliedPermissions} selectedUser={selectedUser} setSelectedUser={setSelectedUser} onPermissionChange={onPermissionChange} permissionLabels={permissionLabels} existingCollections={existingCollections} onUpdateCollectionAccess={onUpdateCollectionAccess} selectedCollection={selectedCollection} setSelectedCollection={setSelectedCollection} />
  </div>
)

ShowUserGroup.propTypes = {
  userGroup: PropTypes.object.isRequired,
  existingUsers: PropTypes.array.isRequired,
  permissions: PropTypes.object.isRequired,
  onDeleteUserGroupClicked: PropTypes.func.isRequired,
  onAddRemoveUserToUserGroup: PropTypes.func.isRequired,
  appliedPermissions: PropTypes.array.isRequired,
  selectedUser: PropTypes.string.isRequired,
  setSelectedUser: PropTypes.func.isRequired,
  onPermissionChange: PropTypes.func.isRequired,
  permissionLabels: PropTypes.object.isRequired,
  existingCollections: PropTypes.array.isRequired,
  onUpdateCollectionAccess: PropTypes.func.isRequired,
  selectedCollection: PropTypes.string.isRequired,
  setSelectedCollection: PropTypes.func.isRequired
}

export default ShowUserGroup
