import {HttpClient} from './httpClient' 
import {API_URL} from './RestAPI'
import apiHeaders from './apiHeaders'

//Setting the teams URI
const TEAM_API = `${API_URL}/teams`

// Create
const createTeam = team => {
  return HttpClient.post(
    `${TEAM_API}/create`,
    team,
    apiHeaders()
  )
}

// Read
const getTeams = (page) => {
  return HttpClient.get(
    `${TEAM_API}?page=${page}`,
    apiHeaders()
  )
}
// Read
const sendUserGift = () => {
  return HttpClient.get(
    `${API_URL}/users/send-gift`,
    apiHeaders()
  )
}

// Get
const getTeam = id => {
  return HttpClient.get(
    `${TEAM_API}/${id}`,
    apiHeaders()
  )
}

// Update
const updateTeam = (id, team) => {
  return HttpClient.put(
    `${TEAM_API}/${id}/update`,
    team,
    apiHeaders()
  )
}

// Delete
const removeTeam = team => {
  return HttpClient.delete(
    `${TEAM_API}/${team.id}`,
    apiHeaders()
  )
}

//Encapsulating in a JSON object
const TeamApi = {getTeams, createTeam, getTeam, updateTeam, removeTeam, sendUserGift}

export {TeamApi}
