import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import EditPage from '../components/Page/editPage';
import { setSuccessNotification } from "../actions/notificationAction";
import useTranslation from '../hooks/useTranslation'
import { PageApi } from '../api/pageApi'

const emptyPage = () => ({
  id: 0, name: '', image: '', content: '',
  show_in_top_menu: false, show_in_side_menu: false, show_in_footer: false
});

const EditPageContainer = ({ match, history }) => {
  const [page, setPage] = useState(emptyPage);
  const [formErrors, setFormErrors] = useState({});
  const [formValid, setFormValid] = useState(false);
  const [saving, setSaving] = useState(false);

  const dispatch = useDispatch()
  const { t } = useTranslation();

  useEffect(async () => {
    if (match.params.id) {
      const { data } = await PageApi.getPage(match.params.id);
      setPage(data)
    }
  }, [match.params.id]);

  const updatePageState = event => {
    const field = event.target.name
    let pge = page
    let errors = formErrors
    if (errors.hasOwnProperty(field)) delete(errors[field])
    switch (event.target.type) {
      case 'checkbox':
        if(pge[field] === false) {
          pge[field] = true
        } else if(pge[field] === true) {
          pge[field] = false
        } else {
          pge[field] = false
        }
        break
      case 'file':
        pge[field] = event.target.files[0]
        break
      default:
        pge[field] = event.target.value
    }
    setPage({...pge})
    setFormErrors(errors)
  }

  const formIsValid = () => {
    if (page.name === '') {
      setFormErrors({ name: `can't be blank` })
      setFormValid(false)
      return false
    }
    return true
  }
  
  const resetValidationState = () => {
    setFormErrors({})
    setFormValid(true)
  }

  const savePage = async (event) => {
    event.preventDefault()
    resetValidationState()
    if (formIsValid()) {
      setSaving(true)
      let formData = new FormData()
      for (const [field, value] of Object.entries(page)) {
        if( value || (typeof value === 'boolean')) {
          formData.append(field, value)
        }
      }
      if(page.id > 0) {
        await PageApi.updatePage(page.id, formData)
        setSaving(false)
        dispatch(setSuccessNotification({ message: t('response_messages.pages.updated') }));
      } else {
        const { data } = await PageApi.createPage(formData)
        setSaving(false)
        dispatch(setSuccessNotification({ message: t('response_messages.pages.created') }));
        history.push({ pathname: `/admin/pages/${data.id}/edit`, state: { created: true }})
      }
    }
  }

  return (
    <div>
      <EditPage
        page={page}
        validationErrors={formErrors}
        onSave={savePage}
        onChange={updatePageState}
        onContentChange={content => setPage({ ...page, content: content })}/>
    </div>
  )
}

EditPageContainer.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object  
}

export default EditPageContainer
