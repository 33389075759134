import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import EditDiscount from '../components/Discount/editDiscount'
import { DiscountApi } from '../api/discountApi'
import { setCreated } from '../actions/discountActions'
import { setSuccessNotification, setErrorNotification } from '../actions/notificationAction'
import useTranslation from '../hooks/useTranslation'

const EditDiscountContainer = () => {
  const { created } = useSelector(state => state.discounts);
  const { t } = useTranslation()

  const [discount, setDiscount] = useState({
    id: 0,
    name: '',
    description: '',
    image: '',
    single_use: true,
    expires_at: new Date().toISOString().slice(0, 10),
    prefix: '',
    value: '',
    is_percentage: false,
    type: 'percentage',
    active: false,
    limit_no_of_items: true,
    multiple_coupons: false,
    no_of_items: 0
  })

  const [formErrors, setFormErrors] = useState({})
  const [formValid, setFormValid] = useState(false)
  const [defaultCoupon, setDefaultCoupon] = useState({})
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams()

  const url = window.location.pathname.split('/').pop();

  useEffect( async () => {
    const discountId = params.hasOwnProperty('id') ? + params.id : 0
    if( discountId > 0 ) {
      const response = await DiscountApi.getDiscount(discountId)
      const defaultCouponResponse = await DiscountApi.getDefaultCoupon(discountId)
      setDiscount({...discount, ...response.data})
      setDefaultCoupon(defaultCouponResponse.data)
    }
    if (created){
      dispatch(setCreated(false))
      dispatch(setSuccessNotification({ message: t('response_messages.discounts.created') }))
    }
  },[url])

  const updateDefaultCoupon = async (event) => {
    if(discount.id > 0) {
      const code = event.target.value.toLowerCase().replace(' ', '')
      if(Object.keys(defaultCoupon).length > 0) {
        setDefaultCoupon({...defaultCoupon, code: code})
      }
      else {
        try {
          const result = await DiscountApi.createCoupons(discount.id, { coupons_to_create: 1, prefix: code, default: true })
          setDefaultCoupon(result.data.data[0])
        }
        catch(error) {
          dispatch(setErrorNotification({ message: error.response.data }))
        }
      }
    }
  }

  const updateDiscountState = event => {
    const field = event.target.name
    const disc = {...discount}
    if (formErrors.hasOwnProperty(field)) delete(formErrors[field])
    switch (event.target.type) {
      case 'checkbox':
        if(disc[field] === false) {
          disc[field] = true
        } else if(disc[field] === true) {
          disc[field] = false
        } else {
          disc[field] = false
        }
        break
      case 'file':
        disc[field] = event.target.files[0]
        break
      default:
        disc[field] = event.target.value
    }
    setDiscount({...discount, ...disc})
  }

  const formIsValid = () => {
    let formValidation = true
    if (discount.name === '') {
      setFormErrors( { name: `can't be blank` })
      formValidation = false
    }
    return formValidation
  }
  
  const resetValidationState = () => {
    setFormErrors({});
    setFormValid(true)
  }

  const saveDiscount = async (event) => {
    event.preventDefault()
    resetValidationState()
    if (formIsValid()) {
      let formData = new FormData()
      for (const [field, value] of Object.entries(discount)) {
        formData.append(field, value)
      }
      if(defaultCoupon) {
        formData.append('default_coupon_code', defaultCoupon.code)
      }
      if(discount.id > 0) {
        const response = await DiscountApi.updateDiscount(discount.id, formData)
        if (response.status === 200){
          dispatch(setSuccessNotification({ message: t('response_messages.discounts.updated') }))
        }
      } else {
        const response = await DiscountApi.createDiscount(formData)
        if (response.status === 201){
          dispatch(setCreated(true))
          history.push(`/admin/discounts/edit/${response.data.id}`)
        }
      }
    } else {
      for (const [key, value] of Object.entries(formErrors)){
        dispatch(setErrorNotification({ message: `${key} ${value}` }))
      }
    }
  }

  return (
    <EditDiscount
      discount={discount}
      defaultCoupon={defaultCoupon}
      validationErrors={formErrors}
      onSave={saveDiscount}
      onChange={updateDiscountState}
      defaultCouponOnChange={updateDefaultCoupon} />
  )
}

export default EditDiscountContainer;
