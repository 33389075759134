import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { TableCell, TableRow } from '@mui/material';
import { indexColumn } from '../../styles'
import ListingThumbnail from '../Shared/ListingThumbnail';

const Collection = ({ id, name, image_thumbnail, show_on_homepage, visible, deleteCollection, index }) => {

  return (
    <TableRow key={id}>
      <TableCell component="th" scope="row" sx={indexColumn}><Link to={`/admin/collections/edit/${id}`}>{index}</Link></TableCell>
      <ListingThumbnail image={image_thumbnail}/>
      <TableCell component="th" scope="row"><Link to={`/admin/collections/${id}`}> {name}</Link></TableCell>
      <TableCell component="th" scope="row">{ show_on_homepage && '✔'}</TableCell>
      <TableCell component="th" scope="row">{ visible && '✔' }</TableCell>
      <TableCell align="left">
        <Link to={`/admin/collections/${id}`}>
          <IconButton color="primary" size="small"><VisibilityIcon /></IconButton>
        </Link>
        <Link to={`/admin/collections/edit/${id}`}>
          <IconButton color="primary" size="small"><EditIcon /></IconButton>
        </Link>
        <IconButton color="error" size="small" onClick={() => { if (window.confirm('Are you sure?')) deleteCollection() } }>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

Collection.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
  deleteCollection: PropTypes.func.isRequired
}

export default Collection
