import React from 'react'
import { Switch, Route } from 'react-router-dom'
import MenuContainer from '../containers/Menu/menuContainer'
import EditMenuContainer from '../containers/Menu/editMenuContainer'

export default ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}`} component={MenuContainer} />
    <Route path={`${match.path}/edit/:id`} component={EditMenuContainer} />
  </Switch>
)
